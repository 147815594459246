<template>
  <div>
    <merge-tags-dropdown
      v-if="showMergeTagsDropdown"
      @change="insertMergeTag($event)"
      :emailTemplateFilter="templateType"
      :language="language"
    ></merge-tags-dropdown>
    <textarea :value="$attrs.value" id="summernote" />
  </div>
</template>

<script>
import MergeTagsDropdown from "./MergeTagsDropdown.vue";
export default {
  name: "EmailTemplateEditor",
  components: { MergeTagsDropdown },
  props: {
    templateType: String,
    showMergeTagsDropdown: {
      type: Boolean,
      default: true,
    },
    customToolbar: {
      type: Array,
    },
    language: {
      type: String,
    },
  },
  data() {
    return {
      summernoteControl: null,
    };
  },
  methods: {
    focus() {
      this.summernoteControl.summernote("focus");
    },
    insertMergeTag(mergeTag) {
      this.summernoteControl.summernote("editor.restoreRange");
      this.summernoteControl.summernote("editor.focus");
      // If the parent is RevisionAgencyTimeline and can therefore replace placeholders, do that immediately to solve some issues with the range selection
      if (this.$parent.replacePlaceholdersHelper) {
        this.summernoteControl.summernote(
          "editor.insertText",
          this.$parent.replacePlaceholdersHelper(mergeTag.value)
        );
      } else {
        this.summernoteControl.summernote("editor.insertText", mergeTag.value);
      }
    },
    snChange(we, contents) {
      this.summernoteControl.summernote("editor.saveRange");
      this.$emit("input", contents);
    },
  },
  watch: {
    "$attrs.value": function (newVal) {
      let curVal = this.summernoteControl.summernote("code");
      if (newVal !== curVal) {
        this.summernoteControl.summernote("code", newVal);
      }
    },
  },
  mounted() {
    // Add summernote
    let summernoteCss = document.createElement("link");
    summernoteCss.setAttribute(
      "href",
      "/assets/vendor/summernote/summernote.css"
    );
    summernoteCss.setAttribute("rel", "stylesheet");
    document.head.appendChild(summernoteCss);

    let summernoteScript = document.createElement("script");
    summernoteScript.setAttribute(
      "src",
      "/assets/vendor/summernote/summernote.js"
    );
    document.head.appendChild(summernoteScript);
    summernoteScript.onload = () => {
      this.summernoteControl = window.summernote(this.snChange);
      let x = this.summernoteControl;
      this.summernoteControl.on(
        "summernote.mouseup",
        function (/*e, mouseEvent*/) {
          x.summernote("editor.setLastRange"); // Set Range where the cursor is
        }
      );

      if (this.customToolbar) x.summernote({ toolbar: this.customToolbar });
      else
        x.summernote({
          toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "italic", "underline", "clear"]],
            ["font", ["fontname", "fontsize"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link"]],
            ["view", ["fullscreen", "codeview"]],
          ],
        });

      this.focus();
    };
  },
};
</script>
