import { DateTime } from "luxon-business-days";
import { store } from "@/store";

export default {
  methods: {
    /**
     * Convert a JS Date or String to Luxon DateTime object
     * @param {Date | string} date A JS Date or ISO formatted string, ex: "2022-04-02T09:37:54.000Z"
     * @param {boolean} fromUTC If the Luxon date object needs to be created from a UTC datetime
     * @param {boolean} toLocal If the Luxon date needs to be converted to the local timezone
     */
    getLuxonDateTime(date, fromUTC = false, toLocal = true) {
      let luxonDate = null;

      if (typeof date === "string") {
        let dt = DateTime.fromISO(date, {
          ...(fromUTC && { zone: "UTC" }),
        });
        if (dt.isValid) luxonDate = dt;
        else
          luxonDate = DateTime.fromSQL(date, {
            ...(fromUTC && { zone: "UTC" }),
          });
      }

      if (!luxonDate) luxonDate = DateTime.fromJSDate(date);

      if (toLocal) luxonDate = luxonDate.toLocal();

      return luxonDate;
    },
    /**
     * Convert a Luxon date to a string which is formatted in the workspace's setting
     * @param date {DateTime} a Luxon date
     * @returns {string} formatted string
     */
    toWorkspaceDateTimeFormat(date) {
      return date.toFormat(
        store.getters.dateFormat
          .replace("YYYY", "yyyy")
          .replace("DD", "dd")
          .replace("mm/", "MM/")
      );
    },
    /**
     * Get a string/message representing the number of days or hours elapsed between the 2 dates
     * @param {Date | string} from start date
     * @param {Date? | string?} to Optional. end date. If omitted, use 'Now'
     * @param {bool?} asBusinessDays Optional. Show as Business days or true days
     * @returns String.
     * @example "1 hour", "2 days overdue"
     */
    timeElapsedBetween(
      from,
      to = new Date(),
      asBusinessDays = true,
      pastSuffix = " overdue"
    ) {
      const duration = this.getLuxonDateTime(to).diff(
        this.getLuxonDateTime(from),
        ["days", "hours"]
      );

      const days = Math.abs(duration.days);
      const hours = Math.abs(Math.round(duration.hours));

      const suffix =
        this.getLuxonDateTime(from) < this.getLuxonDateTime(to)
          ? ""
          : pastSuffix;
      const totalHours = Math.abs(Math.round(duration.as("hours")));

      // Only when difference is of 2 days or more, does it make sense to use Business Days
      if (asBusinessDays && totalHours >= 47) {
        // we use duration.days instead of checking the 'suffix'
        const businessDays = this.timeElapsedInBusinessDays(from, to);
        if (businessDays === 1) {
          return "1 business day" + suffix;
        }
        return businessDays + " business days" + suffix;
      } else {
        if (totalHours == 1) {
          return "1 hour" + suffix;
        } else if (totalHours < 24) {
          return hours + " hours" + suffix;
        } else if (totalHours === 24) {
          return "1 day" + suffix;
        } else if (totalHours < 48) {
          if (hours == 1) {
            return "1 day 1 hour" + suffix;
          } else {
            return "1 day " + hours + " hours" + suffix;
          }
        }
        return days + " days" + suffix;
      }
    },
    calculateDaysDifference(
      from,
      to = new Date(),
      asBusinessDays = true,
      isRoundOff = true
    ) {
      const duration = this.getLuxonDateTime(to).diff(
        this.getLuxonDateTime(from),
        ["days", "hours"]
      );

      const totalHours = Math.abs(duration.as("hours"));
      if (asBusinessDays && totalHours >= 47) {
        // we use duration.days instead of checking the 'suffix'
        const businessDays = this.timeElapsedInBusinessDays(from, to);
        return businessDays;
      }
      // Round up to the nearest whole day
      return isRoundOff ? Math.ceil(totalHours / 24) : totalHours / 24;
    },

    /**
     * Get the number of days elapsed between the 2 dates
     * @param {Date | string} from start date
     * @param {Date | string} to end date
     * @returns Number
     */
    timeElapsedInDays(from, to) {
      const duration = this.getLuxonDateTime(to).diff(
        this.getLuxonDateTime(from)
      );
      return duration.as("days");
    },
    /**
     * Get the difference in Business days between the 2 dates
     * @param {Date | string} from start date
     * @param {Date | string} to end date
     * @returns Number
     */
    timeElapsedInBusinessDays(from, to) {
      return this.businessDiff(
        this.getLuxonDateTime(from),
        this.getLuxonDateTime(to),
        false
      );
    },
    /**
     * Get the difference in Business days between the 2 Luxon dates
     * @param {DateTime} from
     * @param {DateTime} to
     * @param {bool?} relative Optional. If true (default), returns +ve value always, else, returns negative if @param from > @param to
     * @returns Number. Difference in Business days
     */
    businessDiff(from, to, relative = true) {
      var positive = from >= to;
      var start = from < to ? from : to;
      var end = to > from ? to : from;
      var daysBetween = 0;

      if (start.hasSame(end, "day")) {
        return daysBetween;
      }

      while (start.startOf("day") < end.startOf("day")) {
        if (start.isBusinessDay && start.isBusinessDay()) {
          daysBetween += 1;
        }
        start = start.plus({ days: 1 });
      }

      if (start.isBusinessDay && !end.isBusinessDay()) {
        if (daysBetween > 0) {
          // In case we have something sent on a Saturday and we check on Sunday or Monday
          daysBetween -= 1;
        }
      }

      if (relative) {
        return positive ? daysBetween : -daysBetween;
      }

      return daysBetween;
    },
    formatDate(dateString, format = "dd-MM-yyyy") {
      const date = DateTime.fromISO(dateString);
      const formattedDate = date.toFormat(format);
      return formattedDate;
    },
  },
};
