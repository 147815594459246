const config = require("./config.json");
const { get } = require("./common").default;

/**
 * Retrieve a Trackable Link by id
 * @param id Guid
 * @returns JSON
 */
exports.getTrackLinkById = async (id, tenantInfo) => {
  return await get(config.apis.base.url + "tracklinks/" + id, {
    headers: {
      tenant_id: tenantInfo.id,
    },
  });
};
