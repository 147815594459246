<template>
  <div>
    <div class="pattern">
      <span class="red"></span>
      <span class="indigo"></span>
      <span class="blue"></span>
      <span class="green"></span>
      <span class="orange"></span>
    </div>
    <div class="auth-main particles_js">
      <div class="auth_div vivify popIn" style="width: 450px">
        <div class="auth_brand">
          <a
            class="navbar-brand"
            href="javascript:void(0);"
            style="color: #192c72"
          >
            <img
              src="/assets/images/logo.png"
              width="200"
              class="d-inline-block align-top mr-2"
              alt="Planeolo"
          /></a>
        </div>
        <!-- Agency URL Retrieval form -->
        <div class="card">
          <div class="body">
            <p class="subtitle">Retrieve workspace URL</p>
            <p class="lead">Enter your email</p>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="email"
                v-model="email"
              />
            </div>
            <button
              type="button"
              class="btn btn-primary mt-4"
              @click="retrieveDomains"
              :disabled="isRetrievingDomains"
            >
              Continue
            </button>
          </div>
        </div>

        <div>
          Don't have an account yet?
          <router-link :to="{ name: 'signup' }">
            <span>Sign up for free</span>
          </router-link>
        </div>

        <!-- Logged in Agencies list -->
        <div
          class="card vivify fadeInBottom"
          style="margin-top: 40px"
          v-if="$auth.user?.email && userAgencies.length > 0"
        >
          <div class="body">
            <p class="subtitle">You're already signed in to...</p>
            <div>
              <div v-for="(agency, index) in userAgencies" :key="index">
                <button
                  class="btn btn-info btn-block mt-2"
                  @click="goToChosenAgency(agency.domain)"
                >
                  {{ agency.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTenantsForUser } from "../apis/app";
import { getDomains } from "@/apis/tenant";

export default {
  name: "RetrieveWorkspaceURL",
  data() {
    return {
      userAgencies: [],
      email: "",
      isRetrievingDomains: false,
    };
  },
  methods: {
    retrieveDomains() {
      if (this.isRetrievingDomains) return;

      this.isRetrievingDomains = true;
      getDomains(this.email)
        .then(() => {
          this.notifySuccess(
            "An email with your workspace details has been sent"
          );
          this.$router.push({ name: "signin" });
          this.isRetrievingDomains = false;
          this.email = "";
        })
        .catch(() => {
          this.isRetrievingDomains = false;
          this.notifyError("Failed to retrieve workspace URL");
        });
    },
    /**
     * Gets list of domains for which this tenant is known to have access
     */
    async getAgenciesForUser() {
      if (this.$auth.user?.email) {
        this.userAgencies = await getTenantsForUser(this.$auth.user.email);
      }
    },
    goToChosenAgency(domain) {
      window.location = "https://" + domain;
    },
  },
  mounted: function () {
    document.title = "Planeolo - Retrieve Workspace URL";
    this.getAgenciesForUser();
    window.onWrapperMounted();
  },
  watch: {
    "$auth.user": function () {
      this.getAgenciesForUser();
    },
  },
};
</script>

<style scoped>
.subtitle {
  color: #192c72;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.lead {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #626a71;
  margin-top: 15px;
  margin-bottom: 5px;
}
.auth-main {
  flex-wrap: wrap;
}
</style>
