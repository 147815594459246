import timeMixin from "./time";
import workflow from "./workflow";
export default {
  mixins: [timeMixin, workflow],
  methods: {
    productionBreakdownInDays(workflowTasks, project) {
      let totalRevisionDays = 0;
      let totalAwaitingFeedbackDays = 0;
      let totalOverdueFeedbackDays = 0;
      let totalInProductionDays = 0;
      const asBusinessDays = true;
      const isRoundOff = false;

      workflowTasks.forEach((item) => {
        if (item.tasks && item.tasks.length > 0 && item.status) {
          item.tasks.forEach((task) => {
            if (task.type === "Revision" && task.started_on) {
              const startedOn = new Date(task.started_on);
              const completedOn = task.completed_on
                ? new Date(task.completed_on)
                : new Date();

              if (task.sent_on) {
                const sentOn = new Date(task.sent_on);
                const totalAwaitingPlusOverdueDays =
                  this.calculateDaysDifference(
                    sentOn,
                    completedOn,
                    asBusinessDays,
                    isRoundOff
                  );
                const allowedAwaitingFeedback =
                  project.days_allowed_for_client_feedback;
                if (totalAwaitingPlusOverdueDays > allowedAwaitingFeedback) {
                  totalAwaitingFeedbackDays += allowedAwaitingFeedback;
                  totalOverdueFeedbackDays +=
                    totalAwaitingPlusOverdueDays - allowedAwaitingFeedback;
                } else {
                  totalAwaitingFeedbackDays += totalAwaitingPlusOverdueDays;
                }
                totalInProductionDays += this.calculateDaysDifference(
                  startedOn,
                  sentOn,
                  asBusinessDays,
                  isRoundOff
                );
              } else {
                totalInProductionDays += this.calculateDaysDifference(
                  startedOn,
                  completedOn,
                  asBusinessDays,
                  isRoundOff
                );
              }

              totalRevisionDays += this.calculateDaysDifference(
                startedOn,
                completedOn,
                asBusinessDays,
                isRoundOff
              );
            }
          });
        } else if (item.tasks?.length === 0) {
          const startedOn = item.started_on;
          let completedOn = item.approved_on;
          if (!startedOn || !completedOn) totalInProductionDays += 0;
          else {
            totalInProductionDays += this.calculateDaysDifference(
              new Date(startedOn),
              new Date(completedOn),
              asBusinessDays,
              isRoundOff
            );
          }
        }
      });

      return {
        totalRevisionDays: Math.round(totalRevisionDays * 10) / 10,
        totalAwaitingFeedbackDays:
          Math.round(totalAwaitingFeedbackDays * 10) / 10,
        totalOverdueFeedbackDays:
          Math.round(totalOverdueFeedbackDays * 10) / 10,
        totalInProductionDays: Math.round(totalInProductionDays * 10) / 10,
      };
    },
    getProjectStatusWithColorCode(statusText) {
      let color = "#2bbe76"; // Default color
      let text = ""; // Default color
      switch (statusText) {
        case "Overdue Feedback":
          color = ["warning", "#ffa117"];
          text = "Overdue";
          break;
        case "Awaiting Feedback":
          color = ["warning", "#ffa117"];
          text = "Awaiting";
          break;
        case "In production":
          color = ["primary", "#5c8ed4"];
          text = statusText;
          break;
        case "Completed":
          color = ["success", "#5CB65F"];
          text = statusText;
          break;
        case "Pending CSAT":
          color = ["warning", "#ffa117"];
          text = statusText;
          break;
      }

      return {
        color: color,
        text,
      };
    },
    calculateOpenDurationOfEachStage(workflow) {
      const nameAndOpenDuration = [];
      workflow?.tasks.forEach((stage) => {
        nameAndOpenDuration.push({
          name: stage.title,
          value: this.stageDurationText(stage),
        });
      });
      this.stageNameAndOpenDuration = [...nameAndOpenDuration];
    },
    stageDurationText(stage) {
      let from = stage.started_on;
      if (!from) from = stage.tasks?.[0]?.started_on;

      if (!from) return null;
      if (!stage.approved_on) return null;

      const timeDifferenceString = this.calculateDaysDifference(
        new Date(from),
        new Date(stage.approved_on),
        true
      );
      return timeDifferenceString;
    },
    getProjectDuration(workflow, project) {
      // Ensure workflow is defined and has a creation date
      if (!workflow || !workflow.created_on) return 0;

      // Find the earliest start date among tasks, if any
      let startDate = new Date();
      const startedTasks = workflow.tasks
        ? workflow.tasks.filter((task) => task.started_on)
        : [];
      if (startedTasks.length > 0) {
        const minStartedDate = new Date(
          Math.min(...startedTasks.map((task) => new Date(task.started_on)))
        );
        startDate = minStartedDate;
      }

      // Determine current date based on project status
      const currentDate = project.cancelled
        ? new Date(project.updated_on)
        : new Date();

      // Calculate total business day duration
      let totalBusinessDayDuration = this.calculateDaysDifference(
        startDate,
        currentDate,
        true
      );

      // If all tasks are completed, update duration based on latest approval date
      if (workflow.tasks && workflow.tasks.length > 0) {
        const projectStatus =
          this.getProjectCompletedStatusWithApprovalDate(workflow);
        if (projectStatus.allTasksCompleted) {
          const approvedDate = new Date(projectStatus.latestApprovedDate);
          totalBusinessDayDuration = this.calculateDaysDifference(
            startDate,
            approvedDate,
            true
          );
        }
      }

      return totalBusinessDayDuration;
    },
    currentStagesSortedByFrequency(projects_stats) {
      let groupBy = [];

      // Go through all current stages
      projects_stats.forEach((ps) => {
        if (!ps.current_stage) return;

        let pos = groupBy.findIndex((p) => p.name === ps.current_stage);
        if (pos > -1) {
          groupBy[pos].count++;
        } else {
          groupBy.push({ name: ps.current_stage, count: 1 });
        }
      });

      return groupBy.sort((a, b) => b.count - a.count);
    },
    /**
     * Gets a list of the current stages in order of frequency (number of times used in all projects)
     */
    upcomingStagesSortedByFrequency(projects_stats) {
      let groupBy = [];

      // Go through all current stages
      projects_stats.forEach((ps) => {
        if (!ps.upcoming_stage) return;

        let pos = groupBy.findIndex((p) => p.name === ps.upcoming_stage);
        if (pos > -1) {
          groupBy[pos].count++;
        } else {
          groupBy.push({ name: ps.upcoming_stage, count: 1 });
        }
      });

      return groupBy.sort((a, b) => b.count - a.count);
    },
    createArchivedCancelledEventsMap(events) {
      const archiveMap = new Map();
      const cancelledMap = new Map();

      events.forEach((event) => {
        const { event_time, project_id, category } = event;
        const eventDate = new Date(event_time).toLocaleDateString();

        if (
          !archiveMap.has(project_id) &&
          ["Project Archived", "Project Unarchived"].includes(category)
        ) {
          archiveMap.set(project_id, {
            archivedDates: [],
            unarchivedDates: [],
          });
        } else if (
          !cancelledMap.has(project_id) &&
          category === "Cancel Project"
        ) {
          cancelledMap.set(project_id, {
            cancelledDates: [],
          });
        }

        const projectArchiveInfo = archiveMap.get(project_id);
        const projectCancelInfo = cancelledMap.get(project_id);

        if (category === "Project Archived") {
          projectArchiveInfo.archivedDates.push(eventDate);
        } else if (category === "Project Unarchived") {
          projectArchiveInfo.unarchivedDates.push(eventDate);
        } else if (category === "Cancel Project") {
          projectCancelInfo.cancelledDates.push(eventDate);
        }
      });

      return { archiveMap, cancelledMap };
    },
    isProjectArchived(archiveMap, projectId, dateToCheck) {
      if (!archiveMap.has(projectId)) {
        return false; // Project not found, so it's not archived
      }

      const projectInfo = archiveMap.get(projectId);
      const formattedDate = dateToCheck.split(" ")[0]; // Extracting only the date part

      const archivedDates = Array.from(projectInfo.archivedDates);
      const unarchivedDates = Array.from(projectInfo.unarchivedDates);

      for (let i = 0; i < archivedDates.length; i++) {
        const archivedDate = archivedDates[i];
        const unarchivedDate = unarchivedDates[i];

        if (
          new Date(formattedDate) > new Date(archivedDate) &&
          (unarchivedDate === undefined ||
            new Date(formattedDate) < new Date(unarchivedDate))
        ) {
          return true; // Project was archived on this date
        }
      }

      return false; // Project was not archived on this date
    },
    isProjectCancelled(cancelledMap, projectId, dateToCheck) {
      if (!cancelledMap.has(projectId)) {
        return false; // Project not found, so it's not cancelled
      }
      const projectInfo = cancelledMap.get(projectId);
      const formattedDate = dateToCheck.split(" ")[0];

      const projectCancelledDates = Array.from(projectInfo.cancelledDates);
      if (new Date(formattedDate) > new Date(projectCancelledDates[0])) {
        return true;
      }
      return false;
    },
    completedProjectgroupByClient(projects_stats) {
      const completedProjectsCount = {};

      projects_stats.forEach((project) => {
        const { client_id, status } = project;

        if (status === "completed") {
          if (client_id in completedProjectsCount) {
            completedProjectsCount[client_id]++;
          } else {
            completedProjectsCount[client_id] = 1;
          }
        }
      });

      return completedProjectsCount;
    },
  },
};
