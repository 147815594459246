var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",attrs:{"id":"left-sidebar"}},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.$store.getters.logo)?_c('img',{staticClass:"img-fluid logo",attrs:{"src":_vm.$store.getters.logo,"alt":"Logo"}}):_vm._e(),(_vm.$store.state.tenantProfile.name)?_c('span',[_vm._v(" "+_vm._s(_vm.$store.state.tenantProfile.name)+" ")]):_vm._e()]),_vm._m(0)],1),_c('div',{staticClass:"sidebar-scroll"},[_c('div',{staticClass:"user-account"},[_c('div',{staticClass:"user_div"},[_c('img',{staticClass:"user-photo",attrs:{"src":this.$auth.user.picture,"alt":"User Profile Picture"}})]),_c('div',{staticClass:"dropdown"},[_c('span',[_vm._v("Welcome,")]),_c('a',{staticClass:"dropdown-toggle user-name",attrs:{"href":"javascript:void(0);","data-toggle":"dropdown"}},[_c('strong',[_vm._v(_vm._s(_vm.$auth.user.name)+" "+_vm._s(_vm.$auth.user.surname))])]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-right account vivify flipInY p-0"},[(_vm.$auth.user.isAgent)?_c('li',[_c('router-link',{staticClass:"pb-0",attrs:{"to":{ name: 'user-profile' }}},[_c('span',[_c('i',{staticClass:"icon-user"}),_vm._v("My Profile")])])],1):_vm._e(),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":_vm.logout}},[_c('i',{staticClass:"icon-power"}),_vm._v("Logout")])])])])]),_c('nav',{staticClass:"sidebar-nav",attrs:{"id":"left-sidebar-nav"}},[_c('ul',{staticClass:"metismenu",attrs:{"id":"main-menu"}},[_c('li',{staticClass:"header"},[_vm._v("Main")]),(_vm.$auth.user.isAgent)?_c('li',_vm._b({},'li',_vm.menuItemClass('/'),false),[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('i',{staticClass:"icon-home"}),_c('span',[_vm._v("Dashboard")])])],1):_vm._e(),(_vm.$auth.user.isAgent)?_c('li',_vm._b({},'li',
            this.$route.path.endsWith('create')
              ? { class: 'active open' }
              : {}
          ,false),[_vm._m(1),_c('ul',[(_vm.$auth.user.create_projects)?_c('li',_vm._b({},'li',_vm.menuItemClass('/projects/create'),false),[_c('router-link',{attrs:{"to":{
                  name: 'project-details',
                  params: { projectId: 'create' },
                }}},[_c('span',[_vm._v("Project")])])],1):_vm._e(),(_vm.$auth.user.isAgent)?_c('li',_vm._b({},'li',_vm.menuItemClass('/clients/create'),false),[_c('router-link',{attrs:{"to":{
                  name: 'client-details',
                  params: { clientId: 'create' },
                }}},[_c('span',[_vm._v("Client")])])],1):_vm._e()])]):_vm._e(),_c('li',_vm._b({},'li',_vm.menuItemClass('/projects', false),false),[_c('router-link',{staticClass:"has-arrow",attrs:{"to":{ name: 'projects-index' }}},[_c('i',{staticClass:"icon-rocket"}),_c('span',[_vm._v("Projects")])]),_c('ul',[_c('li',_vm._b({},'li',_vm.menuItemClass('/projects'),false),[_c('router-link',{attrs:{"to":{ name: 'projects-index' }}},[_c('span',[_vm._v("List Active")])])],1),_c('li',_vm._b({},'li',_vm.menuItemClass('/projects/archived'),false),[_c('router-link',{attrs:{"to":{ name: 'projects-archived-index' }}},[_c('span',[_vm._v("List Archived")])])],1)])],1),(_vm.$auth.user.isAgent)?_c('li',_vm._b({},'li',_vm.menuItemClass('/clients'),false),[_c('router-link',{attrs:{"to":{ name: 'clients-index' }}},[_c('i',{staticClass:"icon-grid"}),_c('span',[_vm._v("Clients")])])],1):_vm._e(),(_vm.$auth.user.isAgent)?_c('li',_vm._b({},'li',_vm.menuItemClass('/workspace-analytics'),false),[_c('router-link',{attrs:{"to":{ name: 'workspace-analytics' }}},[_c('i',{staticClass:"icon-graph"}),_c('span',[_vm._v("Workspace Analytics")])])],1):_vm._e(),(
            _vm.$auth.user.isAgent &&
            (_vm.$auth.user.owner ||
              _vm.$auth.user.admin ||
              _vm.$auth.user.add_users ||
              _vm.$auth.user.email_templates ||
              _vm.$auth.user.workflow_templates)
          )?_c('li',{staticClass:"header"},[_vm._v(" Administration ")]):_vm._e(),(
            _vm.$auth.user.isAgent &&
            (_vm.$auth.user.owner || _vm.$auth.user.admin || _vm.$auth.user.add_users)
          )?_c('li',_vm._b({},'li',_vm.menuItemClass('/settings', false),false),[_vm._m(2),_c('ul',[(
                _vm.$auth.user.owner || _vm.$auth.user.admin || _vm.$auth.user.add_users
              )?_c('li',_vm._b({},'li',_vm.menuItemClass('/settings/team'),false),[_c('router-link',{attrs:{"to":{ name: 'settings-team' }}},[_vm._v("Team")])],1):_vm._e(),(
                _vm.$auth.user.owner ||
                (_vm.$auth.user.admin &&
                  _vm.$store.state.tenantProfile
                    .admins_can_edit_workspace_settings)
              )?_c('li',_vm._b({},'li',_vm.menuItemClass('/settings/workspace'),false),[_c('router-link',{attrs:{"to":{ name: 'settings-workspace' }}},[_vm._v(" Workspace ")])],1):_vm._e(),(_vm.$auth.user.owner || _vm.$auth.user.admin)?_c('li',_vm._b({},'li',_vm.menuItemClass('/settings/email-settings'),false),[_c('router-link',{attrs:{"to":{ name: 'settings-email-settings' }}},[_vm._v("Email Sender Authentication")])],1):_vm._e(),(_vm.$auth.user.owner || _vm.$auth.user.admin)?_c('li',_vm._b({},'li',_vm.menuItemClass('/settings/custom-domain'),false),[_c('router-link',{attrs:{"to":{ name: 'settings-custom-domain' }}},[_vm._v("Custom Domain")])],1):_vm._e()])]):_vm._e(),(
            _vm.$auth.user.isAgent &&
            (_vm.$auth.user.owner ||
              _vm.$auth.user.admin ||
              _vm.$auth.user.email_templates ||
              _vm.$auth.user.workflow_templates)
          )?_c('li',_vm._b({},'li',_vm.menuItemClass('/templates', false),false),[_vm._m(3),_c('ul',[(
                _vm.$auth.user.owner ||
                _vm.$auth.user.admin ||
                _vm.$auth.user.email_templates
              )?_c('li',_vm._b({},'li',_vm.menuItemClass('/templates/email-templates'),false),[_c('router-link',{attrs:{"to":{ name: 'templates-email-templates' }}},[_vm._v("Email Templates")])],1):_vm._e(),(
                _vm.$auth.user.owner ||
                _vm.$auth.user.admin ||
                _vm.$auth.user.workflow_templates
              )?_c('li',_vm._b({},'li',_vm.menuItemClass('/templates/workflow-templates'),false),[_c('router-link',{attrs:{"to":{ name: 'templates-workflow-templates' }}},[_vm._v("Workflow Templates")])],1):_vm._e(),(
                _vm.$auth.user.owner ||
                _vm.$auth.user.admin ||
                _vm.$auth.user.email_templates
              )?_c('li',_vm._b({},'li',_vm.menuItemClass('/templates/survey-templates'),false),[_c('router-link',{attrs:{"to":{ name: 'templates-survey-templates' }}},[_vm._v("CSAT survey templates")])],1):_vm._e()])]):_vm._e(),(_vm.$auth.user.owner)?_c('li',[_c('a',{attrs:{"href":_vm.stripeCustomerPortalLink,"target":"_blank"}},[_c('i',{staticClass:"icon-credit-card"}),_c('span',[_vm._v("Billing")])])]):_vm._e(),(_vm.$auth.user.owner && _vm.showAppSumoMenu)?_c('li',_vm._b({},'li',_vm.menuItemClass('/billing/appsumo'),false),[_c('router-link',{attrs:{"to":{ name: 'billing-appsumo' }}},[_c('i',{staticClass:"icon-credit-card"}),_c('span',[_vm._v("AppSumo Codes")])])],1):_vm._e(),_c('li',{staticClass:"header"},[_vm._v("Help & Support")]),(_vm.$auth.user.isAgent)?_c('li',[_vm._m(4)]):_c('li',[_vm._m(5)]),(_vm.$auth.user.isAgent)?_c('li',[_vm._m(6)]):_vm._e(),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn-toggle-offcanvas btn btn-sm float-right",attrs:{"type":"button"}},[_c('i',{staticClass:"lnr lnr-menu icon-close"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-plus"}),_c('span',[_vm._v("Create")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-settings"}),_c('span',[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-list"}),_c('span',[_vm._v("Templates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://help.planeolo.com/","target":"_blank"}},[_c('i',{staticClass:"fa fa-book"}),_c('span',[_vm._v("Knowledge base")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://help.planeolo.com/customer-portal/category/211","target":"_blank"}},[_c('i',{staticClass:"fa fa-book"}),_c('span',[_vm._v("Knowledge base")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ustfl-start",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"fa fa-toggle-right"}),_c('span',[_vm._v("Guided Tour")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.planeolo.com/roadmap","target":"_blank"}},[_c('i',{staticClass:"fa fa-bug"}),_c('span',[_vm._v("Report a Bug")])])])
}]

export { render, staticRenderFns }