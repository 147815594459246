const config = require("./config.json");
const { get, create, remove, update } = require("./common").default;

const scopeUrl = config.apis.base.url + "projects/";

/**
 * Add scope to a project.
 *
 * @param {string} projectId - The ID of the project to add scope to.
 * @param {object} scopePayload - The payload containing the scope data.
 */
exports.addScope = async (projectId, scopePayload) => {
  return await create(scopeUrl + projectId + "/scope", scopePayload);
};

/**
 * Get scope for a project.
 *
 * @param {string} projectId - The ID of the project to retrieve scope from.
 */
exports.getScope = async (projectId) => {
  return await get(scopeUrl + projectId + "/scope");
};

/**
 * Delete a scope from a project.
 *
 * @param {string} projectId - The ID of the project containing the scope.
 * @param {string} scopeId - The ID of the scope to be deleted.
 */
exports.deleteScope = async (projectId, scopeId) => {
  return await remove(scopeUrl + projectId + "/scope/" + scopeId);
};

/**
 * Update a scope in a project.
 *
 * @param {string} projectId - The ID of the project containing the scope.
 * @param {string} scopeId - The ID of the scope to be updated.
 * @param {object} updatedScope - The updated scope data.
 */
exports.updateScope = async (projectId, scopeId, updatedScope) => {
  return await update(scopeUrl + projectId + "/scope/" + scopeId, updatedScope);
};
