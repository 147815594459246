var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"small text-black-50",staticStyle:{"position":"relative","line-height":"1.6"}},[(_vm.fixedText)?_c('span',[_vm._v(_vm._s(_vm.fixedText))]):(_vm.stage.status === 'completed')?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.statusText)}}),(_vm.stage.target_date)?_c('span',{attrs:{"title":`target date: ${_vm.stage.target_date.substring(0, 10)}`}},[_vm._v(" | "+_vm._s(_vm.completionTimeDifferenceText)+" ")]):_vm._e(),(_vm.stage?.tasks?.length && _vm.stageDurationText)?_c('span',[_vm._v(" | stage duration: "+_vm._s(_vm.stageDurationText)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.completedRevisionRoundsText)+" ")]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.statusText)}}),(_vm.stage.target_date)?_c('span',[_vm._v(" | Target completion date "),(_vm.$auth.user.isAgent)?_c('label',{staticClass:"cursor-pointer select-none",attrs:{"for":`change-target-date-stage-${this.stage.id}`}},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" "+_vm._s(_vm.targetDateText)+" ")]):[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" "+_vm._s(_vm.targetDateText)+" ")]],2):_vm._e()]),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"date","value":new Date(_vm.removeZ(_vm.stage.target_date)),"format":"YYYY-MM-DD","input-class":"form-date-control","input-attr":{
      id: `change-target-date-stage-${_vm.stage.id}`,
      ref: `change-target-date-stage-${_vm.stage.id}`,
    },"disabled-date":(date) => {
        const started = new Date(this.stage.started_on);
        return date < started;
      }},on:{"input":(v) => _vm.onTargetDateChange(v)}}):_vm._e(),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"datetime","value":new Date(this.stage.approved_on),"format":"YYYY-MM-DD HH:mm A","input-class":"form-date-control","input-attr":{
      id: `change-approved-date-stage-${_vm.stage.id}`,
      ref: `change-approved-date-stage-${_vm.stage.id}`,
    },"disabled-date":(date) => {
        const today = new Date();
        return date > today;
      }},on:{"input":(v) => _vm.onDateChange(v, 'approved_on')}}):_vm._e(),(_vm.$auth.user.isAgent)?_c('date-time-picker',{staticClass:"stage-date-change",attrs:{"type":"datetime","value":new Date(this.stage.started_on),"format":"YYYY-MM-DD HH:mm A","input-class":"form-date-control","input-attr":{
      id: `change-started-date-stage-${_vm.stage.id}`,
      ref: `change-started-date-stage-${_vm.stage.id}`,
    },"disabled-date":(date) => {
        const today = new Date();
        return date > today;
      }},on:{"input":(v) => _vm.onDateChange(v, 'started_on')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }