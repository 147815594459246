<template>
  <Modal
    title="Warning: Irreversible Deletion"
    :show-close-button="true"
    @submit="$emit('submit')"
    @close="$emit('close')"
    cancel-text="Go Back"
    submit-text="Delete Permanently"
    :close-on-outside-click="true"
    :disable-submit-button="disableSubmitButton || !matchedDeleteInput"
    submit-class="btn-danger"
  >
    <div class="container">
      <p>
        Type DELETE to confirm. This action is irreversible and Planeolo isn't
        responsible for data loss. Complete account deletion may take up to 72
        hours.
      </p>
      <div class="input-group">
        <input
          type="text"
          placeholder="Type DELETE to confirm"
          v-model="deleteInput"
          class="form-control"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "DeleteTenantConfirmModal",
  components: { Modal },
  props: {
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteInput: "",
    };
  },
  computed: {
    matchedDeleteInput() {
      return this.deleteInput === "DELETE";
    },
  },
};
</script>
