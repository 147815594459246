<template>
  <div>
    <div
      v-if="surveyToLoad.surveyItems.length"
      class="body d-flex flex-column"
      style="padding-top: 10px; padding-bottom: 0px; border: none !important"
    >
      <div class="d-flex align-items-center">
        <h4
          class="d-inline-block mr-2"
          style="margin-bottom: 0; line-height: 1.7rem"
        >
          Share your experience so we can improve our service
        </h4>
        <span
          v-if="isAgent"
          :class="
            surveyToLoad?.survey_completed
              ? 'badge xs badge-success'
              : 'badge xs badge-primary'
          "
          >{{
            surveyToLoad?.survey_completed
              ? "COMPLETED"
              : isPartialFeedbackSubmitted
              ? "INCOMPLETE"
              : "PENDING"
          }}</span
        >
      </div>
      <ul class="list-group mt-4">
        <li
          v-for="(item, index) in surveyToLoad.surveyItems"
          :key="index"
          :class="
            item.is_star_rating
              ? 'list-group-item d-flex justify-content-between'
              : 'list-group-item'
          "
        >
          <template v-if="item.is_star_rating && item.question.trim() !== ''">
            <p class="survey-question">{{ item.question }}</p>
            <div class="star-rating">
              <dynamicStarRating
                :dynamicFontSize="'1.8rem'"
                :maxRating="5"
                :initial-rating="item.rating"
                @rated="(value) => setRating(value, item)"
                :readyOnly="!!isReviewSubmitted || isAgent"
              ></dynamicStarRating>
            </div>
          </template>
          <template v-else-if="item.question.trim() !== ''">
            <p class="survey-question">{{ item.question }}</p>
            <div class="form-group">
              <textarea
                :disabled="isReviewSubmitted || isAgent"
                class="form-control"
                rows="5"
                name="comment"
                v-model="item.comment"
              ></textarea>
            </div>
          </template>
        </li>
      </ul>
      <p v-if="isReviewSubmitted && !isAgent" class="ml-2 mt-0">
        <i class="fa fa-check-circle"></i>
        Feedback submitted by {{ fullContactName }}
      </p>

      <div v-if="!isReviewSubmitted && !isAgent" class="text-right">
        <button
          class="btn btn-primary mt-1"
          @click="onSubmit"
          :disabled="
            !isAllStartRatingFeedbackSubmitted || isSubscriptionReadOnly()
          "
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dynamicStarRating from "../Dashboard/DynamicStarRating.vue";
import { setFeedback } from "../../../apis/survey";

export default {
  name: "SubmitFeedbackTemplate",
  components: {
    dynamicStarRating,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    surveyToLoad: {
      type: Object,
      default: () => ({ surveyItems: [] }),
    },
  },
  data() {
    return {
      isReviewSubmitted: false,
      shouldEnableSubmitButton: false,
    };
  },
  computed: {
    isAgent() {
      return this.$auth.user.isAgent;
    },
    matchedContact() {
      return this.project.contacts.find(
        (contact) => contact.email === this.surveyToLoad.contact_email
      );
    },
    fullContactName() {
      if (this.matchedContact) {
        return `${this.matchedContact.firstname} ${this.matchedContact.lastname}`;
      } else {
        return "";
      }
    },
    isAllStartRatingFeedbackSubmitted() {
      const allRatingsGreaterThanZero = this.surveyToLoad.surveyItems?.every(
        (feedback) => {
          return feedback.is_star_rating ? feedback.rating > 0 : true;
        }
      );
      return allRatingsGreaterThanZero || this.shouldEnableSubmitButton;
    },
    isPartialFeedbackSubmitted() {
      let partialFeedbackSubmitted = false;
      this.surveyToLoad?.surveyItems?.forEach((feedback) => {
        if (feedback.is_star_rating && feedback.rating > 0) {
          partialFeedbackSubmitted = true;
          return;
        }
      });
      return partialFeedbackSubmitted && !this.surveyToLoad?.survey_completed;
    },
  },
  methods: {
    setRating(rating, item) {
      item.rating = rating;
      this.shouldEnableSubmitButton = this.surveyToLoad.surveyItems?.every(
        (feedback) => {
          return feedback.is_star_rating ? feedback.rating > 0 : true;
        }
      );
      const payload = {
        rating: item.rating,
      };
      setFeedback(this.project?.id, item.survey_id, item.id, payload);
    },
    async onSubmit() {
      let promises = [];
      this.surveyToLoad.surveyItems.forEach((item, index) => {
        const payload = {
          rating: item.rating,
          comment: item.comment,
          surveyCompleted:
            index === this.surveyToLoad.surveyItems.length - 1 ? true : false,
        };
        promises.push(
          setFeedback(this.project?.id, item.survey_id, item.id, payload)
        );
      });
      const feedbackResponse = await Promise.allSettled(promises);
      let isFeedbackSubmittedInDatabase = true;
      feedbackResponse.forEach((settledPromise) => {
        if (settledPromise.status === "rejected") {
          isFeedbackSubmittedInDatabase = false;
        }
      });
      if (isFeedbackSubmittedInDatabase) {
        this.notifySuccess("Feedback submitted");
        this.surveyToLoad.survey_completed = true;
      } else {
        this.notifyError("Error on feedback submission");
      }
      this.isReviewSubmitted = isFeedbackSubmittedInDatabase;
    },
  },
  watch: {
    surveyToLoad: function (new_val) {
      this.isReviewSubmitted = !!new_val.survey_completed;
    },
  },
};
</script>

<style scoped>
li {
  border: none;
  padding: 1rem 0.5rem !important;
}
.star-rating {
  text-wrap: nowrap;
}
.survey-question {
  font-weight: 400;
  color: #494949;
}
.btn-primary {
  border-color: #1773f6;
}
</style>
