import Vue from "vue";
import Vuex from "vuex";
import { getTenantProfile } from "@/apis/tenant";
import { getUserProfile } from "@/apis/users";
import { getSubscriptions } from "./apis/subscriptions";
import { getUsers } from "./apis/users";
import { getEmailTemplates } from "./apis/email-templates";
import { getQCTemplates } from "./apis/qc-templates";

Vue.use(Vuex);

let retrieveTenantInfo = () => {};

export const setRetrieveTenantInfoFunction = (f) => {
  retrieveTenantInfo = f;
};

export const store = new Vuex.Store({
  state: {
    tenantProfile: { name: null, logo: null },
    userProfile: {},
    subscriptions: undefined, // undefined means the subscriptions were not fetched yet (once fetched it will be an array, possibly empty)
    users: [],
    emailTemplates: [],
    qcChecklistTemplates: [],
  },
  getters: {
    isLoadingSubscriptions: (state, getters) =>
      getters.subscription === undefined,
    users: (state) => state.users,
    emailTemplates: (state) => state.emailTemplates,
    qcChecklistTemplates: (state) => state.qcChecklistTemplates,
    userId: (state) => state.userProfile?.id,
    logo: (state) => state.tenantProfile.logo,
    theme: (state) => state.tenantProfile?.theme ?? "cyan",
    timezone: (state) => {
      const defaultTimeZone = "America/Bogota";

      if (state.userProfile?.timezone) return state.userProfile.timezone;

      return state.tenantProfile?.timezone ?? defaultTimeZone;
    },
    dateFormat: (state) => {
      const defaultDateFormat = "DD/MM/YYYY";

      if (state.userProfile?.dt_format) return state.userProfile.dt_format;

      return state.tenantProfile?.dt_format ?? defaultDateFormat;
    },
    defaultDaysAllowedForClientFeedback: (state) =>
      state.tenantProfile?.days_allowed_for_client_feedback ?? 1,
    emailSignature: (state) => state.userProfile?.html_email_signature,
    subscription: (state) => {
      if (!state.subscriptions) return undefined;

      return state.subscriptions?.findLast(
        (s) => s.tenant_id === state.tenantProfile.id
      );
    },
    usersRemaining: (state, getters) => {
      const subscription = getters.subscription;
      if (!subscription) return 0;

      const usersAllowed = +(
        subscription.users_allowed ?? Number.MAX_SAFE_INTEGER
      );
      const usersUsed = +(subscription.active_users ?? 0);
      const usersRemaining = usersAllowed - usersUsed;

      if (usersRemaining < 0) return 0;

      return usersRemaining;
    },
    activeProjectsRemaining: (state, getters) => {
      const subscription = getters.subscription;
      if (!subscription) return 0;

      const projectsAllowed = +(
        subscription.active_projects_allowed ?? Number.MAX_SAFE_INTEGER
      );
      const projectsUsed = +(subscription.active_projects ?? 0);
      const projectsRemaining = projectsAllowed - projectsUsed;

      if (projectsRemaining < 0) return 0;

      return projectsRemaining;
    },
    archivedProjectsRemaining: (state, getters) => {
      const subscription = getters.subscription;
      if (!subscription) return 0;

      const projectsAllowed = +(
        subscription.archived_projects_allowed ?? Number.MAX_SAFE_INTEGER
      );
      const projectsUsed = +(subscription.archived_projects ?? 0);
      const projectsRemaining = projectsAllowed - projectsUsed;

      if (projectsRemaining < 0) return 0;

      return projectsRemaining;
    },
    messageTemplatesRemaining: (state, getters) => {
      const subscription = getters.subscription;
      if (!subscription) return 0;

      const templatesAllowed = +(
        subscription.message_templates_allowed ?? Number.MAX_SAFE_INTEGER
      );
      const systemTemplates = +(subscription.system_message_templates ?? 0);
      const templatesUsed = +(subscription.active_message_templates ?? 0);
      const templatesRemaining =
        systemTemplates + templatesAllowed - templatesUsed;

      if (templatesRemaining < 0) return 0;

      return templatesRemaining;
    },
    workflowTemplatesRemaining: (state, getters) => {
      const subscription = getters.subscription;
      if (!subscription) return 0;

      const templatesAllowed = +(
        subscription.workflow_templates_allowed ?? Number.MAX_SAFE_INTEGER
      );
      const systemTemplates = +(subscription.system_workflow_templates ?? 0);
      const templatesUsed = +(subscription.active_workflow_templates ?? 0);
      const templatesRemaining =
        systemTemplates + templatesAllowed - templatesUsed;

      if (templatesRemaining < 0) return 0;

      return templatesRemaining;
    },
  },
  mutations: {
    SET_TENANT_PROFILE(state, tenantProfile) {
      state.tenantProfile = tenantProfile;
    },
    SET_USER_PROFILE(state, userProfile) {
      state.userProfile = userProfile;
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = Array.isArray(subscriptions) ? subscriptions : [];
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_EMAIL_TEMPLATES(state, emailTemplates) {
      state.emailTemplates = emailTemplates;
    },
    SET_QC_CHECKLIST_TEMPLATES(state, qcChecklistTemplates) {
      state.qcChecklistTemplates = qcChecklistTemplates;
    },
  },
  actions: {
    async getTenantProfile(context) {
      const tenant = await retrieveTenantInfo();

      if (!tenant) return;

      getTenantProfile(tenant.id).then((result) => {
        context.commit("SET_TENANT_PROFILE", result);
      });
    },
    async getUserProfile(context) {
      getUserProfile().then((result) => {
        context.commit("SET_USER_PROFILE", result);
      });
    },
    async getUsers(context) {
      getUsers().then((result) => {
        context.commit("SET_USERS", result);
      });
    },
    async getEmailTemplates(context) {
      getEmailTemplates().then((result) => {
        context.commit("SET_EMAIL_TEMPLATES", result);
      });
    },
    async getSubscriptions(context) {
      getSubscriptions().then((result) => {
        context.commit("SET_SUBSCRIPTIONS", result);
      });
    },
    async getQCChecklistTemplates(context) {
      getQCTemplates().then((result) => {
        context.commit("SET_QC_CHECKLIST_TEMPLATES", result);
      });
    },
  },
});
