const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

const qcTemplatesUrl = config.apis.base.url + "qc_templates/";

exports.getQCTemplates = async () => {
  return await get(qcTemplatesUrl);
};

exports.addQCTemplate = async (qcTemplate) => {
  return await create(qcTemplatesUrl, qcTemplate);
};

exports.deleteQCTemplateById = async (qcTemplateId) => {
  return await remove(qcTemplatesUrl + qcTemplateId);
};

exports.updateQCTemplate = async (qcTemplate, qcTemplateId) => {
  if (!qcTemplate.id || qcTemplate.id < 1) {
    if (!qcTemplateId || qcTemplateId < 1) {
      throw new Error("No valid Workflow Template ID provided");
    }
    qcTemplate.id = qcTemplateId;
  }
  return await update(qcTemplatesUrl + qcTemplate.id, qcTemplate);
};
