const config = require("./config.json");
const { get, create } = require("./common").default;

exports.getSubscriptions = async () => {
  return await get(config.apis.base.url + "subscriptions/");
};

exports.getSubscriptionPlans = async () => {
  return await get(config.apis.base.url + "subscriptionplans/");
};

exports.subscribeToPlan = async (planId) => {
  return await create(config.apis.base.url + "subscriptionplans/subscribe", {
    planId,
  });
};

exports.getStripeSetupIntent = async () => {
  return await get(config.apis.base.url + "stripe/setup-intent");
};

exports.addAppSumoCode = async (appSumoCode) => {
  return await create(config.apis.base.url + "subscriptions/appsumo/add", {
    appSumoCode,
  });
};
