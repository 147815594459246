<template>
  <Modal
    title="Reset Password"
    :show-close-button="false"
    :show-submit-button="false"
    @submit="$emit('submit')"
    @close="$emit('close')"
    :close-on-outside-click="true"
  >
    <div class="container">
      <p>
        To reset your password, return to the login page and select "Forgot Your
        Password".
      </p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "ChangePasswordModal",
  components: { Modal },
};
</script>
