<template>
  <div class="group-item">
    <span>{{ label }}</span>
    <div class="toggle-button">
      <label class="switch">
        <input type="checkbox" class="rtl-btn" v-model="isToggled" />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "ToggleButton",
  props: {
    initialToggleValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isToggled: this.initialToggleValue,
    };
  },
  watch: {
    initialToggleValue(newVal) {
      this.isToggled = newVal;
    },
    isToggled(newVal) {
      this.$emit("on-toggle", newVal);
    },
  },
};
</script>
<style>
/* Custom CSS class to remove border from <li> elements with class "list-group-item" */
.group-item {
  border: none;
  display: flex;
}

.toggle-button {
  margin-left: 8px;
}
</style>
