<template>
  <wrapper-page>
    <template v-if="!isLoadingSettings && settings">
      <div class="card">
        <div class="header">
          <h6>Your Workspace</h6>
          <small>General settings for your workspace</small>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label>
                  {{ workspaceNameField.label }}
                </label>
              </b>
              <div class="input-group mb-3">
                <div
                  class="form-control d-flex justify-content-between align-items-stretch"
                  :readonly="true"
                >
                  <span>{{ settings[workspaceNameField.name] }}</span>
                  <!-- TODO hide till backend is implemented
                  <button
                    type="button"
                    class="btn p-0"
                    @click="showEditWorkspaceNameModal = true"
                  >
                    EDIT
                  </button>
                  -->
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-auto">
              <b>
                <label>
                  {{ workspaceURLField.label }}
                </label>
              </b>
              <div class="input-group mb-3 gap-y-2">
                <div
                  v-for="(url, i) in workspaceUrls"
                  :key="url"
                  class="d-flex align-items-center"
                >
                  <div
                    class="form-control d-flex justify-content-between align-items-stretch h-auto"
                    :readonly="true"
                  >
                    <span>{{ url }}</span>
                    <button
                      v-if="canEditWorkspaceUrl"
                      type="button"
                      class="btn p-0"
                      :disabled="isSubscriptionReadOnly()"
                    >
                      EDIT
                    </button>
                  </div>
                  <span v-if="i < workspaceUrls.length - 1" class="mx-2 mx-md-3"
                    >or</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--- TODO hide temporarily -->
          <div v-if="false" class="row clearfix">
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label>
                  {{ workspaceOwnerField.label }}
                </label>
              </b>
              <div class="input-group mb-3">
                <div
                  class="form-control d-flex justify-content-between align-items-stretch"
                  :readonly="true"
                >
                  <!-- TODO -->
                  <span>{{ workspaceOwnerField.value }}</span>
                  <button
                    type="button"
                    class="btn p-0"
                    :disabled="isSubscriptionReadOnly()"
                  >
                    EDIT
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label> Active license </label>
              </b>
              <div class="input-group mb-3">
                <div
                  class="form-control bg-transparent d-flex justify-content-between align-items-stretch"
                  :readonly="true"
                >
                  <span>
                    <!-- TODO where to get the current license? -->
                    TODO
                  </span>
                  <router-link
                    :to="{ name: 'billing-price-page' }"
                    class="btn p-0"
                    >EDIT
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row clearfix">
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label>
                  Email Language
                  <small>(this applies to emails only)</small></label
                >
              </b>
              <form-input-group
                v-model="settings[workspaceLanguageField.name]"
                :field="workspaceLanguageField"
                :show-label="false"
              />
            </div>
          </div>
          -->
        </div>
      </div>

      <div class="card">
        <div class="header">
          <h6>Workspace Branding</h6>
          <small>Customize your workspace</small>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label>
                  {{ workspaceLogoField.label }}
                </label>
              </b>
              <div class="input-group pl-3">
                <div class="d-flex flex-column align-items-center">
                  <ClickableImagePlaceholder
                    target="workspace-logo-file-input"
                    text="ADD LOGO"
                    :image="settings.logo"
                    :disabled="isUploadingLogo"
                  />
                  <button
                    v-if="settings.logo"
                    class="btn btn-sm btn-default btnRemoveLogo"
                    type="button"
                    @click="removeLogo"
                    :disabled="isSubscriptionReadOnly()"
                  >
                    Remove logo
                  </button>
                </div>
                <input
                  type="file"
                  class="d-none"
                  id="workspace-logo-file-input"
                  name="workspace-logo-file-input"
                  @change="loadWorkspaceLogoFile($event)"
                  accept="image/*"
                />
                <div class="mt-2 d-flex flex-column align-items-center ml-4">
                  <small
                    style="font-size: 10px; margin-top: 10px; color: #747474"
                  >
                    Recommended size:<br />
                    250px by 250px
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <b>
                <label>
                  {{ themeColorField.label }}
                </label>
                <div class="input-group d-flex gap-x-1 gap-y-1">
                  <button
                    v-for="color in themeColorField.options"
                    :key="color.value"
                    type="button"
                    class="btnTheme"
                    :style="{ backgroundColor: color.hex }"
                    @click="onSelectTheme(color)"
                    :disabled="isSubscriptionReadOnly()"
                  >
                    <i
                      v-if="settings[themeColorField.name] === color.value"
                      class="fa fa-thumbs-o-up"
                    />
                  </button>
                </div>
              </b>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="header">
          <h6>Notifications</h6>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div
              class="col-12 col-md-9"
              style="
                display: grid;
                grid-template-columns: auto 1fr;
                row-gap: 0.5rem;
                column-gap: 1rem;
              "
            >
              <div
                v-for="notification in notifications"
                :key="notification.label"
                style="display: contents"
              >
                <span>{{ notification.label }}</span>
                <div class="ml-0 toggle-button">
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="rtl-btn"
                      v-model="settings[notification.name]"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="header">
          <h6>Workspace Settings</h6>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div
              class="col-12 col-md-9"
              style="
                display: grid;
                grid-template-columns: auto 1fr;
                row-gap: 0.5rem;
                column-gap: 1rem;
              "
            >
              <div style="display: contents" @click="makeCsatCompClicked">
                <span
                  >Make CSAT survey compulsory before completing projects</span
                >
                <div class="ml-0 toggle-button">
                  <div class="d-flex gap-x-2">
                    <label class="switch">
                      <input
                        type="checkbox"
                        class="rtl-btn"
                        :disabled="!isCsatSurveyAvailable"
                        v-model="
                          settings.csat_mandatory_before_project_complete
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <i
                      v-if="!isCsatSurveyAvailable"
                      class="fa fa-lock"
                      style="margin-top: 4px"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                v-for="setting in workspaceSettings"
                :key="setting.label"
                style="display: contents"
              >
                <span>{{ setting.label }}</span>
                <div class="ml-0 toggle-button">
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="rtl-btn"
                      v-model="settings[setting.name]"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div style="display: contents">
                <label
                  for="input-days_allowed_for_client_feedback"
                  style="margin-bottom: 0"
                  >Default time allowed for client feedback</label
                >
                <div class="input-group d-flex align-items-center">
                  <input
                    type="number"
                    id="input-days_allowed_for_client_feedback"
                    class="form-control"
                    style="width: 5em; flex: none"
                    v-model="settings.days_allowed_for_client_feedback"
                    required
                    :min="1"
                    @input="
                      (e) => {
                        if (e.target.value < 1)
                          settings.days_allowed_for_client_feedback = 1;
                      }
                    "
                  />
                  <span
                    style="
                      color: #77797c;
                      font-size: 14px;
                      margin-left: 0.75rem;
                    "
                    >business days</span
                  >
                </div>
              </div>
              <div v-if="$auth.user.owner" style="display: contents">
                <div class="d-flex gap-x-2" @click="bccClicked">
                  <label for="input-bcc-email" style="margin-bottom: 0"
                    >Auto BCC for all outgoing emails</label
                  >
                  <i
                    v-if="!isBccAvailable"
                    class="fa fa-lock"
                    style="margin-top: 4px"
                  ></i>
                </div>
                <div class="input-group" style="max-width: 300px">
                  <input
                    type="email"
                    id="input-bcc-email"
                    class="form-control flex-1"
                    v-model="settings.bcc_email"
                    :readonly="!isBccAvailable"
                    @click="bccClicked"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="header">
          <h6>Date & Time</h6>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div class="col-12" style="max-width: 350px">
              <form-input-group
                v-for="setting in dateTimeSettings"
                :key="setting.name"
                v-model="settings[setting.name]"
                :field="setting"
                :show-label="false"
              />
            </div>
          </div>
        </div>
      </div>

      <!--- TODO hide temporarily -->
      <div v-if="false" class="card">
        <div class="header">
          <h6>Client Portal Settings</h6>
        </div>
        <div class="body">
          <div class="row clearfix">
            <div class="col-12 col-sm-6">
              <div
                v-for="setting in portalSettings"
                :key="setting.label"
                class="d-flex justify-content-between list-group-item px-0 py-1"
                style="max-width: 520px"
              >
                <span>{{ setting.label }}</span>
                <div class="ml-0 toggle-button">
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="rtl-btn"
                      v-model="settings[setting.name]"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--- TODO hide temporarily -->
      <div v-if="false" class="card">
        <div class="header">
          <h6>Delete Workspace</h6>
          <small
            >Deleting your Planeolo account is irreversible and will result in
            the
            <br />
            permanent loss of all your projects, files, and data. Proceed with
            caution.</small
          >
        </div>
        <div class="body">
          <button
            class="btn btn-danger"
            type="button"
            @click="showDeleteTenantModal = true"
            :disabled="isSubscriptionReadOnly()"
          >
            Delete Account
          </button>
        </div>
      </div>

      <DeleteTenantConfirmModal
        v-if="showDeleteTenantModal"
        @close="showDeleteTenantModal = false"
        @submit="onDeleteTenant"
        :disableSubmitButton="isDeletingTenant"
      />
      <EditWorkspaceNameModal
        v-if="showEditWorkspaceNameModal"
        @close="showEditWorkspaceNameModal = false"
        @submit="
          (newName) => {
            settings[workspaceNameField.name] = newName;
            showEditWorkspaceNameModal = false;
          }
        "
        :old-name="settings.name"
      />
      <CropImageModal
        v-if="showCropImageModal && newWorkspaceLogo"
        :file="newWorkspaceLogo"
        title="Crop your workspace logo"
        :aspect-ratio="1 / 1"
        :width="400"
        :height="400"
        :is-saving="isUploadingLogo"
        @close="
          () => {
            this.clearLogoInput();
            this.clearWorkspaceLogoFile();
            showCropImageModal = false;
          }
        "
        @submit="
          (img) => {
            this.clearLogoInput();
            img.toBlob((blob) => {
              this.newWorkspaceLogo.file = blob;
              this.uploadLogo();
            });
          }
        "
      />
      <div class="card"></div>
    </template>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";
import ClickableImagePlaceholder from "@/components/ui/ClickableImagePlaceholder.vue";
import { getMimeType } from "@/utils/mime";
import DeleteTenantConfirmModal from "@/components/ui/Modals/DeleteTenantConfirmModal.vue";
import {
  addTenantFile,
  deleteTenant,
  getTenantProfile,
  updateTenantProfile,
} from "@/apis/tenant";
import { LANGUAGES, TIME_FORMAT } from "@/utils/app.constant";
import { timeZonesNames } from "@vvo/tzdb";
import _ from "lodash";
import changeTheme from "../../utils/changeTheme";
import EditWorkspaceNameModal from "@/components/ui/Modals/EditWorkspaceNameModal.vue";
import CropImageModal from "@/components/ui/Modals/CropImageModal.vue";
import { isEmail, isWorkspaceProfessionalLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "SettingsWorkspacePage",
  components: {
    CropImageModal,
    EditWorkspaceNameModal,
    DeleteTenantConfirmModal,
    ClickableImagePlaceholder,
    FormInputGroup,
    WrapperPage,
  },
  data() {
    return {
      showDeleteTenantModal: false,
      showEditWorkspaceNameModal: false,
      showCropImageModal: false,
      isDeletingTenant: false,
      settings: {},
      tenantInfo: {},
      workspaceNameField: {
        label: "Workspace name",
        name: "name",
      },
      workspaceURLField: {
        label: "Workspace URL",
        name: "domain",
      },
      workspaceOwnerField: {
        label: "Workspace owner",
        name: "owner",
        value: "TODO", // TODO
      },
      workspaceLogoField: {
        label: "Workspace Logo",
        name: "logo",
      },
      workspaceLanguageField: {
        label: "Email Language",
        name: "language",
        type: "select",
        options: LANGUAGES.map((l) => ({
          value: l["1"],
          text: l.name,
        })),
      },
      themeColorField: {
        label: "Theme Color",
        name: "theme",
        options: [
          { value: "green", hex: "#5CB65F" },
          { value: "orange", hex: "#FFA117" },
          { value: "blush", hex: "#E25985" },
          { value: "cyan", hex: "#17C2D7" },
          { value: "indigo", hex: "#9367B4" },
          { value: "red", hex: "#E15858" },
        ],
      },
      notifications: [
        {
          label: "Owners notified when new client is added to workspace",
          name: "notify_owner_on_new_client",
        },
        {
          label: "Owners notified when new user joins workspace",
          name: "notify_owner_on_new_user",
        },
        {
          label: "Workspace Owner receives CSAT results for all team members",
          name: "notify_owner_on_user_csat",
        },
      ],
      workspaceSettings: [
        /*
          {
            label: "Team members receive daily overdue feedback emails",
            name: "receive_overdue_notifications",
          },
           */
        {
          label: "Allow admins to access and edit workspace settings",
          name: "admins_can_edit_workspace_settings",
        },
      ],
      dateTimeSettings: [
        {
          label: "Timezone",
          name: "timezone",
          type: "select",
          options: timeZonesNames.map((tz) => ({
            value: tz,
            text: tz,
          })),
        },
        {
          label: "Format",
          name: "dt_format",
          type: "select",
          options: TIME_FORMAT.map((tf) => ({ value: tf, text: tf })),
        },
      ],
      portalSettings: [
        {
          label: "Allow client contacts to add guests to a project",
          name: "clients_allow_adding_guests",
        },
        {
          label: "Show production details in timeline",
          name: "show_production_details_in_timeline",
        },
      ],
      newWorkspaceLogo: {
        name: null,
        file: null,
        src: null,
        type: null,
      },
      isSavingSettings: false,
      isUploadingLogo: false,
      isLoadingSettings: false,
      timeZonesNames,
    };
  },
  computed: {
    workspaceUrls() {
      const domains = [];

      if (this.tenantInfo.custom_domains)
        domains.push(this.tenantInfo.custom_domains);

      if (this.settings.domain) domains.push(this.settings.domain);

      return domains;
    },
    canEditWorkspaceUrl() {
      // TODO
      return false;
    },
    isBccAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    isCsatSurveyAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
  },
  methods: {
    bccClicked() {
      if (!this.isBccAvailable)
        eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
    },
    makeCsatCompClicked() {
      if (!this.isCsatSurveyAvailable)
        eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
    },
    removeLogo() {
      this.$set(this.settings, this.workspaceLogoField.name, null);
    },
    onSelectTheme(color) {
      changeTheme(color.value);
      this.settings[this.themeColorField.name] = color.value;
    },
    onDeleteTenant() {
      this.isDeletingTenant = true;
      deleteTenant(this.$auth.tenant.id)
        .then(() => {
          this.$auth.logout({
            returnTo: window.location.origin,
          });
        })
        .catch((error) => {
          this.notifyError(error, "Failed to delete workspace");
          this.isDeletingTenant = false;
        });
    },
    uploadLogo() {
      if (this.isUploadingLogo || !this.newWorkspaceLogo?.file) return;

      this.isUploadingLogo = true;
      addTenantFile(
        this.$auth.tenant.id,
        "logo",
        this.newWorkspaceLogo.file,
        this.newWorkspaceLogo.name
      )
        .then((result) => {
          this.$set(this.settings, this.workspaceLogoField.name, result.path);
          this.isUploadingLogo = false;
          this.showCropImageModal = false;
        })
        .catch((error) => {
          this.notifyError(error, "Error uploading workspace logo");
          this.isUploadingLogo = false;
          this.showCropImageModal = false;
        });
    },
    clearWorkspaceLogoFile() {
      if (this.newWorkspaceLogo.src) {
        URL.revokeObjectURL(this.newWorkspaceLogo.src);
      }

      this.newWorkspaceLogo = {
        name: null,
        file: null,
        src: null,
        type: null,
      };
    },
    clearLogoInput() {
      document.getElementById("workspace-logo-file-input").value = null;
    },
    loadWorkspaceLogoFile(event) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.newWorkspaceLogo.src) {
          URL.revokeObjectURL(this.newWorkspaceLogo.src);
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          this.newWorkspaceLogo = {
            name: files[0].name,
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
          this.showCropImageModal = true;
        };

        reader.readAsArrayBuffer(files[0]);
      }
    },
    saveDetails: _.debounce(function () {
      if (this.isSubscriptionReadOnly()) return;

      this.isSavingSettings = true;

      const settings = { ...this.settings };

      delete settings.custom_domains;

      const daysAllowedForClientFeedback =
        settings.days_allowed_for_client_feedback;

      if (!this.isBccAvailable) {
        delete settings.bcc_email;
      } else if (
        settings.bcc_email?.trim()?.length &&
        !isEmail(settings.bcc_email)
      ) {
        delete settings.bcc_email;
        this.notifyError("BCC email is not valid");
      }

      if (
        !daysAllowedForClientFeedback?.length ||
        daysAllowedForClientFeedback < 1
      ) {
        settings.days_allowed_for_client_feedback = 1;
      }

      updateTenantProfile(this.$auth.tenant.id, settings)
        .then((result) => {
          this.$store.commit("SET_TENANT_PROFILE", result);
          this.isSavingSettings = false;
          this.notifySuccess("Workspace Settings saved");
        })
        .catch((error) => {
          this.notifyError(error, "Error updating workspace");
          this.isSavingSettings = false;
        });
    }, 1500),
  },
  watch: {
    settings: {
      deep: true,
      handler: function () {
        if (this.isLoadingSettings) return;

        this.saveDetails();
      },
    },
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    this.tenantInfo = await this.getTenantInfo();

    this.isLoadingSettings = true;
    getTenantProfile(this.$auth.tenant.id)
      .then((res) => {
        const settings = {
          bcc_email: null,
          days_allowed_for_client_feedback: 1,
          ...res,
        };

        if (
          !(
            this.$auth.user.admin && settings.admins_can_edit_workspace_settings
          )
        ) {
          if (!this.$auth.user.owner) {
            this.$router.push("/");
            return;
          }
        }

        this.settings = settings;
        if (!this.isCsatSurveyAvailable)
          this.settings.csat_mandatory_before_project_complete = false;

        this.$nextTick(() => {
          this.isLoadingSettings = false;
        });
      })
      .catch((error) => {
        this.notifyError(error, "Error loading workspace settings");
        this.isLoadingSettings = false;
      });
    // TODO get the license plan that is active
    // TODO get the workspace owner
  },
  destroyed() {
    if (this.newWorkspaceLogo?.src) {
      URL.revokeObjectURL(this.newWorkspaceLogo.src);
    }
  },
};
</script>

<style lang="css">
.list-group-item {
  border: none;
}

.btnTheme {
  height: 2rem;
  width: 2rem;
  border-radius: 0.125rem;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.btnTheme:focus {
  outline: 0;
}

.btnTheme i {
  color: #fff;
}

.btnRemoveLogo {
  font-size: 11px;
  border: none;
}
</style>
