<template>
  <Modal
    title="Multi-lingual email templates"
    :show-close-button="true"
    @submit="$emit('submit', language)"
    @close="$emit('close')"
    submit-text="Create"
    :close-on-outside-click="true"
  >
    <div class="container">
      <form-input-group
        :field="{
          type: 'select',
          label: 'Select language',
          name: 'language',
          options: languageOptions,
        }"
        v-model="language"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";
import { LANGUAGES } from "@/utils/app.constant";

export default {
  name: "EmailTemplateAddLanguageModal",
  components: { FormInputGroup, Modal },
  props: {
    exclude: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      language: "en",
    };
  },
  computed: {
    languageOptions() {
      return LANGUAGES.map((l) => ({
        value: this.exclude.includes(l["1"]) ? 0 : l["1"], // to set as disabled if excluded
        text: l.name,
      }));
    },
  },
  mounted() {
    this.language =
      this.languageOptions.filter((lo) => lo.value)?.[0]?.value ?? "en";
  },
};
</script>
