<template>
  <Modal
    title="Pending Setup"
    :show-close-button="false"
    :show-cancel-button="true"
    :show-submit-button="true"
    @close="goBack"
    @submit="goToEmailTemplates"
    cancel-text="Back"
    submit-text="Email Templates"
    :close-on-outside-click="false"
  >
    <div class="container">
      <p>
        In order to send multi-updates, you need to set up an email template
        first.
      </p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "PendingMultiUpdateEmailTemplateSetupModal",
  components: { Modal },
  methods: {
    goBack() {
      if (window.history.state.back === null) {
        this.$router.push("/");
      } else {
        this.$router.back();
      }
    },
    goToEmailTemplates() {
      this.$router.push({ name: "templates-email-templates" });
    },
  },
};
</script>
