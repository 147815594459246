<template>
  <Modal
    :show-close-button="false"
    :showSubmitButton="false"
    :maxWidth="700"
    @close="$emit('close')"
    :close-on-outside-click="true"
    cancel-text="Close"
  >
    <div class="container">
      <div v-if="stageNotes?.length > 1" class="align-right">
        <span @click="onPrevClick"
          ><i
            class="icon-arrow-left cursor-pointer mr-2 fa-2x icon-custom-medium"
          ></i
        ></span>
        <span @click="onNextClick"
          ><i
            class="icon-arrow-right cursor-pointer fa-2x icon-custom-medium"
          ></i
        ></span>
      </div>
      <div class="form-group mt-2 mb-0">
        <NotesTemplate
          v-if="selectedNotes"
          :selected-notes="selectedNotes"
          :alwaysScrollable="true"
          class="body"
        >
        </NotesTemplate>
      </div>
    </div>
  </Modal>
</template>
<script>
import NotesTemplate from "./NotesTemplate.vue";
import Modal from "../Modals/Modal.vue";
export default {
  components: {
    NotesTemplate,
    Modal,
  },
  name: "NotesModel",
  props: {
    stageNotes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chosenNotesIndex: 0,
      isHide: false,
    };
  },
  computed: {
    selectedNotes() {
      return this.stageNotes[this.chosenNotesIndex];
    },
  },
  methods: {
    onNextClick() {
      this.chosenNotesIndex =
        (this.chosenNotesIndex + 1) % this.stageNotes.length;
      return this.chosenNotesIndex;
    },
    onPrevClick() {
      this.chosenNotesIndex = this.chosenNotesIndex - 1;
      if (this.chosenNotesIndex < 0) {
        this.chosenNotesIndex = this.stageNotes.length - 1;
      }
      return this.chosenNotesIndex;
    },
  },
};
</script>
<style>
.custom-icon-color {
  color: gray;
}
</style>
