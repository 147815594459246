<template>
  <ApexChart
    type="bar"
    :chartHeight="chartHeight"
    :chartData="chartData"
  ></ApexChart>
</template>

<script>
import ApexChart from "./ApexChart.vue";

export default {
  name: "StageProductionRound",
  components: {
    ApexChart,
  },
  props: {
    stageProductionDurationGraphData: Object,
  },
  data() {
    return {
      chartHeight: 350,
    };
  },
  computed: {
    chartData() {
      const series =
        Object.keys(this.stageProductionDurationGraphData).length > 0
          ? this.generateSeries(this.stageProductionDurationGraphData)
          : undefined;
      const chartOptions = this.generateChartOptions();
      return {
        series: series,
        chartOptions: chartOptions,
      };
    },
  },
  methods: {
    generateSeries(options) {
      const [, ...values] = options?.budgeted;
      const dataPoints = values.map((value, i) => {
        return {
          x: options?.categories[i],
          y: value,
          goals: [
            {
              name: "Executed",
              value: options?.executed[i + 1],
              strokeHeight: 5,
              strokeColor: "#00e396",
            },
          ],
        };
      });
      return [
        {
          name: "Budgeted",
          data: dataPoints,
        },
      ];
    },
    generateChartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return Math.round(value); // Rounds off the value to the nearest whole number
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["Budgeted", "Executed"],
          markers: {
            fillColors: ["#008ffb", "#00e396"],
          },
        },
        grid: {
          borderColor: "#90A4AE",
        },
        colors: ["#008ffb", "#00e396"],
      };
    },
  },
};
</script>
<style></style>
