<template>
  <vue-apex-charts
    v-if="!areAllValuesZero"
    type="pie"
    ref="projectHealthChart"
    :height="chartHeight"
    :options="chartData.chartOptions"
    :series="chartData.series"
    class="custom-apex-chart"
  ></vue-apex-charts>
  <div v-else class="no-data">
    <p>No data available</p>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import analyticsMixin from "../../../mixins/analytics";

export default {
  name: "AverageProjectDaysBreakdown",
  mixins: [analyticsMixin],
  components: {
    VueApexCharts,
  },
  props: {
    timeRange: Array,
    projects_stats: Array,
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartHeight: 350,
      keyToLabelMap: {
        averageRevisionDays: "Average revision days",
        averageAwaitingFeedbackDays: "Average awaiting feedback days",
        averageOverdueFeedbackDays: "Average overdue feedback days",
        averageInProductionDays: "Average in production days",
      },
    };
  },
  computed: {
    areAllValuesZero() {
      return Object.values(this.totalProductionBreakdowns).every(
        (value) => value === 0
      );
    },
    clientProjects() {
      return this.projects.filter(
        (project) => project?.status?.allTasksCompleted
      );
    },
    chartData() {
      const chartOptions = this.generateChartOptions();
      return {
        series: this.totalProductionBreakdowns
          ? Object.values(this.totalProductionBreakdowns)
          : [],
        chartOptions: chartOptions,
      };
    },
    totalProductionBreakdowns() {
      return this.calculateAverageProjectDayBreakdown(this.clientProjects);
    },
  },
  methods: {
    generateChartOptions() {
      return {
        chart: {
          type: "pie",
        },
        labels: this.totalProductionBreakdowns
          ? Object.keys(this.totalProductionBreakdowns).map(
              (key) => this.keyToLabelMap[key] || key
            )
          : [],
        dataLabels: {
          enabled: true,
          style: {
            dropShadow: {
              enabled: false, // Disable drop shadow for data labels
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: [
          "#ff4560", // Red
          "#feb019", // Orange
          "#f5e52d", //yellow
          "#00e396", // Green
        ],
      };
    },
    calculateAverageProjectDayBreakdown(projects) {
      const totalProductionBreakdowns = {
        totalRevisionDays: 0,
        totalAwaitingFeedbackDays: 0,
        totalOverdueFeedbackDays: 0,
        totalInProductionDays: 0,
      };
      if (!projects.length) return totalProductionBreakdowns;
      let totalProjects = 0;
      projects.forEach((project) => {
        if (project?.workflow) {
          const daysBreakdowns = this.productionBreakdownInDays(
            project?.workflow?.tasks,
            project
          );
          totalProjects++;
          totalProductionBreakdowns.totalAwaitingFeedbackDays +=
            daysBreakdowns.totalAwaitingFeedbackDays;
          totalProductionBreakdowns.totalRevisionDays +=
            daysBreakdowns.totalRevisionDays;
          totalProductionBreakdowns.totalOverdueFeedbackDays +=
            daysBreakdowns.totalOverdueFeedbackDays;
          totalProductionBreakdowns.totalInProductionDays +=
            daysBreakdowns.totalInProductionDays;
        }
      });
      if (totalProjects) {
        const averageAwaitingFeedbackDays =
          Math.round(
            (totalProductionBreakdowns.totalAwaitingFeedbackDays /
              totalProjects) *
              10
          ) / 10;
        const averageRevisionDays =
          Math.round(
            (totalProductionBreakdowns.totalRevisionDays / totalProjects) * 10
          ) / 10;
        const averageOverdueFeedbackDays =
          Math.round(
            (totalProductionBreakdowns.totalOverdueFeedbackDays /
              totalProjects) *
              10
          ) / 10;
        const averageInProductionDays =
          Math.round(
            (totalProductionBreakdowns.totalInProductionDays / totalProjects) *
              10
          ) / 10;
        return {
          averageRevisionDays,
          averageAwaitingFeedbackDays,
          averageOverdueFeedbackDays,
          averageInProductionDays,
        };
      } else {
        return {
          averageRevisionDays: 0,
          averageAwaitingFeedbackDays: 0,
          averageOverdueFeedbackDays: 0,
          averageInProductionDays: 0,
        };
      }
    },
  },
  watch: {
    timeRange(newTimeRange) {
      const projectsToFilter = this.clientProjects;
      let filteredProjects = projectsToFilter;
      if (newTimeRange.length === 2) {
        const startTime = new Date(newTimeRange[0]);
        const endTime = new Date(newTimeRange[1]);

        filteredProjects = projectsToFilter.filter((project) => {
          const projectStartDate = new Date(project.start_date);
          return projectStartDate >= startTime && projectStartDate <= endTime;
        });
      }

      this.calculateAverageProjectDayBreakdown(filteredProjects);
    },
  },
};
</script>
<style scoped>
.no-data {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
