<template>
  <label
    class="image-placeholder-container"
    :for="!disabled && target"
    :class="{ 'no-image': !image }"
  >
    <img v-if="image" :src="image" class="image" />
    <template v-else>
      <span>{{ text }}</span>
      <i class="icon-cloud-upload" />
    </template>
  </label>
</template>

<script>
export default {
  name: "ClickableImagePlaceholder",
  props: {
    text: {
      type: String,
      default: "Upload Image",
    },
    image: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.image-placeholder-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  cursor: pointer;
  overflow: hidden;
}

.image-placeholder-container.no-image {
  border: 1px solid #959ca1;
}

span {
  font-size: 10px;
  color: #959ca1;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 3px 4px;
}

i {
  color: #959ca1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
