<template>
  <div class="previewBox d-flex-column row-12">
    <email-template-preview :value="emailTemplate" :language="language" />

    <div class="d-flex justify-content-end mt-4" style="padding-right: 20px">
      <button type="button" class="btn btn-danger" @click="close">Close</button>
    </div>
  </div>
</template>

<script>
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import { mergeTagsWithValue } from "../../../utils/app.constant";
import EmailTemplatePreview from "../EmailTemplates/EmailTemplatePreview.vue";

export default {
  name: "EmailTemplatePreviewModal",
  components: { EmailTemplatePreview },
  mixins: [textEditorCompilerMixin],
  props: {
    template: {
      type: String,
    },
    language: {
      type: String,
      default: "en",
    },
  },
  computed: {
    emailTemplate() {
      return this.replaceMergeTags(
        this.template,
        mergeTagsWithValue(this.language)
      );
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("email-template-preview-modal", state);
    },
  },
};
</script>
<style scoped>
.previewBox {
  padding: 20px;
}
</style>
