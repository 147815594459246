<template>
  <span v-if="items?.length">
    <div class="header-dropdown dropdown d-inline-block px-1">
      <div class="dropdown">
        <a data-toggle="dropdown" aria-haspopup="true">
          <img
            src="/assets/icons/dots-three-circle.svg"
            height="20px"
            width="20px"
            style="margin-top: -1px"
            alt="menu"
          />
        </a>
        <ul class="dropdown-menu text-nowrap">
          <li v-for="item in items" :key="item" @click="$emit('clicked', item)">
            <a v-if="item === menuItems.StartStage">
              <i class="fa fa-play-circle-o"></i>
              Start stage
            </a>
            <a v-else-if="item === menuItems.AddNote">
              <i class="icon-plus"></i>
              Add note
            </a>
            <a v-else-if="item === menuItems.SendMessage">
              <i class="icon-envelope"></i>
              Send message
            </a>
            <a v-else-if="item === menuItems.UndoLastAction">
              <i class="fa fa-undo"></i>
              Undo last action
            </a>
            <a v-else-if="item === menuItems.reOpenStage">
              <i class="fa fa-undo"></i>
              Re-open stage
            </a>
            <a v-else-if="item === menuItems.ClientApproved">
              <i class="fa fa-check"></i>
              Client approved
            </a>
          </li>
        </ul>
      </div>
    </div>
  </span>
</template>

<script>
export const makeMenu = (items) => items.filter((i) => i);
export const menuItems = {
  AddNote: "addNote",
  SendMessage: "sendMessage",
  UndoLastAction: "undoLastAction",
  StartStage: "startStage",
  reOpenStage: "reOpenStage",
  ClientApproved: "clientApproved",
};

export default {
  name: "StageMenu",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuItems,
    };
  },
};
</script>

<style>
.dropdown a {
  cursor: pointer;
}

.dropdown-menu li {
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.dropdown-menu li a {
  font-size: 14px;
  font-weight: 400;
  color: #191f28;
}

.dropdown-menu li a i {
  display: inline-block;
  width: 1rem;
  object-fit: contain;
  padding: 0;
  margin-right: 0.5rem;
}
</style>
