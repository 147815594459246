<template>
  <ApexChart
    type="bar"
    :chartHeight="chartHeight"
    :chartData="chartData"
  ></ApexChart>
</template>

<script>
import ApexChart from "./ApexChart.vue";

export default {
  name: "StageDurationChart",
  components: {
    ApexChart,
  },
  props: {
    tasks: Array, // Define tasks as a prop of type Array
  },
  data() {
    return {
      chartHeight: 350,
    };
  },
  computed: {
    chartData() {
      const series = this.tasks.length
        ? this.generateSeries(this.tasks)
        : undefined;
      const chartOptions = this.generateChartOptions();
      return {
        series: series,
        chartOptions: chartOptions,
      };
    },
  },
  methods: {
    generateSeries(tasks) {
      const data = tasks?.map((task) =>
        task.value === null || isNaN(task.value) ? 0 : task.value
      );
      return [{ data }];
    },
    generateChartOptions() {
      const categories = this.tasks?.map((task) => task.name);

      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["days"],
        },
        xaxis: {
          categories,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box px-4 py-2">' +
              "<span>" +
              w.globals.labels[dataPointIndex] +
              ": " +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "</div>"
            );
          },
        },
        colors: ["#00e396"],
      };
    },
  },
};
</script>
<style></style>
