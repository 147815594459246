<template>
  <ApexChart
    v-if="isLinear && !areAllValuesZero"
    type="pie"
    :chartHeight="chartHeight"
    :chartData="chartData"
  ></ApexChart>
  <div v-else-if="areAllValuesZero" class="no-data">
    <p>No data available</p>
  </div>
  <div v-else-if="!isLinear" class="no-data">
    <p>
      Project days breakdown analytic currently not available for non-linear
      projects.
    </p>
  </div>
</template>

<script>
import ApexChart from "./ApexChart.vue";

export default {
  name: "DurationBreakdownChart",
  components: {
    ApexChart,
  },
  props: {
    totalProductionBreakdowns: Object,
    isLinear: Boolean,
  },
  data() {
    return {
      chartHeight: 350,
      keyToLabelMap: {
        totalRevisionDays: "Total revision days",
        totalAwaitingFeedbackDays: "Total awaiting feedback days",
        totalOverdueFeedbackDays: "Total overdue feedback days",
        totalInProductionDays: "Total in production days",
      },
    };
  },
  computed: {
    areAllValuesZero() {
      return Object.values(this.totalProductionBreakdowns).every(
        (value) => value === 0
      );
    },
    chartData() {
      const chartOptions = this.generateChartOptions();
      return {
        series: this.totalProductionBreakdowns
          ? Object.values(this.totalProductionBreakdowns)
          : [],
        chartOptions: chartOptions,
      };
    },
  },
  methods: {
    generateChartOptions() {
      return {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: this.totalProductionBreakdowns
          ? Object.keys(this.totalProductionBreakdowns).map(
              (key) => this.keyToLabelMap[key] || key
            )
          : [],
        dataLabels: {
          enabled: true,
          style: {
            dropShadow: {
              enabled: false, // Disable drop shadow for data labels
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: [
          "#ff4560", // Red
          "#feb019", // Orange
          "#f5e52d", //yellow
          "#00e396", // Green
        ],
      };
    },
  },
};
</script>
<style>
.no-data {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
