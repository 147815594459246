<template>
  <div class="previewBox d-flex-column row-12">
    <email-template-preview
      :value="surveyEmailTemplate"
      :project="project"
      :client="client"
    />

    <div class="d-flex justify-content-end mt-4" style="padding-right: 20px">
      <button type="button" class="btn btn-danger" @click="close">
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary ml-3"
        @click="sendDeliverable"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import EmailTemplatePreview from "../EmailTemplates/EmailTemplatePreview.vue";

export default {
  name: "SurveryEmailPreviewModal",
  components: { EmailTemplatePreview },
  mixins: [textEditorCompilerMixin],
  props: {
    selectedSurvey: {
      type: Object,
      default: () => {},
    },
    template: {
      type: String,
    },
    project: {
      type: Object,
    },
    client: {
      type: Object,
    },
  },
  computed: {
    surveyEmailTemplate() {
      return this.template.replace(
        /\[survey link\]/g,
        this.updateHtmlWithQuestion(
          this.getQuestionInEmail(this.selectedSurvey)
        ) ?? ""
      );
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("survery-email-preview-modal", state);
    },
    async sendDeliverable() {
      this.close({ value: "send" });
    },
  },
};
</script>
<style scoped>
.previewBox {
  padding: 20px;
}
</style>
