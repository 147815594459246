const config = require("./config.json");
const { get, create, update } = require("./common").default;

exports.getTenantInfo = async (domain) => {
  return await get(config.apis.base.url + "tenants/" + domain);
};
exports.updateCustomDomain = async (custom_domains) => {
  return await update(config.apis.base.url + "tenants", { custom_domains });
};

exports.getTenantsForUser = async (email) => {
  return await get(config.apis.base.url + "tenants/users/" + email);
};

exports.registerTenant = async (data) => {
  return await create(config.apis.base.url + "tenants/signup", data);
};

exports.sendEmail = async (recipient, emailType) => {
  return await create(config.apis.base.url + "email/" + emailType, {
    recipient,
  });
};
