const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

const templatesUrl = config.apis.workflows.url + "templates/";

exports.getWorkflowTemplates = async () => {
  return await get(templatesUrl);
};

exports.getWorkflowTemplateById = async (id) => {
  return await get(`${templatesUrl}${id}`);
};

exports.addWorkflowTemplate = async (workflowTemplate) => {
  return await create(templatesUrl, workflowTemplate);
};

/**
 * Update the given workflowTemplate object.
 * An id must be provided and @param workflowTemplate.id takes precedence over @param workflowTemplateId
 * @param {object} workflowTemplate The object to update. The id can be omitted if specified in @param workflowTemplateId, but is required otherwise
 * @param {number} workflowTemplateId Optional. Needed only if @param workflowTemplate does not have an id property
 * @returns
 */
exports.updateWorkflowTemplate = async (
  workflowTemplate,
  workflowTemplateId
) => {
  if (!workflowTemplate.id || workflowTemplate.id < 1) {
    if (!workflowTemplateId || workflowTemplateId < 1) {
      throw new Error("No valid Workflow Template ID provided");
    }
    workflowTemplate.id = workflowTemplateId;
  }
  return await update(templatesUrl + workflowTemplate.id, workflowTemplate);
};

exports.deleteWorkflowTemplate = async (workflowTemplate) => {
  return this.deleteWorkflowTemplateById(workflowTemplate.id);
};

exports.deleteWorkflowTemplateById = async (id) => {
  return await remove(templatesUrl + id);
};
