<template>
  <div class="body previewEmailBox" v-html="html" style="overflow-y: auto" />
</template>

<script>
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
export default {
  name: "EmailTemplatePreview",
  mixins: [textEditorCompilerMixin],
  props: {
    value: String,
    revision: Object, // Task (Revision)
    workflow: Object, // Workflow (needed?)
    project: Object,
    client: Object,
    reviewLink: String,
    language: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    replacePlaceholdersHelper(text) {
      return this.replacePlaceholders(
        text,
        this.workflow,
        this.client,
        this.revision,
        this.project,
        this.reviewLink,
        true,
        this.language
      );
    },
  },
  computed: {
    html() {
      return this.text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    },
  },
  mounted() {
    this.text = this.replacePlaceholdersHelper(this.value);
  },
  watch: {
    $attrs: function () {
      this.text = this.replacePlaceholdersHelper(this.value);
    },
  },
};
</script>

<style>
.previewEmailBox {
  border: 1px solid #e1e8ed;
  padding: 10px;
  margin: 20px;
}

.previewEmailBox p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.previewEmailBox table {
  width: auto;
}
</style>
