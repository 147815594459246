export default (theme) => {
  const body = document.getElementsByTagName("body")[0];

  // remove existing theme(s)
  Array.from(body.classList)
    .filter((c) => c.includes("theme"))
    .forEach((c) => body.classList.remove(c));

  // add new theme
  body.classList.add(`theme-${theme}`);
};
