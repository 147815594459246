<template>
  <div class="row clearfix">
    <div class="col-12">
      <div class="table-responsive">
        <table
          :id="identifier"
          :class="[
            'table',
            'table-hover',
            'table-custom',
            'spacing8',
            'dataTable',
            identifier,
          ]"
        >
          <thead>
            <tr>
              <th>User</th>
              <th style="text-align: center">Clients serviced</th>
              <th style="text-align: center">Rated Projects</th>
              <th style="padding-left: 20px">Average Rating</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userRating, index) in topFiveUsersRating" :key="index">
              <td>{{ userRating.user_name }}</td>
              <td style="text-align: center">{{ userRating.client_count }}</td>
              <td style="text-align: center">{{ userRating.project_count }}</td>
              <td style="display: flex">
                <star-rating :value="userRating.rating / 5 || 0"></star-rating>
                <span style="margin-left: 10px">
                  {{ userRating.rating.toFixed(1) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "../Dashboard/StarRating.vue";
export default {
  name: "UserRatingTable",
  components: {
    StarRating,
  },
  props: {
    usersProjectSurveyAverageRating: Array,
    identifier: {
      type: String,
      default: "user-rating-js-data-table",
    },
  },
  data() {
    return {
      datatable: null,
    };
  },
  computed: {
    topFiveUsersRating() {
      if (
        !this.usersProjectSurveyAverageRating ||
        this.usersProjectSurveyAverageRating.length === 0
      ) {
        return [];
      }

      const usersRatingCopy = [...this.usersProjectSurveyAverageRating];

      const sortedUsersRating = usersRatingCopy.sort(
        (a, b) => b.rating - a.rating
      );

      return sortedUsersRating;
    },
  },
  async created() {},
  methods: {
    buildDataTable() {
      if (this.datatable) this.datatable.destroy();

      if (this.usersProjectSurveyAverageRating?.length) {
        this.datatable = true;
        setTimeout(() => {
          this.datatable = window.jsDataTable(`#${this.identifier}`, {
            pageLength: 5,
            searching: false, // Disable the search box
            lengthChange: false, // Disable the entries dropdown
            ordering: false, // Disable sorting
          });
        }, 1000); // Just giving Vue some time to render the table
      }
    },
  },
  watch: {
    usersProjectSurveyAverageRating: function () {
      this.buildDataTable();
    },
  },
  mounted() {
    this.buildDataTable();
  },
  beforeDestroy() {
    if (this.datatable) this.datatable.destroy();
  },
};
</script>
