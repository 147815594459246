<template>
  <div style="editor-wrapper">
    <vue-editor
      theme="snow"
      v-model="editorContent"
      ref="editor"
      :editor-toolbar="customToolbar"
      @mounted="setEditorHeight"
      :placeholder="placeholder"
    >
      <template #toolbar>
        <div id="my-toolbar" style="display: flex; justify-content: end">
          <merge-tags-dropdown
            v-if="templateType"
            @change="insertMergeTag($event)"
            :emailTemplateFilter="templateType"
          ></merge-tags-dropdown>
        </div>
      </template>
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import MergeTagsDropdown from "./EmailTemplates/MergeTagsDropdown.vue";
export default {
  name: "TextEditor",
  components: {
    VueEditor,
    MergeTagsDropdown,
  },
  props: {
    editorText: {
      type: String,
      default: "",
    },
    templateType: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "",
    },
    customToolbar: {
      type: Array,
      default: () => [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],

        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],

        ["blockquote", "code-block"],

        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ script: "sub" }, { script: "super" }],

        [{ indent: "-1" }, { indent: "+1" }],

        [{ color: [] }, { background: [] }],
        ["link"],

        [{ direction: "rtl" }],
        ["clean"],
      ],
    },
  },
  data() {
    return {
      editorContent: this.editorText,
      maxEditorHeight: 300,
      // Link to get full toolbar list: https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/fullToolbar.js
    };
  },
  watch: {
    editorText(newVal) {
      this.editorContent = newVal;
    },
    editorContent(newVal) {
      this.$emit("on-editor-change", newVal);
    },
  },
  methods: {
    insertMergeTag(mergeTag) {
      this.addTextAtCursor(mergeTag);
    },
    addTextAtCursor(mergeTag) {
      const textToInsert = mergeTag.value;
      // Get the editor instance
      const editorInstance = this.$refs.editor.quill;
      editorInstance.focus();
      // Get the current cursor position
      const cursorPosition = editorInstance.getSelection().index;
      //reson for doing focus blue is text not updating in editor
      editorInstance.blur();
      editorInstance.editor.insertText(cursorPosition, textToInsert);
      this.editorContent = editorInstance.root.innerHTML;
    },
    setEditorHeight() {
      const editorWrapper = this.$refs.editor.$el.querySelector(".ql-editor");
      editorWrapper.style.maxHeight = "300px"; // Adjust this value as needed
    },
  },
};
</script>
<style>
.ql-container {
  overflow-y: auto !important;
  max-height: 15rem !important;
  min-height: 13rem !important;
  font-family: Montserrat !important;
}
.ql-tooltip {
  position: fixed !important;
  top: 50% !important;
  left: 35% !important;
  padding: 10px !important;
}
</style>
