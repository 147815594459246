<template>
  <div>
    <b v-if="field.label && showLabel">
      <label
        v-if="field.type == 'checkbox'"
        :for="`input-${field.name}`"
        style="width: 100%; text-align: center"
        >{{ field.label }}</label
      >
      <label v-else :for="`input-${field.name}`"
        ><span v-html="field.label"></span
      ></label>
    </b>

    <div class="input-group mb-3">
      <!-- <div class="input-group-prepend">
            <span class="input-group-text">@</span>
            </div> -->
      <date-time-picker
        v-if="field.type === 'date'"
        :value="processedValue"
        type="date"
        format="YYYY-MM-DD"
        placeholder="Choose date"
        @input="(e) => emitDateChange(e)"
      >
      </date-time-picker>

      <input
        v-else-if="field.type !== 'select'"
        :value="value"
        :id="`input-${field.name}`"
        :name="field.name"
        :type="field.type || 'text'"
        :aria-label="field.name"
        :placeholder="field.placeholder || field.label"
        :readonly="readonly || field.readonly"
        :maxlength="field.maxLength"
        :min="field.min"
        :max="field.max"
        class="form-control"
        aria-describedby="basic-addon1"
        v-bind="$attrs"
        ref="input"
        @input="
          (e) =>
            $emit(
              'input',
              field.type == 'checkbox' ? e.target.checked : e.target.value
            )
        "
        @blur="
          (e) =>
            $emit(
              'blur',
              field.type == 'checkbox' ? e.target.checked : e.target.value
            )
        "
      />

      <select
        v-else
        class="form-control"
        :id="`input-${field.name}`"
        :name="field.name"
        :aria-label="field.name"
        aria-describedby="basic-addon1"
        @input="(e) => $emit('input', e.target.value)"
        v-bind="$attrs"
      >
        <option
          v-for="option in field.options"
          :key="option.value"
          :value="option.value"
          :disabled="option.value === 0"
          :selected="value == option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <div class="input-group-append">
        <slot name="input-group-append"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../mixins/time";
import DateTimePicker from "@/components/ui/DateTimePicker.vue";
import { DateTime } from "luxon";

export default {
  name: "FormInputGroup",
  mixins: [timeMixin],
  components: {
    DateTimePicker,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: [String, Number, Boolean, Date],
    readonly: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * @param {Date} e JS Date object of date chosen (@ midnight)
     * @returns {String} Date part for ISO/UTC of date chosen
     *
     * A note on the datepicker component. It is important to use the (use-utc=true) prop to ensure that the date is handled properly.
     * Prior to using this, since the default is false, I was getting issues with the date being off by 1 day.
     * Below is an explanation of some workarounds and behaviour I had before.
     * 
     * If I choose a date (example the 10th in the control) it returns different date/times depending on the timezone...
     * If my browser has a +ve timezone (GMT +#), the date chosen is the 10th at midnight UTC.
     *    Example for Malta (GMT +2):
     *      e = JS Date => 'Mon Apr 10 2023 02:00:00 GMT+0200 (Central European Summer Time)'
     *      e.toISOString() => 2023-04-10T00:00:00.000Z
            FormInputGroup.vue:117 lux 2023-04-10 02:00:00
            FormInputGroup.vue:118 lux to js date 2023-04-10T00:00:00.000Z
     * If my browser has a -ve timezone (GMT -#), the date chosen is the 10th at (24-#) hrs, which when converted to UTC yields the 11th at midnight
     *    Example for Sao Paolo (GMT -3):
     *      e = JS Date -> 'Mon Apr 10 2023 21:00:00 GMT-0300 (Horário Padrão de Brasília)'
     *      e.toISOString() => 2023-04-11T00:00:00.000Z
            FormInputGroup.vue:108 lux 2023-04-10 21:00:00
            FormInputGroup.vue:109 lux to js date 2023-04-11T00:00:00.000Z
     * RESOLUTION: Get the Luxon date, format to YYYY-MM-DD to strip the time part and use that as the value to emit.
     */
    emitDateChange(e) {
      if (e) {
        let ldt = this.getLuxonDateTime(e, false, false); // to UTC
        // console.log('emit', typeof e, e, e.toISOString(), e.toISOString().substring(0,10));
        // console.log('lux', ldt.toFormat('yyyy-MM-dd HH:mm:ss')); // converted to local
        // console.log('lux to js date', ldt.toJSDate().toISOString()); // UTC time, midnight
        // this.$emit('input', ldt.toFormat('yyyy-MM-dd'));
        this.$emit("input", ldt.toFormat("yyyy-MM-dd"));
      } else {
        this.$emit("input", null);
      }
    },
  },
  computed: {
    processedValue() {
      if (this.field.type === "date" && typeof this.value === "string")
        return DateTime.fromISO(this.value).toJSDate();

      return this.value;
    },
  },
};
</script>
