var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{class:[
          'table',
          'table-hover',
          'table-custom',
          'spacing8',
          'dataTable',
          _vm.identifier,
        ],attrs:{"id":_vm.identifier}},[_vm._m(0),_c('tbody',_vm._l((_vm.topFiveUsersRating),function(userRating,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(userRating.user_name))]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(userRating.client_count))]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(userRating.project_count))]),_c('td',{staticStyle:{"display":"flex"}},[_c('star-rating',{attrs:{"value":userRating.rating / 5 || 0}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(userRating.rating.toFixed(1))+" ")])],1)])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("User")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Clients serviced")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Rated Projects")]),_c('th',{staticStyle:{"padding-left":"20px"}},[_vm._v("Average Rating")])])])
}]

export { render, staticRenderFns }