const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

const baseUrl = config.apis.base.url;
const contactsUrl = baseUrl + "contacts/";

exports.getContacts = async (filter) => {
  const contacts = await get(contactsUrl);

  return this.filterContacts(contacts, filter);
};

exports.getContactById = async (id) => {
  return await get(contactsUrl + id);
};
exports.getAllContactAccess = async () => {
  return await get(contactsUrl + "access");
};

exports.associateContactToResource = async (
  contactId,
  resourceType,
  resourceId,
  type
) => {
  return await create(
    `${baseUrl}/${resourceType}/${resourceId}/contacts/${contactId}/${type}`
  );
};

exports.associateContactToProject = async (contactId, projectId, type) => {
  return this.associateContactToResource(
    contactId,
    "projects",
    projectId,
    type
  );
};

exports.associateContactToClient = async (contactId, clientId, type) => {
  return this.associateContactToResource(contactId, "clients", clientId, type);
};

exports.filterContacts = (contacts, filter) => {
  if (filter) {
    return contacts.filter((contact) =>
      contact.name.toLowerCase().includes(filter.toLowerCase())
    );
  }

  return contacts;
};

exports.addContact = async (contact) => {
  return await create(contactsUrl, contact);
};

/**
 * Update the given contact object.
 * An id must be provided and @param contact.id takes precedence over @param contactId
 * @param {object} contact The object to update. The id can be omitted if specified in @param contactId, but is required otherwise
 * @param {number} contactId Optional. Needed only if @param contact does not have an id property
 * @returns
 */
exports.updateContact = async (contact, contactId) => {
  if (!contact.id || contact.id < 1) {
    if (!contactId || contactId < 1) {
      throw new Error("No valid contact ID provided");
    }
    contact.id = contactId;
  }
  return await update(contactsUrl + contact.id, contact);
};

exports.deleteContact = async (contact) => {
  return this.deleteContactById(contact.id);
};

exports.deleteContactById = async (id) => {
  return await remove(contactsUrl + id);
};
