<template>
  <Modal
    :title="title"
    :show-close-button="true"
    :show-submit-button="false"
    @close="$emit('close')"
    cancel-text="Close"
    :close-on-outside-click="true"
    :disable-submit-button="isSubscriptionReadOnly()"
  >
    <div class="container">
      <p v-html="text"></p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "WarningOrErrorModal",
  components: { Modal },
  props: {
    title: {
      type: String,
      default: "Warning/Error",
    },
    text: {
      type: String,
      default: "Warning/Error message",
    },
  },
};
</script>
