<template>
  <div class="text-center">
    <input
      type="text"
      class="knob"
      :name="name"
      :value="value"
      data-width="150"
      data-height="150"
      data-thickness="0.25"
      data-angleArc="180"
      data-angleoffset="-90"
      data-bgColor="#30373e"
      data-fgColor="#64c8c0"
      readonly
    />
  </div>
</template>

<script>
export default {
  name: "Knob",
  props: {
    value: Number,
    name: String,
  },
  mounted() {
    window.refreshKnob(this.name, this.value);
  },
  watch: {
    value() {
      window.refreshKnob(this.name, this.value);
    },
  },
};
</script>
