<template>
  <vue-apex-charts
    :type="chartType"
    ref="projectproductionChart"
    :height="chartHeight"
    :options="chartData.chartOptions"
    :series="chartData.series"
    class="custom-apex-chart"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import analyticsMixin from "../../../mixins/analytics";

export default {
  name: "AverageProjectProductionChart",
  mixins: [analyticsMixin],
  components: {
    VueApexCharts,
  },
  props: {
    timeRange: Array,
    projects_stats: Array,
    projects: {
      type: Array,
      default: () => [],
    },
    archivedEvents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartHeight: 350,
      chartType: "area",
      selection: "one_year",
      maxYAxisValue: 5,
    };
  },
  computed: {
    clientProjects() {
      return this.projects;
    },
    chartOptions() {
      return this.getChartOptions(this.maxYAxisValue);
    },
    chartData() {
      return {
        series: this.generateSeriesDataForOpenProjects(
          this.clientProjects,
          this.timeRange
        ),
        chartOptions: this.chartOptions,
      };
    },
    getArchivedEventsMap() {
      const { archiveMap } = this.createArchivedCancelledEventsMap(
        this.archivedEvents
      );
      return archiveMap;
    },
    getCancelledEventsMap() {
      const { cancelledMap } = this.createArchivedCancelledEventsMap(
        this.archivedEvents
      );
      return cancelledMap;
    },
  },
  methods: {
    isDateToday(inputDate) {
      if (!inputDate) {
        return false;
      }

      const today = new Date();
      const input = new Date(inputDate);

      return (
        input.getDate() === today.getDate() &&
        input.getMonth() === today.getMonth() &&
        input.getFullYear() === today.getFullYear()
      );
    },
    getChartOptions(maxYAxisValue) {
      return {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: maxYAxisValue,
        },
        annotations: {
          yaxis: [
            {
              borderColor: "#999",
              forceNiceScale: true,
              decimalsInFloat: 0,
              label: {
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
          ],
          xaxis: [
            {
              borderColor: "#999",
              yAxisIndex: 0,
              tickAmount: 4,
              label: {
                show: true,
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      };
    },
    zoomChartXAxis(timeRange) {
      if (timeRange.length === 2) {
        this.$refs.projectproductionChart.chart.zoomX(
          timeRange[0].getTime(),
          timeRange[1].getTime()
        );
      } else if (timeRange.length === 0 && this.projects_stats.length) {
        this.$refs.projectproductionChart.chart.zoomX(
          new Date(this.projects_stats[0].start_date).getTime(),
          new Date(
            this.projects_stats[this.projects_stats.length - 1].start_date
          ).getTime()
        );
      }
    },
    generateSeriesDataForOpenProjects(projects, timeRange) {
      if (!projects.length)
        return [
          {
            name: "Active Projects Count",
            data: [],
          },
        ];
      let startDate = new Date(timeRange[0]);
      startDate.setHours(0, 0, 0, 1); // Set to 00:00:00
      let endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      if (!timeRange.length) {
        startDate = projects[0].start_date
          ? new Date(projects[0].start_date)
          : new Date(projects[0].created_on);
        endDate = new Date();
      }

      const activeProjectsPerDate = {};
      let maxCount = this.maxYAxisValue;
      projects.forEach((project) => {
        const projectStartDate = project.start_date
          ? new Date(project.start_date)
          : new Date(project.created_on);
        if (!projectStartDate) return;
        const allTasksCompleted = project.status?.allTasksCompleted || false;
        const latestApprovedDate = project.status?.latestApprovedDate
          ? new Date(project.status.latestApprovedDate)
          : null;

        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          const dateString = currentDate.toLocaleDateString();
          if (
            this.isProjectArchived(
              this.getArchivedEventsMap,
              project.id,
              dateString
            )
          ) {
            currentDate.setDate(currentDate.getDate() + 7);
            continue;
          }
          if (
            this.isProjectCancelled(
              this.getCancelledEventsMap,
              project.id,
              dateString
            )
          ) {
            currentDate.setDate(currentDate.getDate() + 7);
            continue;
          }

          if (
            (projectStartDate <= currentDate && !allTasksCompleted) ||
            (allTasksCompleted &&
              latestApprovedDate &&
              new Date(latestApprovedDate.toLocaleDateString()) >=
                new Date(dateString) &&
              new Date(dateString) >= projectStartDate)
          ) {
            activeProjectsPerDate[dateString] =
              (activeProjectsPerDate[dateString] || 0) + 1;
            if (activeProjectsPerDate[dateString] > maxCount)
              maxCount = activeProjectsPerDate[dateString];
          } else {
            activeProjectsPerDate[dateString] =
              activeProjectsPerDate[dateString] || 0;
          }
          if (currentDate.getDate() + 7 > new Date()) {
            currentDate.setDate(currentDate.getDate() + 1);
          } else {
            currentDate.setDate(currentDate.getDate() + 7);
          }
        }
      });
      this.maxYAxisValue = Math.ceil(maxCount / 5) * 5;

      const activeProjectsData = Object.keys(activeProjectsPerDate)
        .map((dateString) => [
          new Date(dateString).getTime(),
          activeProjectsPerDate[dateString],
        ])
        .sort((a, b) => a[0] - b[0]);

      return [
        {
          name: "Active Projects Count",
          data: activeProjectsData,
        },
      ];
    },
  },
  watch: {
    timeRange(newTimeRange) {
      this.zoomChartXAxis(newTimeRange);
      if (this.clientProjects.length) {
        this.generateSeriesDataForOpenProjects(
          this.clientProjects,
          newTimeRange
        );
      }
    },
  },
};
</script>
<style></style>
