<template>
  <div class="row clearfix">
    <!-- Search bar -->
    <div class="col-12" v-if="showSearchBar">
      <div class="card">
        <div class="body">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  @input="search($event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ref. Code"
                  @input="searchField('reference_code', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Default Subject"
                  @input="
                    searchField(
                      'email_subject_line_default',
                      $event.target.value
                    )
                  "
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <a
                href="javascript:void(0);"
                class="btn btn-sm btn-primary btn-block"
                title=""
                >Search</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="table-responsive">
        <table
          :id="identifier"
          :class="[
            'table',
            'table-hover',
            'table-custom',
            'spacing8',
            'dataTable',
            identifier,
          ]"
        >
          <thead>
            <tr>
              <!-- <th>#</th> -->
              <th>Starting Date</th>
              <th>Deliverable Name</th>
              <th>Status</th>
              <th v-if="!isArchivedProjects">Current Stage</th>
              <!-- <th v-if="$auth.user.isAgent">Stage revisions</th>
              <th v-if="$auth.user.isAgent">Exec./Total revs.</th> -->
              <th>Production Progress</th>
              <th v-if="$auth.user.isAgent">Link Clicked</th>
              <th v-if="$auth.user.isAgent">Settings</th>
            </tr>
          </thead>
          <tbody v-if="filteredDeliverables?.length">
            <tr v-for="model in filteredDeliverables" :key="model.id">
              <td :data-sort="model.dateFieldValue">{{ model.dateField }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'project-timeline',
                    params: {
                      projectId: project.id,
                      workflowId: model.workflow_id,
                    },
                  }"
                  :title="model.workflow_title"
                  >{{ model.workflow_title }}</router-link
                >
              </td>
              <td :data-order="model.statusFieldValue">
                <span :class="model.statusFieldClass">{{
                  model.statusField
                }}</span>
              </td>
              <td v-if="!isArchivedProjects">
                {{ model.stageField }}
                <stage-quick-approve-or-reject
                  v-if="
                    $auth.user.isAgent &&
                    model.stageField !== 'Multiple stages' &&
                    model.stageField !== '--' &&
                    ['awaiting_feedback', 'overdue_feedback'].includes(
                      model?.revision?.status
                    )
                  "
                  class="ml-3"
                  :stage="model.stage"
                  @on_stage_approve_click="
                    onApproveStageClick(model.workflow, model.stage)
                  "
                  @on_stage_reject_click="
                    onRejectStageClick(model.workflow, model.stage)
                  "
                  :isProjectCancelled="Boolean(model.cancelled)"
                />
              </td>
              <td :data-order="model.productionProgress">
                <progress-bar
                  :value="model.productionProgress"
                  :color="model.progressColor"
                  :isProjectCancelled="Boolean(model.cancelled)"
                />
              </td>
              <td v-if="$auth.user.isAgent">
                {{ model.lastClicked }}
              </td>
              <td v-if="$auth.user.isAgent">
                <router-link
                  :to="{
                    name: 'project-details',
                    params: { projectId: project.id },
                  }"
                  title="settings"
                  >Edit</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../mixins/time";
import workflowMixin from "../../mixins/workflow";
import { approveStage, rejectStage } from "../../apis/workflows";
import ProgressBar from "./ProgressBar.vue";
import StageQuickApproveOrReject from "../projects/StageQuickApproveOrReject.vue";

export default {
  components: { ProgressBar, StageQuickApproveOrReject },
  name: "ProjectTimelinesTable",
  mixins: [workflowMixin, timeMixin],
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    showSearchBar: Boolean,
    identifier: {
      type: String,
      default: "js-data-table",
    },
  },
  data() {
    return {
      filterString: "",
      fieldFilters: [],
      datatable: null,
      filteredDeliverables: [],
    };
  },
  computed: {
    isArchivedProjects() {
      return this.checkForArchived(this.$route.path);
    },
  },
  methods: {
    filterDeliverables: function (project) {
      if (Object.keys(project).length === 0) return [];
      let result = this.filterDeliverablesHelper(
        project?.deliverables,
        this.filterString
      );

      return result.map((r) => {
        project.workflow = r.workflow;
        const statusField = this.cellText_ProjectStatus(project);
        const workflow = r.workflow;
        const currentStage = this.getCurrentStage(workflow);
        const extendedWorkflowData = {
          ...r,
          workflow: r.workflow,
          dateField: this.formatDate(project.start_date || project.created_on),
          dateFieldValue: project.start_date || project.created_on,
          statusField,
          statusFieldValue: this.cellValue_ProjectStatus(statusField),
          statusFieldClass: this.cellClass_ProjectStatus(project),
          stageField: this.cellText_CurrentStage(workflow),
          productionProgress: this.getProductionProgress(
            workflow,
            project.is_survey_sent,
            project.send_csat_survey,
            project.survey_completed
          ),
          progressColor: this.progressColor(r),
          stage: currentStage,
          revision: this.getCurrentStageRevision(currentStage),
        };
        delete project.workflow;
        return extendedWorkflowData;
      });
    },
    filterDeliverablesHelper: function (deliverables, filter) {
      if (filter) {
        return deliverables.filter((deliverable) =>
          deliverable.workflow_title
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      }

      return deliverables;
    },
    checkForArchived(url) {
      return url.includes("/archived");
    },
    buildDataTable() {
      if (this.datatable) this.datatable.destroy();

      if (this.filteredDeliverables?.length) {
        this.datatable = true;
        setTimeout(() => {
          this.datatable = window.jsDataTable(`#${this.identifier}`, {
            searching: false,
            paging: false,
            info: false,
            columnDefs: [
              {
                targets:
                  this.$auth.user.isAgent && !this.isClientDetailsPage ? 4 : 3,
                type: "numeric",
              },
            ],
            order: [
              [
                this.$auth.user.isAgent && !this.isClientDetailsPage ? 4 : 3,
                "asc",
              ],
              [0, "desc"],
            ],
          });
        }, 1000); // Just giving Vue some time to render the table
      }
    },
    search: function (searchString) {
      this.filterString = searchString;
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },

    formatDate: function (date) {
      return this.toWorkspaceDateTimeFormat(this.getLuxonDateTime(date, false));
    },
    isComplete(project) {
      const stages = this.getCurrentStages(project.workflow);
      if (!stages?.length || stages.every((s) => s.status === "completed")) {
        return true;
      }
      return false;
    },
    cellText_CurrentStage: function (workflow) {
      const stages = this.getCurrentStages(workflow);
      if (!stages?.length) return "--";

      if (stages.length === 1) return stages?.[0]?.title;

      return "Multiple stages";
    },
    onRejectStageClick: async function (workflow, stage) {
      try {
        await rejectStage(workflow, stage);
        this.notifySuccess("Revision added");
      } catch (err) {
        this.notifyError(err, "Error on marking the stage as rejected");
        return;
      }
    },
    onApproveStageClick: async function (workflow, stage) {
      try {
        await approveStage(workflow, stage);
        this.notifySuccess("Stage approved");
      } catch (err) {
        this.notifyError(err, "Error on approving stage");
        return;
      }
    },
  },
  watch: {
    project: function () {
      this.filteredDeliverables = this.filterDeliverables(this.project);
      this.buildDataTable();
    },
  },
  mounted() {
    this.filteredDeliverables = this.filterDeliverables(this.project);
    this.buildDataTable();
  },
  beforeDestroy() {
    if (this.datatable) this.datatable.destroy();
  },
};
</script>
