export const makeThumbnailFromVideoFile = async (
  file,
  boundBox = [200, 200]
) => {
  const videoUrl = URL.createObjectURL(file);
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  let scaleRatio = 1;

  // Trigger video load
  await new Promise((resolve) => {
    video.addEventListener("loadedmetadata", () => {
      scaleRatio =
        Math.min(...boundBox) / Math.max(video.videoWidth, video.videoHeight);

      video.width = video.videoWidth * scaleRatio;
      video.height = video.videoHeight * scaleRatio;
      canvas.width = video.videoWidth * scaleRatio;
      canvas.height = video.videoHeight * scaleRatio;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnail
  canvas
    .getContext("2d")
    .drawImage(
      video,
      0,
      0,
      video.videoWidth * scaleRatio,
      video.videoHeight * scaleRatio
    );
  const base64 = canvas.toDataURL("image/png");

  const res = await fetch(base64);
  const blob = await res.blob();
  return new File([blob], file.name, { type: "image/png" });
};

export const makeThumbnailFromImageFile = async (
  file,
  boundBox = [200, 200]
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();

  await new Promise((resolve) => {
    img.onload = async function () {
      const scaleRatio =
        Math.min(...boundBox) / Math.max(img.width, img.height);
      const w = img.width * scaleRatio;
      const h = img.height * scaleRatio;
      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(img, 0, 0, w, h);
      resolve();
    };
    img.src = window.URL.createObjectURL(file);
  });

  const base64 = canvas.toDataURL("image/png");
  const res = await fetch(base64);
  const blob = await res.blob();
  return new File([blob], file.name, { type: "image/png" });
};
