<template>
  <!-- Top header/navigation with logo, menu items and icons -->
  <nav class="navbar top-navbar">
    <div class="container-fluid">
      <!-- Left part; logo & menu items. Excludes the 3 icons on the right -->
      <div class="navbar-left">
        <!-- Logo & Burger button visible only on narrow screens -->
        <div class="navbar-btn">
          <router-link to="/"
            ><img
              v-if="$store.getters.logo"
              :src="$store.getters.logo"
              alt="Logo"
              class="img-fluid logo"
          /></router-link>
          <button type="button" class="btn-toggle-offcanvas">
            <i class="lnr lnr-menu fa fa-bars"></i>
          </button>
        </div>
      </div>

      <!-- Search, Messages &  Power icon -->
      <div class="navbar-right">
        <div id="navbar-menu">
          <ul class="nav navbar-nav">
            <!-- <li><a href="javascript:void(0);" class="search_toggle icon-menu" title="Search Result"><i class="icon-magnifier"></i></a></li> -->
            <!-- <li><a href="javascript:void(0);" class="right_toggle icon-menu" title="Right Menu"><i class="icon-bubbles"></i><span class="notification-dot bg-pink">2</span></a></li> -->
            <li>
              <a href="#" class="icon-menu" @click="logout"
                ><i class="icon-power"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-bar" id="myBar"></div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopHeaderNavigation",
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
