const config = require("./config.json");
const { get, create, update, remove, fileUpload } = require("./common").default;

const clientsUrl = config.apis.base.url + "clients/";

exports.getClients = async (filter) => {
  const clients = await get(clientsUrl);
  // Sample data
  // const clients = [
  //     { "id": 1, "name": "Telgo Ltd", "status": "complete", "image": "assets/images/image-gallery/1.jpg", "created_on": "2021-12-24T12:56:29.000Z", "updated_on": "2021-10-05T11:56:21.000Z" },
  //     { "id": 2, "name": "ABX Ltd.", "status": "progress", "image": "assets/images/image-gallery/10.jpg", "created_on": "2021-12-24T12:57:04.000Z", "updated_on": "2021-11-04T23:00:00.000Z" },
  //     { "id": 3, "name": "XYZ Ltd.", "status": "progress", "image": "assets/images/image-gallery/11.jpg", "created_on": "2021-12-24T12:57:27.000Z", "updated_on": "2021-12-24T12:57:44.000Z" },
  //     { "id": 4, "name": "Best Ltd", "status": "incomplete", "image": "assets/images/image-gallery/9.jpg", "created_on": "2021-12-24T12:58:12.000Z", "updated_on": "2021-10-09T11:57:46.000Z" }
  // ]

  return this.filterClients(clients, filter);
};

exports.getClientById = async (id) => {
  return await get(clientsUrl + id);
};

exports.getClientsOpenProjects = async (clients) => {
  const ids = clients.map((c) => c.id);
  const openProjects = await get(
    clientsUrl + ids + "/projects/open?count_only=true"
  );
  let enrichedClients = [...clients]; // We need to make a copy rather than edit 'clients' directly because otherwise the computed property was not picking up the changes.
  enrichedClients.forEach((client) => {
    client.open_projects =
      openProjects.find((entry) => entry.client_id === client.id)?.projects ||
      0;
  });

  return enrichedClients;
};

exports.getClientProjects = async (clientId, status) => {
  return await get(clientsUrl + clientId + "/projects/" + status);
};

exports.filterClients = (clients, filter) => {
  if (filter) {
    return clients.filter((client) =>
      client.name.toLowerCase().includes(filter.toLowerCase())
    );
  }

  return clients;
};

exports.addClient = async (client) => {
  return await create(clientsUrl, client);
};

/**
 * Update the given client object.
 * An id must be provided and @param client.id takes precedence over @param clientId
 * @param {object} client The object to update. The id can be omitted if specified in @param clientId, but is required otherwise
 * @param {number} clientId Optional. Needed only if @param client does not have an id property
 * @returns
 */
exports.updateClient = async (client, clientId) => {
  if (!client.id || client.id < 1) {
    if (!clientId || clientId < 1) {
      throw new Error("No valid client ID provided");
    }
    client.id = clientId;
  }
  return await update(clientsUrl + client.id, client);
};

exports.deleteClient = async (client) => {
  return this.deleteClientById(client.id);
};

exports.deleteClientById = async (id) => {
  return await remove(clientsUrl + id);
};

exports.uploadClientLogo = async (id, logo) => {
  return await fileUpload(
    `${clientsUrl}${id}/logo?filename=${logo.name}`,
    logo.file
  );
};
