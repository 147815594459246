var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-custom spacing8"},[_vm._m(0),_c('tbody',_vm._l((_vm.projects),function(model){return _c('tr',{key:model.id},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'client-details',
                  params: { clientId: model.client_id },
                },"title":model.client_name}},[_vm._v(_vm._s(model.client_name))])],1),_c('td',[_vm._v(_vm._s(model.reference_code))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'project-timeline',
                  params: { projectId: model.id },
                },"title":model.project_name}},[_vm._v(_vm._s(model.project_name.substring(0, 20)))])],1),_c('td',[_vm._v(_vm._s(model.current_stage))]),_c('td',[(model)?_c('span',{class:_vm.cellClass_ProjectStatus(model)},[_vm._v(_vm._s(_vm.cellText_ProjectStatus(model)))]):_vm._e()])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Client")]),_c('th',[_vm._v("Project Code")]),_c('th',[_vm._v("Project Name")]),_c('th',[_vm._v("Current Stage")]),_c('th',[_vm._v("Project Status")])])])
}]

export { render, staticRenderFns }