<template>
  <wrapper-page>
    <!-- Top Number cards -->
    <div class="row clearfix">
      <div id="dashboard-in-production" class="col-lg-3 col-md-6">
        <number-card
          label="In Production"
          :value="`${this.projectsInProduction} projects`"
          iconBgColor="indigo"
          icon="fa-circle-o-notch fa-spin"
        ></number-card>
      </div>

      <div id="dashboard-awaiting-feedback" class="col-lg-3 col-md-6">
        <number-card
          label="Awaiting Feedback"
          :value="`${projectsAwaitingFeedback} projects`"
          iconBgColor="azura"
          icon="icon-hourglass"
        ></number-card>
      </div>

      <div id="dashboard-total-active-projects" class="col-lg-3 col-md-6">
        <number-card
          label="Total active projects"
          :value="`${projectsActive} projects`"
          iconBgColor="orange"
          icon="icon-layers"
        ></number-card>
      </div>

      <div id="dashboard-average-completion-duration" class="col-lg-3 col-md-6">
        <number-card
          label="Average completion duration"
          :value="`${averageCompletionDuration} days`"
          iconBgColor="pink"
          icon="icon-clock"
        ></number-card>
      </div>
    </div>

    <!-- Middle row -->
    <div class="row clearfix">
      <div class="col-lg-3">
        <div class="row">
          <div class="col-6 col-lg-12">
            <div class="card">
              <div class="header">
                <h5>Overall project health (%)</h5>
              </div>
              <div class="body p-3">
                <knob :value="overallProjectHealth" />
                <div class="row clearfix" style="margin-top: -50px">
                  <div class="col-6 align-right"><span>Off-target</span></div>
                  <div class="col-6"><span>On target</span></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 col-lg-12">
            <div class="card">
              <div class="header">
                <h5>Overall Customer satisfaction</h5>
              </div>
              <div class="body p-3 align-center">
                <p
                  v-if="overallCustomerSatisfaction"
                  class="align-center mb-0"
                  style="font-size: 1.5rem; color: rgb(74, 76, 78)"
                >
                  <strong>{{ roundedRating }} / 5</strong>
                </p>
                <star-rating :value="overallCustomerSatisfaction"></star-rating>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="row clearfix">
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <div id="dashboard-top-current-stages" class="header">
                <h5>{{ currentStagesTitle }}</h5>
              </div>
              <div class="body">
                <div
                  v-for="(stage, index) in top5CurrentStages"
                  :key="index"
                  :class="
                    index === 0 ? 'mb-4 mt-3' : index === 4 ? 'mb-0' : 'mb-4'
                  "
                >
                  <label class="d-block"
                    >{{ stage.name
                    }}<span class="float-right">
                      {{ stage.projects }} projects</span
                    ></label
                  >
                  <div class="progress progress-xxs">
                    <div
                      :class="`progress-bar ${progress_bar_colour_sequence[index]}`"
                      role="progressbar"
                      :aria-valuenow="stage.percent"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="`width: ${stage.percent}%`"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="card">
              <div id="dashboard-top-upcoming-stages" class="header">
                <h5>{{ upcomingStagesTitle }}</h5>
              </div>
              <div class="body">
                <div
                  v-for="(stage, index) in top5UpcomingStages"
                  :key="index"
                  :class="
                    index === 0 ? 'mb-4 mt-3' : index === 4 ? 'mb-0' : 'mb-4'
                  "
                >
                  <label class="d-block"
                    >{{ stage.name
                    }}<span class="float-right">
                      {{ stage.projects }} projects</span
                    ></label
                  >
                  <div class="progress progress-xxs">
                    <div
                      :class="`progress-bar ${progress_bar_colour_sequence[index]}`"
                      role="progressbar"
                      :aria-valuenow="stage.percent"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="`width: ${stage.percent}%`"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom Feedback row -->
    <div class="row clearfix">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <h5>Feedback due today</h5>
          <feedback-table :projects="feedbackDueToday" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <h5>Overdue Feedback</h5>
          <feedback-table :projects="overdueFeedback" />
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import { DateTime } from "luxon";
import WrapperPage from "../components/layout/WrapperPage.vue";
import NumberCard from "../components/ui/NumberCard.vue";
import FeedbackTable from "../components/ui/Dashboard/FeedbackTable.vue";
import { getUserBasedProjectsStats } from "../apis/projects";
import Knob from "../components/ui/Dashboard/Knob.vue";
import StarRating from "../components/ui/Dashboard/StarRating.vue";
import { usersProjectsSurveysAverageRating } from "../apis/survey";
import analyticsMixin from "../mixins/analytics";

export default {
  name: "DashboardPage",
  mixins: [analyticsMixin],
  components: {
    WrapperPage,
    NumberCard,
    FeedbackTable,
    Knob,
    StarRating,
  },
  data: function () {
    return {
      isLoadingStats: false,
      projects_stats: [{}],
      progress_bar_colour_sequence: [
        "progress-bar-success",
        "progress-bar-warning",
        "progress-bar-info",
        "progress-bar-danger",
        "bg-indigo",
      ],
      userAverageRating: 0,
    };
  },
  computed: {
    /** Get Active (non-archived), In Progress */
    projectsInProduction() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status === "in_progress"
      )?.length;
    },
    /** Get Active (non-archived) projects Awaiting Feedback */
    projectsAwaitingFeedback() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status === "awaiting_feedback"
      ).length;
    },
    /** Projects being worked on, not completed nor archived */
    projectsActive() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status !== "completed"
      ).length;
    },
    averageCompletionDuration() {
      if (this.projects_stats.length === 0) return 0;

      let projects = 0;

      let total_days = this.projects_stats.reduce((accumulator, current) => {
        if (current.status) {
          // Can be NULL, else, consider projects in any state
          projects++;
          return accumulator + current.production_duration;
        }
        return accumulator;
      }, 0);
      if (projects === 0) return 0;
      return Math.round((total_days * 2) / projects) / 2; // Round to nearest .5
    },
    roundedRating() {
      return (this.overallCustomerSatisfaction * 5).toFixed(1);
    },

    /**
     * Consider only if a project is 100% healthy or not
     * (so 8 on-target and 2 off-target = 80%)
     */
    overallProjectHealth() {
      const considerArchived = true;
      const considerCompleted = true;
      let projects = this.projects_stats.filter(
        (ps) => considerArchived || !ps.archived
      );
      projects = projects.filter(
        (ps) => considerCompleted || ps.status !== "completed"
      );
      if (projects.length === 0) return 0;

      let healthyProjects = projects.filter((ps) => ps.health === 1);

      return Math.round(100 * (healthyProjects.length / projects.length));
    },
    /**
     * Measures the health of live projects (non-archived).
     * Deleted projects are not considered
     * off-target 0 - 100 on-target
     */
    overallTargetRevisionRounds() {
      const nonArchivedProjectStats = this.projects_stats.filter(
        (project) => !project.archived
      );

      if (nonArchivedProjectStats.length === 0) return 100;

      let total = nonArchivedProjectStats.reduce(function (
        accumulator,
        curProjectStats
      ) {
        return accumulator + (curProjectStats.health ?? 0.5);
      },
      0);
      return Math.round(100 * (total / nonArchivedProjectStats.length));
    },
    overallCustomerSatisfaction() {
      // TODO
      return this.userAverageRating;
    },
    top5CurrentStages() {
      let sortedGroups = this.currentStagesSortedByFrequency(
        this.projects_stats
      ).slice(0, 5); // Take top 5 only

      let maxProjectsPerStage = 1;
      for (let i = 0; i < sortedGroups.length; i++) {
        maxProjectsPerStage = Math.max(
          maxProjectsPerStage,
          sortedGroups[i].count
        );
      }

      // Progress bar shows Number of projects over Max projects for Top 5 stages
      const percentMultiplier = 100 / maxProjectsPerStage;

      return sortedGroups.map((sg) => ({
        name: sg.name,
        projects: sg.count,
        percent: sg.count * percentMultiplier,
      }));
    },
    top5UpcomingStages() {
      let sortedGroups = this.upcomingStagesSortedByFrequency(
        this.projects_stats
      ).slice(0, 5); // Take top 5 only

      let maxProjectsPerStage = 1;
      for (let i = 0; i < sortedGroups.length; i++) {
        maxProjectsPerStage = Math.max(
          maxProjectsPerStage,
          sortedGroups[i].count
        );
      }

      // Progress bar shows Number of projects over Max projects for Top 5 stages
      const percentMultiplier = 100 / maxProjectsPerStage;

      return sortedGroups.map((sg) => ({
        name: sg.name,
        projects: sg.count,
        percent: sg.count * percentMultiplier,
      }));
    },
    currentStagesTitle() {
      if (this.currentStagesSortedByFrequency(this.projects_stats).length > 5)
        return "Top 5 current stages";
      return "Current stages";
    },
    upcomingStagesTitle() {
      if (this.upcomingStagesSortedByFrequency(this.projects_stats).length > 5)
        return "Top 5 upcoming stages";
      return "Upcoming stages";
    },
    feedbackDueToday() {
      const today = new Date().toISOString().substring(0, 10);
      try {
        let arr = this.projects_stats.filter(
          (ps) =>
            ps.status === "awaiting_feedback" &&
            ps.feedback_due &&
            new Date(ps.feedback_due.replace(/-/g, "/"))
              .toISOString()
              .substring(0, 10) === today &&
            !ps.archived
        );

        // Sort in reverse order - oldest first
        arr.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            new Date(a.feedback_due.replace(/-/g, "/")) -
            new Date(b.feedback_due.replace(/-/g, "/"))
          );
        });
        return arr;
      } catch (err) {
        console.error(err);
        return this.projects_stats.filter(
          (ps) => ps.status === "awaiting_feedback"
        );
      }
    },
    overdueFeedback() {
      const today = DateTime.now().startOf("day").toJSDate(); // Local/User's timezone
      let arr = this.projects_stats.filter(
        (ps) =>
          ps.status === "awaiting_feedback" &&
          new Date(ps.feedback_due) < today &&
          !ps.archived
      );
      // Sort in reverse order - longest overdue first
      arr.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.feedback_due) - new Date(b.feedback_due);
      });
      arr.forEach((ps) => {
        ps.isOverdueFeedback = true;
      });
      return arr;
    },
  },
  methods: {
    getAllStats: async function () {
      try {
        this.isLoadingStats = true;
        this.projects_stats = await getUserBasedProjectsStats();
      } catch (err) {
        this.notifyError(err);
      }
      this.isLoadingStats = false;
    },
    async getAverageRating() {
      const averageRatings = await usersProjectsSurveysAverageRating();
      if (averageRatings.length) {
        const userAverageRating = averageRatings.find(
          (obj) => obj.user_email === this.$auth.user.email
        );
        if (userAverageRating) {
          const scaledRating = userAverageRating.rating / 5; // Assuming ratings are on a scale of 0 to 5
          this.userAverageRating = scaledRating;
        } else {
          this.userAverageRating = 0;
        }
      }
    },
    calculateAverageRating(data) {
      // Filter the data for entries with a rating other than 0
      const filteredData = data.filter((entry) => entry.rating !== 0);
      if (filteredData.length === 0) {
        // Handle the case when there are no non-zero ratings to avoid division by zero
        return 0;
      }
      // Calculate the average rating
      const sumRating = filteredData.reduce(
        (sum, entry) => sum + entry.rating,
        0
      );
      const averageRating = sumRating / filteredData.length;
      // Scale the average rating to a range of 0 to 1
      const scaledRating = averageRating / 5; // Assuming ratings are on a scale of 0 to 5
      return scaledRating;
    },
  },
  created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
      return;
    }
  },
  async mounted() {
    if (this.$auth.user.isAgent) {
      await this.getAllStats();
      this.getAverageRating();
    }
  },
};
</script>
