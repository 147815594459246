<template>
  <div class="row clearfix">
    <div class="col-12">
      <div class="table-responsive">
        <table
          :id="identifier"
          :class="[
            'table',
            'table-hover',
            'table-custom',
            'spacing8',
            'dataTable',
            identifier,
          ]"
        >
          <thead>
            <tr>
              <th>Client</th>
              <th style="text-align: center">Completed Projects</th>
              <th style="text-align: center">Rated Projects</th>
              <th style="padding-left: 20px">Average Rating</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(clientRating, index) in topClientRatings" :key="index">
              <td>
                <router-link
                  :to="{
                    name: 'client-details',
                    params: { clientId: clientRating.clientId },
                  }"
                  :title="clientRating.clientName"
                  >{{ clientRating.clientName }}</router-link
                >
              </td>
              <td style="text-align: center">
                {{ clientRating.completedProjects }}
              </td>
              <td style="text-align: center">
                {{ clientRating.projectCount }}
              </td>
              <td style="display: flex">
                <star-rating
                  :value="clientRating.averageRating / 5 || 0"
                ></star-rating>
                <span style="padding-left: 10px">
                  {{ clientRating.averageRating }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "../Dashboard/StarRating.vue";
export default {
  name: "ClientRatingTable",
  components: {
    StarRating,
  },
  props: {
    averageRatings: Array,
    identifier: {
      type: String,
      default: "client-rating-js-data-table",
    },
  },
  data() {
    return {
      datatable: null,
    };
  },
  computed: {
    topClientRatings() {
      if (!this.averageRatings || this.averageRatings.length === 0) {
        return [];
      }
      const clientRatingCopy = [...this.averageRatings];

      const sortedClientRating = clientRatingCopy.sort(
        (a, b) => b.averageRating - a.averageRating
      );

      return sortedClientRating;
    },
  },
  async created() {},
  methods: {
    buildDataTable() {
      if (this.datatable) this.datatable.destroy();

      if (this.averageRatings?.length) {
        this.datatable = true;
        setTimeout(() => {
          this.datatable = window.jsDataTable(`#${this.identifier}`, {
            pageLength: 5,
            searching: false, // Disable the search box
            lengthChange: false, // Disable the entries dropdown
            ordering: false, // Disable sorting
          });
        }, 1000); // Just giving Vue some time to render the table
      }
    },
  },
  watch: {
    averageRatings: function () {
      this.buildDataTable();
    },
  },
  mounted() {
    this.buildDataTable();
  },
  beforeDestroy() {
    if (this.datatable) this.datatable.destroy();
  },
};
</script>
