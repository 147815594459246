import { getClientById, getClients } from "@/apis/clients";

export default {
  data() {
    return {
      isLoadingClientOptions: false,
    };
  },
  methods: {
    async clientSelected(client_id) {
      this.client = await getClientById(client_id);

      // clear the contact list
      this.contacts = [{}];
    },
    async getClientOptions() {
      this.isLoadingClientOptions = true;

      // Load clients async'ly
      getClients()
        .then((clients) => {
          let cliMap = clients
            .map((c) => ({
              text: c.name,
              value: c.id,
            }))
            .sort((a, b) => {
              if (a.text < b.text) {
                return -1;
              }
              if (a.text > b.text) {
                return 1;
              }
              return 0;
            });
          cliMap.splice(0, 0, { text: "Choose", value: -1 });
          return cliMap;
        })
        .then((clients) => {
          const clientFieldIndex = this.mainDetails.findIndex(
            (f) => f.name === "client_id"
          );
          this.$set(this.mainDetails[clientFieldIndex], "options", clients);
        })
        .finally(() => {
          this.isLoadingClientOptions = false;
        });
    },
  },
  mounted() {
    this.getClientOptions();
  },
};
