var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:{
    timeline: true,
    disabledComponent: _vm.project.cancelled || _vm.project.archived,
  }},[_vm._l((_vm.model.tasks),function(stage){return [(stage.status === 'completed')?_c('closed-timeline-list-item',{key:stage.id,attrs:{"model":stage,"root":_vm.root,"parent":_vm.model,"project":_vm.project,"events":_vm.filterEventsForStage(stage),"notes":_vm.notes,"stage":stage},on:{"add":_vm.onNoteAdded}}):_c('timeline-list-item',{key:stage.id,attrs:{"preTitle":_vm.isFirstPendingStage(stage) && _vm.root.is_linear ? 'Up next' : '',"model":stage,"root":_vm.root,"parent":_vm.model,"project":_vm.project,"events":_vm.filterEventsForStage(stage),"notes":_vm.notes,"isOpen":stage.status === 'in_progress' || stage.status === 'awaiting_feedback'},on:{"revision_send_preview_click":_vm.onRevisionSendPreviewClick,"revision_send_message_click":_vm.onRevisionSendMessageClick,"stage_send_message_click":_vm.onStageSendMessageClick,"revision_client_approve_click":_vm.onRevisionClientApproveClick,"revision_client_reject_click":_vm.onRevisionClientRejectClick,"revision_reject_revision_click":_vm.onRevisionClientRejectClick,"revision_stage_approve_click":_vm.onApproveStageFromRevisionClick,"add":_vm.onNoteAdded}},[(stage.tasks && stage.tasks.length > 0)?_c('div',{staticClass:"col-lg-12"},[(stage.status === 'in_progress')?_c('timeline-list',{attrs:{"model":stage,"root":_vm.root,"project":_vm.project},on:{"workflow_updated":_vm.onWorkflowUpdated}}):_vm._e()],1):_vm._e(),(
          _vm.$auth.user.isAgent &&
          stage.type === 'Stage' &&
          stage.status === 'in_progress'
        )?_c('stage-action-buttons',{attrs:{"stage":stage,"workflow":_vm.root,"project":_vm.project},on:{"on_stage_client_approve_click":_vm.onStageClientApproveClick,"on_stage_add_revision_click":_vm.onStageAddRevisionClick,"revision_stage_approve_click":_vm.onApproveStageFromRevisionClick,"open_deliverables_tab":(e) => _vm.$emit('goto_tab', 'deliverables', e),"open_feedback_tab":(e) => _vm.$emit('goto_tab', 'feedback')}}):_vm._e()],1)]}),(_vm.$auth.user.isAgent)?_c('CompleteTimelineActionButtons',{attrs:{"surveysFeedback":_vm.surveysFeedback,"workflow":_vm.root,"project":_vm.project},on:{"on_stage_client_approve_click":_vm.onStageClientApproveClick,"on_stage_add_revision_click":_vm.onStageAddRevisionClick,"revision_stage_approve_click":_vm.onApproveStageFromRevisionClick,"open_deliverables_tab":(e) => _vm.$emit('goto_tab', 'deliverables', e),"open_feedback_tab":(e) => _vm.$emit('goto_tab', 'feedback')}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }