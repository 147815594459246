<template>
  <div class="row clearfix">
    <div class="col-lg-3 col-md-6 col-sm-12">
      <div class="card">
        <div class="body top_counter">
          <router-link :to="{ path: '/projects/create' }"
            ><div class="icon bg-warning text-white">
              <i class="fa fa-plus"></i></div
          ></router-link>
          <div class="content">
            <h5 class="text-center mb-0">Start Project</h5>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-lg-3 col-md-6 col-sm-12"
      v-for="model in filteredProjects()"
      :key="model.id"
    >
      <div class="card">
        <div class="body taskboard b-orange progress_task">
          <div class="dd" data-plugin="nestable">
            <router-link
              :to="{ name: 'project-details', params: { projectId: model.id } }"
            >
              <img
                :src="model.image"
                class="card-img-top"
                alt="Card image cap"
              />
            </router-link>
            <div class="body">
              <h4 class="card-title">{{ model.name }}</h4>
              <div class="card-subtitle">{{ model.action }}</div>
              <p class="card-text">
                <router-link
                  :to="{
                    name: 'project-timeline',
                    params: { projectId: model.id },
                  }"
                  v-bind="stageClass(model)"
                  >{{ model.current_stage || "Timeline" }}</router-link
                >
              </p>
              <div class="d-flex">
                <small class="text-muted">Created</small>
                <small class="ml-auto text-muted">{{ model.created_on }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filterProjects } from "../../apis/projects";

export default {
  name: "ProjectsGrid",
  components: {},
  props: {
    projects: Array,
  },
  data() {
    return {
      filterString: "",
      fieldFilters: [],
    };
  },
  methods: {
    stageClass(model) {
      switch (model.current_stage) {
        case "Delivered":
          return { class: "text-success" };
        case "Cancelled":
          return { class: "text-danger" };
        default:
          return { class: "text-warning" };
      }
    },
    search: function (searchString) {
      this.filterString = searchString;
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },
    filteredProjects: function () {
      let result = filterProjects(this.projects, this.filterString);
      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v[element.field]
              .toLowerCase()
              .includes(element.searchString.toLowerCase())
          );
        }
      });
      return result;
    },
  },
};
</script>
