<!-- TODO
  Reproduce Slack's login experience 
  https://slack.com/workspace-signin
-->
<template>
  <div>
    <div class="pattern">
      <span class="red"></span>
      <span class="indigo"></span>
      <span class="blue"></span>
      <span class="green"></span>
      <span class="orange"></span>
    </div>
    <div class="auth-main particles_js">
      <div class="auth_div vivify popIn" style="width: 450px">
        <div class="auth_brand">
          <a
            class="navbar-brand"
            href="javascript:void(0);"
            style="color: #192c72"
          >
            <img
              src="/assets/images/logo.png"
              width="200"
              class="d-inline-block align-top mr-2"
              alt="Planeolo"
          /></a>
        </div>
        <!-- Agency signin form -->
        <div class="card">
          <div class="body">
            <p class="subtitle">Sign in to your Planeolo account</p>
            <p class="lead">Enter your workspace name</p>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="subdomain"
                v-model="subdomain"
              />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                  >.planeolo.com</span
                >
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary mt-4"
              @click="goToChosenAgency(subdomain + '.planeolo.com')"
            >
              Continue
            </button>
          </div>
        </div>

        <div>
          Don't have an account yet?
          <router-link :to="{ name: 'signup' }">
            <span>Sign up for free</span>
          </router-link>
        </div>

        <div style="margin-top: 8px">
          Forgot your workspace URL?
          <router-link :to="{ name: 'retrieve-workspace-url' }">
            <span>Click here to retrieve</span>
          </router-link>
        </div>

        <!-- Logged in Agencies list -->
        <div
          class="card vivify fadeInBottom"
          style="margin-top: 40px"
          v-if="$auth.user?.email && userAgencies.length > 0"
        >
          <div class="body">
            <p class="subtitle">You're already signed in to...</p>
            <div>
              <div v-for="(agency, index) in userAgencies" :key="index">
                <button
                  class="btn btn-info btn-block mt-2"
                  @click="goToChosenAgency(agency.domain)"
                >
                  {{ agency.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTenantsForUser } from "../apis/app";

export default {
  name: "SignIn",
  data() {
    return {
      userAgencies: [],
      subdomain: "",
    };
  },
  methods: {
    /**
     * Gets list of domains for which this tenant is known to have access
     */
    async getAgenciesForUser() {
      if (this.$auth.user?.email) {
        this.userAgencies = await getTenantsForUser(this.$auth.user.email);
      }
    },
    goToChosenAgency(domain) {
      window.location = "https://" + domain;
    },
  },
  mounted: function () {
    document.title = "Planeolo - Sign In";
    this.getAgenciesForUser();
    window.onWrapperMounted();
  },
  watch: {
    "$auth.user": function () {
      this.getAgenciesForUser();
    },
  },
};
</script>

<style scoped>
.subtitle {
  color: #192c72;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.lead {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #626a71;
  margin-top: 15px;
  margin-bottom: 5px;
}
.auth-main {
  flex-wrap: wrap;
}
</style>
