var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"star-rating"},[_vm._l((_vm.currentRating),function(index){return _c('label',{key:`f${index}`,staticClass:"fa fa-star ml-2",style:({ fontSize: _vm.dynamicFontSize, color: '#FDD836' }),on:{"click":function($event){return _vm.setRating(index)}}})}),_vm._l((_vm.maxRating - _vm.currentRating),function(index){return _c('label',{key:`h${index + _vm.currentRating}`,class:[
      'fa',
      'ml-2',
      { 'fa-star': _vm.readyOnly == true, 'fa-star-o': _vm.readyOnly === false },
    ],style:({
      fontSize: _vm.dynamicFontSize,
      color: '#D8D8D8',
    }),on:{"click":function($event){return _vm.setRating(index + _vm.currentRating)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }