import langs from "langs";

export const isEmail = (input) =>
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(input);

export const isWorkspaceFree = (subscription) =>
  ["free forever"].includes(subscription?.name?.toLowerCase());

export const isWorkspaceProfessionalLevel = (subscription) =>
  ["professional", "appsumo"].includes(subscription?.name?.toLowerCase());

export const isWorkspaceStarterLevel = (subscription) =>
  ["starter", "professional", "appsumo"].includes(
    subscription?.name?.toLowerCase()
  );

export const removeZ = (dateTimeString) => {
  if (!dateTimeString?.length) return dateTimeString;

  if (dateTimeString[dateTimeString.length - 1].toLowerCase() === "z")
    return dateTimeString.slice(0, -1); // Slice the string from beginning to 1 character before the end

  return dateTimeString;
};

export const languageFromCode = (code) => langs.where("1", code);
