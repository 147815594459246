<template>
  <div class="progress progress-xs progress-bar-info w-100 mb-2">
    <div
      class="progress-bar"
      role="progressbar"
      :aria-valuenow="percent"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="`width: ${percent}%; transition: width 0.15s`"
    >
      <span class="sr-only">{{ percent }}% Uploaded</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number, // decimal
      default: 0,
    },
  },
  computed: {
    percent() {
      return this.progress * 100;
    },
  },
};
</script>
