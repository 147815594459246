<template>
  <Modal
    :title="title"
    :show-close-button="true"
    @submit="$emit('submit', image)"
    @close="$emit('close')"
    cancel-text="Cancel"
    submit-text="Save"
    :disable-submit-button="isSaving"
    :close-on-outside-click="false"
  >
    <div class="container">
      <cropper
        backgroundClass="bg-white"
        class="cropper"
        :style="{
          width: `${width}px`,
          height: `${height}px`,
        }"
        ref="cropper"
        stencil-component="circle-stencil"
        :stencil-props="{
          aspectRatio,
        }"
        :src="file.src"
        @change="cropperChange"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "CropImageModal",
  components: { Modal, Cropper },
  props: {
    title: {
      type: String,
      default: "Crop Image",
    },
    file: {
      type: Object,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    cropperChange({ canvas }) {
      this.image = canvas;
    },
  },
};
</script>

<style scoped>
.cropper {
  background: #ddd;
}
</style>

<style>
.bg-white {
  background-color: #fff !important;
}
</style>
