export default {
  data() {
    return {
      isLoadingUserOptions: false,
      userOptions: [],
      userInput: null,
      users: [],
    };
  },
  methods: {
    async getUserOptions() {
      this.isLoadingUserOptions = true;

      let userOptions = [];

      // Assuming you have user data available in 'userData' in your store
      userOptions = this.$store.getters.users.map((c) => ({
        text: (c.name + " " + (c.surname ?? "")).trim(),
        value: c.id,
      }));
      userOptions.splice(0, 0, { text: "Choose", value: -1 });

      // This applies only for 'Client', not 'Project'
      const clientFieldIndex = this.mainDetails.findIndex(
        (f) => f.name === "agency_client_director"
      );
      this.$set(this.mainDetails[clientFieldIndex], "options", userOptions);

      this.userOptions = userOptions;
      this.isLoadingUserOptions = false;
    },
    addUser() {
      if (!this.userInput) return;

      if (!this.users.includes(+this.userInput))
        this.users.push(+this.userInput);
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },
  },
  computed: {
    attachedUsers() {
      const attached = [];
      let selectedUsers = [...this.users];

      if (!selectedUsers.length) return attached;

      this.userOptions.forEach((option) => {
        if (selectedUsers.includes(option.value)) {
          attached.push(option);
          const index = selectedUsers.findIndex((j) => j === option.value);
          selectedUsers.splice(index, 1);
        }
      });

      return attached;
    },
  },
  mounted() {
    this.getUserOptions();
  },
};
