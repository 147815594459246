const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

const surveyBaseUrl = config.apis.base.url + "surveys/";
const surveyFeedbackBaseUrl = config.apis.base.url + "projects/";

/**
 * Create a new survey template.
 *
 * @param {object} surveyPayload - The payload containing the survey template data.
 */
exports.createSurveyTemplate = async (surveyPayload) => {
  await create(surveyBaseUrl, surveyPayload);
};

/**
 * Update an existing survey template.
 *
 * @param {object} surveyPayload - The updated survey template data.
 * @param {string} surveyId - The ID of the survey template to be updated.
 */
exports.updateSurveyTemplate = async (surveyPayload, surveyId) => {
  await update(surveyBaseUrl + surveyId, surveyPayload);
};

/**
 * Delete a survey template.
 *
 * @param {string} surveyId - The ID of the survey template to be deleted.
 */
exports.deleteSurveyTemplate = async (surveyId) => {
  await remove(surveyBaseUrl + surveyId);
};

/**
 * Fetch all survey templates.
 */
exports.getAllSurvey = async () => {
  return await get(surveyBaseUrl);
};
exports.getAllSurveyWithFeedback = async (projectId) => {
  return await get(surveyFeedbackBaseUrl + projectId + "/surveys");
};
exports.setFeedback = async (projectId, surveyId, surveyItemId, payload) => {
  await update(
    surveyFeedbackBaseUrl +
      projectId +
      "/surveys/" +
      surveyId +
      "/items/" +
      surveyItemId +
      "/feedback",
    payload
  );
};
exports.sendSurveyEmail = async (projectId, surveyId, payload) => {
  return await create(
    surveyFeedbackBaseUrl + projectId + "/surveys/" + surveyId + "/email",
    payload
  );
};
exports.projectsSurveysAverageRating = async () => {
  return await get(config.apis.base.url + "projects-surveys-average-rating");
};
exports.usersProjectsSurveysAverageRating = async () => {
  return await get(
    config.apis.base.url + "users-projects-surveys-average-rating"
  );
};
exports.surveysStatus = async () => {
  return await get(surveyBaseUrl + "status");
};
