import { getInstance } from "./authWrapper";
import axios from "axios";
import { DateTime } from "luxon";
import { store } from "@/store";
import { Buffer } from "buffer";

window.Buffer = Buffer;

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    // Doc: https://auth0.github.io/auth0-spa-js/interfaces/redirectloginoptions.html
    let options = { appState: { targetUrl: to.fullPath } };
    if (to.query.conn) {
      options.connection = to.query.conn;
    }
    if (to.query.email) {
      options.login_hint = to.query.email;
    }

    authService.loginWithRedirect(options);
    return next(false);
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }

    return next(false);
  });

  authService.$watch("user", async (user) => {
    try {
      if (!user || "isAgent" in user) return; // If set already, we return or we end up in an infite loop

      let token;
      try {
        token = await authService.auth0Client.getTokenSilently();
      } catch (e) {
        console.error("In authGuard watch user", e);
        authService.auth0Client.loginWithRedirect();
        return;
      }
      // token = {
      //   "https://planeolo/tenant": {"id": 1,"name": "Planeolo","domain": "app.planeolo.com"},
      //   "https://planeolo/user": {"email": "tonysammut@gmail.com","name": "Tony Sammut!","isAgent": true},
      //   "iss": "https://dev-ekh910nq.us.auth0.com/",
      //   "sub": "google-oauth2|118212731476714959912",
      //   "aud": [
      //     "https://app.planeolo.com",
      //     "https://dev-ekh910nq.us.auth0.com/userinfo"
      //   ],
      //   "iat": 1651925245,
      //   "exp": 1652011645,
      //   "azp": "eZ0dRw3j4uj1zsrJgt8ekb5App9pWjMq",
      //   "scope": "openid profile email",
      //   "permissions": []
      // }

      // Setting Auth token for all requests
      axios.defaults.headers.common["authorization"] = "Bearer " + token;

      const parseJwt = (token) => {
        var base64Payload = token.split(".")[1];
        var payload = Buffer.from(base64Payload, "base64");
        return JSON.parse(payload.toString());
      };
      let userMeta = parseJwt(token);

      authService.user = {
        ...authService.user,
        ...userMeta["https://planeolo/user"],
      };

      const tenant = { ...userMeta["https://planeolo/tenant"] };
      tenant.subscription = {};
      tenant.subscription.expires = DateTime.fromISO(tenant.expires).toJSDate();
      tenant.subscription.grace_period = DateTime.fromISO(
        tenant.grace_period
      ).toJSDate();
      tenant.subscription.cancellation_date = DateTime.fromISO(
        tenant.cancellation_date
      ).toJSDate();
      tenant.subscription.name = tenant.subscription_name;
      tenant.subscription.isAppSumo = tenant.isAppSumo || false;
      tenant.subscription.users_allowed = tenant.users_allowed || 1;
      tenant.subscription.active_users = tenant.active_users || 1;
      authService.tenant = { ...authService.tenant, ...tenant };

      try {
        await Promise.all([
          store.dispatch("getUsers"),
          store.dispatch("getTenantProfile"),
          store.dispatch("getSubscriptions"),

          ...(authService.user?.isAgent
            ? [
                store.dispatch("getUserProfile"),
                store.dispatch("getEmailTemplates"),
                store.dispatch("getQCChecklistTemplates"),
              ]
            : []),
        ]);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    } finally {
      authService.loading = false;
    }
  });
};
