<template>
  <div>
    <div class="pattern">
      <span class="red"></span>
      <span class="indigo"></span>
      <span class="blue"></span>
      <span class="green"></span>
      <span class="orange"></span>
    </div>
    <div class="auth-main particles_js">
      <div class="auth_div vivify popIn">
        <div class="auth_brand">
          <a
            class="navbar-brand"
            href="javascript:void(0);"
            style="color: #192c72"
          >
            <img
              src="/assets/images/logo.png"
              width="130"
              class="d-inline-block align-top mr-2"
              alt="Planeolo"
          /></a>
        </div>
        <!-- Agency creation form -->
        <div class="card" v-if="step === 'getAgencyDetails'">
          <div class="body">
            <p class="lead">Create an account</p>
            <div class="form-group">
              <input
                type="text"
                class="form-control round"
                placeholder="Account name"
                v-model="agencyName"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control round"
                placeholder="Your First name"
                v-model="adminFirstName"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control round"
                placeholder="Your Last name"
                v-model="adminLastName"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control round"
                placeholder="Your email"
                v-model="adminEmail"
              />
            </div>
            <div class="input-group">
              <input
                type="text"
                class="form-control round"
                placeholder="subdomain"
                v-model="subdomain"
              />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                  >.planeolo.com</span
                >
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary btn-round btn-block mt-4"
              @click="goToLoginChoice()"
            >
              Next
            </button>
          </div>
        </div>
        <!-- Login choice (Password vs Gmail) -->
        <div class="card" v-if="step === 'loginChoice'">
          <div class="body">
            <p class="lead">Sign up with</p>
            <div class="form-group">
              <input
                type="password"
                class="form-control round"
                placeholder="Password"
                v-model="password"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control round"
                placeholder="Confirm Password"
                v-model="confirmPassword"
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-round btn-block mt-4"
              @click="submitSignUp('password')"
            >
              Create Account
            </button>

            <div class="separator-linethrough"><span>OR</span></div>
            <button
              class="btn btn-round btn-signin-social"
              @click="submitSignUp('google')"
            >
              <i class="fa sfa-google sgoogle-color google-icon">&nbsp;</i> Sign
              in with Google
            </button>

            <button
              type="button"
              class="btn btn-secondary btn-round btn-block mt-5"
              @click="goToAgencyDetails()"
            >
              Back to Account Details
            </button>
          </div>
        </div>
        <!-- Spinner while creating -->
        <div
          class="card"
          v-if="step === 'creatingAgency'"
          style="margin-bottom: 300px"
        >
          <div class="body">
            <p class="lead">Your account is being created</p>
            <br />
            <p>
              In a few seconds you will be redirected to <em>your site</em> and
              you can register with your email or login via Google SSO.
            </p>
          </div>
        </div>
        <div class="small text-muted">
          We respect your privacy.<br />Unsubscribe at any time.
        </div>
        <div class="loader-wrapper" v-if="step === 'creatingAgency'">
          <div class="loader">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
            <div class="bar4"></div>
            <div class="bar5"></div>
          </div>
        </div>
      </div>
      <div id="particles-js"></div>
    </div>
  </div>
</template>

<script>
import { registerTenant } from "../apis/app";

export default {
  name: "SignUp",
  data() {
    return {
      step: "getAgencyDetails", // -> loginChoice -> creatingAgency -> redirect to new agency url
      agencyName: "",
      adminFirstName: "",
      adminLastName: "",
      adminEmail: "",
      subdomain: "",
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    goToLoginChoice() {
      this.step = "loginChoice";
    },
    goToAgencyDetails() {
      this.step = "getAgencyDetails";
    },
    async submitSignUp(loginType) {
      // Password validation
      if (loginType === "password") {
        if (!this.password || !this.password.trim()) {
          this.notifyError("Please fill in the password.");
          return;
        }
        if (this.password !== this.confirmPassword) {
          this.notifyError("Password don't match");
          return;
        }
      }

      try {
        this.step = "creatingAgency";
        const tenantInfo = {
          tenantName: this.agencyName,
          adminFirstName: this.adminFirstName,
          adminLastName: this.adminLastName,
          adminEmail: this.adminEmail,
          subdomain: this.subdomain,
        };
        if (loginType === "google") {
          let result = await registerTenant(tenantInfo);
          window.location = `https://${result.domain}?conn=google-oauth2`;
        } else {
          tenantInfo.adminPassword = this.password;
          let result = await registerTenant(tenantInfo);
          // Redirect to a 'Verify your email page' for DB Users
          window.location = `https://${
            result.domain
          }/verify-email/?email=${encodeURIComponent(this.adminEmail)}`;
        }
      } catch (err) {
        let errMsg = err?.response?.data;
        if (!errMsg) {
          errMsg = err.message;
        }
        errMsg = errMsg
          .replace(/tenantName/, "Agency Name")
          .replace(/adminName/, "Admin Name")
          .replace(/adminEmail/, "Admin Email");
        this.notifyError(errMsg);
      } finally {
        this.step = "loginChoice";
      }
    },
  },
  mounted: function () {
    document.title = "Planeolo - Sign Up";
    window.onWrapperMounted();
  },
  watch: {
    agencyName: function (new_val) {
      this.subdomain = new_val.toLowerCase().replace(/[^a-z0-9]| /gi, "");
    },
  },
};
</script>

<style scoped>
.auth-main .auth_div {
  width: 500px;
}
.loader-wrapper {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background: #22252a; */
}
.loader-wrapper .loader {
  margin: 0 auto;
  width: 70px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.loader-wrapper .loader > div {
  height: 100%;
  width: 8px;
  display: inline-block;
  margin-left: 2px;
  -webkit-animation: delay 0.9s infinite ease-in-out;
  animation: delay 0.9s infinite ease-in-out;
}
.loader-wrapper .loader .bar1 {
  background-color: #fc3c5f;
}
.loader-wrapper .loader .bar2 {
  background-color: #993cfc;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.loader-wrapper .loader .bar3 {
  background-color: #3ca9fc;
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader-wrapper .loader .bar4 {
  background-color: #3cfc5f;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.loader-wrapper .loader .bar5 {
  background-color: #fcdf3c;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
@-webkit-keyframes delay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.05);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

.separator-linethrough span {
  background-color: white;
}
.google-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E");
  width: 16px;
}

.btn-block {
  display: inline-block;
  width: 80%;
}
</style>
