import Vue from "vue";
import Router from "vue-router";

import { authGuard } from "./auth";

import DashboardPage from "./pages/DashboardPage";
import WorkspaceAnalytics from "./pages/WorkspaceAnalytics";
import ProjectDetailsPage from "./pages/ProjectDetailsPage.vue";
import ListProjectsPage from "./pages/ListProjectsPage.vue";
import ListClientsPage from "./pages/ListClientsPage.vue";
import ListDeliverablesPage from "./pages/ListDeliverablesPage.vue";
import ClientDetailsPage from "./pages/ClientDetailsPage/";
import ProjectActions from "./pages/ProjectActions";
import TimelinePage from "./pages/TimelinePage";
import TrackableLinkPage from "./pages/TrackableLinkPage";
import SettingsTeamPage from "./pages/settings/TeamPage";
import SettingsWorkspacePage from "./pages/settings/WorkspacePage";
import CustomDomainPage from "./pages/settings/CustomDomainPage";
import SettingsEmailSettingsPage from "./pages/settings/EmailSettingsPage";
import SettingsEmailTemplatesPage from "./pages/settings/EmailTemplatesPage";
import SettingsWorkflowTemplatesPage from "./pages/settings/WorkflowTemplatesPage";
import SettingsSurveyTemplatesPage from "./pages/settings/SurveyTemplatesPage.vue";
import SignupPage from "./pages/SignupPage";
import AppsumoSignupPage from "./pages/AppsumoSignupPage";
import SigninPage from "./pages/SigninPage";
import RetrieveWorkspaceURLPage from "./pages/RetrieveWorkspaceURLPage";
import AppsumoPage from "./pages/subscriptions/AppSumoPage";
import AddPaymentMethodPage from "./pages/subscriptions/AddPaymentMethodPage";
import PaymentMethodAddedPage from "./pages/subscriptions/PaymentMethodAddedPage";
import WaitEmailVerification from "./pages/WaitEmailVerification";
import MyProfile from "./pages/MyProfile";
import NotFound from "./pages/404.vue";
import MultiUpdatePage from "@/pages/MultiUpdatePage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "dashboard",
      meta: {
        label: "Dashboard",
      },
      component: DashboardPage,
      beforeEnter: authGuard,
    },
    {
      path: "/workspace-analytics",
      name: "workspace-analytics",
      meta: {
        label: "Workspace Analytics",
      },
      component: WorkspaceAnalytics,
      beforeEnter: authGuard,
    },
    {
      path: "/projects",
      name: "projects-index",
      meta: {
        label: "Projects",
      },
      component: ListProjectsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/projects/archived",
      name: "projects-archived-index",
      meta: {
        label: "Archived Projects",
      },
      component: ListProjectsPage,
      props: { archived: true },
      beforeEnter: authGuard,
    },
    {
      path: "/projects/:projectId",
      name: "project-details",
      meta: {
        label: "Project",
      },
      component: ProjectDetailsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/projects/:projectId/timeline/:workflowId?",
      name: "project-timeline",
      meta: {
        label: "Project Timeline",
      },
      component: TimelinePage,
      beforeEnter: authGuard,
    },
    {
      path: "/projects/:projectId/timelines",
      name: "project-timelines",
      meta: {
        label: "Project Timelines",
      },
      component: ListDeliverablesPage,
      beforeEnter: authGuard,
    },
    {
      path: "/projects/:projectId/multi-update",
      name: "project-multi-update",
      meta: {
        label: "Multi-update",
      },
      component: MultiUpdatePage,
      beforeEnter: authGuard,
    },
    {
      path: "/projects/:projectId/actions/:action",
      name: "project-actions",
      meta: {
        label: "Project Actions",
      },
      component: ProjectActions,
      beforeEnter: authGuard,
    },
    {
      path: "/clients",
      name: "clients-index",
      meta: {
        label: "Clients",
      },
      component: ListClientsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/clients/:clientId",
      name: "client-details",
      meta: {
        label: "Client",
      },
      component: ClientDetailsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/workspace",
      name: "settings-workspace",
      meta: {
        label: "Workspace",
      },
      component: SettingsWorkspacePage,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/team",
      name: "settings-team",
      meta: {
        label: "Team",
      },
      component: SettingsTeamPage,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/email-settings",
      name: "settings-email-settings",
      meta: {
        label: "Email Settings",
      },
      component: SettingsEmailSettingsPage,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/custom-domain",
      name: "settings-custom-domain",
      meta: {
        label: "Custom Domain",
      },
      component: CustomDomainPage,
      beforeEnter: authGuard,
    },
    {
      path: "/templates/email-templates",
      name: "templates-email-templates",
      meta: {
        label: "Email Templates",
      },
      component: SettingsEmailTemplatesPage,
      beforeEnter: authGuard,
    },
    {
      path: "/templates/workflow-templates",
      name: "templates-workflow-templates",
      meta: {
        label: "Workflow Templates",
      },
      component: SettingsWorkflowTemplatesPage,
      beforeEnter: authGuard,
    },
    {
      path: "/templates/survey-templates",
      name: "templates-survey-templates",
      meta: {
        label: "CSAT Survey templates",
      },
      component: SettingsSurveyTemplatesPage,
      beforeEnter: authGuard,
    },
    {
      path: "/link/:linkId",
      name: "trackable-link",
      component: TrackableLinkPage,
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupPage,
    },
    {
      path: "/signup/appsumo",
      name: "signup-appsumo",
      component: AppsumoSignupPage,
    },
    {
      path: "/signin",
      name: "signin",
      component: SigninPage,
    },
    {
      path: "/signin/url-retrieve",
      name: "retrieve-workspace-url",
      component: RetrieveWorkspaceURLPage,
    },
    {
      path: "/billing/add-payment-method",
      name: "billing-add-payment-method",
      meta: {
        label: "Add Payment Method",
      },
      component: AddPaymentMethodPage,
      beforeEnter: authGuard,
    },
    {
      path: "/billing/payment-method-added",
      name: "billing-payment-method-added",
      meta: {
        label: "Payment Method Added",
      },
      component: PaymentMethodAddedPage,
      beforeEnter: authGuard,
    },
    {
      path: "/billing/appsumo",
      name: "billing-appsumo",
      meta: {
        label: "AppSumo",
      },
      component: AppsumoPage,
      beforeEnter: authGuard,
    },
    {
      path: "/verify-email",
      name: "verify-email",
      meta: {
        label: "Email Verification",
      },
      component: WaitEmailVerification,
    },
    {
      path: "/profile",
      name: "user-profile",
      meta: {
        label: "User Profile",
      },
      component: MyProfile,
      beforeEnter: authGuard,
    },
    {
      path: "*",
      name: "page-not-found",
      meta: {
        label: "Page not found",
      },
      component: NotFound,
    },
  ],
});

export default router;
