<template>
  <div>
    <button
      :disabled="isProjectCancelled"
      class="btn btn-sm btn-success mr-1"
      @click="approveStage()"
      title="Approve Stage"
    >
      <span class="sr-only">Approve Stage</span><i class="icon-check"></i>
    </button>
    <button
      :disabled="isProjectCancelled"
      class="btn btn-sm btn-danger"
      @click="rejectStage()"
      title="Reject Stage"
    >
      <span class="sr-only">Reject Stage</span><i class="icon-close"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "StageQuickApproveOrReject",
  props: {
    stage: Object,
    isProjectCancelled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    rejectStage() {
      this.$emit("on_stage_reject_click", this.stage);
    },
    approveStage() {
      this.$emit("on_stage_approve_click", this.stage);
    },
  },
};
</script>

<style scoped>
.btn-sm {
  font-size: 12px;
}
</style>
