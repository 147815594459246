<template>
  <div class="card">
    <div class="body">
      <div class="d-flex align-items-center">
        <div v-bind="iconStyle">
          <i style="font-size: 25px" v-bind="iconClasses"></i>
        </div>
        <div class="flex-1 ml-4 ml-xl-3 d-flex flex-column">
          <span style="color: #4a4c4e; font-size: 13px" class="mb-2">{{
            this.label
          }}</span>
          <h6 style="color: #4a4c4e" class="mb-0 font-weight-medium">
            {{ this.value }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberCard",
  props: {
    label: String,
    value: [String, Number],
    icon: String,
    iconBgColor: String,
  },
  computed: {
    iconClasses() {
      return { class: "fa " + this.icon };
    },
    iconStyle() {
      return {
        class:
          "flex-none icon-in-bg text-white rounded-circle bg-" +
          this.iconBgColor,
      };
    },
    // icona() {
    //   return createElement(
    //     "div", // tag name
    //     {
    //       class: "icon-in-bg text-white rounded-circle bg-" + this.iconBgColor,
    //     }, // attributes
    //     [
    //       createElement(
    //         "i", // tag name
    //         {
    //           class: "fa " + this.icon,
    //         }, // attributes
    //         this.$slots.default // array of children
    //       ),
    //     ]
    //   );
    // },
  },
};
</script>
