<template>
  <div class="col-lg-8 col-sm-12 pl-0">
    <h6>{{ lastClickedText }}</h6>
    <p>
      Feedback time left : <strong>{{ timeLeftForFeedback }}</strong>
    </p>

    <revision-files
      v-if="files?.length"
      :files="files"
      @removeFile="(i) => $emit('removeFile', i)"
    />

    <timeline-revision-agency-action-buttons
      :revision="revision"
      :workflow="workflow"
      @open_revision_for_reminder="$emit('open_revision_for_reminder', $event)"
      @revision_reject_revision_click="
        $emit('revision_reject_revision_click', $event)
      "
      @revision_stage_approve_click="
        $emit('revision_stage_approve_click', $event)
      "
    />
  </div>
</template>

<script>
import RevisionFiles from "@/components/ui/Timeline/RevisionFiles.vue";

const { DateTime } = require("luxon-business-days");
import timeMixin from "../../../mixins/time";
import workflowMixin from "../../../mixins/workflow";
import TimelineRevisionAgencyActionButtons from "./RevisionAgencyActionButtons.vue";
import { getTracklinkForProjectRevision } from "../../../apis/projects";

export default {
  name: "TimelineRevisionAgencyAwaitingFeedback",
  components: {
    RevisionFiles,
    TimelineRevisionAgencyActionButtons,
  },
  mixins: [timeMixin, workflowMixin],
  props: {
    revision: Object, // Task (Revision)
    workflow: Object, // Workflow (needed?)
    project: Object,
    files: Array,
  },
  data() {
    return {
      lastClicked: "never",
    };
  },
  computed: {
    timeLeftForFeedback() {
      const daysForFeedback = this.project.days_allowed_for_client_feedback;
      let until = DateTime.fromISO(this.revision.sent_on);
      if (!until.isValid) {
        return daysForFeedback + " business days";
      }
      until = until.plusBusiness({ days: daysForFeedback }).toJSDate();

      return this.timeElapsedBetween(new Date(), until);
    },
    revisionStage() {
      return this.getRevisionParentStage(this.revision, this.workflow);
    },
    isLastRevision() {
      return this.isLastRevisionInStage(this.revision, this.revisionStage);
    },
    lastClickedText() {
      if (this.lastClicked === "never") {
        return "Client has not clicked on review link yet";
      } else {
        return "Last clicked: " + this.lastClicked;
      }
    },
  },
  methods: {
    async setProjectLastClickedTracklink() {
      const tracklink = await getTracklinkForProjectRevision(
        this.project.id,
        this.revision.id
      );
      if (tracklink.last_clicked) {
        this.lastClicked = this.toWorkspaceDateTimeFormat(
          DateTime.fromSQL(tracklink.last_clicked)
        );
      }
    },
  },
  async mounted() {
    await this.setProjectLastClickedTracklink();
  },
};
</script>
