<template>
  <wrapper-page :ignoreSubscriptionStatus="true">
    Your payment method has been set up and will be used for your next payment
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";

export default {
  name: "PaymentMethodAddedPage",
  components: { WrapperPage },
};
</script>
