<template>
  <h6 class="small text-black-50" style="position: relative; line-height: 1.6">
    <span v-if="fixedText">{{ fixedText }}</span>
    <!-- Not really used -->
    <span v-else-if="stage.status === 'completed'">
      <!-- for completed stages: Shows status (date completed), completion difference, revisions used to complete -->
      <span v-html="statusText" />
      <span
        v-if="stage.target_date"
        :title="`target date: ${stage.target_date.substring(0, 10)}`"
      >
        | {{ completionTimeDifferenceText }}
      </span>
      <span v-if="stage?.tasks?.length && stageDurationText">
        | stage duration: {{ stageDurationText }}
      </span>
      {{ completedRevisionRoundsText }}
    </span>
    <span v-else>
      <!-- For in-progress or pending: Show status and if set, target completion date -->
      <span v-html="statusText" />
      <span v-if="stage.target_date">
        | Target completion date
        <label
          v-if="$auth.user.isAgent"
          :for="`change-target-date-stage-${this.stage.id}`"
          class="cursor-pointer select-none"
        >
          <i class="fa fa-calendar"></i>
          {{ targetDateText }}
        </label>
        <template v-else>
          <i class="fa fa-calendar"></i>
          {{ targetDateText }}
        </template>
      </span>
    </span>
    <date-time-picker
      v-if="$auth.user.isAgent"
      class="stage-date-change"
      type="date"
      :value="new Date(removeZ(stage.target_date))"
      format="YYYY-MM-DD"
      input-class="form-date-control"
      @input="(v) => onTargetDateChange(v)"
      :input-attr="{
        id: `change-target-date-stage-${stage.id}`,
        ref: `change-target-date-stage-${stage.id}`,
      }"
      :disabled-date="
        (date) => {
          const started = new Date(this.stage.started_on);
          return date < started;
        }
      "
    >
    </date-time-picker>
    <date-time-picker
      v-if="$auth.user.isAgent"
      class="stage-date-change"
      type="datetime"
      :value="new Date(this.stage.approved_on)"
      format="YYYY-MM-DD HH:mm A"
      input-class="form-date-control"
      @input="(v) => onDateChange(v, 'approved_on')"
      :input-attr="{
        id: `change-approved-date-stage-${stage.id}`,
        ref: `change-approved-date-stage-${stage.id}`,
      }"
      :disabled-date="
        (date) => {
          const today = new Date();
          return date > today;
        }
      "
    >
    </date-time-picker>
    <date-time-picker
      v-if="$auth.user.isAgent"
      class="stage-date-change"
      type="datetime"
      :value="new Date(this.stage.started_on)"
      format="YYYY-MM-DD HH:mm A"
      input-class="form-date-control"
      @input="(v) => onDateChange(v, 'started_on')"
      :input-attr="{
        id: `change-started-date-stage-${stage.id}`,
        ref: `change-started-date-stage-${stage.id}`,
      }"
      :disabled-date="
        (date) => {
          const today = new Date();
          return date > today;
        }
      "
    >
    </date-time-picker>
  </h6>
</template>

<script>
import timeMixin from "../../../mixins/time";
import { updateStageDateTime } from "@/apis/workflows";
import eventBus, { channels } from "@/eventBus";
import DateTimePicker from "@/components/ui/DateTimePicker.vue";
import { removeZ } from "@/utils/helper";

export default {
  name: "StageSubtitleText",
  mixins: [timeMixin],
  components: {
    DateTimePicker,
  },
  props: {
    stage: Object,
    workflow: Object,
  },
  computed: {
    fixedText() {
      return this.stage.subtitle;
    },
    targetDateText() {
      return this.toWorkspaceDateTimeFormat(
        this.getLuxonDateTime(this.stage.target_date, true, false)
      );
    },
    statusText() {
      if (this.stage.completed_on) {
        return (
          this.capitalize(this.stage.status) +
          " " +
          this.timeElapsed(this.stage.completed_on, "")
        );
      }
      if (
        this.stage.approved_on &&
        this.stage.status === "completed" &&
        this.stage?.tasks?.length === 0
      ) {
        if (this.$auth.user.isAgent) {
          return `<label for="change-approved-date-stage-${
            this.stage.id
          }" class="cursor-pointer select-none">
${this.capitalize(this.stage.status)} ${this.toWorkspaceDateTimeFormat(
            this.getLuxonDateTime(this.stage.approved_on, true)
          )}
</label>`;
        } else {
          return `${this.capitalize(
            this.stage.status
          )} ${this.toWorkspaceDateTimeFormat(
            this.getLuxonDateTime(this.stage.approved_on, true)
          )}`;
        }
      }
      if (this.stage.approved_on) {
        const approvedDate = this.toWorkspaceDateTimeFormat(
          this.getLuxonDateTime(this.stage.approved_on, true)
        );

        if (this.$auth.user.isAgent)
          return `<label for="change-approved-date-stage-${this.stage.id}" class="cursor-pointer select-none">
          Approved ${approvedDate}
        </label>`;
        else return `Approved ${approvedDate}`;
      }
      if (this.stage.sent_on) {
        return "Shared " + this.timeElapsed(this.stage.sent_on, "");
      }
      if (this.stage.started_on) {
        if (this.$auth.user.isAgent)
          return `<label for="change-started-date-stage-${
            this.stage.id
          }" class="cursor-pointer select-none">
          Started ${this.timeElapsed(this.stage.started_on, "")}
        </label>`;
        else return "Started " + this.timeElapsed(this.stage.started_on, "");
      }
      if (this.stage.requested_on) {
        return "Requested " + this.timeElapsed(this.stage.requested_on, "");
      }
      if (this.stage.revisionsWithoutPreview?.length === 0) {
        return "No revisions for this stage";
      } else if (this.stage.revisionsWithoutPreview?.length === 1) {
        return "1 round of revisions included";
      }
      return (
        this.stage.revisionsWithoutPreview?.length +
        " rounds of revisions included"
      );
    },
    completionTimeDifferenceText() {
      if (!this.stage.target_date) return null;

      const daysDiff = this.businessDiff(
        this.getLuxonDateTime(this.stage.approved_on),
        this.getLuxonDateTime(this.stage.target_date)
      );

      const daysDiffAbs = Math.abs(daysDiff);

      if (daysDiff >= 1) {
        return `Completion delay: ${daysDiffAbs} business ${
          daysDiffAbs === 1 ? "day" : "days"
        }`;
      } else if (daysDiff <= -1) {
        return `Completed earlier: ${daysDiffAbs} business ${
          daysDiffAbs === 1 ? "day" : "days"
        }`;
      } else {
        return "Completed on time";
      }
    },
    completedRevisionRoundsText() {
      if (!this.stage?.tasks?.length) return null;

      return " | " + this.revisionsRequiredToComplete + " revision rounds";
    },
    revisionsRequiredToComplete() {
      let cnt = 0;
      for (; cnt < this.stage?.revisionsWithoutPreview?.length || 0; cnt++) {
        const revision = this.stage.revisionsWithoutPreview[cnt];
        if (!revision.completed_on) break;
      }

      return cnt;
    },
    stageDurationText() {
      let from = this.stage.started_on;
      if (!from) from = this.stage.tasks?.[0]?.started_on;

      if (!from) return null;

      return this.timeElapsedBetween(
        new Date(from),
        new Date(this.stage.approved_on),
        true,
        ""
      );
    },
  },
  methods: {
    removeZ,
    onTargetDateChange(value) {
      value = this.getLuxonDateTime(value, true).toFormat("yyyy-MM-dd");

      updateStageDateTime(this.workflow.id, this.stage.id, value, "target_date")
        .then(() => {
          eventBus.$emit(channels.fetchTimeline);
        })
        .catch((error) => {
          this.notifyError(error);
        });
    },
    onDateChange(value, type) {
      let ldt = this.getLuxonDateTime(value, false, false);
      const newDate = ldt.toUTC().toISO();

      updateStageDateTime(this.workflow.id, this.stage.id, newDate, type)
        .then(() => {
          eventBus.$emit(channels.fetchTimeline);
        })
        .catch((error) => {
          this.notifyError(error);
        });
    },
    timeElapsed(since, pastSuffix = " overdue") {
      return (
        this.timeElapsedBetween(since, new Date(), true, pastSuffix) + " ago"
      );
    },
    capitalize(text) {
      return text[0].toUpperCase() + text.substring(1);
    },
  },
};
</script>
