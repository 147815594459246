import timeMixin from "./time";
export default {
  mixins: [timeMixin],
  methods: {
    formatNotes(element, timeline) {
      const getStageTitle = (stage_id) => {
        let title = "";
        timeline?.tasks?.forEach((task) => {
          if (task.id === stage_id) {
            title = task.title;
            return;
          }
        });
        return title;
      };
      const { toWorkspaceDateTimeFormat, getLuxonDateTime } = this;

      const dateObject = this.getLuxonDateTime(element.created_on);
      const timeString = dateObject.toFormat("HH:mm");
      const formattedDate = toWorkspaceDateTimeFormat(
        getLuxonDateTime(element.created_on)
      );
      const stageTitle = getStageTitle(element.stage_id);

      element.time = timeString;
      element.created_on = formattedDate;
      element.stage_title = stageTitle;
    },
  },
};
