<template>
  <div>
    <div class="row clearfix">
      <div class="col-lg-6 col-md-8 my-4">
        <p class="mb-1 title">
          Send emails using your verified domain
          <span class="title-info text-info"> (Recommended setup) </span>
        </p>
        <p class="sub-title">
          Authenticate your own domain so that Planeolo emails can be sent from
          your own sending domain. e.g. from: anyone@yourcompany.com
        </p>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-6 col-md-8">
        <form-input-group
          v-model="domainName"
          :field="field"
          class="flex-grow-1"
        >
          <template slot="input-group-append">
            <button
              v-if="!hasDnsRecords"
              @click="$emit('addDomain', domainName)"
              type="button"
              class="btn btn-primary"
              title="Add Domain"
              :disabled="isSubscriptionReadOnly()"
            >
              <span class="sr-only">Add Domain</span> <i class="fa fa-plus"></i>
            </button>
            <button
              v-else
              @click="$emit('deleteDomain', domainName)"
              type="button"
              class="btn btn-danger"
              title="Delete Domain"
              :disabled="isSubscriptionReadOnly()"
            >
              <span class="sr-only">Delete Domain</span>
              <i class="fa fa-minus"></i>
            </button>
          </template>
        </form-input-group>

        <template v-if="hasDnsRecords && !isVerified">
          <h6>Install the following DNS Records</h6>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Host</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody v-if="domain.dns.mail_cname">
                <tr>
                  <td>{{ domain.dns.mail_cname.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.mail_cname.host }}</td>
                  <td>{{ domain.dns.mail_cname.data }}</td>
                </tr>
                <tr>
                  <td>{{ domain.dns.dkim1.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.dkim1.host }}</td>
                  <td>{{ domain.dns.dkim1.data }}</td>
                </tr>
                <tr>
                  <td>{{ domain.dns.dkim2.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.dkim2.host }}</td>
                  <td>{{ domain.dns.dkim2.data }}</td>
                </tr>
              </tbody>
              <tbody v-if="domain.dns.mail_server">
                <tr>
                  <td>{{ domain.dns.mail_server.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.mail_server.host }}</td>
                  <td>{{ domain.dns.mail_server.data }}</td>
                </tr>
                <tr>
                  <td>{{ domain.dns.dkim.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.dkim.host }}</td>
                  <td>{{ domain.dns.dkim.data }}</td>
                </tr>
                <tr>
                  <td>{{ domain.dns.subdomain_spf.type.toUpperCase() }}</td>
                  <td>{{ domain.dns.subdomain_spf.host }}</td>
                  <td>{{ domain.dns.subdomain_spf.data }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <span>When you have added the DNS entries, click on:</span>
          <button
            @click="$emit('verifyDomain', domainName)"
            type="button"
            class="btn btn-primary ml-2"
            title="Verify Domain"
            :disabled="isSubscriptionReadOnly()"
          >
            <span>Verify Domain</span>
          </button>
        </template>
        <template v-if="!hasDnsRecords && !isVerified">
          <div>
            <a
              href="https://planeolo.deskudocs.com/article/send-emails-from-your-own-domain"
              target="_blank"
              >How to send email from your own domain</a
            >
          </div>
        </template>
      </div>
      <div class="col-lg-6 col-md-4" v-if="hasDnsRecords && !isVerified">
        <h6>Important technical notes</h6>
        <p>
          Some DNS hosts will automatically add your top-level domain to the end
          of DNS records you create, which can turn a DNS Record for
          <em>email.domain.com</em> into <em>email.domain.com.domain.com</em>.
        </p>
        <p>
          Be sure to follow the convention on existing records in your DNS panel
          when adding new ones, as sometimes you will only need to add what is
          to the left of the top-level domain in the Host.
        </p>
        <p>
          For example, in the Host part, for <em>email.domain.com</em> one might
          only need to write, <em>email</em>
        </p>
        <p>
          You can read in more detail on the help page:
          <a
            href="https://planeolo.deskudocs.com/article/send-emails-from-your-own-domain"
            target="_blank"
            >Send emails from your own domain</a
          >
        </p>
      </div>
    </div>
    <div class="row clearfix" v-if="!isVerified">
      <div class="col-lg-6 col-md-8 mt-4 pt-4 border-top">
        <p class="mb-0 title no-wrap">
          Send emails using an unverifiable domain
          <span
            v-if="isSendFromOwnEmail"
            class="bg-success text-white ml-2 px-3 py-0.5 d-inline-block"
            style="border-radius: 50px; font-size: 14px; font-weight: 400"
            >Active Setup</span
          >
        </p>
        <p class="title-info">(Example gmail, yahoo mail and hotmail)</p>

        <p class="sub-title">
          To enhance email deliverability, messages are sent from
          client@planeolo.com by default. However, if you prefer to send emails
          from an unverifiable email domain like Gmail, Hotmail, or Yahoo Mail,
          you can enable the toggle below. Keep in mind that when sending from
          an unverified email, there is a possibility that the emails may be
          marked as spam.
        </p>

        <toggle-button
          :initial-toggle-value="isSendFromOwnEmail"
          :label="'Send from unverified email address'"
          @on-toggle="onToggleChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputGroup from "../FormInputGroup.vue";
import ToggleButton from "../ToggleButton.vue";

export default {
  name: "EmailDomainVerificationEntry",
  components: { FormInputGroup, ToggleButton },
  props: {
    domain: Object,
  },
  data() {
    return {
      domainName: this.domain.domain,
      isVerified: this.domain.is_verified,
      hasDnsRecords: this.domain.dns !== undefined,
      field: {
        label:
          "Email Domain Name" +
          (this.domain.is_verified
            ? ` (verified) <span class="bg-success text-white ml-2 px-3 d-inline-block" style="border-radius: 50px; font-size: 14px; font-weight: 400">Active Setup</span>`
            : ""),
        name: "domain_name",
        placeholder: "The part after the '@' in your email addresses.",
        readonly: this.domain.dns !== undefined,
      },
      isSendFromOwnEmail: this.domain?.send_from_own_email,
    };
  },
  methods: {
    onToggleChange(value) {
      this.isSendFromOwnEmail = value;
      this.$emit("updateDomainSettings", {
        domain: this.domainName,
        isSendFromOwnEmail: value,
      });
    },
  },
};
</script>

<style scoped>
table.table,
table.table td,
table.table th {
  border: 1px solid lightgray;
  text-align: center;
}

table.table th {
  font-weight: bold;
}

table.table td {
  white-space: normal;
  max-width: 10vw;
}
.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #747474;
}
.title-info {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
</style>
