<template>
  <div class="row clearfix" style="margin: 0px; padding: 0px">
    <div class="col-lg-3 col-md-3" style="padding: 0px">
      <div class="px-3" style="background-color: #f7f8fa">
        <div class="header pt-2 pb-4">
          <h2><strong>Settings</strong></h2>
        </div>
        <div class="card">
          <form-input-group
            v-model="template.name"
            :field="{ label: 'Template title', name: 'name' }"
            class="flex-grow-1"
            :showLabel="true"
          />
          <div class="">
            <label><strong>Builder</strong></label>
            <ul class="right_chat list-unstyled mb-0">
              <li>
                <a href="javascript:void(0);" @click="appendStarRatingSurvey">
                  <div class="media">
                    <div class="avtar-pic w35 bg-primary">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                    <div class="media-body">
                      <span class="name">Star rating</span>
                      <span class="message">No revisions</span>
                    </div>
                  </div>
                </a>
              </li>
              <li class="online">
                <a href="javascript:void(0);" @click="appendCommentBoxSurvey">
                  <div class="media">
                    <div class="avtar-pic w35 bg-primary">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                    <div class="media-body">
                      <span class="name">Comment box question</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-9 pl-4">
      <div class="" style="min-height: 400px">
        <div
          class="chat-header clearfix header"
          style="padding-left: 0px; padding-right: 0px"
        >
          <div class="row clearfix">
            <div class="col-1">
              <!-- Currently, I've hidden the reorder functionality as it necessitates some additional modifications
                    to the database. -->
              <ul
                class="header-dropdown dropdown"
                style="position: relative; display: none"
              >
                <li class="dropdown">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                  ></a>
                  <ul class="dropdown-menu">
                    <li v-if="isEditingSurvey">
                      <a @click="isEditingSurvey = false"
                        >Reorder survey items</a
                      >
                    </li>
                    <li v-if="!isEditingSurvey">
                      <a @click="isEditingSurvey = true">Edit survey items</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-7">
              <h2 class="m-b-0"><strong>Survey Questions</strong></h2>
            </div>
            <div class="col-4">
              <h2 class="m-b-0"><strong>Question in email</strong></h2>
            </div>
          </div>
        </div>
        <div class="dd nestable">
          <textarea
            ref="taWorkflowOrder"
            class="special-textarea"
            style="display: none"
            v-model="taWorkflowOrder"
          ></textarea>
          <!-- required to store nestable changes, i.e., the order of the stages -->
          <ol class="dd-list">
            <li
              class="dd-item"
              :data-id="index"
              v-for="(survey, index) in computedSurveyItems"
              :key="index"
            >
              <div v-if="canReorder()" class="dd-handle">
                <div class="row clearfix">
                  <div class="col-1"></div>
                  <div class="col-4">
                    <span>{{ survey.question }}</span>
                  </div>
                  <div
                    v-if="survey.is_star_rating"
                    class="col-4"
                    style="min-width: 7em; max-width: 7em"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked=""
                        v-model="survey.is_question_in_email"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row clearfix">
                  <div
                    class="col-1 justify-content-center align-items-center"
                    style="margin-top: 32px"
                  >
                    <button
                      v-if="isEditingSurvey"
                      @click="removeSurveyItem(index)"
                      type="button"
                      class="btn btn-danger btn-sm"
                      title="Remove Survey"
                      :disabled="isSubscriptionReadOnly()"
                    >
                      <span class="sr-only">Remove survey</span>
                      <i class="icon-trash"></i>
                    </button>
                  </div>
                  <div class="col-7 d-flex flex-column">
                    <h6 class="mt-2 mb-1">
                      {{
                        survey.is_star_rating
                          ? "Star rating question"
                          : "Comment box question"
                      }}
                    </h6>
                    <textarea
                      ref="textareaInput"
                      :placeholder="'Type question here'"
                      class="form-control"
                      :name="`survey-${index}`"
                      :aria-label="`survey-${index}`"
                      style="
                        resize: vertical;
                        overflow-y: auto;
                        word-wrap: break-word;
                        height: auto;
                      "
                      v-model="survey.question"
                    ></textarea>
                    <div v-if="survey.is_star_rating" class="mt-2">
                      <dynamicStarRating
                        :dynamicFontSize="'2rem'"
                        :maxRating="5"
                        :ready-only="true"
                      ></dynamicStarRating>
                    </div>
                  </div>
                  <div
                    v-if="survey.is_star_rating"
                    class="col-4 d-flex align-items-center"
                    style="padding-left: 60px; padding-bottom: 10px"
                  >
                    <label class="switch">
                      <input
                        type="checkbox"
                        :checked="survey.is_question_in_email"
                        @change="
                          ($event) => handleCheckboxChange($event, index)
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputGroup from "../FormInputGroup.vue";
import dynamicStarRating from "../Dashboard/DynamicStarRating.vue";

export default {
  name: "SurveyTemplateEditor",
  components: { FormInputGroup, dynamicStarRating },
  props: {
    template: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isEditingSurvey: true,
      taWorkflowOrder: "",
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  computed: {
    surveyTemplate() {
      return this.template;
    },
    computedSurveyItems() {
      return this.template?.surveyItems?.filter((item) => {
        return !item.deleted; // Include items with deleted set to 0, false or null
      });
    },
  },
  methods: {
    handleCheckboxChange($event, index) {
      if ($event.target.checked === false) {
        $event.target.checked = true;
        return;
      }
      this.surveyTemplate.surveyItems.forEach((item, i) => {
        if (i !== index) {
          item.is_question_in_email = false;
        } else {
          item.is_question_in_email = true;
        }
      });
    },
    validate: function () {
      if (!this.template) return "No survey template";
      if (!this.template.surveyItems) return "No survey items";
      if (this.template.surveyItems.length === 0) return "No survey items";
      // No empty survey names
      if (
        this.template.surveyItems.find(
          (survey) => !survey.question || survey.question.trim().length === 0
        )
      )
        return "Empty survey question";

      return true;
    },
    canReorder() {
      return !this.isEditingSurvey;
    },
    appendStarRatingSurvey: function () {
      const survey = {
        question: "",
        is_star_rating: true,
        is_question_in_email: this.surveyTemplate.surveyItems.length
          ? false
          : true,
      };
      this.surveyTemplate.surveyItems.push(survey);
    },
    appendCommentBoxSurvey: function () {
      const survey = {
        question: "",
        is_star_rating: false,
        is_question_in_email: false,
      };
      this.surveyTemplate.surveyItems.push(survey);
    },
    removeSurveyItem: function (index) {
      if (this.template.surveyItems[index].id) {
        this.$set(this.template.surveyItems[index], "deleted", true);
      } else {
        this.template.surveyItems.splice(index, 1);
      }
    },
    getSurveyWorkflow() {
      let newWorkflow = JSON.parse(JSON.stringify(this.surveyTemplate));
      if (
        typeof this.taWorkflowOrder === "string" &&
        this.taWorkflowOrder.length > 5 &&
        Array.isArray(JSON.parse(this.taWorkflowOrder))
      ) {
        newWorkflow.surveyItems = [];
        JSON.parse(this.taWorkflowOrder).forEach((entry) => {
          if (this.template.surveyItems[entry.id]) {
            newWorkflow.surveyItems.push(this.template.surveyItems[entry.id]);
          }
        });
      }

      return newWorkflow;
    },
  },
  watch: {
    isEditingSurvey: function () {
      window.nestable();
    },
  },
};
</script>

<style scoped>
.chatapp_body {
  border-right: 0;
}
.chatapp_list {
  min-width: 280px;
}
</style>
