<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>CSAT Survey templates</h2>
          </div>

          <div
            class="body d-flex flex-column align-items-center"
            v-if="templates.length === 0"
          >
            <p class="text-center">
              You have no survey templates so far.<br />
            </p>
            <button
              @click="addTemplate"
              type="button"
              class="btn btn-primary mt-4"
              title="Add Template"
              :disabled="isSubscriptionReadOnly()"
            >
              <i class="fa fa-plus"></i>
              <span> Add Survey Template</span>
            </button>
          </div>

          <div class="body" v-else>
            <div class="tab-bar-container body p-0" style="border: none">
              <div class="tab-bar-scroll">
                <ul class="nav nav-tabs">
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'csat_survey' }"
                      @click="setActiveTab('csat_survey')"
                    >
                      CSAT Survey Templates
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-5 col-md-5">
                <b><label class="pt-4">Survey templates</label></b>
              </div>
              <div class="col-lg-7 col-md-7"></div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-3 col-md-3">
                <ul class="list-group">
                  <li
                    v-for="(template, index) in templates"
                    :key="template.title"
                    :class="[
                      'list-group-item',
                      'd-flex',
                      'justify-content-between',
                      'align-items-center',
                      {
                        'active-list': chosenTemplateIndex === index,
                      },
                      {
                        'inactive-list': chosenTemplateIndex !== index,
                      },
                    ]"
                    @click="
                      () => {
                        chosenTemplateIndex = index;
                      }
                    "
                  >
                    <div
                      v-if="chosenTemplateIndex === index"
                      class="d-inline-block header"
                      style="padding-bottom: 0px !important"
                    >
                      <h2 style="font-size: 14px">
                        <strong>{{ template.name }}</strong>
                      </h2>
                    </div>
                    <div v-else class="d-inline-block">
                      {{ template.name }}
                    </div>
                    <div class="header-dropdown dropdown d-inline-block px-1">
                      <div v-if="activeTab !== 'invites'" class="dropdown">
                        <a data-toggle="dropdown" aria-haspopup="true">
                          <img
                            src="/assets/icons/dots-three-circle.svg"
                            height="20px"
                            width="20px"
                            style="margin-top: -1px"
                            alt="menu"
                          />
                        </a>
                        <ul class="dropdown-menu text-nowrap">
                          <li v-for="item in menuItems" :key="item">
                            <a
                              v-if="item === 'Clone template'"
                              @click="cloneTemplate"
                            >
                              <span class="fa fa-copy mr-1"></span>
                              {{ item }}
                            </a>
                            <a
                              v-if="item === 'Delete template'"
                              @click="removeTemplate"
                            >
                              <span class="fa fa-trash-o mr-1"></span>
                              {{ item }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <div
                    v-if="activeTab !== 'invites'"
                    class="align-right mt-2 mr-1"
                  >
                    <button
                      @click="addTemplate"
                      type="button"
                      class="btn btn-primary"
                      title="Add Survey Template"
                      :disabled="isSubscriptionReadOnly()"
                    >
                      <span class="sr-only">Add Survey Template</span>
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </ul>
              </div>
              <div
                class="col-lg-9 col-md-9 d-flex flex-column"
                style="margin: 0px; padding: 0px"
              >
                <div
                  class="card"
                  v-for="(template, index) in templates"
                  :key="index"
                  v-show="index == chosenTemplateIndex"
                >
                  <survey-template-editor
                    :ref="`templateEditor_${index}`"
                    :template="template"
                  />
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 d-flex justify-content-center">
                    <button
                      v-on:click="saveDetails"
                      :disabled="isSavingDetails || isSubscriptionReadOnly()"
                      class="btn btn-primary"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card"
              v-for="(template, index) in templates"
              :key="index"
              v-show="index == chosenTemplateIndex"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import SurveyTemplateEditor from "../../components/ui/FeedbackSurvey/SurveyTemplateEditor.vue";
import {
  createSurveyTemplate,
  deleteSurveyTemplate,
  getAllSurvey,
  updateSurveyTemplate,
} from "../../apis/survey";

export default {
  name: "SettingsSurveyTemplatesPage2",
  components: {
    WrapperPage,
    SurveyTemplateEditor,
  },
  data() {
    return {
      isLoadingTemplates: false,
      isSavingDetails: false,
      templates: [],
      removedTemplates: [], // Keeps track of templates that were deleted.
      chosenTemplateIndex: 0,
      menuItems: ["Clone template", "Delete template"],
      activeTab: "csat_survey",
    };
  },
  computed: {
    currentTemplate() {
      if (this.templates.length === 0) {
        return null;
      }
      return this.templates[this.chosenTemplateIndex ?? 0];
    },
  },
  methods: {
    async loadData() {
      this.templates = await getAllSurvey();
      if (this.templates === undefined) {
        this.templates = [];
      }
      this.chosenTemplateIndex = 0;
    },
    addTemplate() {
      this.templates.push({
        name: "New Survey",
        surveyItems: [
          {
            question: "",
            is_star_rating: true,
            is_question_in_email: true,
          },
        ],
      });
      this.chosenTemplateIndex = this.templates.length - 1;
      window.nestable();
    },
    cloneTemplate() {
      let newTemplate = JSON.parse(
        JSON.stringify(this.currentTemplate, (key, value) =>
          key === "id" ? undefined : value
        )
      );
      newTemplate.name += " Copy";
      delete newTemplate.id;
      this.templates.push(newTemplate);
      this.chosenTemplateIndex = this.templates.length - 1;
      window.nestable();
    },
    removeTemplate() {
      let index = this.chosenTemplateIndex;
      const removedTemplate = this.templates.splice(index, 1)[0];
      if (removedTemplate.id) {
        this.removedTemplates.push(removedTemplate.id);
      }
      this.chosenTemplateIndex = Math.min(
        this.chosenTemplateIndex,
        this.templates.length - 1
      );
      this.notifyWarn(
        "Template - " +
          removedTemplate.name +
          ' - will be deleted when you click on "Save Changes"',
        "Edit survey Templates"
      );
    },
    loadTemplate(index) {
      this.chosenTemplateIndex = index;
    },
    async saveDetails() {
      this.isSavingDetails = true;

      try {
        // Delete removed templates
        for (let u = 0; u < this.removedTemplates.length; u++) {
          await deleteSurveyTemplate(this.removedTemplates[u]);
        }
        // Add new templates & Update others
        let promises = [];
        for (let t = 0; t < this.templates.length; t++) {
          const surveyValidation =
            this.$refs[`templateEditor_${t}`][0].validate();
          if (surveyValidation !== true) {
            this.notifyError(surveyValidation);
            this.isSavingDetails = false;
            return;
          }
          const template =
            this.$refs[`templateEditor_${t}`][0].getSurveyWorkflow();
          if ("id" in template) {
            promises.push(updateSurveyTemplate(template, template.id));
          } else {
            promises.push(createSurveyTemplate(template));
          }
        }
        await Promise.allSettled(promises);
        this.notifySuccess("Survey Template saved");
        this.loadData();
      } catch (err) {
        this.notifyError(err, "Failed to save Survey Templates");
      }
      this.isSavingDetails = false;
    },
  },
  watch: {
    chosenTemplateIndex: function () {
      document.getElementById("input-name").focus();
    },
  },
  created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.active-list {
  background-color: #f7f8fa;
  border-bottom: 1px solid #dde4ea;
  border-left: 1px solid #dde4ea;
  border-right: 1px solid #dde4ea;
  margin-bottom: 0px;
  /* border-radius: 0.25rem; */
}
.inactive-list {
  margin-bottom: 0px;
  border-bottom: 1px solid #dde4ea;
  /* border-radius: 0.25rem; */
}
</style>
