<template>
  <Modal
    :title="'Quality Control Checklist'"
    :show-close-button="false"
    :maxWidth="350"
    :alignTitleLeft="false"
    :showCancelButton="true"
    submit-text="Next"
    @close="$emit('close')"
    @submit="$emit('send')"
    :disable-submit-button="!isAllQualityChecked"
  >
    <div class="container">
      <div class="col-12">
        <div v-for="(checkbox, index) in qcChecklist" :key="index">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="checkbox.checked"
              @change="handleCheckboxChange"
            />
            <span class="ml-2">{{ checkbox.label }}</span>
          </label>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "QualityControlChecklistModal",
  components: { Modal },
  props: {
    qcChecklistTobeDisplayed: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      placeholder: "Add text here",
      isAllQualityChecked: false,
    };
  },
  computed: {
    qcChecklist() {
      if (
        !this.qcChecklistTobeDisplayed ||
        !this.qcChecklistTobeDisplayed.stages
      ) {
        return [];
      }

      return this.qcChecklistTobeDisplayed.stages.map((stage) => ({
        label: stage.title,
        checked: false,
        // You can add more properties to each checkbox object as needed
      }));
    },
  },
  methods: {
    handleCheckboxChange() {
      // Check if all checkboxes are selected
      const allChecked = this.qcChecklist.every((checkbox) => checkbox.checked);

      // Perform action when all checkboxes are selected
      if (allChecked) {
        this.performAction();
      } else {
        this.isAllQualityChecked = false;
      }
    },
    performAction() {
      // Your action when all checkboxes are selected
      console.log("All checkboxes are selected. Performing action...");
      this.isAllQualityChecked = true;
    },
  },
};
</script>
<style scoped></style>
