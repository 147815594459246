var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wrapper-page',[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v("Email Templates")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"tab-bar-container body p-0",staticStyle:{"border":"none"}},[_c('div',{staticClass:"tab-bar-scroll"},[_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item mr-1"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTab === 'production' },on:{"click":function($event){return _vm.setActiveTab('production')}}},[_vm._v(" Production ")])]),_c('li',{staticClass:"nav-item mr-1"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTab === 'reminders' },on:{"click":function($event){return _vm.setActiveTab('reminders')}}},[_vm._v(" Reminders ")])]),_c('li',{staticClass:"nav-item mr-1"},[_c('a',{staticClass:"nav-link mr-1",class:{ active: _vm.activeTab === 'invites' },on:{"click":function($event){return _vm.setActiveTab('invites')}}},[_vm._v(" Invites ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTab === 'surveys' },on:{"click":function($event){return _vm.setActiveTab('surveys')}}},[_vm._v(" CSAT Surveys ")])])])])]),_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-5 col-md-5"},[_c('b',[_c('label',{staticClass:"pt-4"},[_vm._v("Email Templates")])])]),_c('div',{staticClass:"col-lg-7 col-md-7"},[_c('b',[_c('label',{staticClass:"pt-4"},[_vm._v("Email body")])])])]),_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-5 col-md-5"},[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('ul',{staticClass:"list-group"},[_vm._l((_vm.selectedTabEmailsTemplate),function(template,index){return _c('li',{key:`${template.id}-${template.title}`,class:[
                        'list-group-item',
                        'd-flex',
                        'justify-content-between',
                        'align-items-center',
                        {
                          'active-list': _vm.chosenTemplateIndex === index,
                        },
                        {
                          'inactive-list': _vm.chosenTemplateIndex !== index,
                        },
                      ],on:{"click":() => {
                          _vm.chosenTemplateIndex = index;
                          _vm.chosenMultiLingualTemplateIndex = 0;
                        }}},[(_vm.chosenTemplateIndex === index)?_c('div',{staticClass:"d-inline-block header",staticStyle:{"padding-bottom":"0px !important"}},[_c('h2',{staticStyle:{"font-size":"14px"}},[_c('strong',[_vm._v(_vm._s(template.title))])])]):_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(template.title)+" ")]),_c('div',{staticClass:"header-dropdown dropdown d-inline-block px-1"},[(_vm.activeTab !== 'invites')?_c('div',{staticClass:"dropdown"},[_c('a',{attrs:{"data-toggle":"dropdown","aria-haspopup":"true"}},[_c('img',{staticStyle:{"margin-top":"-1px"},attrs:{"src":"/assets/icons/dots-three-circle.svg","height":"20px","width":"20px","alt":"menu"}})]),_c('ul',{staticClass:"dropdown-menu text-nowrap"},_vm._l((_vm.menuItems),function(item){return _c('li',{key:item},[(item === 'Clone template')?_c('a',{on:{"click":_vm.cloneTemplate}},[_c('span',{staticClass:"fa fa-copy mr-1"}),_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),(item === 'Delete template')?_c('a',{on:{"click":_vm.removeTemplate}},[_c('span',{staticClass:"fa fa-trash-o mr-1"}),_vm._v(" "+_vm._s(item)+" ")]):_vm._e()])}),0)]):_vm._e()])])}),(_vm.activeTab !== 'invites')?_c('div',{staticClass:"align-right mt-2 mr-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","title":_vm.canCreateEmailTemplate
                            ? 'Add Email Template'
                            : 'You reached the email templates limit for your workspace.',"disabled":_vm.isSubscriptionReadOnly() || !_vm.canCreateEmailTemplate},on:{"click":_vm.addTemplate}},[_c('span',{staticClass:"sr-only"},[_vm._v("Add Email Template")]),_c('i',{staticClass:"fa fa-plus"})])]):_vm._e()],2)]),_c('div',{staticClass:"col-lg-6 col-md-6 d-flex flex-column",staticStyle:{"background-color":"#f7f8fa"}},[_c('div',{staticClass:"header pt-2 pb-4"},[_c('h2',[_c('strong',[_vm._v("Settings")])])]),_c('div',{staticClass:"card",staticStyle:{"margin-bottom":"0"}},[(_vm.emailTemplate)?_c('form-input-group',{staticClass:"flex-grow-1",attrs:{"field":_vm.templateFields[0],"showLabel":true},model:{value:(_vm.emailTemplate.title),callback:function ($$v) {_vm.$set(_vm.emailTemplate, "title", $$v)},expression:"emailTemplate.title"}}):_vm._e(),_c('form-input-group',{attrs:{"field":_vm.templateFields[1],"disabled":_vm.activeTab === 'reminders'},model:{value:(_vm.emailTemplate.type),callback:function ($$v) {_vm.$set(_vm.emailTemplate, "type", $$v)},expression:"emailTemplate.type"}})],1),_c('div',{staticClass:"divider mt-1 mb-1"}),_c('div',{staticClass:"mt-3 mb-3 d-flex flex-column flex-1"},[_c('div',{staticClass:"header pb-1"},[_c('h2',{staticClass:"text-black"},[_c('strong',[_vm._v("Multi-language")])])]),_c('div',{staticClass:"pt-3 pb-3 flex-1 d-flex flex-column list-group",staticStyle:{"overflow-y":"auto"}},_vm._l((_vm.multiLingualTemplatesForSelectedTemplate),function(multiLingualTemplate,mli){return _c('a',{key:`${multiLingualTemplate.id}-${multiLingualTemplate.language}`,staticClass:"header bg-white cursor-pointer d-flex align-items-center justify-content-between list-group-item",class:`${
                          _vm.chosenMultiLingualTemplateIndex === mli
                            ? 'active-list'
                            : 'inactive-list'
                        }`,staticStyle:{"padding-bottom":"0.75rem","padding-top":"0.75rem"},attrs:{"role":"button","tabindex":"-1"},on:{"click":function($event){_vm.chosenMultiLingualTemplateIndex = mli}}},[_c('h2',{staticClass:"mb-0 mt-0 font-weight-normal",class:{
                            'text-black':
                              _vm.chosenMultiLingualTemplateIndex !== mli,
                          },staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.languageFromCode(multiLingualTemplate.language) ?.name)+" ")]),(multiLingualTemplate.parent_id)?_c('a',{staticClass:"cursor-pointer text-black",attrs:{"role":"button","tabindex":"-1","title":"remove language"},on:{"click":function($event){return _vm.removeLanguage(mli)}}},[_c('i',{staticClass:"fa fa-trash-o"})]):_vm._e()])}),0),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.emailTemplate?.id)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":!_vm.hasAvailableLanguagesToAdd,"title":_vm.hasAvailableLanguagesToAdd
                            ? 'Add Language'
                            : 'There are no more languages'},on:{"click":function($event){_vm.showAddLanguageModal = true}}},[_c('i',{staticClass:"fa fa-plus"})]):_vm._e()])])])])]),_c('div',{staticClass:"col-lg-7 col-md-7 d-flex flex-column"},[_c('email-template-editor',{ref:"emailTemplateEditor",attrs:{"templateType":_vm.emailTemplate.type,"language":_vm.multiLingualEmailTemplate.language ?? 'en'},model:{value:(_vm.multiLingualEmailTemplate.body),callback:function ($$v) {_vm.$set(_vm.multiLingualEmailTemplate, "body", $$v)},expression:"multiLingualEmailTemplate.body"}}),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-7 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isSavingDetails || _vm.isSubscriptionReadOnly()},on:{"click":_vm.saveDetails}},[_vm._v(" Save Changes ")])]),_c('div',{staticClass:"col-md-5 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-info",on:{"click":_vm.preview}},[_vm._v(" Preview ")])])])],1)])])])]),(_vm.showAddLanguageModal)?_c('email-template-add-language-modal',{attrs:{"exclude":_vm.usedLanguagesTemplate},on:{"close":function($event){_vm.showAddLanguageModal = false},"submit":(language) => {
          _vm.addLanguage(language);
          _vm.showAddLanguageModal = false;
        }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }