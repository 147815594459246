<template>
  <div id="app">
    <div id="isClient" ref="isClient"></div>
    <!-- Overlay For Sidebars -->
    <div class="overlay"></div>
    <div id="wrapper">
      <TopHeaderNavigation />
      <search-panel
        ref="searchPanel"
        @search="searchRelayFunction"
      ></search-panel>
      <LeftSideBarNavigation />

      <!-- Main content -->
      <div id="main-content">
        <div class="container-fluid">
          <main-content-header
            ><template slot="MainContentHeaderActions"
              ><slot name="MainContentHeaderActions"></slot></template
          ></main-content-header>
          <!-- Actual content -->
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../mixins/time";
import TopHeaderNavigation from "./TopHeaderNavigation.vue";
import LeftSideBarNavigation from "./LeftSideBarNavigation.vue";
import MainContentHeader from "./MainContentHeader.vue";
import SearchPanel from "./SearchPanel.vue";

export default {
  name: "WrapperPage",
  mixins: [timeMixin],
  components: {
    TopHeaderNavigation,
    LeftSideBarNavigation,
    MainContentHeader,
    SearchPanel,
  },
  props: {
    ignoreSubscriptionStatus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // This is called each time a page is loaded
    window.onWrapperMounted();

    if (this.$auth.user.isAgent) {
      // If the user is an Agent, then delete the element with id 'isClient'
      // This is used by Usetiful to not show help tips for Clients
      this.$refs.isClient.remove();

      // If the user is an Agent show the Chat widget
      const script = document.createElement("script");
      script.src = "https://livechat.desku.io/cdn/widget.js?lc_id=006749295423";
      script.async = true;
      document.body.appendChild(script);
    }

    if (this.$auth.user.isAgent && !this.ignoreSubscriptionStatus) {
      const subscription = this.$auth.tenant.subscription;

      // If it's a Client, not an Agent, then we definitely don't show subscription warnings
      if (this.isSubscriptionWithinGracePeriod()) {
        this.notifyWarn(
          "Please update your payment details by " +
            this.getLuxonDateTime(subscription.grace_period, false).toFormat(
              "d MMMM"
            ),
          "Subscription Expired",
          5000
        );
      }
      if (this.isSubscriptionExceedingSeats()) {
        this.notifyWarn(
          "Please reduce your team members to available number of seats",
          "Subscription Seats Exceeded",
          5000
        );

        if (this.$route.name !== "settings-team") {
          if (
            this.$auth.user.owner ||
            this.$auth.user.admin ||
            this.$auth.user.add_users
          )
            this.$router.push({
              name: "settings-team",
            });
          else this.$router.push("/");
        }
      }
      if (this.isSubscriptionReadOnly()) {
        this.notifyWarn(
          "Please update your payment details by " +
            this.getLuxonDateTime(
              subscription.cancellation_date,
              false
            ).toFormat("d MMMM"),
          "Subscription Expired - Read Only",
          5000
        );
      }
      if (this.isSubscriptionSuspended()) {
        this.notifyError(
          "Please update your payment details or switch to a Free Plan",
          "Subscription Suspended"
        );

        if (
          this.$route.name !== "billing-price-page" &&
          this.$route.name !== "settings-team"
        ) {
          if (this.$auth.user.owner)
            this.$router.push({ name: "billing-price-page" });
          else this.$router.push("/");
        }
      }
    }
  },
  methods: {
    /**
     * Child components using WrapperPage can listen to the 'search' event relayed from here.
     * To allow all components to listen directly to the 'search' event, we should use;
     *    $root.$emit('search', data)
     * in SearchPanel (as opposed to just $emit)
     * Then the components have to use to listen to the 'search' event;
     *    mounted() { this.$root.$on('search', (data) => { ... }); }
     * We don't use the $root approach so we can control the flow and debug better
     */
    searchRelayFunction: function (event) {
      this.$emit("search", event.target.value);
    },
  },
};
</script>

<style scoped>
/* Always hide the isClient div */
#isClient {
  display: none;
}
</style>
