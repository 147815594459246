<template>
  <DatePicker
    :value="value"
    @input="(v) => $emit('input', v)"
    :type="type"
    :format="format"
    :placeholder="placeholder"
    :input-class="inputClass"
    :input-attr="inputAttr"
    :disabled-date="disabledDate"
  />
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "DateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    type: {
      type: String,
      default: "date",
    },
    value: {
      type: [Date, String],
      default: null,
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "mx-input",
    },
    inputAttr: {
      type: Object,
      default: () => {},
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
  },
};
</script>
