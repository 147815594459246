<template>
  <wrapper-page>
    <div class="card">
      <div class="header">
        <h2>{{ "Workspace Analytics" }}</h2>
      </div>
      <div class="row clearfix">
        <div
          id="workspace-in-production"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="In Production"
            :value="`${projectStats.projectsInProduction} projects`"
            iconBgColor="indigo"
            icon="fa-circle-o-notch fa-spin"
            t
          ></number-card>
        </div>
        <div
          id="workspace-awaiting-feedback"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Awaiting Feedback"
            :value="`${projectStats.projectsAwaitingFeedback} projects`"
            iconBgColor="azura"
            icon="icon-hourglass"
          ></number-card>
        </div>
        <div
          id="workspace-total-active-projects"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Total Active Projects"
            :value="`${projectStats.projectsActive} projects`"
            iconBgColor="orange"
            icon="icon-layers"
          ></number-card>
        </div>
        <div
          id="workspace-customer-satisfaction"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Customer Satisfaction"
            :value="`${averageRatings.averageRatingOverall} / 5`"
            iconBgColor="orange"
            icon="fa-star"
          ></number-card>
        </div>
      </div>
      <div class="row clearfix">
        <div
          id="workspace-active-clients"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Active Clients"
            :value="`${this.totalUniqueClientsInProgress} Client`"
            iconBgColor="indigo"
            icon="fa-user"
          ></number-card>
        </div>

        <div
          id="workspace-completed-projects"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Completed Projects"
            :value="`${projectStats.projectsCompleted} projects`"
            iconBgColor="green"
            icon="fa-check"
          ></number-card>
        </div>
        <div
          id="workspace-completed-projects"
          class="col-lg-3 col-md-6"
          style="padding: 0 5px"
        >
          <number-card
            label="Cancelled Projects"
            :value="`${projectStats.projectsCancelled} projects`"
            iconBgColor="red"
            icon="fa-times"
          ></number-card>
        </div>
      </div>
      <div class="row clearfix" style="justify-content: right">
        <div
          class="col-lg-3 col-md-6 col-xs-6 mb-2 align-right"
          style="max-width: 270px"
        >
          <div class="body align-right" style="padding: 10px; border: none">
            <button
              class="btn btn-sm btn-outline-secondary mr-1 p-2"
              :class="{ active: selectedButton === '1month' }"
              id="one_month"
              @click="handleTimePeriodClick(1, 'month')"
            >
              1M
            </button>
            <button
              class="btn btn-sm btn-outline-secondary mr-1 p-2"
              :class="{ active: selectedButton === '6month' }"
              id="six_months"
              @click="handleTimePeriodClick(6, 'month')"
            >
              6M
            </button>
            <button
              class="btn btn-sm btn-outline-secondary mr-1 p-2"
              :class="{ active: selectedButton === '1year' }"
              id="one_year"
              @click="handleTimePeriodClick(1, 'year')"
            >
              1Y
            </button>
            <button
              class="btn btn-sm btn-outline-secondary mr-1 p-2"
              :class="{ active: selectedButton === '1ytd' }"
              id="ytd"
              @click="handleTimePeriodClick(1, 'ytd')"
            >
              YTD
            </button>
            <button
              class="btn btn-sm btn-outline-secondary p-2"
              :class="{ active: selectedButton === '1all' }"
              id="all"
              @click="handleTimePeriodClick(1, 'all')"
            >
              ALL
            </button>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-6 col-md-12">
          <div id="workspace-csat-average-rating-chart" class="header">
            <h2>{{ "Average CSAT star rating" }}</h2>
          </div>
          <div class="body">
            <CSATAverageRatingChart
              :timeRange="timeRange"
              :projectsSurveysAverageRating="projectsSurveysAverageRating"
              :projects_stats="projects_stats"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div id="workspace-average-project-health-chart" class="header">
            <h2>{{ "Average project health" }}</h2>
          </div>
          <div class="body">
            <AverageProjectHealthChart
              :timeRange="timeRange"
              :projects_stats="projects_stats"
            />
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-lg-6 col-md-12">
          <div id="workspace-average-project-production-chart" class="header">
            <h2>{{ "Active Projects" }}</h2>
          </div>
          <div class="body">
            <AverageProjectProductionChart
              :timeRange="timeRange"
              :projects_stats="projects_stats"
              :projects="allProjects"
              :archivedEvents="archivedEvents"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div id="workspace-top-client-projects-health" class="header">
            <h2>{{ "Top Clients project health" }}</h2>
          </div>
          <div class="body" style="height: 410px">
            <div
              v-for="client in topClientsHealthData"
              :key="client.clientName"
              class="form-group"
            >
              <label class="d-block"
                >{{ client.clientName }}
                <span class="float-right">{{
                  client.healthPercentage
                }}</span></label
              >
              <div class="progress progress-xxs">
                <div
                  class="progress-bar progress-bar-success"
                  role="progressbar"
                  :aria-valuenow="parseFloat(client.healthPercentage)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{ width: client.healthPercentage }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div id="workspace-top-current-stages" class="header">
              <h2>{{ currentStagesTitle }}</h2>
            </div>
            <div class="body">
              <div
                v-for="(stage, index) in top5CurrentStages"
                :key="index"
                :class="
                  index === 0 ? 'mb-4 mt-3' : index === 4 ? 'mb-0' : 'mb-4'
                "
              >
                <label class="d-block"
                  >{{ stage.name
                  }}<span class="float-right">
                    {{ stage.projects }} projects</span
                  ></label
                >
                <div class="progress progress-xxs">
                  <div
                    :class="`progress-bar ${progress_bar_colour_sequence[index]}`"
                    role="progressbar"
                    :aria-valuenow="stage.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="`width: ${stage.percent}%`"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div id="workspace-top-upcoming-stages" class="header">
              <h2>{{ upcomingStagesTitle }}</h2>
            </div>
            <div class="body">
              <div
                v-for="(stage, index) in top5UpcomingStages"
                :key="index"
                :class="
                  index === 0 ? 'mb-4 mt-3' : index === 4 ? 'mb-0' : 'mb-4'
                "
              >
                <label class="d-block"
                  >{{ stage.name
                  }}<span class="float-right">
                    {{ stage.projects }} projects</span
                  ></label
                >
                <div class="progress progress-xxs">
                  <div
                    :class="`progress-bar ${progress_bar_colour_sequence[index]}`"
                    role="progressbar"
                    :aria-valuenow="stage.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="`width: ${stage.percent}%`"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div id="workspace-average-ratings-clients" class="header">
              <h2>{{ "Clients ratings" }}</h2>
            </div>
            <ClientRatingTable
              :averageRatings="averageRatings.averageRatingsByClient"
              identifier="client-rating-js-data-table"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <div id="workspace-average-ratings-users" class="header">
              <h2>{{ "Users ratings" }}</h2>
            </div>
            <UserRatingTable
              :usersProjectSurveyAverageRating="usersProjectSurveyAverageRating"
              identifier="user-rating-js-data-table"
            />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../components/layout/WrapperPage.vue";
import {
  getProjects,
  getProjectsEvents,
  getProjectsStats,
} from "../apis/projects";
import CSATAverageRatingChart from "../components/ui/ClientCharts/CSATAverageRatingChart.vue";
import AverageProjectHealthChart from "../components/ui/ClientCharts/AverageProjectHealthChart.vue";
import AverageProjectProductionChart from "../components/ui/ClientCharts/AverageProjectProductionChart.vue";
import NumberCard from "../components/ui/NumberCard.vue";
import ClientRatingTable from "../components/ui/workspaceAnalytics/ClientRatingTable.vue";
import UserRatingTable from "../components/ui/workspaceAnalytics/UserRatingTable.vue";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";
import { getAllWorkflows } from "@/apis/workflows";
import {
  projectsSurveysAverageRating,
  usersProjectsSurveysAverageRating,
} from "@/apis/survey";
import workflowMixin from "../mixins/workflow";
import analyticsMixin from "../mixins/analytics";

export default {
  name: "WorkspaceAnalytics",
  mixins: [workflowMixin, analyticsMixin],
  components: {
    WrapperPage,
    NumberCard,
    ClientRatingTable,
    UserRatingTable,
    CSATAverageRatingChart,
    AverageProjectHealthChart,
    AverageProjectProductionChart,
  },
  data() {
    return {
      projects_stats: [],
      openProjects: [],
      archivedProjects: [],
      timeRange: [],
      selectedButton: "all",
      projectsSurveysAverageRating: [],
      usersProjectSurveyAverageRating: [],
      archivedEvents: [],
      progress_bar_colour_sequence: [
        "progress-bar-success",
        "progress-bar-warning",
        "progress-bar-info",
        "progress-bar-danger",
        "bg-indigo",
      ],
    };
  },
  computed: {
    isWorkspaceAnalyticsAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    allProjects() {
      return [...this.openProjects, ...this.archivedProjects].sort((a, b) => {
        return new Date(a.created_on) - new Date(b.created_on);
      });
    },
    projectStats() {
      let projectsInProduction = 0;
      let projectsAwaitingFeedback = 0;
      let projectsActive = 0;
      let projectsCompleted = 0;
      let projectsCancelled = 0;

      this.projects_stats.forEach((ps) => {
        if (!ps.archived && !ps.cancelled) {
          if (ps.status === "in_progress") {
            projectsInProduction++;
          }
          if (ps.status === "awaiting_feedback") {
            projectsAwaitingFeedback++;
          }
          if (ps.status !== "completed") {
            projectsActive++;
          }
        }
        if (ps.status === "completed") {
          projectsCompleted++;
        }
        if (ps.cancelled) {
          projectsCancelled++;
        }
      });

      return {
        projectsInProduction,
        projectsAwaitingFeedback,
        projectsActive,
        projectsCompleted,
        projectsCancelled,
      };
    },
    topClientsHealthData() {
      if (this.projects_stats.length) {
        return this.getTopClientsWithAverageHealth(this.projects_stats);
      } else {
        return {};
      }
    },
    averageRatings() {
      return this.calculateAverageRatings(this.projectsSurveysAverageRating);
    },
    totalUniqueClientsInProgress() {
      const inProgressProjects = this.projects_stats.filter(
        (project) => project.status === "in_progress"
      );
      const uniqueClients = [
        ...new Set(inProgressProjects.map((project) => project.client_id)),
      ];
      const totalUniqueClients = uniqueClients.length;

      return totalUniqueClients;
    },
    top5CurrentStages() {
      let sortedGroups = this.currentStagesSortedByFrequency(
        this.projects_stats
      ).slice(0, 5); // Take top 5 only

      let maxProjectsPerStage = 1;
      for (let i = 0; i < sortedGroups.length; i++) {
        maxProjectsPerStage = Math.max(
          maxProjectsPerStage,
          sortedGroups[i].count
        );
      }

      // Progress bar shows Number of projects over Max projects for Top 5 stages
      const percentMultiplier = 100 / maxProjectsPerStage;

      return sortedGroups.map((sg) => ({
        name: sg.name,
        projects: sg.count,
        percent: sg.count * percentMultiplier,
      }));
    },
    top5UpcomingStages() {
      let sortedGroups = this.upcomingStagesSortedByFrequency(
        this.projects_stats
      ).slice(0, 5); // Take top 5 only

      let maxProjectsPerStage = 1;
      for (let i = 0; i < sortedGroups.length; i++) {
        maxProjectsPerStage = Math.max(
          maxProjectsPerStage,
          sortedGroups[i].count
        );
      }

      // Progress bar shows Number of projects over Max projects for Top 5 stages
      const percentMultiplier = 100 / maxProjectsPerStage;

      return sortedGroups.map((sg) => ({
        name: sg.name,
        projects: sg.count,
        percent: sg.count * percentMultiplier,
      }));
    },
    currentStagesTitle() {
      if (this.currentStagesSortedByFrequency(this.projects_stats).length > 5)
        return "Top 5 current stages";
      return "Current stages";
    },
    upcomingStagesTitle() {
      if (this.upcomingStagesSortedByFrequency(this.projects_stats).length > 5)
        return "Top 5 upcoming stages";
      return "Upcoming stages";
    },
    clientCompletedProjects() {
      return this.completedProjectgroupByClient(this.projects_stats);
    },
  },
  methods: {
    calculateAverageRatings(averageRatingsData) {
      const clientAverageRatings = {};

      averageRatingsData.forEach((item) => {
        if (!clientAverageRatings[item.client_id]) {
          clientAverageRatings[item.client_id] = [];
        }
        clientAverageRatings[item.client_id].push(item.rating);
      });

      const averageRatingsByClient = [];
      let totalAverageRating = 0;
      let clientCount = 0;

      for (const clientId in clientAverageRatings) {
        const ratings = clientAverageRatings[clientId];
        const clientName = averageRatingsData.find(
          (clientRatingObj) => clientRatingObj.client_id === parseInt(clientId)
        ).client_name;
        const averageRating =
          ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length;
        averageRatingsByClient.push({
          clientId,
          clientName,
          averageRating: parseFloat(averageRating.toFixed(1)),
          projectCount: ratings.length,
          completedProjects: this.clientCompletedProjects[clientId],
        });
        totalAverageRating += averageRating;
        clientCount++;
      }

      const averageRatingOverall =
        clientCount > 0 ? (totalAverageRating / clientCount).toFixed(1) : 0;

      return {
        averageRatingsByClient,
        averageRatingOverall,
      };
    },
    getTopClientsWithAverageHealth(projectStatusList) {
      const clientHealthMap = {};
      projectStatusList.forEach((project) => {
        const { client_id, health } = project;
        if (!clientHealthMap[client_id]) {
          clientHealthMap[client_id] = { totalHealth: 0, projectCount: 0 };
        }
        clientHealthMap[client_id].totalHealth += health;
        clientHealthMap[client_id].projectCount++;
      });

      const clientAverages = Object.entries(clientHealthMap).map(
        ([clientId, { totalHealth, projectCount }]) => ({
          clientName: projectStatusList.find(
            (project) => project.client_id === parseInt(clientId)
          ).client_name,
          averageHealth: totalHealth / projectCount,
        })
      );

      const topClients = clientAverages
        .sort((a, b) => b.averageHealth - a.averageHealth)
        .slice(0, 5);

      return topClients.map((client) => ({
        clientName: client.clientName,
        healthPercentage: `${Math.round(client.averageHealth * 100)}%`,
      }));
    },
    handleTimePeriodClick: function (time, type) {
      this.selectedButton = time + type;
      if (!time || type === "all") {
        this.timeRange = [];
        return;
      }

      const today = new Date();
      today.setHours(23, 59, 59, 999);

      const startDate = new Date();

      if (type === "day") {
        startDate.setDate(today.getDate() - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "month") {
        const currentMonth = startDate.getMonth();
        startDate.setMonth(currentMonth - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "year") {
        const currentYear = startDate.getFullYear();
        startDate.setFullYear(currentYear - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "ytd") {
        startDate.setMonth(0);
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);
      }
      this.timeRange = [startDate, today];
    },
    getAllProjects: async function () {
      try {
        this.isLoadingStats = true;
        let openProjects = await getProjects(0);
        let archivedProjects = await getProjects(1);
        const resetWorkflow = (projects) => {
          projects.forEach((p) => {
            p.workflow = null;
          });
        };

        resetWorkflow(openProjects);
        resetWorkflow(archivedProjects);

        await Promise.all([
          this.loadWorkflowsInProjects(openProjects),
          this.loadWorkflowsInProjects(archivedProjects),
        ]);
        this.openProjects = openProjects;
        this.archivedProjects = archivedProjects;
      } catch (err) {
        this.notifyError(err);
      }
      this.isLoadingStats = false;
    },
    getAllProjectsArchivedEvent: async function () {
      const events = await getProjectsEvents();
      this.archivedEvents = events;
    },
    getAllStats: async function () {
      try {
        this.isLoadingStats = true;
        const allProjects = await getProjectsStats();
        if (allProjects) {
          // Filter projects based on client_id and sort by start_date before assigning
          this.projects_stats = allProjects.sort(
            (a, b) => new Date(a.start_date) - new Date(b.start_date)
          );
        }
      } catch (err) {
        this.notifyError(err);
      }
      this.isLoadingStats = false;
    },
    getProjectSurveyAverageRatings: async function () {
      const [allProjectsSurveysAverageRating, usersProjectSurveyAverageRating] =
        await Promise.all([
          projectsSurveysAverageRating(),
          usersProjectsSurveysAverageRating(),
        ]);
      if (allProjectsSurveysAverageRating) {
        this.projectsSurveysAverageRating = allProjectsSurveysAverageRating;
      }
      if (usersProjectSurveyAverageRating) {
        this.usersProjectSurveyAverageRating = usersProjectSurveyAverageRating;
      }
    },
    async loadWorkflowsInProjects(projects) {
      this.isLoadingWorkflows = true;
      try {
        // Fetch all workflows for the loaded projects
        const wfIds = projects.reduce(function (accumulator, curProject) {
          if (
            curProject.workflow_id &&
            accumulator.indexOf(curProject.workflow_id) == -1
          ) {
            accumulator.push(curProject.workflow_id);
          }
          return accumulator;
        }, []);
        if (wfIds.length > 0) {
          let workflows = await getAllWorkflows("id in (" + wfIds.join() + ")");
          projects.forEach((project) => {
            const workflow = workflows.find(
              (wf) => wf.id === project.workflow_id
            );
            project.workflow = workflow;
            project.status =
              this.getProjectCompletedStatusWithApprovalDate(workflow);
          });
        }
      } finally {
        this.isLoadingWorkflows = false;
      }
    },
  },
  async mounted() {
    if (!this.isWorkspaceAnalyticsAvailable)
      eventBus.$emit(channels.upgradeSubscription, { allowDismissal: false });

    await Promise.all([
      this.getAllStats(),
      this.getAllProjects(),
      this.getProjectSurveyAverageRatings(),
      this.getAllProjectsArchivedEvent(),
    ]);
  },
};
</script>

<style></style>
