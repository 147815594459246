<template>
  <Modal
    title="Subscription Inactive"
    :show-close-button="false"
    :show-cancel-button="false"
    :show-submit-button="$auth.user.owner || $auth.user.admin"
    @submit="goToStripe"
    submit-text="Upgrade"
    :close-on-outside-click="false"
  >
    <div class="container">
      <p>{{ modalText }}</p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import config from "@/apis/config.json";

export default {
  name: "SubscriptionInactiveModal",
  components: { Modal },
  methods: {
    goToStripe() {
      window.location.assign(this.stripeCustomerPortalLink);
    },
  },
  computed: {
    modalText() {
      if (this.$auth.user.isAgent)
        return "Your team's access has expired. Please ask the workspace owner or administrator to reactivate the subscription to continue. Workspace members and clients will not have access until the subscription is reactivated.";

      return "The workspace you are trying to access does not have an active subscription. Please contact your workspace representative for more information.";
    },
    stripeCustomerPortalLink() {
      return config.stripe.customerPortalLink;
    },
  },
};
</script>
