const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

const templatesUrl = config.apis.base.url + "email-templates/";

exports.getEmailTemplates = async () => {
  return await get(templatesUrl);
};

exports.getEmailTemplateById = async (id) => {
  return await get(`${templatesUrl}${id}`);
};

exports.addEmailTemplate = async (emailTemplate) => {
  return await create(templatesUrl, emailTemplate);
};

/**
 * Update the given emailTemplate object.
 * An id must be provided and @param emailTemplate.id takes precedence over @param emailTemplateId
 * @param {object} emailTemplate The object to update. The id can be omitted if specified in @param emailTemplateId, but is required otherwise
 * @param {number} emailTemplateId Optional. Needed only if @param emailTemplate does not have an id property
 * @returns
 */
exports.updateEmailTemplate = async (emailTemplate, emailTemplateId) => {
  if (!emailTemplate.id || emailTemplate.id < 1) {
    if (!emailTemplateId || emailTemplateId < 1) {
      throw new Error("No valid Email Template ID provided");
    }
    emailTemplate.id = emailTemplateId;
  }
  return await update(templatesUrl + emailTemplate.id, emailTemplate);
};

exports.deleteEmailTemplate = async (emailTemplate) => {
  return this.deleteEmailTemplateById(emailTemplate.id);
};

exports.deleteEmailTemplateById = async (id) => {
  return await remove(templatesUrl + id);
};
