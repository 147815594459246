<template>
  <div class="d-flex flex-column" style="padding: 10px; max-height: 90vh">
    <email-template-preview
      :value="computedTemplate"
      :project="project"
      :language="language"
    />

    <div class="col-12 mt-4 d-flex justify-content-end">
      <button type="button" class="btn btn-danger" @click="close">
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary ml-3"
        @click="sendDeliverable"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import EmailTemplatePreview from "../ui/EmailTemplates/EmailTemplatePreview.vue";

export default {
  name: "ProjectMultiUpdatePreviewModal",
  components: { EmailTemplatePreview },
  props: {
    multiDeliverables: {
      type: Array,
    },
    template: {
      type: String,
    },
    project: {
      type: Object,
    },
    language: {
      type: String,
      default: "en",
    },
  },
  computed: {
    computedTemplate() {
      return this.template.replace(
        /\[multi deliverables preview\]/g,
        this.deliverableLines
      );
    },
    deliverableLines() {
      return this.multiDeliverables
        .map((deliverable) => {
          return `<a href="#">${deliverable.workflowTitle} - ${deliverable.stageTitle}</a>`;
        })
        .join("\n");
    },
  },
  methods: {
    close(state = null) {
      this.$modal.hide("project-multi-update-preview-modal", state);
    },
    async sendDeliverable() {
      this.close({
        value: {
          action: "send",
        },
      });
    },
  },
};
</script>
