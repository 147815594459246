<template>
  <vue-apex-charts
    :type="chartType"
    ref="projectHealthChart"
    :height="chartHeight"
    :options="chartData.chartOptions"
    :series="chartData.series"
    class="custom-apex-chart"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "AverageProjectHealthChart",
  components: {
    VueApexCharts,
  },
  props: {
    timeRange: Array,
    projects_stats: Array,
  },
  data() {
    return {
      chartHeight: 350,
      chartType: "area",
      series: [
        {
          name: "Project health (%)",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100,
        },
        annotations: {
          yaxis: [
            {
              borderColor: "#999",
              forceNiceScale: true,
              decimalsInFloat: 0,
              label: {
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
          ],
          xaxis: [
            {
              borderColor: "#999",
              yAxisIndex: 0,
              tickAmount: 4,
              label: {
                show: true,
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },
      selection: "one_year",
    };
  },
  computed: {
    chartData() {
      return {
        series: this.series,
        chartOptions: this.chartOptions,
      };
    },
  },
  methods: {
    zoomChartXAxis(timeRange) {
      if (timeRange.length === 2) {
        this.$refs.projectHealthChart.chart.zoomX(
          timeRange[0].getTime(),
          timeRange[1].getTime()
        );
      } else if (timeRange.length === 0 && this.projects_stats.length) {
        this.$refs.projectHealthChart.chart.zoomX(
          new Date(this.projects_stats[0].start_date).getTime(),
          new Date(
            this.projects_stats[this.projects_stats.length - 1].start_date
          ).getTime()
        );
      }
    },
    generateSeriesDataForAverageProjectHealth(projects) {
      if (!projects.length) {
        this.series = [
          {
            name: "Project health (%)",
            data: [],
          },
        ];
        return;
      }
      const healthPerDate = {};

      projects.forEach((project) => {
        const startDate = new Date(project.start_date);
        if (!project.health) return;
        const healthValue = project.health;
        const dateString = startDate.toISOString().split("T")[0];

        if (healthPerDate[dateString]) {
          healthPerDate[dateString].push(healthValue);
        } else {
          healthPerDate[dateString] = [healthValue];
        }
      });

      const averageHealthPerDate = Object.keys(healthPerDate).map(
        (dateString) => {
          const healthValues = healthPerDate[dateString];
          const averageHealth = Math.round(
            (healthValues.reduce((sum, value) => sum + value, 0) /
              healthValues.length) *
              100
          );

          return [new Date(dateString).getTime(), averageHealth];
        }
      );

      this.series = [
        {
          name: "Project health (%)",
          data: averageHealthPerDate || [],
        },
      ];
    },
  },
  watch: {
    timeRange(newTimeRange) {
      this.zoomChartXAxis(newTimeRange);
    },
    projects_stats(newProjectStates) {
      if (newProjectStates.length) {
        this.generateSeriesDataForAverageProjectHealth(newProjectStates);
      }
    },
  },
};
</script>
<style></style>
