<template>
  <!-- Main/Left Collapsible Menu -->
  <div id="left-sidebar" class="sidebar">
    <div class="navbar-brand">
      <router-link to="/">
        <img
          v-if="$store.getters.logo"
          :src="$store.getters.logo"
          alt="Logo"
          class="img-fluid logo"
        />
        <span v-if="$store.state.tenantProfile.name">
          {{ $store.state.tenantProfile.name }}
        </span>
      </router-link>
      <!-- button visible only on narrow screens -->
      <button type="button" class="btn-toggle-offcanvas btn btn-sm float-right">
        <i class="lnr lnr-menu icon-close"></i>
      </button>
    </div>
    <div class="sidebar-scroll">
      <div class="user-account">
        <div class="user_div">
          <img
            :src="this.$auth.user.picture"
            class="user-photo"
            alt="User Profile Picture"
          />
        </div>
        <div class="dropdown">
          <span>Welcome,</span>
          <a
            href="javascript:void(0);"
            class="dropdown-toggle user-name"
            data-toggle="dropdown"
            ><strong>{{ $auth.user.name }} {{ $auth.user.surname }}</strong></a
          >
          <ul
            class="dropdown-menu dropdown-menu-right account vivify flipInY p-0"
          >
            <li v-if="$auth.user.isAgent">
              <router-link :to="{ name: 'user-profile' }" class="pb-0"
                ><span><i class="icon-user"></i>My Profile</span></router-link
              >
            </li>
            <li>
              <a href="#" @click="logout"><i class="icon-power"></i>Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <nav id="left-sidebar-nav" class="sidebar-nav">
        <ul id="main-menu" class="metismenu">
          <li class="header">Main</li>

          <li v-bind="menuItemClass('/')" v-if="$auth.user.isAgent">
            <router-link :to="{ name: 'dashboard' }"
              ><i class="icon-home"></i><span>Dashboard</span></router-link
            >
          </li>

          <li
            v-if="$auth.user.isAgent"
            v-bind="
              this.$route.path.endsWith('create')
                ? { class: 'active open' }
                : {}
            "
          >
            <a href="#" class="has-arrow"
              ><i class="icon-plus"></i><span>Create</span></a
            >
            <ul>
              <li
                v-if="$auth.user.create_projects"
                v-bind="menuItemClass('/projects/create')"
              >
                <router-link
                  :to="{
                    name: 'project-details',
                    params: { projectId: 'create' },
                  }"
                  ><span>Project</span></router-link
                >
              </li>
              <li
                v-if="$auth.user.isAgent"
                v-bind="menuItemClass('/clients/create')"
              >
                <router-link
                  :to="{
                    name: 'client-details',
                    params: { clientId: 'create' },
                  }"
                  ><span>Client</span></router-link
                >
              </li>
            </ul>
          </li>

          <li v-bind="menuItemClass('/projects', false)">
            <router-link :to="{ name: 'projects-index' }" class="has-arrow"
              ><i class="icon-rocket"></i><span>Projects</span></router-link
            >
            <ul>
              <li v-bind="menuItemClass('/projects')">
                <router-link :to="{ name: 'projects-index' }"
                  ><span>List Active</span></router-link
                >
              </li>
              <li v-bind="menuItemClass('/projects/archived')">
                <router-link :to="{ name: 'projects-archived-index' }"
                  ><span>List Archived</span></router-link
                >
              </li>
            </ul>
          </li>

          <li v-if="$auth.user.isAgent" v-bind="menuItemClass('/clients')">
            <router-link :to="{ name: 'clients-index' }"
              ><i class="icon-grid"></i><span>Clients</span></router-link
            >
          </li>
          <li
            v-bind="menuItemClass('/workspace-analytics')"
            v-if="$auth.user.isAgent"
          >
            <router-link :to="{ name: 'workspace-analytics' }"
              ><i class="icon-graph"></i
              ><span>Workspace Analytics</span></router-link
            >
          </li>

          <li
            v-if="
              $auth.user.isAgent &&
              ($auth.user.owner ||
                $auth.user.admin ||
                $auth.user.add_users ||
                $auth.user.email_templates ||
                $auth.user.workflow_templates)
            "
            class="header"
          >
            Administration
          </li>
          <li
            v-if="
              $auth.user.isAgent &&
              ($auth.user.owner || $auth.user.admin || $auth.user.add_users)
            "
            v-bind="menuItemClass('/settings', false)"
          >
            <a href="#" class="has-arrow"
              ><i class="icon-settings"></i><span>Settings</span></a
            >
            <ul>
              <li
                v-if="
                  $auth.user.owner || $auth.user.admin || $auth.user.add_users
                "
                v-bind="menuItemClass('/settings/team')"
              >
                <router-link :to="{ name: 'settings-team' }">Team</router-link>
              </li>
              <li
                v-if="
                  $auth.user.owner ||
                  ($auth.user.admin &&
                    $store.state.tenantProfile
                      .admins_can_edit_workspace_settings)
                "
                v-bind="menuItemClass('/settings/workspace')"
              >
                <router-link :to="{ name: 'settings-workspace' }">
                  Workspace
                </router-link>
              </li>
              <li
                v-if="$auth.user.owner || $auth.user.admin"
                v-bind="menuItemClass('/settings/email-settings')"
              >
                <router-link :to="{ name: 'settings-email-settings' }"
                  >Email Sender Authentication</router-link
                >
              </li>
              <li
                v-if="$auth.user.owner || $auth.user.admin"
                v-bind="menuItemClass('/settings/custom-domain')"
              >
                <router-link :to="{ name: 'settings-custom-domain' }"
                  >Custom Domain</router-link
                >
              </li>
            </ul>
          </li>
          <li
            v-if="
              $auth.user.isAgent &&
              ($auth.user.owner ||
                $auth.user.admin ||
                $auth.user.email_templates ||
                $auth.user.workflow_templates)
            "
            v-bind="menuItemClass('/templates', false)"
          >
            <a href="#" class="has-arrow"
              ><i class="icon-list"></i><span>Templates</span></a
            >
            <ul>
              <li
                v-if="
                  $auth.user.owner ||
                  $auth.user.admin ||
                  $auth.user.email_templates
                "
                v-bind="menuItemClass('/templates/email-templates')"
              >
                <router-link :to="{ name: 'templates-email-templates' }"
                  >Email Templates</router-link
                >
              </li>
              <li
                v-if="
                  $auth.user.owner ||
                  $auth.user.admin ||
                  $auth.user.workflow_templates
                "
                v-bind="menuItemClass('/templates/workflow-templates')"
              >
                <router-link :to="{ name: 'templates-workflow-templates' }"
                  >Workflow Templates</router-link
                >
              </li>
              <li
                v-if="
                  $auth.user.owner ||
                  $auth.user.admin ||
                  $auth.user.email_templates
                "
                v-bind="menuItemClass('/templates/survey-templates')"
              >
                <router-link :to="{ name: 'templates-survey-templates' }"
                  >CSAT survey templates</router-link
                >
              </li>
            </ul>
          </li>
          <li v-if="$auth.user.owner">
            <a :href="stripeCustomerPortalLink" target="_blank"
              ><i class="icon-credit-card"></i><span>Billing</span></a
            >
          </li>
          <li
            v-if="$auth.user.owner && showAppSumoMenu"
            v-bind="menuItemClass('/billing/appsumo')"
          >
            <router-link :to="{ name: 'billing-appsumo' }"
              ><i class="icon-credit-card"></i
              ><span>AppSumo Codes</span></router-link
            >
          </li>

          <li class="header">Help &amp; Support</li>
          <li v-if="$auth.user.isAgent">
            <a href="https://help.planeolo.com/" target="_blank"
              ><i class="fa fa-book"></i><span>Knowledge base</span></a
            >
          </li>
          <li v-else>
            <a
              href="https://help.planeolo.com/customer-portal/category/211"
              target="_blank"
              ><i class="fa fa-book"></i><span>Knowledge base</span></a
            >
          </li>
          <li v-if="$auth.user.isAgent">
            <a href="javascript:void(0);" class="ustfl-start"
              ><i class="fa fa-toggle-right"></i><span>Guided Tour</span></a
            >
          </li>
          <li>
            <a href="https://www.planeolo.com/roadmap" target="_blank"
              ><i class="fa fa-bug"></i><span>Report a Bug</span></a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import config from "@/apis/config.json";

export default {
  name: "LeftSideBarNavigation",
  data() {
    return {
      showAppSumoMenu: this.$auth.tenant?.subscription?.isAppSumo === 1,
    };
  },
  methods: {
    /**
     * Apply style to menu item in navigation based on current route
     * @param {string} path The path for this menu item
     * @param {boolean} isChildItem Is this a parent/group menu item or an actual link to a page?
     */
    menuItemClass(path, isChildItem = true) {
      if (isChildItem === true && this.$route.path === path) {
        return { class: "active" };
      } else if (
        isChildItem === false &&
        this.$route.path.startsWith(path) &&
        !this.$route.path.endsWith("create")
      ) {
        return { class: "active open" };
      }
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    openUserProfile() {
      this.$router.push({
        path: "/profile",
      });
    },
  },
  computed: {
    stripeCustomerPortalLink() {
      return config.stripe.customerPortalLink;
    },
  },
};
</script>
