<template>
  <Modal
    :show-close-button="true"
    @submit="onSubmitClick"
    @close="onCloseClick"
    cancel-text="Cancel"
    :submit-text="selectedDeliverable ? 'Save' : 'Next step'"
    :close-on-outside-click="false"
    :disable-submit-button="!flow || !workflow_title"
    submit-class="btn-primary"
    :maxWidth="800"
  >
    <div class="container" style="text-align: center">
      <h3 class="modal-title">
        {{ selectedDeliverable ? "Edit Deliverable" : "Add Deliverable" }}
      </h3>
      <div class="form-container mt-4">
        <form-input-group
          v-model="workflow_title"
          :field="{
            name: 'workflow_title',
            label: 'Deliverable name',
            type: 'text',
          }"
          style="text-align: left"
        />
        <form-input-group
          v-model="template_id"
          :field="{
            name: 'deliverable_workflow',
            label: 'Workflow template',
            type: 'select',
            options: templateOptions,
          }"
          style="text-align: left"
        />
        <template v-if="canSwitchToLinear">
          <div
            class="card"
            :class="{ selected: flow === 'linear' }"
            @click="toggleSelection('linear')"
          >
            <div class="body" style="height: 100%">
              <div class="d-flex">
                <div
                  class="d-flex flex-column mr-4"
                  style="align-items: center; width: 15px"
                >
                  <div class="dot small-dot">
                    <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                  </div>
                  <div class="vertical-line"></div>
                  <div class="dot small-dot">
                    <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                  </div>
                  <div class="vertical-line"></div>
                  <div class="dot small-dot">
                    <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                  </div>
                </div>
                <div class="align-left">
                  <p><strong>Linear workflow</strong></p>
                  <p class="description">
                    Displays stages chronologically, allowing subsequent stages
                    to commence only after the previous ones have been
                    completed, which is ideal for projects with a sequential
                    workflow.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card"
            :class="{ selected: flow === 'non-linear' }"
            @click="toggleSelection('non-linear')"
          >
            <div class="body" style="height: 100%">
              <div class="d-flex">
                <div
                  class="d-flex flex-column mr-4"
                  style="align-items: center; width: 15px"
                >
                  <div class="dot small-dot">
                    <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                  </div>
                  <div class="vertical-line" style="width: 0"></div>
                  <div class="dot small-dot">
                    <i class="fa fa-circle-o" style="color: #17c2d7"></i>
                  </div>
                  <div class="vertical-line" style="width: 0"></div>
                  <div class="dot small-dot">
                    <i class="fa fa-check-circle" style="color: #17c2d7"></i>
                  </div>
                </div>
                <div class="align-left">
                  <p><strong>Non-linear workflow</strong></p>
                  <p class="description">
                    Enables initiating and completing project stages in any
                    order, providing flexibility in project management and
                    execution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-if="selectedDeliverable" v-slot:corner-action>
      <a
        href="javascript:void(0);"
        @click="$emit('delete', selectedDeliverable.workflow_id)"
        class="text-danger font-weight-medium"
      >
        Delete
      </a>
    </template>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";

export default {
  name: "ChooseTimelineStructureModal",
  components: { FormInputGroup, Modal },
  props: {
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    selectedDeliverable: {
      type: Object,
      default: undefined,
    },
    templates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    let flow = "linear";

    if (this.selectedDeliverable) {
      flow = this.selectedDeliverable.isLinear ? "linear" : "non-linear";
    }

    return {
      workflow_title: this.selectedDeliverable?.workflow_title || "",
      template_id: this.selectedDeliverable ? 0 : this.templates?.[0]?.id,
      flow,
    };
  },
  methods: {
    toggleSelection(option) {
      this.flow = option;
    },
    onSubmitClick() {
      this.$emit("submit", {
        selected: this.flow,
        workflow_title: this.workflow_title,
        template_id: this.template_id,
        mode: this.selectedDeliverable ? "edit" : "add",
      });
    },
    onCloseClick() {
      this.$emit("close");
    },
    getCurrentStage() {
      if (
        !this.selectedDeliverable.workflow?.tasks ||
        this.selectedDeliverable.workflow?.tasks.length === 0
      ) {
        return null;
      }
      let stage = this.selectedDeliverable.workflow?.tasks.find(
        (task) => task.status == "in_progress"
      );
      if (!stage) {
        stage = this.selectedDeliverable.workflow?.tasks.find(
          (stage) => !stage.status || stage.status == "pending"
        );
      }
      return stage;
    },
  },
  computed: {
    canSwitchToLinear() {
      if (!this.selectedDeliverable || this.selectedDeliverable.isLinear)
        return true;

      let activeStages = [];

      if (this.selectedDeliverable.isLinear) {
        activeStages = [this.getCurrentStage()];
      } else {
        activeStages = this.selectedDeliverable?.workflow?.tasks.filter(
          (task) => task.status == "in_progress"
        );
      }

      let activeStage;
      // If there is just 1 active stage (or none), check that all previous stages are closed and all future stages are pending/unactioned
      if (activeStages.length === 0) {
        // In this case, let's fetch the first pending stage and assume that is the active one
        activeStage = this.getCurrentStage();
      } else {
        activeStage = activeStages[0];
      }

      if (!activeStage) return false;

      const currentIndex = this.selectedDeliverable.workflow?.tasks.findIndex(
        (task) => task.id === activeStage.id
      );
      // Previous ones must be completed
      for (let i = 0; i < currentIndex; i++) {
        const stage = this.selectedDeliverable?.workflow?.tasks[i];
        if (!stage.status || stage.status !== "completed") return false;
      }
      // Next ones must be pending
      for (
        let i = currentIndex + 1;
        i < this.selectedDeliverable?.workflow?.tasks.length;
        i++
      ) {
        const stage = this.selectedDeliverable?.workflow?.tasks[i];
        if (stage.status && stage.status !== "pending") return false;
      }

      return true;
    },
    templateOptions() {
      return [
        { text: "Select a template", value: 0 },
        ...this.templates.map((t) => ({ text: t.name, value: t.id })),
      ];
    },
  },
};
</script>
<style scoped>
.modal-title {
  margin-bottom: 20px;
  color: #17c2d7;
  font-size: 23px;
}

.description {
  color: #b1b1b1;
  font-size: 12px;
}

.dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.small-dot {
  width: 15px;
  height: 15px;
  background-color: white;
}

.vertical-line {
  width: 1px;
  height: 50px; /* Adjust height to your preference */
  background-color: #f5f5f5;
}

.card {
  border: 1px solid #dde4ea;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.selected {
  border-color: #17c2d7;
  box-shadow: 0 0 5px rgba(23, 194, 215, 0.4);
}

.card {
  margin: 0;
}

.form-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2rem;
  row-gap: 1rem;
}
</style>
