const config = require("./config.json");
const { get, create, update, remove, fileUpload } = require("./common").default;

const tenantsUrl = config.apis.base.url + "tenants/";
const tenantProfilesUrl = config.apis.base.url + "tenant-profiles/";

exports.getTenantProfile = async (id) => {
  return await get(tenantProfilesUrl + id);
};

exports.updateTenantProfile = async (id, tenantProfile) => {
  return await update(tenantProfilesUrl + id, tenantProfile);
};

exports.deleteTenant = async (id) => {
  return await remove(tenantsUrl + id);
};

exports.addTenantFile = async (tenantId, type, file, filename) => {
  return await fileUpload(
    `${tenantProfilesUrl}${tenantId}/files/${type}?filename=${
      filename ?? file.name
    }`,
    file
  );
};

exports.getDomains = async (email) => {
  return await create(`${tenantsUrl}email`, { email });
};
