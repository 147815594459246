<template>
  <wrapper-page>
    <div class="card">
      <div class="header">
        <h6>Update your information below</h6>
      </div>
      <div class="body">
        <div class="row clearfix">
          <div
            v-for="model in mainDetails"
            :key="model.name"
            class="col-lg-6 col-md-12"
          >
            <form-input-group :field="model" v-model="details[model.name]" />
          </div>
          <div
            v-for="model in dateTimeSettings"
            :key="model.name"
            class="col-lg-6 col-md-12"
          >
            <form-input-group :field="model" v-model="details[model.name]" />
          </div>
        </div>
      </div>
    </div>
    <div class="card" :class="{ disabledComponent: !isCsatFeedbackAvailable }">
      <div class="body">
        <div class="header">
          <h6>Overall client satisfaction rating</h6>
        </div>
        <div class="row clearfix">
          <div class="col-12 mt-3">
            <template v-if="isCsatFeedbackAvailable">
              <star-rating
                v-if="averageRatingPercent >= 0 && averageRatingPercent <= 1"
                :value="averageRatingPercent"
              />
              <p v-if="averageRatings" class="mt-1 ml-2">
                <small>
                  {{ averageRatingStars }} / 5 stars based on
                  {{ surveyStatusReport.projects }}
                  {{
                    surveyStatusReport.projects === 1 ? "project" : "projects"
                  }}
                  delivered to {{ surveyStatusReport.clients }}
                  {{ surveyStatusReport.clients === 1 ? "client" : "clients" }}
                  since {{ surveyStatusReport.since }}.
                </small>
              </p>
            </template>
            <p v-else>data not available for this version</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="body">
        <div class="header">
          <h6>Change Password</h6>
          <!-- <p>Press button and check your email to reset your password</p> -->
        </div>
        <div class="row clearfix">
          <div class="col-md-12 col-sm-12 text-left">
            <button
              class="btn btn-primary"
              @click="showChangePasswordModal = true"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="card">
      <div class="body">
        <div class="header">
          <h6>Notifications</h6>
        </div>
        <div class="row clearfix">
          <div class="col-12 col-sm-6">
            <div
              class="d-flex justify-content-between list-group-item px-0 py-1"
              style="max-width: 520px"
            >
              <span>Recieve overdue feedback notifications</span>
              <div class="toggle-button">
                <label class="switch">
                  <input
                    type="checkbox"
                    class="rtl-btn"
                    v-model="details.receive_overdue_notifications"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="body">
        <div class="header d-flex gap-x-2" @click="emailSignatureClicked">
          <h6>Email Signature</h6>
          <i
            v-if="!isCustomEmailSignatureAvailable"
            class="fa fa-lock"
            style="margin-top: 3px"
          ></i>
        </div>
        <div class="row clearfix" @click="emailSignatureClicked">
          <div class="col-lg-9 col-xl-6 col-sm-12 text-left">
            <EmailTemplateEditor
              v-model="emailSignature"
              :show-merge-tags-dropdown="false"
              :custom-toolbar="[
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['misc', ['codeview']],
              ]"
            />
            <button
              v-if="isCustomEmailSignatureAvailable"
              class="btn btn-primary text-right mt-2"
              @click="saveEmailSignature"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card"></div>
    <ChangePasswordModal
      v-if="showChangePasswordModal"
      @close="showChangePasswordModal = false"
    />
  </wrapper-page>
</template>

<script>
import { TIME_FORMAT } from "@/utils/app.constant";
import WrapperPage from "../components/layout/WrapperPage.vue";
import FormInputGroup from "../components/ui/FormInputGroup.vue";
import { timeZonesNames } from "@vvo/tzdb";
import _ from "lodash";
import { getUserProfile, updateUserProfile } from "@/apis/users";
import StarRating from "@/components/ui/Dashboard/StarRating.vue";
import { DateTime } from "luxon";
import timeMixin from "@/mixins/time";
import ChangePasswordModal from "@/components/ui/Modals/ChangePasswordModal.vue";
import EmailTemplateEditor from "@/components/ui/EmailTemplates/EmailTemplateEditor.vue";
import { usersProjectsSurveysAverageRating } from "@/apis/survey";
import {
  isWorkspaceProfessionalLevel,
  isWorkspaceStarterLevel,
} from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "MyProfile",
  mixins: [timeMixin],
  components: {
    EmailTemplateEditor,
    StarRating,
    WrapperPage,
    FormInputGroup,
    ChangePasswordModal,
  },
  props: {},
  data() {
    return {
      details: {
        name: "",
        surname: "",
        timezone: this.$store.getters.timezone,
        email: "",
        dt_format: this.$store.getters.dateFormat,
        receive_overdue_notifications: false,
      },
      mainDetails: [
        { label: "First name", name: "name" },
        { label: "Last name", name: "surname" },
        { label: "Email address", name: "email", readonly: true },
      ],
      dateTimeSettings: [
        {
          label: "My timezone",
          name: "timezone",
          type: "select",
          options: timeZonesNames.map((tz) => ({
            value: tz,
            text: tz,
          })),
        },
        {
          label: "Date format",
          name: "dt_format",
          type: "select",
          options: TIME_FORMAT.map((tf) => ({ value: tf, text: tf })),
        },
      ],
      emailSignature: null,
      averageRatings: [],
      timeZonesNames,
      showChangePasswordModal: false,
      isSavingDetails: false,
      isLoadingDetails: false,
    };
  },
  computed: {
    isCsatFeedbackAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    isCustomEmailSignatureAvailable() {
      return isWorkspaceStarterLevel(this.$auth.tenant.subscription);
    },
    timeZoneOptions() {
      return this.timeZonesNames.map((tz) => ({
        value: tz,
        text: tz,
      }));
    },
    averageRatingPercent() {
      if (!this.averageRatings?.length) return 0;
      const userAverageRating = this.averageRatings.find(
        (obj) => obj.user_email === this.$auth.user.email
      );
      if (userAverageRating) {
        return userAverageRating.rating / 5; // Assuming ratings are on a scale of 0 to 5
      }
      return 0;
    },
    userAverageRatingData() {
      return this.averageRatings.find(
        (obj) => obj.user_email === this.$auth.user.email
      );
    },
    averageRatingStars() {
      if (!this.userAverageRatingData) return 0;
      const average = this.userAverageRatingData.rating;

      return Math.round(10 * average) / 10; // round to tenth
    },
    surveyStatusReport() {
      const report = {
        projects: 0,
        clients: 0,
        since: null,
      };

      if (!this.userAverageRatingData) return report;

      const firstSurvey = this.userAverageRatingData.created_at;
      const firstSurveyDate = this.getLuxonDateTime(firstSurvey, true);
      const msAgo = Math.abs(firstSurveyDate.diffNow().milliseconds);
      report.since = DateTime.now()
        .minus({ milliseconds: msAgo })
        .toRelative({ locale: "en-US" });

      report.projects = this.userAverageRatingData.project_count;
      report.clients = this.userAverageRatingData.client_count;

      return report;
    },
  },
  methods: {
    emailSignatureClicked() {
      if (!this.isCustomEmailSignatureAvailable)
        eventBus.$emit(channels.upgradeSubscription, { allowDismissal: true });
    },
    saveDetails: _.debounce(function () {
      this.isSavingDetails = true;

      const details = { ...this.details };
      delete details.html_email_signature;

      updateUserProfile(this.$store.getters.userId, details)
        .then((result) => {
          this.$store.commit("SET_USER_PROFILE", result);
          this.isSavingDetails = false;
          this.notifySuccess("User Profile saved");
        })
        .catch((error) => {
          this.notifyError(error, "Error updating profile");
          this.isSavingDetails = false;
        });
    }, 1500),
    saveEmailSignature() {
      if (!this.isCustomEmailSignatureAvailable) return;

      updateUserProfile(this.$store.getters.userId, {
        html_email_signature: this.emailSignature,
      })
        .then((result) => {
          this.$store.commit("SET_USER_PROFILE", result);
          this.notifySuccess("Email Signature saved");
        })
        .catch((error) => {
          this.notifyError(error, "Error updating Email Signature");
        });
    },
  },
  watch: {
    details: {
      deep: true,
      handler: function () {
        if (this.isLoadingDetails) return;

        this.saveDetails();
      },
    },
  },
  async mounted() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/");
      return;
    }

    usersProjectsSurveysAverageRating().then((res) => {
      this.averageRatings = res;
    });

    this.isLoadingDetails = true;
    getUserProfile()
      .then((res) => {
        this.details = res;

        let emailSignature = res.html_email_signature;

        if (!emailSignature)
          emailSignature = `<p><b>${res.name} ${res.surname}</b></p>`;

        this.emailSignature = emailSignature;
        this.$nextTick(() => {
          this.isLoadingDetails = false;
        });
      })
      .catch((error) => {
        this.notifyError(error, "Error loading user profile");
        this.isLoadingDetails = false;
      });
  },
};
</script>

<style>
.note-editable {
  min-height: 100px !important;
}
</style>
