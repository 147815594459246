<template>
  <vue-apex-charts
    :type="type"
    :height="chartHeight"
    :options="chartData.chartOptions"
    :series="chartData.series"
    class="custom-apex-chart"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ApexChart",
  components: {
    VueApexCharts,
  },
  props: {
    type: String,
    chartHeight: Number,
    chartData: Object,
  },
};
</script>
<style>
.apexcharts-canvas .apexcharts-radialbar-track path {
  stroke: rgba(242, 242, 242, 0.85) !important;
}
.apexcharts-canvas .apexcharts-gridline {
  stroke: inherit;
}
</style>
