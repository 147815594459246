const config = require("./config.json");
const { get, create, update, remove } = require("./common").default;

/**
 * Retrieve all users
 * @returns
 */
exports.getUsers = async () => {
  return await get(usersUrl);
};

exports.addUser = async (user) => {
  return await create(usersUrl, user);
};

/**
 * Update the given user object.
 * An id must be provided and @param user.id takes precedence over @param userId
 * @param {object} user The object to update. The id can be omitted if specified in @param userId, but is required otherwise
 * @param {number} userId Optional. Needed only if @param user does not have an id property
 * @returns
 */
exports.updateUser = async (user, userId) => {
  if (!user.id || user.id < 1) {
    if (!userId || userId < 1) {
      throw new Error("No valid user ID provided");
    }
    user.id = userId;
  }
  return await update(usersUrl + "/" + user.id, user);
};

exports.deleteUser = async (user) => {
  return this.deleteUserById(user.id);
};

exports.deleteUserById = async (id) => {
  return await remove(usersUrl + "/" + id);
};

exports.getUserProfile = async () => {
  return await get(userProfilesUrl);
};

exports.updateUserProfile = async (id, userProfile) => {
  return await update(`${userProfilesUrl}/${id}`, userProfile);
};

const usersUrl = config.apis.base.url + "users";
const userProfilesUrl = config.apis.base.url + "user-profiles";
