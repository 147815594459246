<template>
  <div>
    <b v-if="field.label && showLabel">
      <label :for="`input-${field.name}`">{{ field.label }}</label>
    </b>
    <multiselect
      :value="value"
      :close-on-select="closeOnSelect"
      :options="field.options"
      :multiple="true"
      :disabled="readonly"
      track-by="value"
      :placeholder="field.placeholder"
      :showLabels="showLabels"
      label="text"
      :showNoOptions="showNoOptions"
      :showNoResults="showNoResults"
      @input="(e) => $emit('input', e)"
    >
      <!-- <template slot="tag" slot-scope="{ values, search, isOpen }">Your code</template> -->
      <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
      <template slot="option" slot-scope="props">
        <img class="option__image" :src="props.option.img" alt="No Man’s Sky">
        <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span class="option__small">{{ props.option.desc }}</span></div>
      </template> -->
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: Array,
    readonly: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    showNoOptions: {
      type: Boolean,
      default: true,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  line-height: 0.5;
  min-height: 35px;
}
.multiselect__placeholder {
  padding-top: 4px;
}
</style>
