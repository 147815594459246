<template>
  <div class="input-container input-group mb-3">
    <div
      @dragover="dragover"
      @dragleave="dragleave"
      @drop.prevent="drop"
      class="file-drop-container"
      :class="{ active: isDragging }"
    >
      <input
        :id="`input-${field.name}`"
        :name="`input-${field.name}`"
        type="file"
        :multiple="field.multiple"
        class="d-none"
        :readonly="!!field.readonly"
        ref="input"
        @change="onChange"
        onclick="this.value=null;"
      />
      <label
        v-if="files.length"
        class="preview-container"
        :for="`input-${field.name}`"
      >
        <div
          v-for="(file, i) in files"
          :key="`${file.name}-${i}`"
          class="preview-card"
        >
          <i
            v-if="fileTypeIcon(file.type, null)"
            :class="`fa ${fileTypeIcon(file.type)}`"
          />
          <img
            v-else-if="file.thumbnail"
            :src="file.thumbnail"
            :alt="file.name"
          />
          <i v-else class="fa fa-file-o" />

          <p class="name">
            {{ file.name }}
          </p>
          <button
            v-if="!file.isUploading"
            class="btn-remove btn-danger"
            type="button"
            @click.prevent="$emit('remove', files.indexOf(file))"
            title="Remove file"
          >
            remove
          </button>
          <div v-if="file.isUploading" class="loader">
            <i class="fa fa-spinner fa-spin"></i>
            <UploadProgress :progress="file.progress" />
          </div>
        </div>
      </label>
      <label v-else class="no-preview-container" :for="`input-${field.name}`">
        <i class="icon-cloud-upload"></i>
        <span v-if="textToShow" class="select-none"> {{ textToShow }}</span>
        <!--
        <span class="select-none small">Max upload size: 100MB per file</span>
        -->
      </label>
    </div>
  </div>
</template>

<script>
import UploadProgress from "@/components/ui/UploadProgress.vue";
import { fileTypeIcon } from "@/utils/icon";

export default {
  name: "FileDrop",
  components: { UploadProgress },
  props: {
    field: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  methods: {
    fileTypeIcon,
    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      if (this.field.multiple) {
        const files = Array.from(e.dataTransfer.files);
        if (files?.length) this.$emit("added", files);
      } else {
        const file = Array.from(e.dataTransfer.files)?.[0];
        if (file) this.$emit("added", [file]);
      }
      this.isDragging = false;
    },
    async onChange(e) {
      this.$emit("added", Array.from(e.target.files));
    },
  },
  computed: {
    textToShow() {
      if (this.isDragging) return "Release files here";

      return "Click or drop files here";
    },
  },
};
</script>

<style scoped>
.file-drop-container {
  width: 100%;
  border: 2px dashed #e1e8ed;
  border-radius: 0.25rem;
}

.file-drop-container.active {
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.02) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.02) 50%,
    rgba(0, 0, 0, 0.02) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  animation: stripes 2s linear infinite;
}

.preview-container {
  column-gap: 0.75rem;
  place-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 60px));
  margin-bottom: 0;
  height: auto !important;
  min-height: 100%;
  row-gap: 1rem;
  padding: 0.75rem;
  position: relative;
}

.preview-container .preview-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 0.113rem;
  position: relative;
}

.preview-container .preview-card img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-bottom: 0.25rem;
  border-radius: 5px;
}

.preview-container .preview-card i {
  margin-top: 3px;
  font-size: 1.75rem;
  margin-bottom: 0.25rem;
}

.preview-container .preview-card p {
  margin-bottom: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.preview-container .preview-card .loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.preview-container .preview-card .btn-remove {
  margin-top: auto;
  border: none;
  font-size: 0.65rem;
}

.no-preview-container {
  display: flex;
  height: auto !important;
  min-height: 105px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.25rem;
  margin-bottom: 0;
  position: relative;
}

.no-preview-container i {
  font-size: 2rem;
  color: #77797c;
}

.no-preview-container span {
  color: #77797c;
}

.file-drop-container.active .no-preview-container span {
  color: #000;
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 30px;
  }
}
</style>
