<template>
  <vue-apex-charts
    v-if="isDataAvailable"
    :type="chartType"
    ref="CSATAverageRating"
    :height="chartHeight"
    :options="chartData.chartOptions"
    :series="chartData.series"
    class="custom-apex-chart"
  ></vue-apex-charts>
  <div v-else class="no-data">
    <p>Not enough data available</p>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "CSATAverageRatingChart",
  components: {
    VueApexCharts,
  },
  props: {
    timeRange: Array,
    projectsSurveysAverageRating: Array,
    projects_stats: Array,
  },
  data() {
    return {
      chartHeight: 350,
      chartType: "area",
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: false,
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 5,
        },
        annotations: {
          yaxis: [
            {
              borderColor: "#999",
              label: {
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
          ],
          // ... xaxis annotations remain unchanged
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: "one_year",
    };
  },
  computed: {
    getProjectsSurveysAverageRating() {
      const dataCopy = [...this.projectsSurveysAverageRating];
      // Sort the copied data by 'created_at'
      dataCopy.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA - dateB;
      });
      return dataCopy;
    },
    chartData() {
      return {
        series: this.generateSeriesDataForAverageRating(
          this.getProjectsSurveysAverageRating
        ),
        chartOptions: this.chartOptions,
      };
    },
    isDataAvailable() {
      return this.chartData.series[0]?.data.length > 1;
    },
  },
  methods: {
    zoomChartXAxis(timeRange) {
      if (timeRange.length === 2) {
        this.$refs.CSATAverageRating.chart.zoomX(
          timeRange[0].getTime(),
          timeRange[1].getTime()
        );
      } else if (
        timeRange.length === 0 &&
        this.getProjectsSurveysAverageRating.length
      ) {
        const projectLength = this.getProjectsSurveysAverageRating.length;
        const firstProjectDate =
          this.getProjectsSurveysAverageRating[0].created_at;
        const lastProjectDate =
          this.getProjectsSurveysAverageRating[projectLength - 1].created_at;
        if (projectLength !== 1) {
          this.$refs.CSATAverageRating.chart.zoomX(
            new Date(firstProjectDate).getTime(),
            new Date(lastProjectDate).getTime()
          );
        } else {
          const fromFirstProjectDate = new Date(firstProjectDate);
          fromFirstProjectDate.setDate(fromFirstProjectDate.getDate() - 15); // Subtract 15 days

          const plusLastProjectDate = new Date(lastProjectDate);
          plusLastProjectDate.setDate(plusLastProjectDate.getDate() + 15); // Add 15 days

          this.$refs.CSATAverageRating.chart.zoomX(
            fromFirstProjectDate.getTime(),
            plusLastProjectDate.getTime()
          );
        }
      }
    },
    generateSeriesDataForAverageRating(projectsArray) {
      const ratingPerDate = {};

      projectsArray.forEach((project) => {
        const createdAt = new Date(project.created_at);
        const ratingValue = project.rating;
        const dateString = createdAt.toISOString().split("T")[0];

        if (ratingPerDate[dateString]) {
          ratingPerDate[dateString].push(ratingValue);
        } else {
          ratingPerDate[dateString] = [ratingValue];
        }
      });

      const averageRatingPerDate = Object.keys(ratingPerDate).map(
        (dateString) => {
          const ratingValues = ratingPerDate[dateString];
          const averageRating = (
            ratingValues.reduce((sum, value) => sum + value, 0) /
            ratingValues.length
          ).toFixed(2);

          return [new Date(dateString).getTime(), parseFloat(averageRating)];
        }
      );

      return [
        {
          name: "CSAT",
          data: averageRatingPerDate,
        },
      ];
    },
  },
  watch: {
    timeRange(newTimeRange) {
      this.zoomChartXAxis(newTimeRange);
    },
  },
};
</script>
<style></style>
