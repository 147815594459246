<template>
  <Modal
    :show-close-button="true"
    @submit="onSubmitClick"
    @close="onCloseClick"
    cancel-text="Cancel"
    submit-text="Next step"
    :close-on-outside-click="false"
    :disable-submit-button="!selected"
    submit-class="btn-primary"
    :maxWidth="800"
  >
    <div class="container" style="text-align: center">
      <h3 class="modal-title">Create new project</h3>
      <div class="d-flex justify-content-center" style="align-items: center">
        <div class="dot-container">
          <div class="dot dot-filled">1</div>
        </div>
        <div class="line"></div>
        <div class="dot-container">
          <div class="dot dot-empty">2</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-4">
        <div class="text"><b id="project-structure">Project structure</b></div>
        <div class="text">Project setup</div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <div
          class="card"
          :class="{ selected: selected === 'Single' }"
          @click="toggleSelection('Single')"
        >
          <div class="body" style="height: 100%">
            <div class="d-flex">
              <div class="align-left">
                <p><strong>Single Deliverable</strong></p>
                <p class="description">
                  The project is completed and delivered in one full piece,
                  without breaking it down into separate milestones or
                  deliverables
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card"
          :class="{ selected: selected === 'Multi' }"
          @click="toggleSelection('Multi')"
        >
          <div class="body" style="height: 100%">
            <div class="d-flex">
              <div class="align-left">
                <p class="d-flex align-items-start" id="multi-deliverable">
                  <strong>Multi-deliverable</strong>
                  <img
                    src="/assets/images/stack.svg"
                    class="ml-2"
                    style="height: 1rem; width: auto"
                  />
                </p>
                <p class="description">
                  The project includes several sub-projects or deliverables,
                  each with its own timeline, grouped for organized execution
                  and communication.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "ChooseProjectDeliverableType",
  components: { Modal },
  props: {
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: "Single",
    };
  },
  methods: {
    toggleSelection(option) {
      this.selected = option;
    },
    onSubmitClick() {
      this.$emit("submit", this.selected);
    },
    onCloseClick() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.modal-title {
  margin-bottom: 20px;
  color: #17c2d7;
  font-size: 23px;
}
.description {
  color: #b1b1b1;
  font-size: 12px;
}

.dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dot-filled {
  background-color: #17c2d7; /* Blue color for the first dot */
  color: white;
}

.dot-empty {
  border: 1px solid #dde4ea; /* Border color for the second dot */
  color: #191f28;
}

.line {
  width: 106px; /* Adjust length of the line */
  height: 1px;
  background-color: #f5f5f5;
}
.card {
  border: 1px solid #dde4ea;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  font-family: Montserrat;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.selected {
  border-color: #17c2d7;
  box-shadow: 0 0 5px rgba(23, 194, 215, 0.4);
}

.text,
.card {
  margin: 0 20px;
}
</style>
