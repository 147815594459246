<template>
  <form id="payment-form">
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div>

    <button class="btn btn-primary mt-1" @click="addPaymentMethod">
      Add Payment Method
    </button>
  </form>
</template>

<script>
import { getStripeSetupIntent } from "../../apis/subscriptions";
const config = require("../../apis/config.json");

export default {
  name: "AddPaymentMethod",
  data() {
    return {
      // These are set when we load the Stripe script and the Stripe iframe
      stripe: null,
      elements: null,
    };
  },
  methods: {
    addPaymentMethod(event) {
      event.preventDefault();

      this.stripe
        .confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements: this.elements,
          confirmParams: {
            return_url:
              window.location.origin + "/billing/payment-method-added",
          },
        })
        .then((result) => {
          if (result.error) {
            this.notifyError(
              result.error,
              `Payment failed: ${result.error.message}`
            );
            this.$emit("paymentFailed", result.error);
          } else {
            this.notifySuccess("Success! Redirecting to your account.");
            this.$emit("paymentSuccessful");
          }
        });
    },
  },
  created() {
    // Add stripe script
    let stripeScript = document.createElement("script");
    stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(stripeScript);

    stripeScript.onload = async () => {
      const setupIntent = await getStripeSetupIntent();
      const options = { clientSecret: setupIntent.client_secret };

      const { stripe, elements } = window.initStripeElements(
        config.stripe.publishableKey,
        options
      );
      this.stripe = stripe;
      this.elements = elements;

      // Create and mount the Payment Element
      const paymentElement = elements.create("payment");
      paymentElement.mount("#payment-element");
    };
  },
};
</script>
