<template>
  <wrapper-page>
    <!-- <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-right hidden-xs">
        <button v-on:click="submitForm" class="btn btn-primary">
          Save Changes
        </button>
      </div>
    </template> -->

    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Team</h2>
          </div>

          <div class="body">
            <form ref="usersForm" @submit.prevent="saveDetails">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        v-for="(field, index) in userFields"
                        :key="field.name"
                        class="text-center"
                        :class="
                          index > 2 ? `text-wrap` : index < 2 ? `w200` : `w350`
                        "
                      >
                        {{ field.label }}
                      </th>
                      <th v-if="users.length > 1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, i) in users" :key="`user-${i}`">
                      <td
                        v-for="field in userFields"
                        :key="`${i}-${field.name}`"
                      >
                        <form-input-group
                          v-model="user[field.name]"
                          v-if="
                            field.name === 'name' ||
                            field.name === 'email' ||
                            field.name === 'surname'
                          "
                          :field="field"
                          class="flex-grow-1"
                          :class="{
                            readonly: field.name === 'email' && !!user.id,
                          }"
                          :showLabel="false"
                          :readonly="field.name === 'email' && !!user.id"
                        />

                        <div
                          v-if="
                            field.name !== 'name' &&
                            field.name !== 'email' &&
                            field.name !== 'surname'
                          "
                          class="fancy-checkbox text-center ml-2 mr-0"
                        >
                          <label>
                            <input
                              type="checkbox"
                              v-model="user[field.name]"
                              :id="`input-${field.name}`"
                              :name="field.name"
                              :aria-label="field.name"
                              @change="permissionUpdate(user, field.name)"
                            /><span>&nbsp;</span></label
                          >
                        </div>
                      </td>

                      <td v-if="users.length > 1">
                        <button
                          v-if="!user.owner && $auth.user.email !== user.email"
                          @click="promptRemoveUser(i)"
                          type="button"
                          class="btn-sm btn btn-danger"
                          title="Remove User"
                        >
                          <span class="sr-only">Remove User</span
                          ><i class="icon-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>

            <div class="pl-3 mt-3">
              <button
                @click="addUser"
                type="button"
                class="btn btn-primary"
                title="Add User"
                :class="{ disabled: !canAddUsers }"
              >
                <i class="fa fa-plus"></i>
                <span> Add User</span>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button v-on:click="submitForm" class="btn btn-primary">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionConfirmModal
      v-if="isRemovingUserIndex !== false && isRemovingUserIndex >= 0"
      @close="isRemovingUserIndex = false"
      @submit="removeUser"
    />
  </wrapper-page>
</template>

<script>
import { getUsers } from "../../apis/users";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputGroup from "../../components/ui/FormInputGroup.vue";
import { addUser, deleteUserById, updateUser } from "@/apis/users";
import ActionConfirmModal from "@/components/ui/Modals/ActionConfirmModal.vue";
import eventBus, { channels } from "@/eventBus";
import { isWorkspaceFree } from "@/utils/helper";

export default {
  name: "SettingsTeamPage",
  components: {
    ActionConfirmModal,
    WrapperPage,
    FormInputGroup,
  },
  data() {
    return {
      isRemovingUserIndex: false,
      isLoadingUsers: false,
      isSavingDetails: false,
      users: [],
      removedUsers: [], // Keeps track of users that were deleted.
      userFields: [
        { label: "First Name", name: "name" },
        { label: "Last Name", name: "surname" },
        { label: "Email", name: "email", type: "email" },
        { label: "Admin", name: "admin", type: "checkbox" },
        // { label: "Billing", name: "billing", type: "checkbox" },
        {
          label: "Create new projects",
          name: "create_projects",
          type: "checkbox",
        },
        {
          label: "Access all projects",
          name: "access_all_projects",
          type: "checkbox",
        },
        {
          label: "Workflow Templates",
          name: "workflow_templates",
          type: "checkbox",
        },
        { label: "Email templates", name: "email_templates", type: "checkbox" },
        { label: "Add/Delete Users", name: "add_users", type: "checkbox" },
      ],
    };
  },
  computed: {
    canAddUsers() {
      return this.$store.getters.usersRemaining !== 0;
    },
  },
  methods: {
    submitForm() {
      // We don't call submitClient() directly so that any form validations can be executed
      if (!this.$refs.usersForm.checkValidity()) {
        this.$refs.usersForm.requestSubmit();
        return;
      }
      this.$refs.usersForm.requestSubmit();
    },
    addUser() {
      if (!this.canAddUsers) {
        if (isWorkspaceFree(this.$auth.tenant.subscription)) {
          eventBus.$emit(channels.upgradeSubscription, {
            allowDismissal: true,
            title: "Action required: Upgrade to add more users",
            upgradableText:
              "You're currently on our Free Trial, which supports one user. Upgrading to our paid plan lets you add additional users to your account and gain access to more advanced features",
          });
        } else {
          eventBus.$emit(channels.upgradeSubscription, {
            allowDismissal: true,
            title: "Action required: Add more users",
            upgradableText: "Upgrade to add additional users to your account",
          });
        }

        return;
      }

      this.users.push({
        workflow_templates: false,
        email_templates: false,
      });
    },
    promptRemoveUser(index) {
      const userToDelete = this.users[index];
      if (userToDelete.owner || this.$auth.user.email === userToDelete.email) {
        // cannot delete workspace owners or yourself
        this.isRemovingUserIndex = false;
        return;
      }

      this.isRemovingUserIndex = index;
    },
    removeUser() {
      if (this.isRemovingUserIndex === false) return;

      const index = this.isRemovingUserIndex;
      const userToDelete = this.users[index];

      if (userToDelete.owner || this.$auth.user.email === userToDelete.email)
        return; // cannot delete workspace owners or yourself

      const removedUser = this.users.splice(index, 1)[0];
      if (removedUser.id) {
        this.removedUsers.push(removedUser.id);
      }

      this.isRemovingUserIndex = false;
    },
    permissionUpdate(user, permission) {
      // If we remove any permission other than admin, the admin permission should also be removed
      if (user["admin"] && permission != "admin" && !user[permission]) {
        user["admin"] = false;
      }
      // If we are setting user to admin, tick all the checkboxes
      if (permission == "admin") {
        if (user[permission]) {
          this.userFields.slice(3).forEach((field) => {
            user[field.name] = true;
          });
        }
      }
    },
    async saveDetails() {
      this.isSavingDetails = true;
      let isUpdatingUsers = false;
      let isAddingNewUser = false;

      try {
        let promises = [];
        // Delete removed users,
        // Add new users &
        // Update permissions for the others
        for (let u = 0; u < this.removedUsers.length; u++) {
          promises.push(deleteUserById(this.removedUsers[u]));
        }
        for (let u = 0; u < this.users.length; u++) {
          const user = { ...this.users[u] };

          if ("id" in user) {
            delete user.email;
            isUpdatingUsers = true;
            promises.push(updateUser(user));
          } else {
            isAddingNewUser = true;
            promises.push(addUser(user));
          }
        }
        const result = await Promise.allSettled(promises);
        let total = 0,
          ok = 0;
        let lastError = { message: "" };
        result.forEach((promise) => {
          total++;
          if (promise.status === "fulfilled") {
            ok++;
          } else {
            lastError.message = promise.reason.response.data;
          }
        });
        if (ok === total) {
          if (isAddingNewUser && isUpdatingUsers) {
            this.notifySuccess("New team member created and invited");
            this.notifySuccess("User settings updated");
          } else if (isAddingNewUser) {
            this.notifySuccess("New team member created and invited");
          } else this.notifySuccess("User settings updated");
        } else if (ok === 0) {
          this.notifyError(
            lastError,
            "Failed to save any of the Team details changes"
          );
        } else if (ok < total) {
          this.notifyError(lastError, "Some Team details were not saved");
        }
        this.users = await getUsers();
        this.$store.commit("SET_USERS", this.users);
        this.removedUsers = [];
      } catch (err) {
        this.notifyError(err, "Failed to save Team details");
      }
      this.isSavingDetails = false;
    },
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    if (
      !this.$auth.user.owner &&
      !this.$auth.user.admin &&
      !this.$auth.user.add_users
    )
      return this.$router.push("/");
  },
  watch: {
    "$store.getters.users": {
      handler(newUsers) {
        // React to changes in users data
        this.users = newUsers;
      },
      immediate: true, // Trigger handler immediately on component mount
    },
  },
};
</script>
