var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row clearfix"},[(_vm.showSearchBar)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Name"},on:{"input":function($event){return _vm.search($event.target.value)}}})])]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Ref. Code"},on:{"input":function($event){return _vm.searchField('reference_code', $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Default Subject"},on:{"input":function($event){return _vm.searchField(
                    'email_subject_line_default',
                    $event.target.value
                  )}}})])]),_vm._m(0)])])])]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{class:[
          'table',
          'table-hover',
          'table-custom',
          'spacing8',
          'dataTable',
          _vm.identifier,
        ],attrs:{"id":_vm.identifier}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Starting Date")]),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('th',[_vm._v("Client")]):_vm._e(),_c('th',[_vm._v("Project Code")]),_c('th',[_vm._v("Project Name")]),_c('th',[_vm._v("Project Status")]),(!_vm.isClientDetailsPage && !_vm.isArchivedProjects)?_c('th',[_vm._v(" Current Stage ")]):_vm._e(),_c('th',[_vm._v("Production Progress")]),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('th',[_vm._v(" Link Clicked ")]):_vm._e(),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('th',[_vm._v(" Settings ")]):_vm._e(),(_vm.$auth.user.isAgent && _vm.isClientDetailsPage && _vm.isArchived)?_c('th',[_vm._v(" Date completed ")]):_vm._e(),(_vm.$auth.user.isAgent && _vm.isClientDetailsPage && _vm.isArchived)?_c('th',[_vm._v(" Feedback rating ")]):_vm._e()])]),(_vm.filteredProjects?.length)?_c('tbody',_vm._l((_vm.filteredProjects),function(model){return _c('tr',{key:model.id},[_c('td',{attrs:{"data-sort":model.dateFieldValue}},[_vm._v(" "+_vm._s(model.dateField)+" ")]),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('td',[_vm._v(" "+_vm._s(model.client_name)+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(model.reference_code))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name:
                    model.deliverable_type === 'Multi'
                      ? 'project-timelines'
                      : 'project-timeline',
                  params: { projectId: model.id },
                },"title":model.name}},[_vm._v(_vm._s(model.name))]),(model.deliverable_type === 'Multi')?_c('img',{staticClass:"ml-2",staticStyle:{"height":"1rem","width":"auto","margin-top":"-2px"},attrs:{"src":"/assets/images/stack.svg"}}):_vm._e(),_c('span',{staticClass:"ti-files"})],1),_c('td',{attrs:{"data-order":model.statusFieldValue}},[_c('span',{class:model.statusFieldClass},[_vm._v(_vm._s(model.statusField))])]),(!_vm.isClientDetailsPage && !_vm.isArchivedProjects)?_c('td',[_vm._v(" "+_vm._s(model.stageField)+" "),(
                  _vm.$auth.user.isAgent &&
                  model.stageField !== 'Multiple stages' &&
                  model.stageField !== '--' &&
                  ['awaiting_feedback', 'overdue_feedback'].includes(
                    model?.revision?.status
                  )
                )?_c('stage-quick-approve-or-reject',{staticClass:"ml-3",attrs:{"stage":model.stage,"isProjectCancelled":Boolean(model.cancelled)},on:{"on_stage_approve_click":function($event){return _vm.onApproveStageClick(model.workflow, model.stage)},"on_stage_reject_click":function($event){return _vm.onRejectStageClick(model.workflow, model.stage)}}}):_vm._e()],1):_vm._e(),_c('td',{attrs:{"data-order":model.productionProgress}},[_c('progress-bar',{attrs:{"value":model.productionProgress,"color":model.progressColor,"isProjectCancelled":Boolean(model.cancelled)}})],1),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('td',[_vm._v(" "+_vm._s(model.lastClicked)+" ")]):_vm._e(),(_vm.$auth.user.isAgent && !_vm.isClientDetailsPage)?_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'project-details',
                  params: { projectId: model.id },
                },"title":"settings"}},[_vm._v("Edit")])],1):_vm._e(),(_vm.$auth.user.isAgent && _vm.isClientDetailsPage && _vm.isArchived)?_c('td',[_vm._v(" "+_vm._s(_vm.cellText_CurrentStage_Achived(model.workflow))+" ")]):_vm._e(),(_vm.$auth.user.isAgent && _vm.isClientDetailsPage && _vm.isArchived)?_c('td',[(model.is_survey_sent && model.isFeedbackSubmitted)?_c('star-rating',{attrs:{"dynamicFontSize":'1.2rem',"value":model.averageRating,"readyOnly":true}}):(
                  !model.is_survey_sent && !model.isFeedbackSubmitted
                )?_c('div',[_vm._v(" CSAT not sent ")]):(model.is_survey_sent && !model.isFeedbackSubmitted)?_c('div',[_vm._v(" Pending CSAT feedback ")]):_vm._e()],1):_vm._e()])}),0):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6"},[_c('a',{staticClass:"btn btn-sm btn-primary btn-block",attrs:{"href":"javascript:void(0);","title":""}},[_vm._v("Search")])])
}]

export { render, staticRenderFns }