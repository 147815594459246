<template>
  <div style="padding-bottom: 20px">
    <button
      v-if="
        stageWithRevisions &&
        currentRevisionIsTheLastForThisStage &&
        revisionIsAwaitingFeedback
      "
      class="btn btn-danger mr-2"
      @click="addRevision()"
      :disabled="isSubscriptionReadOnly()"
    >
      Add extra revision round
    </button>
    <button
      v-if="stage.is_deliverable"
      ref="btnDeliverables"
      class="btn btn-primary-custom mr-2"
      @click="goToDeliverablesTab()"
      :disabled="isSubscriptionReadOnly()"
    >
      Send Deliverables
    </button>
    <button
      v-if="!stageWithRevisions"
      :disabled="isSubscriptionReadOnly()"
      ref="btnCompleted"
      class="btn btn-success"
      @click="approve()"
    >
      Complete Stage
    </button>
  </div>
</template>

<script>
import workflowMixin from "../../../mixins/workflow";

export default {
  name: "StageActionButtons",
  mixins: [workflowMixin],
  props: {
    stage: {},
    workflow: {},
    project: {},
  },
  computed: {
    /** Checks if the stage has at least a preview as well (PM can send something to client). */
    stageWithRevisions() {
      return this.stage.tasks.length > 0;
    },
    currentRevisionIsTheLastForThisStage() {
      const revision = this.getCurrentRevision(this.workflow);
      if (revision) {
        return this.isLastRevisionInStage(revision, this.stage);
      } else {
        return false;
      }
    },
    revisionIsAwaitingFeedback() {
      const revision = this.getCurrentRevision(this.workflow);
      return revision.status === "awaiting_feedback";
    },
  },
  methods: {
    addRevision() {
      this.$emit("on_stage_add_revision_click", this.stage);
    },
    goToDeliverablesTab() {
      this.$emit("open_deliverables_tab", this.stage);
    },
    approve() {
      this.$emit("on_stage_client_approve_click", this.stage);
    },
  },
};
</script>

<style scoped>
.btn-success {
  background-color: #4e9f7b;
  border-color: #4e9f7b;
}
.btn-primary-custom {
  background-color: #5995b7;
  border-color: #5995b7;
  color: #fff;
}
/* .btn-warning {
  background-color: #e57671;
  border-color: #e57671;
  color: white;
} */
</style>
