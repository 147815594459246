<template>
  <!-- Collapsed right panel with search field & results - opened when clicking on search icon -->
  <div class="search_div">
    <div class="card">
      <div class="body">
        <form id="navbar-search" class="navbar-form search-form">
          <div class="input-group mb-0">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              @input="$emit('search', $event)"
            />
            <div class="input-group-append">
              <span class="input-group-text"
                ><i class="icon-magnifier"></i
              ></span>
              <a href="javascript:void(0);" class="search_toggle btn btn-danger"
                ><i class="icon-close"></i
              ></a>
            </div>
          </div>
        </form>
      </div>
    </div>

    <span v-if="searchResult.results"
      >Search Result
      <small class="float-right text-muted"
        >{{ searchResult.results.length }} results
        <span v-if="searchResult.durationInSeconds"
          >in {{ searchResult.durationInSeconds }} seconds</span
        ></small
      ></span
    >
    <div v-if="searchResult.results" class="table-responsive">
      <table class="table table-hover table-custom spacing5">
        <tbody>
          <tr v-for="(result, index) in searchResult.results" :key="index">
            <td class="w40">
              <!-- w40 sets the column width to w40 -->
              <span>{{ (index + 1).toString().padStart(2, 0) }}</span>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <img
                  v-if="result.icon && result.icon.img"
                  :src="result.icon.img"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  :alt="result.title"
                  class="w35 h35 rounded"
                  :data-original-title="result.title"
                />
                <div
                  v-else-if="result.icon && result.icon.text"
                  class="avtar-pic w35"
                  :class="'bg-' + result.icon.backgroundColour"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  :data-original-title="result.title"
                >
                  <span>{{ result.icon.text }}</span>
                </div>
                <div v-else class="avtar-pic w35"><span></span></div>
                <div class="ml-3">
                  <router-link :to="result.link" :title="result.title">{{
                    result.title
                  }}</router-link>
                  <p class="mb-0">{{ result.subtitle }}</p>
                  <!-- <p class="mb-0" v-html="result.subtitle"></p> -->
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchPanel",
  data() {
    return {
      searchResult: {},
    };
  },
  methods: {
    /**
     * @param {object} result Search-Result object with list of results and some meta data
     * Expected structure as defined below;
     * {
            durationIanSeconds: 0.47,
            results: [
                {
                    icon: {
                        img: '/assets/images/icon.svg'
                    },
                    title: 'Zoe Baker',
                    subtitle: 'zoe.baker@example.com',
                    link: 'javascript:void(0);'
                },
                {
                    title: 'No Icon',
                    subtitle: '<a href="mailtot:north.shyanne@example.com">link</a>',
                    link: 'user/1'
                },
                {
                    icon: {
                        text: 'NS',
                        backgroundColour: 'green'
                    },
                    title: 'North Shyanne',
                    subtitle: '<a href="mailtot:north.shyanne@example.com">link</a>',
                    link: 'user/2'
                }
            ]
        }
    */
    setSearchResult: function (result) {
      this.searchResult = result;
    },
    /**
     * Adds more results if the search process sends results incrementally
     */
    appendSearchResult: function (result) {
      if (this.searchResult.durationInSeconds) {
        this.searchResult.durationInSeconds +=
          Number(result.durationInSeconds) || 0;
      } else {
        this.searchResult.durationInSeconds = Number(result.durationInSeconds);
      }
      this.searchResult.results = this.searchResult.results.concat(
        result.results
      );
    },
  },
};
</script>
