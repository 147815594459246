/**
 * @param {string} type
 * @param {string|null} fallback
 * @return string
 */
export const fileTypeIcon = (type, fallback = "fa-file-o") => {
  type = type.toLowerCase();

  if (type.match("pdf")) return "fa-file-pdf-o";
  else if (
    [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      ".docx",
    ].includes(type)
  )
    return "fa-file-word-o";
  else if ([".mp3", ".wav", "audio/wav", "audio/mpeg"].includes(type))
    return "fa-file-audio-o";
  else if (
    [
      ".zip",
      ".rar",
      ".7zip",
      "application/vnd.rar",
      "application/x-rar-compressed",
      "application/octet-stream",
      "application/zip",
      "application/octet-stream",
      "application/x-zip-compressed",
      "multipart/x-zip",
      "application/x-7z-compressed",
    ].includes(type)
  )
    return "fa-file-zip-o";

  return fallback;
};
