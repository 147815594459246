<!-- This page doesn't display anything, but performs some operations on a project and then redirects away -->
<template>
  <wrapper-page></wrapper-page>
</template>

<script>
import { getProjectById } from "../apis/projects";
import { approveStage, getWorkflowById, rejectStage } from "../apis/workflows";
import WrapperPage from "../components/layout/WrapperPage.vue";
import workflowMixin from "../mixins/workflow";

export default {
  name: "ProjectActions",
  components: { WrapperPage },
  mixins: [workflowMixin],
  async mounted() {
    try {
      const projectId = this.$route.params.projectId;
      const action = (this.$route.params.action || "").toLowerCase();

      const project = await getProjectById(projectId, false);

      if (action.endsWith("-revision")) {
        const revisionId = this.$route.query.revisionId;
        if (!revisionId) {
          throw new Error("Revision ID expected");
        }

        const workflow = await getWorkflowById(project.workflow_id);
        const stage = this.getCurrentStage(workflow);
        const revision = this.getCurrentRevision(workflow);

        if (action === "approve-revision") {
          if (revision.id === revisionId) {
            await approveStage(workflow, stage);
          } else {
            this.notifyWarn(
              "The provided revision was already rejected.",
              "Action"
            );
          }
        } else if (action === "reject-revision") {
          if (revision.id === revisionId) {
            await rejectStage(workflow, stage);
          } else {
            this.notifyWarn(
              "The provided revision was already rejected.",
              "Action"
            );
          }
        }
        this.$router.push("/projects/" + projectId + "/timeline");
        return;
      }
      this.$router.push("/projects/" + projectId);
    } catch (err) {
      this.notifyError(err, "Action error");
      this.$router.push("/projects");
    }
  },
};
</script>
