<template>
  <Modal
    title="Edit workspace name"
    :show-close-button="true"
    @submit="$emit('submit', name)"
    @close="$emit('close')"
    :close-on-outside-click="true"
  >
    <div class="container">
      <form-input-group
        v-model="name"
        :field="field"
        ref="workspaceNameField"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";

export default {
  name: "EditWorkspaceNameModal",
  components: { FormInputGroup, Modal },
  data() {
    return {
      name: `${this.oldName}`, // copy the string without binding reference
      field: {
        label: "Workspace name",
        name: "name",
        placeholder: "Workspace name",
      },
    };
  },
  props: {
    oldName: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$refs?.workspaceNameField?.$refs?.input?.focus();
  },
};
</script>
