<template>
  <Modal
    :title="title"
    :show-close-button="showCloseButton"
    :show-cancel-button="true"
    :show-submit-button="canUserUpgrade"
    @close="$emit('close')"
    @submit="goToStripe"
    :cancel-text="cancelText"
    submit-text="Upgrade"
    :close-on-outside-click="closeOnOutsideClick"
  >
    <div class="container">
      <p v-if="canUserUpgrade">
        {{ upgradableText }}
      </p>
      <p v-else>{{ notUpgradableText }}</p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import config from "@/apis/config.json";

export default {
  name: "UpgradeSubscriptionModal",
  components: { Modal },
  props: {
    closeOnOutsideClick: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "Close",
    },
    title: {
      type: String,
      default: "You don't have access to this feature",
    },
    upgradableText: {
      type: String,
      default: "Upgrade your workspace to get access to this feature",
    },
    notUpgradableText: {
      type: String,
      default: "Contact your administrator",
    },
  },
  methods: {
    goToStripe() {
      window.location.assign(this.stripeCustomerPortalLink);
    },
  },
  computed: {
    canUserUpgrade() {
      return this.$auth.user.owner || this.$auth.user.admin;
    },
    stripeCustomerPortalLink() {
      return config.stripe.customerPortalLink;
    },
  },
};
</script>
