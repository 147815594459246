<template>
  <div>
    <router-view :key="$route.path" />
    <UpgradeSubscriptionModal
      v-if="showUpgradeSubscriptionModal"
      @close="closeUpgradeModal"
      :show-close-button="allowUpgradeModalDismissal"
      :cancel-text="allowUpgradeModalDismissal ? 'Close' : 'Back'"
      :close-on-outside-click="allowUpgradeModalDismissal"
      :title="upgradeModalTitle"
      :upgradable-text="upgradeModalUpgradableText"
      :not-upgradable-text="upgradeModalNotUpgradableText"
    />
    <SubscriptionInactiveModal v-if="isSubscriptionSuspended()" />
    <notifications position="top center" />
  </div>
</template>

<script>
import changeTheme from "./utils/changeTheme";
import UpgradeSubscriptionModal from "@/components/ui/Modals/UpgradeSubscriptionModal.vue";
import eventBus, { channels } from "@/eventBus";
import SubscriptionInactiveModal from "@/components/ui/Modals/SubscriptionInactiveModal.vue";

export default {
  components: { SubscriptionInactiveModal, UpgradeSubscriptionModal },
  data() {
    return {
      showUpgradeSubscriptionModal: false,
      allowUpgradeModalDismissal: true,
      upgradeModalTitle: undefined,
      upgradeModalUpgradableText: undefined,
      upgradeModalNotUpgradableText: undefined,
    };
  },
  computed: {
    theme() {
      return this.$store.getters.theme;
    },
    logo() {
      return this.$store.getters.logo;
    },
  },
  methods: {
    closeUpgradeModal() {
      this.showUpgradeSubscriptionModal = false;
      if (!this.allowUpgradeModalDismissal) {
        if (this.isSubscriptionExceedingSeats())
          return this.$router.push({ name: "settings-team" });

        if (window.history.length > 1) return this.$router.go(-1);

        return this.$router.push("/");
      }
    },
  },
  watch: {
    theme() {
      changeTheme(this.$store.getters.theme);
    },
    logo() {
      const favicon = document.getElementById("favicon");
      if (!favicon) return;
      favicon.setAttribute("href", this.logo);
    },
  },
  mounted() {
    eventBus.$on(channels.upgradeSubscription, async (settings) => {
      this.allowUpgradeModalDismissal = !!settings?.allowDismissal;
      this.upgradeModalTitle = settings?.title;
      this.upgradeModalUpgradableText = settings?.upgradableText;
      this.upgradeModalNotUpgradableText = settings?.notUpgradableText;
      this.showUpgradeSubscriptionModal = true;
    });
  },
  beforeDestroy() {
    eventBus.$off(channels.upgradeSubscription);
  },
};
</script>
