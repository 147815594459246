<template>
  <div>
    <div class="pattern">
      <span class="red"></span>
      <span class="indigo"></span>
      <span class="blue"></span>
      <span class="green"></span>
      <span class="orange"></span>
    </div>
    <div class="auth-main particles_js">
      <div class="auth_div vivify popIn">
        <!-- Spinner while creating -->
        <div class="card" style="margin-bottom: 300px">
          <div class="body">
            <p class="lead">Your email needs to be verified</p>
            <br />
            <p>We sent you a verification email from Auth0.</p>
            <p>
              Click on the <em>'Verify Your Account'</em> button in the email,
              then on the link below.
            </p>
            <p>
              <a class="btn btn-primary btn-round" :href="`/?email=` + email"
                >Take me to my Portal</a
              >
            </p>
          </div>
        </div>
        <div class="loader-wrapper">
          <div class="loader">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
            <div class="bar4"></div>
            <div class="bar5"></div>
          </div>
        </div>
      </div>
      <div id="particles-js"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaitEmailVerification",
  data() {
    return {
      email: this.$route.query.email,
    };
  },
  mounted: function () {
    document.title = "Planeolo - Welcome";
    window.onWrapperMounted();
  },
};
</script>
