<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Custom Domain</h2>
          </div>
          <div class="body">
            <h6>Select your workspace address</h6>
            <div class="btn-group mr-2">
              <button
                type="button"
                :class="{
                  btn: true,
                  'btn-default': !isSubDomainSelected,
                  'btn-primary': isSubDomainSelected,
                }"
                class="btn-round"
                @click="toggleMode('sub_domain')"
              >
                Sub domain
              </button>
              <button
                type="button"
                :class="{
                  btn: true,
                  'btn-default': isSubDomainSelected,
                  'btn-primary': !isSubDomainSelected,
                }"
                class="btn-round"
                @click="toggleMode('custom_domain')"
              >
                Custom domain
              </button>
            </div>
            <div
              class="col-lg-6 col-md-6 mt-4"
              :class="{
                disabledComponent: !isCustomDomainAvailable,
              }"
            >
              <div v-if="isSubDomainSelected" class="col-lg-8 col-md-8 mt-4">
                <form-input-group
                  :field="subDomainModal"
                  v-model="tenantInfo.domain"
                  :disabled="true"
                />
              </div>
              <div v-else>
                <p class="title-info">
                  Customize the domain of the entire app (e.g. instead of
                  yourcompany.planeolo.com, use portal.yourcompany.com)
                </p>
                <p class="mt-2 sub-title">
                  To set a custom domain for your account, you first need to add
                  a CNAME record to your domain's DNS configuration and point it
                  to Planeolo's host. This does not work if you host on Netlify
                </p>
                <div>
                  <a
                    href="https://help.planeolo.com/customer-portal/article/set-your-custom-domain"
                    target="_blank"
                    >Learn how to set up your custom domain</a
                  >
                </div>
                <div class="col-lg-12 col-md-12 mt-4">
                  <template
                    v-if="!tenantInfo.custom_domains && !isCustomDomainAddClick"
                  >
                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td style="width: 150px">
                              <form-input-group
                                v-model="host"
                                :field="fields[0]"
                                class="flex-grow-1"
                              />
                            </td>
                            <td style="width: 250px">
                              <form-input-group
                                v-model="inputDomain"
                                :field="fields[1]"
                                class="flex-grow-1"
                              />
                            </td>
                            <td style="width: 350px">
                              <form-input-group
                                v-model="inputCustomDomain"
                                :field="fields[2]"
                                class="flex-grow-1"
                                :disabled="true"
                              >
                                <template slot="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    title="Add Domain"
                                    @click="addCustomDomain"
                                    :disabled="isSubscriptionReadOnly()"
                                  >
                                    <span class="sr-only">Add Domain</span>
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </template>
                              </form-input-group>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <template
                    v-else-if="
                      tenantInfo.custom_domains || isCustomDomainAddClick
                    "
                  >
                    <div class="col-lg-8 col-md-12 col-sm-12 mt-4">
                      <form-input-group
                        :value="tenantInfo.custom_domains || inputCustomDomain"
                        :field="fields[3]"
                        class="flex-grow-1"
                        :disabled="true"
                      >
                        <template slot="input-group-append">
                          <button
                            type="button"
                            class="btn btn-danger"
                            title="Delete Domain"
                            @click="shouldShowWarningModal = true"
                          >
                            <span class="sr-only">Delete Domain</span>
                            <i class="fa fa-minus"></i>
                          </button>
                        </template>
                      </form-input-group>
                      <div class="header mt-4 pb-0">
                        <h2 style="font-weight: 800">Instructions:</h2>
                        <p style="color: #747474">
                          Add these CNAME records to your DNS configuration
                        </p>
                        <div class="table-responsive">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td style="width: 150px">
                                  <form-input-group
                                    :value="
                                      tenantInfo.custom_domains?.split(
                                        '.'
                                      )[0] || host
                                    "
                                    :field="fields[4]"
                                    ref="inputToCopyHost"
                                    class="flex-grow-1"
                                  >
                                    <template slot="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-outline"
                                        title="Add Domain"
                                        @click="
                                          copyToClipboard('inputToCopyHost')
                                        "
                                      >
                                        <span class="sr-only">Add Domain</span>
                                        <i
                                          class="fa fa-copy"
                                          style="color: #bcb9bf"
                                        ></i>
                                      </button>
                                    </template>
                                  </form-input-group>
                                </td>
                                <td style="width: 350px">
                                  <form-input-group
                                    v-model="cnameValue"
                                    :field="fields[5]"
                                    ref="inputToCopyHostValue"
                                    class="flex-grow-1"
                                  >
                                    <template slot="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-outline"
                                        title="Add Domain"
                                        @click="
                                          copyToClipboard(
                                            'inputToCopyHostValue'
                                          )
                                        "
                                      >
                                        <span class="sr-only">Add Domain</span>
                                        <i
                                          class="fa fa-copy"
                                          style="color: #bcb9bf"
                                        ></i>
                                      </button>
                                    </template>
                                  </form-input-group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-4"
                      style="
                        background-color: #f7f8fa;
                        border-radius: 2px;
                        border: 1px solid #dde4ea;
                      "
                    >
                      <div class="header p-2">
                        <h2 class="py-1" style="font-weight: 800">Note:</h2>
                        <p class="sub-title">
                          Be aware that it may take up to 48 hours for your DNS
                          updates to be fully distributed across the Internet.
                          You can exit this page.
                        </p>
                      </div>
                    </div>
                    <div class="align-right mt-4">
                      <button
                        type="button"
                        @click="shouldShowWarningModal = true"
                        class="btn btn-danger btn-sm"
                        title="Remove"
                      >
                        <span class="sr-only">Remove</span>
                        Remove
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionConfirmModal
      v-if="shouldShowWarningModal"
      :title="deleteModalProps.title"
      :text="deleteModalProps.text"
      :submitText="deleteModalProps.submitText"
      @close="shouldShowWarningModal = false"
      @submit="
        () => {
          deleteCustomDomain();
        }
      "
    />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputGroup from "../../components/ui/FormInputGroup.vue";
import ActionConfirmModal from "../../components/ui/Modals/ActionConfirmModal.vue";
import { updateCustomDomain } from "../../apis/app";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "CustomDomainPage",
  components: {
    WrapperPage,
    FormInputGroup,
    ActionConfirmModal,
  },
  data() {
    return {
      isSubDomainSelected: true,
      subDomainModal: { label: "Workspace URL", name: "name" },
      host: "",
      inputDomain: "",
      isCustomDomainAddClick: false,
      hostName: "",
      cnameValue: "planeolo.netlify.app",
      shouldShowWarningModal: false,
      deleteModalProps: {
        submitText: "Remove",
        title: "Are you sure you want to remove your custom domain?",
        text: `<div>
                <p>By resetting the custom domain, you will:</p>
                <ul>
                  <li>Disconnect the whitelabeled domain from this Planeolo.</li>
                  <li>Revert back to the default domain provided by Planeolo</li>
                </ul>
                <p>This action may impact your clients who access the web app through the custom domain.</p>
              </div>`,
      },
      fields: [
        { label: "Host", name: "host" },
        { label: "Your domain", name: "your_domain" },
        { label: "Workspace URL", name: "workspace_url" },
        { label: "Setup for custom domain", name: "setup_custom_domain" },
        { label: "Host / Name", name: "host_name" },
        { label: "Value", name: "value" },
      ],
      tenantInfo: {},
    };
  },
  computed: {
    inputCustomDomain() {
      if (this.host || this.inputDomain) {
        return this.host + "." + this.inputDomain;
      }
      return "";
    },
    isCustomDomainAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
  },
  methods: {
    toggleMode(value) {
      if (value === "sub_domain") this.isSubDomainSelected = true;
      else this.isSubDomainSelected = false;
    },
    async addCustomDomain() {
      const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Regular expression to validate abc.com format

      if (!this.host || !this.inputDomain) {
        this.notifyError("Please enter both host and domain values.");
        return;
      }
      if (!domainPattern.test(this.inputDomain)) {
        this.notifyError("Invalid domain format.");
        return;
      }
      const response = await updateCustomDomain(this.inputCustomDomain);
      if (response) {
        this.tenantInfo.custom_domains = response.custom_domains;
        this.isCustomDomainAddClick = true;
        this.notifySuccess("Success");
      }
    },
    async deleteCustomDomain() {
      await updateCustomDomain(null);
      this.tenantInfo.custom_domains = null;
      this.isSubDomainSelected = true;
      this.isCustomDomainAddClick = false;
      this.shouldShowWarningModal = false;
    },
    async copyToClipboard(refName) {
      const inputGroup = this.$refs[refName];

      if (inputGroup && inputGroup.$refs.input) {
        const inputToCopy = inputGroup.$refs.input;
        try {
          inputToCopy.focus();
          inputToCopy.select();
          await navigator.clipboard.writeText(inputToCopy.value);
          this.notifySuccess("Text copied to clipboard");
        } catch (err) {
          console.error("Unable to copy text: ", err);
        }
      }
    },
  },
  async mounted() {
    if (!this.isCustomDomainAvailable)
      eventBus.$emit(channels.upgradeSubscription, { allowDismissal: false });

    this.tenantInfo = await this.getTenantInfo();
    if (this.tenantInfo.custom_domains) {
      this.isSubDomainSelected = false;
    }
  },
};
</script>
<style scoped>
.sub-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #747474;
}
.title-info {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #747474;
}
</style>
