<template>
  <div class="star-rating">
    <label
      v-for="index in currentRating"
      class="fa fa-star ml-2"
      :key="`f${index}`"
      :style="{ fontSize: dynamicFontSize, color: '#FDD836' }"
      @click="setRating(index)"
    ></label>
    <label
      v-for="index in maxRating - currentRating"
      :class="[
        'fa',
        'ml-2',
        { 'fa-star': readyOnly == true, 'fa-star-o': readyOnly === false },
      ]"
      :key="`h${index + currentRating}`"
      :style="{
        fontSize: dynamicFontSize,
        color: '#D8D8D8',
      }"
      @click="setRating(index + currentRating)"
    ></label>
  </div>
</template>

<script>
export default {
  name: "dynamicStarRating",
  props: {
    dynamicFontSize: {
      type: String,
      default: "1.5rem", // Set the default font size to "text-large"
    },
    maxRating: {
      type: Number,
      required: true,
    },
    initialRating: {
      type: Number,
      default: 0,
    },
    readyOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRating: 0,
    };
  },
  computed: {
    currentRating() {
      if (this.selectedRating) return this.selectedRating;
      return this.initialRating;
    },
  },
  methods: {
    setRating(rating) {
      if (!this.readyOnly) {
        this.selectedRating = rating;
        this.$emit("rated", rating);
      }
    },
  },
};
</script>
