<template>
  <div class="card">
    <div class="header">
      <h2>{{ "Client Analytics" }}</h2>
    </div>
    <div class="row clearfix">
      <div class="col-lg-3 col-md-6" style="padding: 0 5px">
        <number-card
          label="In Production"
          :value="`${this.projectsInProduction} projects`"
          iconBgColor="indigo"
          icon="fa-circle-o-notch fa-spin"
        ></number-card>
      </div>
      <div class="col-lg-3 col-md-6" style="padding: 0 5px">
        <number-card
          label="Awaiting Feedback"
          :value="`${projectsAwaitingFeedback} projects`"
          iconBgColor="azura"
          icon="icon-hourglass"
        ></number-card>
      </div>
      <div class="col-lg-3 col-md-6" style="padding: 0 5px">
        <number-card
          label="Total active projects"
          :value="`${projectsActive} projects`"
          iconBgColor="orange"
          icon="icon-layers"
        ></number-card>
      </div>
      <div class="col-lg-3 col-md-6" style="padding: 0 5px">
        <number-card
          label="Average completion duration"
          :value="`${averageCompletionDuration} days`"
          iconBgColor="pink"
          icon="icon-clock"
        ></number-card>
      </div>
    </div>
    <div class="row clearfix" style="justify-content: right">
      <div
        class="col-lg-3 col-md-6 col-xs-6 mb-2 align-right"
        style="max-width: 270px"
      >
        <div class="body align-right" style="padding: 10px; border: none">
          <button
            class="btn btn-sm btn-outline-secondary mr-1 p-2"
            :class="{ active: selectedButton === '1month' }"
            id="one_month"
            @click="handleTimePeriodClick(1, 'month')"
          >
            1M
          </button>
          <button
            class="btn btn-sm btn-outline-secondary mr-1 p-2"
            :class="{ active: selectedButton === '6month' }"
            id="six_months"
            @click="handleTimePeriodClick(6, 'month')"
          >
            6M
          </button>
          <button
            class="btn btn-sm btn-outline-secondary mr-1 p-2"
            :class="{ active: selectedButton === '1year' }"
            id="one_year"
            @click="handleTimePeriodClick(1, 'year')"
          >
            1Y
          </button>
          <button
            class="btn btn-sm btn-outline-secondary mr-1 p-2"
            :class="{ active: selectedButton === '1ytd' }"
            id="ytd"
            @click="handleTimePeriodClick(1, 'ytd')"
          >
            YTD
          </button>
          <button
            class="btn btn-sm btn-outline-secondary p-2"
            :class="{ active: selectedButton === '1all' }"
            id="all"
            @click="handleTimePeriodClick(1, 'all')"
          >
            ALL
          </button>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-6 col-md-12">
        <div class="header">
          <h2>{{ "Average CSAT star rating" }}</h2>
        </div>
        <div class="body">
          <CSATAverageRatingChart
            :timeRange="timeRange"
            :projectsSurveysAverageRating="projectsSurveysAverageRating"
            :projects_stats="projects_stats"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="header">
          <h2>{{ "Average project health over time" }}</h2>
        </div>
        <div class="body">
          <AverageProjectHealthChart
            :timeRange="timeRange"
            :projects_stats="projects_stats"
          />
        </div>
      </div>
    </div>
    <div class="row clearfix mt-4">
      <div class="col-lg-6 col-md-12">
        <div class="header">
          <h2>{{ "Average Project days breakdown" }}</h2>
        </div>
        <div class="body">
          <AverageProjectDaysBreakdown
            :timeRange="timeRange"
            :projects_stats="projects_stats"
            :projects="clientProjects"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="header">
          <h2>{{ "Active Projects over time" }}</h2>
        </div>
        <div class="body">
          <AverageProjectProductionChart
            :timeRange="timeRange"
            :projects_stats="projects_stats"
            :projects="clientProjects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjectsStats } from "../../apis/projects";
import CSATAverageRatingChart from "../../components/ui/ClientCharts/CSATAverageRatingChart.vue";
import AverageProjectHealthChart from "../../components/ui/ClientCharts/AverageProjectHealthChart.vue";
import AverageProjectProductionChart from "../../components/ui/ClientCharts/AverageProjectProductionChart.vue";
import AverageProjectDaysBreakdown from "../../components/ui/ClientCharts/AverageProjectDaysBreakdown.vue";
import NumberCard from "../../components/ui/NumberCard.vue";
import { isWorkspaceStarterLevel } from "@/utils/helper";
import eventBus, { channels } from "@/eventBus";

export default {
  name: "ClientAnalytics",
  components: {
    NumberCard,
    CSATAverageRatingChart,
    AverageProjectHealthChart,
    AverageProjectProductionChart,
    AverageProjectDaysBreakdown,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    projectsSurveysAverageRating: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      projects_stats: [{}],
      clientId: Number(this.$route.params.clientId),
      timeRange: [],
      selectedButton: "all",
    };
  },
  computed: {
    isClientAnalyticsAvailable() {
      return isWorkspaceStarterLevel(this.$auth.tenant.subscription);
    },
    clientProjects() {
      return this.projects.slice().sort((a, b) => {
        return new Date(a.created_on) - new Date(b.created_on);
      });
    },
    projectsInProduction() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status === "in_progress"
      )?.length;
    },
    projectsAwaitingFeedback() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status === "awaiting_feedback"
      ).length;
    },
    projectsActive() {
      return this.projects_stats.filter(
        (ps) => !ps.archived && ps.status !== "completed"
      ).length;
    },
    averageCompletionDuration() {
      if (this.projects_stats.length === 0) return 0;
      let projects = 0;
      let total_days = this.projects_stats.reduce((accumulator, current) => {
        if (current.status) {
          // Can be NULL, else, consider projects in any state
          projects++;
          return accumulator + current.production_duration;
        }
        return accumulator;
      }, 0);
      if (projects === 0) return 0;
      return Math.round((total_days * 2) / projects) / 2; // Round to nearest .5
    },
  },
  methods: {
    handleTimePeriodClick: function (time, type) {
      this.selectedButton = time + type;
      if (!time || type === "all") {
        this.timeRange = [];
        return;
      }

      const today = new Date();
      today.setHours(23, 59, 59, 999);

      const startDate = new Date();

      if (type === "day") {
        startDate.setDate(today.getDate() - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "month") {
        const currentMonth = startDate.getMonth();
        startDate.setMonth(currentMonth - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "year") {
        const currentYear = startDate.getFullYear();
        startDate.setFullYear(currentYear - parseInt(time, 10));
        startDate.setHours(0, 0, 0, 0);
      } else if (type === "ytd") {
        startDate.setMonth(0);
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);
      }
      this.timeRange = [startDate, today];
    },

    getAllStats: async function () {
      try {
        this.isLoadingStats = true;
        const allProjects = await getProjectsStats();
        if (allProjects) {
          // Filter projects based on client_id and sort by start_date before assigning
          this.projects_stats = allProjects
            .filter((project) => project.client_id === this.clientId)
            .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        }
      } catch (err) {
        this.notifyError(err);
      }
      this.isLoadingStats = false;
    },
  },
  async mounted() {
    if (!this.isClientAnalyticsAvailable)
      eventBus.$emit(channels.upgradeSubscription, { allowDismissal: false });

    await this.getAllStats();
  },
};
</script>

<style></style>
