<template>
  <div class="row clearfix">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-hover table-custom spacing8">
          <thead>
            <tr>
              <!-- <th>#</th> -->
              <th>Client</th>
              <th>Project Code</th>
              <th>Project Name</th>
              <th>Current Stage</th>
              <th>Project Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="model in projects" :key="model.id">
              <td>
                <router-link
                  :to="{
                    name: 'client-details',
                    params: { clientId: model.client_id },
                  }"
                  :title="model.client_name"
                  >{{ model.client_name }}</router-link
                >
              </td>
              <td>{{ model.reference_code }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'project-timeline',
                    params: { projectId: model.id },
                  }"
                  :title="model.project_name"
                  >{{ model.project_name.substring(0, 20) }}</router-link
                >
              </td>
              <td>{{ model.current_stage }}</td>
              <td>
                <span v-if="model" :class="cellClass_ProjectStatus(model)">{{
                  cellText_ProjectStatus(model)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import workflowMixin from "../../../mixins/workflow";

export default {
  name: "DashboardFeedbackTable",
  mixins: [workflowMixin],
  props: {
    projects: Array,
  },
  data() {
    return {};
  },
  async created() {},
  methods: {
    cellText_ProjectStatus: function (project) {
      switch (project.status) {
        case "awaiting_feedback":
          if (project.isOverdueFeedback === true) {
            return "Overdue Feedback";
          } else {
            return "Awaiting Feeback";
          }
        case "completed":
          return "Completed";
        default:
          return "In Production";
      }
    },
    cellClass_ProjectStatus: function (project) {
      switch (project.status) {
        case "awaiting_feedback":
          return "badge xs badge-primary";
        case "in_progress":
          return "badge xs badge-info";
        default:
          return "badge xs badge-success";
      }
    },
  },
};
</script>
