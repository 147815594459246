<template>
  <wrapper-page>
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-right hidden-xs" v-if="showAsGrid">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search projects..."
            @input="search($event.target.value)"
          />
        </div>
      </div>
    </template>

    <div class="row clearfix">
      <div class="col-sm-12">
        <div class="card" v-if="isProjectsFetched && filteredProjects?.length">
          <projects-grid :projects="filteredProjects" v-if="showAsGrid" />
          <projects-table
            v-else
            :projects="filteredProjects"
            :showSearchBar="false"
            identifier="projects-js-data-table"
          />
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-12">
        <div class="card" v-if="!filteredProjects?.length && isProjectsFetched">
          <div
            class="body d-flex flex-column align-items-center justify-content-center"
            style="height: calc(100vh - 185px)"
          >
            <p class="text-center" style="font-size: 18px">
              You have no
              {{ archived ? "archived" : "active" }} projects.
            </p>
            <button
              v-if="$auth.user.isAgent"
              type="button"
              class="btn btn-primary mt-4"
              title="Add Template"
              @click="addProject"
            >
              <i class="fa fa-plus"></i>
              <span> Create new project</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
const { DateTime } = require("luxon-business-days");
import { surveysStatus } from "@/apis/survey";
import {
  filterProjects,
  getLastClickedTracklinkForProjectsList,
  getProjects,
} from "../apis/projects";
import { getAllWorkflows } from "../apis/workflows";
import WrapperPage from "../components/layout/WrapperPage.vue";
import ProjectsGrid from "../components/ui/ProjectsGrid.vue";
import ProjectsTable from "../components/ui/ProjectsTable.vue";
import timeMixin from "../mixins/time";

export default {
  name: "ListProjectsPage",
  components: {
    WrapperPage,
    ProjectsTable,
    ProjectsGrid,
  },
  mixins: [timeMixin],
  props: {
    archived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projects: [],
      filterString: "",
      showAsGrid: false,
      isProjectsFetched: false,
    };
  },
  async mounted() {
    await this.refresh();
  },
  computed: {
    filteredProjects: function () {
      return filterProjects(this.projects, this.filterString);
    },
  },
  methods: {
    addProject() {
      this.$router.push({
        path: "/projects/create",
      });
    },
    refresh: async function () {
      try {
        let projects = await getProjects(this.archived);
        let surveyStatus;
        if (!this.$auth.user.isAgent) {
          surveyStatus = await surveysStatus();
          await this.loadSurveyStatusInProject(projects, surveyStatus);
        }
        // Create temp props before we make additional calls to show projects on screen asap
        projects.forEach((p) => {
          p.workflow = null;
          p.lastClicked = "";
        });
        this.projects = projects;
        await this.loadWorkflowsInProjects(projects); // Await this so that datatables can search in Status and Current stage too
        await this.loadLastClicked(projects);
        this.isProjectsFetched = true;
      } catch (error) {
        console.log("Error loading project list or survey status", error);
      }
    },
    search: function (searchString) {
      this.filterString = searchString;
    },
    async loadWorkflowsInProjects(projects) {
      // Fetch all workflows for the loaded projects
      const wfIds = projects.reduce(function (accumulator, curProject) {
        if (
          curProject.workflow_id &&
          accumulator.indexOf(curProject.workflow_id) == -1
        ) {
          accumulator.push(curProject.workflow_id);
        }
        return accumulator;
      }, []);
      if (wfIds.length > 0) {
        let workflows = await getAllWorkflows("id in (" + wfIds.join() + ")");
        projects.forEach((project) => {
          if (project.workflow_id.includes(",")) {
            project.workflows = workflows.filter((wf) =>
              project.workflow_id.split(",").includes(`${wf.id}`)
            );
          } else {
            project.workflow = workflows.find(
              (wf) => wf.id == project.workflow_id
            );
          }
        });
      }
    },
    async loadSurveyStatusInProject(projects, surveyStatus) {
      projects.forEach((project) => {
        const matchedSurveyStatus = surveyStatus.find(
          (status) => status.project_id === project.id
        );
        if (matchedSurveyStatus) {
          project.survey_completed = matchedSurveyStatus.survey_completed;
        } else {
          project.survey_completed = null; // Set to null if no survey status is found
        }
      });
    },
    /**
     * Loads information about the last time a user clicked the review link
     */
    async loadLastClicked(projects) {
      if (!projects || projects.length === 0) return;

      const projectIds = projects.map((proj) => proj.id);
      const tracklinks = await getLastClickedTracklinkForProjectsList(
        projectIds
      );

      projects.forEach((project) => {
        const tracklink = tracklinks.find(
          (link) => link.project_id === project.id
        );
        if (!tracklink || !tracklink.last_clicked) {
          project.lastClicked = "never";
          return;
        }

        if (tracklink.last_clicked) {
          const index = this.projects.findIndex((p) => p.id === project.id);

          if (index !== -1)
            this.$set(
              this.projects[index],
              "lastClicked",
              this.toWorkspaceDateTimeFormat(
                DateTime.fromSQL(tracklink.last_clicked)
              )
            );
        }
      });
    },
  },
  watch: {
    archived: function (new_val) {
      if (new_val) {
        this.$router.push({ path: "/projects-archived" });
      } else {
        this.$router.push({ path: "/projects" });
      }
    },
  },
};
</script>
