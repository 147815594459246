<template>
  <div class="row clearfix" style="margin: 0px; padding: 0px">
    <div class="col-lg-3 col-md-3 pl-1" style="padding: 0px">
      <div
        class="px-3"
        style="
          background-color: #f7f8fa;
          min-height: 400px;
          border: #e1e8ed solid 1px;
        "
      >
        <div class="header pt-2 pb-4">
          <h2><strong>Settings</strong></h2>
        </div>
        <div class="card">
          <form-input-group
            v-model="template.name"
            :field="
              activeTab === 'workflows'
                ? { label: 'Template title', name: 'name' }
                : { label: 'Checklist title', name: 'name' }
            "
            class="flex-grow-1"
            :showLabel="true"
          />
          <div class="">
            <label><strong>Builder</strong></label>
            <ul class="right_chat list-unstyled mb-0">
              <li v-if="activeTab === 'workflows'">
                <a
                  href="javascript:void(0);"
                  @click="appendStage"
                  :disabled="isSubscriptionReadOnly()"
                >
                  <div class="media">
                    <div class="avtar-pic w35 bg-primary">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                    <div class="media-body">
                      <span class="name">Add Stage</span>
                      <span class="message">No revisions</span>
                    </div>
                  </div>
                </a>
              </li>
              <li v-if="activeTab === 'workflows'" class="online">
                <a href="javascript:void(0);" @click="appendStageWithRevs">
                  <div class="media">
                    <div class="avtar-pic w35 bg-primary">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                    <div class="media-body">
                      <span class="name">Add Stage</span>
                      <span class="message">With revisions</span>
                    </div>
                  </div>
                </a>
              </li>
              <li v-else class="online">
                <a href="javascript:void(0);" @click="appendCheck">
                  <div class="media d-flex align-items-center">
                    <div class="avtar-pic w35 bg-primary">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                    <div class="media-body">
                      <span class="name">Add check</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-9">
      <div class="" style="min-height: 400px">
        <div
          class="chat-header clearfix header"
          style="padding-left: 0px; padding-right: 0px"
        >
          <div class="row clearfix">
            <div class="col-4">
              <h2 style="display: flex">
                <strong>{{
                  activeTab === "workflows" ? "Stages" : "Checklist"
                }}</strong>
                <div class="col-1 d-inline-block">
                  <ul class="header-dropdown dropdown">
                    <li class="dropdown">
                      <a
                        href="javascript:void(0);"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                      ></a>
                      <ul class="dropdown-menu">
                        <li v-if="isEditingWorkflow">
                          <a @click="isEditingWorkflow = false">{{
                            activeTab === "workflows"
                              ? "Reorder stages"
                              : "Reorder checklist"
                          }}</a>
                        </li>
                        <li v-if="!isEditingWorkflow">
                          <a @click="isEditingWorkflow = true">{{
                            activeTab === "workflows"
                              ? "Edit stages"
                              : "Edit checklist"
                          }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </h2>
            </div>
            <div v-if="activeTab === 'workflows'" class="col-2">
              <h2><strong>Revision</strong></h2>
            </div>
            <div v-if="activeTab === 'workflows'" class="col-2">
              <h2><strong>Allowed revisions</strong></h2>
            </div>
            <div
              v-if="activeTab === 'workflows' && !canReorder()"
              class="col-3"
            >
              <h2><strong>QC Checklist</strong></h2>
            </div>
            <div v-if="activeTab === 'workflows'" class="col-1"></div>
          </div>
        </div>
        <div class="dd nestable">
          <textarea
            ref="taWorkflowOrder"
            class="special-textarea"
            style="display: none"
            v-model="taWorkflowOrder"
          ></textarea>
          <!-- required to store nestable changes, i.e., the order of the stages -->
          <ol class="dd-list">
            <li
              class="dd-item"
              :data-id="index"
              v-for="(stage, index) in ActiveTemplate.stages"
              :key="index"
            >
              <div v-if="canReorder()" class="dd-handle">
                <div class="row clearfix">
                  <div class="col-4">
                    <span>{{ stage.title }}</span>
                  </div>
                  <div v-if="activeTab === 'workflows'" class="col-2">
                    <div>
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="!!Number(stage.revisions)"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-2" style="min-width: 7em; max-width: 7em">
                    <span>{{ stage.revisions }}</span>
                  </div>
                  <!-- <div class="col-2" style="min-width: 7em; max-width: 7em">
                    <span>{{ stage.revisions }}</span>
                  </div> -->
                  <div class="col-1">
                    <button
                      v-if="isEditingWorkflow"
                      @click="removeStage(index)"
                      type="button"
                      class="btn btn-danger btn-sm"
                      :title="
                        activeTab === 'workflows'
                          ? 'Remove stage'
                          : 'Remove check'
                      "
                      :disabled="isSubscriptionReadOnly()"
                    >
                      <span class="sr-only">{{
                        activeTab === "workflows"
                          ? "Remove stage"
                          : "Remove check"
                      }}</span>
                      <i class="icon-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="row clearfix">
                <!-- <div class="col-1 d-flex justify-content-end p-0"></div> -->
                <div class="col-4">
                  <form-input-group
                    :field="{
                      vlabel: `Stage ${index}`,
                      name: `stage-${index}`,
                    }"
                    v-model="stage.title"
                  />
                </div>
                <div v-if="activeTab === 'workflows'" class="col-2">
                  <div class="col-2">
                    <label class="switch">
                      <input
                        :id="`checkbox-${index}`"
                        type="checkbox"
                        :checked="!!Number(stage.revisions)"
                        @change="
                          ($event) => {
                            if (Number(stage.revisions) === 0) {
                              stage.revisions = 1;
                            } else stage.revisions = 0;
                          }
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div v-if="activeTab === 'workflows'" class="col-2">
                  <form-input-group
                    :field="{
                      name: `revisions-${index}`,
                      type: 'number',
                      min: 0,
                    }"
                    v-model="stage.revisions"
                    :disabled="stage.revisions == 0"
                  />
                </div>
                <div v-if="activeTab === 'workflows'" class="col-3">
                  <form-input-group
                    :field="qcChecklistFields"
                    :value="stage.data ? stage.data.qcChecklistId : null"
                    @input="
                      (newValue) => {
                        if (!stage.data) {
                          stage.data = {};
                        }
                        stage.data.qcChecklistId = newValue;
                      }
                    "
                  />
                </div>
                <div class="col-1">
                  <button
                    v-if="isEditingWorkflow"
                    @click="removeStage(index)"
                    type="button"
                    class="btn btn-danger btn-sm"
                    title="Remove Stage"
                    :disabled="isSubscriptionReadOnly()"
                  >
                    <span class="sr-only">Remove stage</span>
                    <i class="icon-trash"></i>
                  </button>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputGroup from "../FormInputGroup.vue";

export default {
  name: "WorkflowTemplatesEditor",
  components: { FormInputGroup },
  props: {
    template: {
      type: Object,
      default() {
        return {};
      },
    },
    activeTab: String,
    qcTemplates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isEditingWorkflow: true,
      taWorkflowOrder: "",
    };
  },
  computed: {
    ActiveTemplate() {
      return this.template;
    },
    qcChecklistFields() {
      const options = this.qcTemplates
        .filter((template) => template.id)
        .map((template) => ({
          text: template.name,
          value: template.id,
        }));
      options.push({
        text: "None",
        value: null,
      });
      return {
        type: "select",
        name: "qcChecklist",
        options,
      };
    },
  },
  methods: {
    validate: function () {
      if (!this.template) return "No workflow template";
      if (!this.template.stages) return "No stages";
      if (this.template.stages.length === 0) return "No stages";
      // No empty stage names
      if (
        this.template.stages.find(
          (stage) => !stage.title || stage.title.trim().length === 0
        )
      )
        return "Empty stage name";

      return true;
    },
    canReorder() {
      return !this.isEditingWorkflow;
    },
    appendStage: function () {
      const stage = {
        title: "Stage " + this.ActiveTemplate.stages.length,
        revisions: 0,
      };
      this.ActiveTemplate.stages.push(stage);
    },
    appendStageWithRevs: function () {
      const stage = {
        title: "Stage " + this.ActiveTemplate.stages.length,
        revisions: 1,
      };
      this.ActiveTemplate.stages.push(stage);
    },
    appendCheck: function () {
      const stage = {
        title: "New QC " + this.ActiveTemplate.stages.length,
      };
      this.ActiveTemplate.stages.push(stage);
    },
    removeStage: function (index) {
      this.ActiveTemplate.stages.splice(index, 1);
    },
    getOrderedWorkflow() {
      let newWorkflow = JSON.parse(JSON.stringify(this.template));
      if (
        typeof this.taWorkflowOrder === "string" &&
        this.taWorkflowOrder.length > 5 &&
        Array.isArray(JSON.parse(this.taWorkflowOrder))
      ) {
        newWorkflow.stages = [];
        JSON.parse(this.taWorkflowOrder).forEach((entry) => {
          if (this.template.stages[entry.id]) {
            newWorkflow.stages.push(this.template.stages[entry.id]);
          }
        });
      }

      return newWorkflow;
    },
  },
  watch: {
    isEditingWorkflow: function () {
      window.nestable();
    },
  },
};
</script>

<style scoped>
.chatapp_body {
  border-right: 0;
}
</style>
