<template>
  <Modal
    :title="title"
    :show-close-button="true"
    :show-submit-button="isShowSubmitButton"
    @submit="$emit('submit')"
    @close="$emit('close')"
    cancel-text="Go Back"
    :submit-text="submitText"
    :submitClass="submitClass"
    :close-on-outside-click="closeOnOutsideClick"
    :disable-submit-button="isSubscriptionReadOnly()"
  >
    <div class="container">
      <p v-html="text"></p>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";

export default {
  name: "ActionConfirmModal",
  components: { Modal },
  props: {
    title: {
      type: String,
      default: "Are you sure?",
    },
    text: {
      type: String,
      default: "This action cannot be undone. Proceed with caution.",
    },
    submitText: {
      type: String,
      default: "Delete",
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    isShowSubmitButton: {
      type: Boolean,
      default: true,
    },
    submitClass: {
      type: String,
      default: "btn-danger",
    },
  },
};
</script>
