<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Email Settings</h2>
          </div>

          <div class="body">
            <email-domain-verification-entry
              v-for="(domain, index) in emailSettings.domains"
              :key="`dom_${domain.domain}_${domain.is_verified}`"
              :domain="domain"
              @addDomain="addDomain(index, $event)"
              @updateDomainSettings="updateDomainSettings"
              @verifyDomain="verifyDomainDNS(index, $event)"
              @deleteDomain="deleteDomain"
            />
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import {
  addEmailAuthenticationDomain,
  getEmailSettings,
  verifyDomainDnsEntries,
  deleteEmailAuthenticationDomain,
  updateEmailDomainSettings,
} from "../../apis/email-settings";
import EmailDomainVerificationEntry from "../../components/ui/Settings/EmailDomainVerificationEntry.vue";

export default {
  name: "EmailSettingsPage",
  components: {
    WrapperPage,
    EmailDomainVerificationEntry,
  },
  data() {
    return {
      isLoadingDetails: false,
      isSavingDetails: false,
      emailSettings: { domains: [{ domain: "" }] },
    };
  },
  methods: {
    async addDomain(index, domain) {
      this.isSavingDetails = true;

      try {
        const dnsRecords = await addEmailAuthenticationDomain(domain);
        /*
        {
          "id": 17203674,
          "dns": {
            "mail_cname": {
              "valid": false,
              "type": "cname",
              "host": "em4811.g.co.uk",
              "data": "u15241543.wl044.sendgrid.net"
            },
            "dkim1": { ... },
            "dkim2": { ... }
          }
        }
        */
        // Find the editable one
        let newEntry = {
          domain,
          is_verified: false,
          dns: dnsRecords.dns,
        };
        this.emailSettings.domains[index] = newEntry;
        this.emailSettings.domains = [...this.emailSettings.domains];
      } catch (err) {
        this.notifyError(err, "Failed to save Email Settings");
      }
      this.isSavingDetails = false;
    },
    async verifyDomainDNS(index, domain) {
      this.isSavingDetails = true;
      try {
        const result = await verifyDomainDnsEntries(domain);
        if (result.verified) {
          this.emailSettings.domains[index].is_verified = true;
          this.notifySuccess(
            "Your DNS settings are correct. Your emails will no longer show 'SendGrid'",
            "Email Domain Authentication"
          );
        } else {
          this.notifyWarn(
            "Your DNS settings are not correct. Please double check.",
            "Email Domain Authentication"
          );
        }
      } catch (err) {
        this.notifyError(
          err,
          "An error occurred when Verifying the DNS settings"
        );
      }
      this.isSavingDetails = false;
    },
    async deleteDomain(domain) {
      if (
        confirm(
          "Do you want to remove this Domain authentication?\nYou should remove these entries from your own DNS as well."
        )
      ) {
        try {
          await deleteEmailAuthenticationDomain(domain);
          this.emailSettings.domains = this.emailSettings.domains.filter(
            (d) => d.domain !== domain
          );
          if (this.emailSettings.domains.length === 0) {
            this.emailSettings.domains = [{ domain: "" }];
          }
          this.notifySuccess(
            "Your Email Domain has been removed from our system.\nPlease remove the DNS entries from your own DNS settings as well.",
            "Email Domain Authentication",
            5000
          );
        } catch (err) {
          this.notifyError(
            "Sorry, we cannot do this right now.\nPlease notify Planeolo support so that we can handle this for you.",
            domain,
            5000
          );
        }
      }
    },
    async loadEmailSettings() {
      this.isLoadingDetails = true;
      this.emailSettings = await getEmailSettings();
      if (this.emailSettings.domains.length === 0) {
        this.emailSettings.domains = [{ domain: "" }];
      }
      this.isLoadingDetails = false;
    },
    updateDomainSettings(settings) {
      const payload = {
        domain: settings.domain,
        send_from_own_email: settings.isSendFromOwnEmail,
      };
      updateEmailDomainSettings(payload);
    },
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    if (!this.$auth.user.admin) this.$router.push("/");

    await this.loadEmailSettings();
  },
};
</script>
