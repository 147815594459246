<template>
  <div class="row clearfix">
    <!-- Search bar -->
    <div class="col-12" v-if="showSearchBar">
      <div class="card">
        <div class="body">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  @input="search($event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ref. Code"
                  @input="searchField('reference_code', $event.target.value)"
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Default Subject"
                  @input="
                    searchField(
                      'email_subject_line_default',
                      $event.target.value
                    )
                  "
                />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <a
                href="javascript:void(0);"
                class="btn btn-sm btn-primary btn-block"
                title=""
                >Search</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="table-responsive">
        <table
          :id="identifier"
          :class="[
            'table',
            'table-hover',
            'table-custom',
            'spacing8',
            'dataTable',
            identifier,
          ]"
        >
          <thead>
            <tr>
              <!-- <th>#</th> -->
              <th>Starting Date</th>
              <th v-if="$auth.user.isAgent && !isClientDetailsPage">Client</th>
              <th>Project Code</th>
              <th>Project Name</th>
              <th>Project Status</th>
              <th v-if="!isClientDetailsPage && !isArchivedProjects">
                Current Stage
              </th>
              <!-- <th v-if="$auth.user.isAgent">Stage revisions</th>
              <th v-if="$auth.user.isAgent">Exec./Total revs.</th> -->
              <th>Production Progress</th>
              <th v-if="$auth.user.isAgent && !isClientDetailsPage">
                Link Clicked
              </th>
              <th v-if="$auth.user.isAgent && !isClientDetailsPage">
                Settings
              </th>
              <th
                v-if="$auth.user.isAgent && isClientDetailsPage && isArchived"
              >
                Date completed
              </th>
              <th
                v-if="$auth.user.isAgent && isClientDetailsPage && isArchived"
              >
                Feedback rating
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredProjects?.length">
            <tr v-for="model in filteredProjects" :key="model.id">
              <td :data-sort="model.dateFieldValue">
                {{ model.dateField }}
              </td>
              <td v-if="$auth.user.isAgent && !isClientDetailsPage">
                {{ model.client_name }}
                <!-- <router-link :to="{name: 'client-details', params:{clientId:model.client_id}}" :title="clients[model.client_id].name">{{ clients[model.client_id].name }}</router-link> -->
              </td>
              <td>{{ model.reference_code }}</td>
              <td>
                <router-link
                  :to="{
                    name:
                      model.deliverable_type === 'Multi'
                        ? 'project-timelines'
                        : 'project-timeline',
                    params: { projectId: model.id },
                  }"
                  :title="model.name"
                  >{{ model.name }}</router-link
                >
                <img
                  v-if="model.deliverable_type === 'Multi'"
                  src="/assets/images/stack.svg"
                  class="ml-2"
                  style="height: 1rem; width: auto; margin-top: -2px"
                />
                <span class="ti-files"></span>
              </td>
              <td :data-order="model.statusFieldValue">
                <span :class="model.statusFieldClass">{{
                  model.statusField
                }}</span>
              </td>
              <td v-if="!isClientDetailsPage && !isArchivedProjects">
                {{ model.stageField }}
                <stage-quick-approve-or-reject
                  v-if="
                    $auth.user.isAgent &&
                    model.stageField !== 'Multiple stages' &&
                    model.stageField !== '--' &&
                    ['awaiting_feedback', 'overdue_feedback'].includes(
                      model?.revision?.status
                    )
                  "
                  class="ml-3"
                  :stage="model.stage"
                  @on_stage_approve_click="
                    onApproveStageClick(model.workflow, model.stage)
                  "
                  @on_stage_reject_click="
                    onRejectStageClick(model.workflow, model.stage)
                  "
                  :isProjectCancelled="Boolean(model.cancelled)"
                />
              </td>
              <!-- <td v-if="$auth.user.isAgent">
                {{ cellText_StageRevs(model.workflow) }}
              </td>
              <td v-if="$auth.user.isAgent">
                {{ cellText_CompletedRevs(model.workflow) }} / {{ cellText_TotalRevs(model.workflow) }}
              </td> -->
              <td :data-order="model.productionProgress">
                <progress-bar
                  :value="model.productionProgress"
                  :color="model.progressColor"
                  :isProjectCancelled="Boolean(model.cancelled)"
                />
              </td>
              <td v-if="$auth.user.isAgent && !isClientDetailsPage">
                {{ model.lastClicked }}
              </td>
              <td v-if="$auth.user.isAgent && !isClientDetailsPage">
                <router-link
                  :to="{
                    name: 'project-details',
                    params: { projectId: model.id },
                  }"
                  title="settings"
                  >Edit</router-link
                >
              </td>
              <td
                v-if="$auth.user.isAgent && isClientDetailsPage && isArchived"
              >
                {{ cellText_CurrentStage_Achived(model.workflow) }}
              </td>
              <td
                v-if="$auth.user.isAgent && isClientDetailsPage && isArchived"
              >
                <star-rating
                  v-if="model.is_survey_sent && model.isFeedbackSubmitted"
                  :dynamicFontSize="'1.2rem'"
                  :value="model.averageRating"
                  :readyOnly="true"
                ></star-rating>
                <div
                  v-else-if="
                    !model.is_survey_sent && !model.isFeedbackSubmitted
                  "
                >
                  CSAT not sent
                </div>
                <div
                  v-else-if="model.is_survey_sent && !model.isFeedbackSubmitted"
                >
                  Pending CSAT feedback
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import timeMixin from "../../mixins/time";
import workflowMixin from "../../mixins/workflow";
import { filterProjects } from "../../apis/projects";
import { approveStage, rejectStage } from "../../apis/workflows";
import ProgressBar from "./ProgressBar.vue";
import StageQuickApproveOrReject from "../projects/StageQuickApproveOrReject.vue";
import StarRating from "../../components/ui/Dashboard/StarRating.vue";

export default {
  components: { ProgressBar, StageQuickApproveOrReject, StarRating },
  name: "ProjectsTable",
  mixins: [workflowMixin, timeMixin],
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    showSearchBar: Boolean,
    isClientDetailsPage: {
      type: Boolean,
      default: false,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: "js-data-table",
    },
  },
  data() {
    return {
      filterString: "",
      fieldFilters: [],
      datatable: null,
      filteredProjects: [],
    };
  },
  computed: {
    isArchivedProjects() {
      return this.checkForArchived(this.$route.path);
    },
  },
  methods: {
    filterProjects: function () {
      let result = filterProjects(this.projects, this.filterString);

      this.fieldFilters.forEach((element) => {
        if (element.searchString) {
          result = result.filter((v) =>
            v?.[element.field]
              ?.toLowerCase()
              ?.includes(element.searchString.toLowerCase())
          );
        }
      });

      return result.map((r) => {
        const statusField = this.cellText_ProjectStatus(r);
        let workflow =
          r.workflow ??
          this.getCurrentWorkflow(r.workflows) ??
          r.workflows?.[0];
        const currentStage = this.getCurrentStage(workflow);

        return {
          ...r,
          workflow,
          dateField: this.formatDate(r.start_date || r.created_on),
          dateFieldValue: r.start_date || r.created_on,
          statusField,
          statusFieldValue: this.cellValue_ProjectStatus(statusField),
          statusFieldClass: this.cellClass_ProjectStatus(r),
          stageField: r?.workflows?.length
            ? "--"
            : this.cellText_CurrentStage(workflow),
          productionProgress: this.getProductionProgress(
            workflow,
            r.is_survey_sent,
            r.send_csat_survey,
            r.survey_completed
          ),
          lastClicked: r.lastClicked,
          progressColor: this.progressColor(r),
          stage: currentStage,
          revision: this.getCurrentStageRevision(currentStage),
        };
      });
    },
    checkForArchived(url) {
      return url.includes("/archived");
    },
    buildDataTable() {
      if (this.datatable) this.datatable.destroy();

      if (this.filteredProjects?.length) {
        this.datatable = true;
        setTimeout(() => {
          this.datatable = window.jsDataTable(`#${this.identifier}`, {
            columnDefs: [
              {
                targets:
                  this.$auth.user.isAgent && !this.isClientDetailsPage ? 4 : 3,
                type: "numeric",
              },
            ],
            order: [
              [
                this.$auth.user.isAgent && !this.isClientDetailsPage ? 4 : 3,
                "asc",
              ],
              [0, "desc"],
            ],
          });
        }, 1000); // Just giving Vue some time to render the table
      }
    },
    search: function (searchString) {
      this.filterString = searchString;
    },
    searchField: function (field, searchString) {
      let elem = this.fieldFilters?.find((f) => f.field === field);
      if (elem !== undefined) {
        elem.searchString = searchString;
      } else {
        this.fieldFilters.push({
          field,
          searchString,
        });
      }
    },

    formatDate: function (date) {
      return this.toWorkspaceDateTimeFormat(this.getLuxonDateTime(date, false));
    },
    isComplete(project) {
      const stages = this.getCurrentStages(project.workflow);
      if (!stages?.length || stages.every((s) => s.status === "completed")) {
        return true;
      }
      return false;
    },
    cellText_CurrentStage: function (workflow) {
      const stages = this.getCurrentStages(workflow);
      if (!stages?.length) return "--";

      if (stages.length === 1) return stages?.[0]?.title;

      return "Multiple stages";
    },
    cellText_CurrentStage_Achived: function (workflow) {
      const stage = this.getCurrentStage(workflow);
      if (!stage) {
        if (workflow?.tasks?.length) {
          const taskLength = workflow.tasks.length;
          const lastStage = workflow.tasks[taskLength - 1];
          return lastStage.approved_on
            ? this.formatDate(lastStage.approved_on)
            : "--";
        }
        return "--";
      }
      return "Not completed";
    },
    onRejectStageClick: async function (workflow, stage) {
      try {
        await rejectStage(workflow, stage);
        this.notifySuccess("Revision added");
      } catch (err) {
        this.notifyError(err, "Error on marking the stage as rejected");
        return;
      }
    },
    onApproveStageClick: async function (workflow, stage) {
      try {
        await approveStage(workflow, stage);
        this.notifySuccess("Stage approved");
      } catch (err) {
        this.notifyError(err, "Error on approving stage");
        return;
      }
    },
  },
  watch: {
    projects: function () {
      this.filteredProjects = this.filterProjects();
      this.buildDataTable();
    },
  },
  mounted() {
    this.filteredProjects = this.filterProjects();
    this.buildDataTable();
  },
  beforeDestroy() {
    if (this.datatable) this.datatable.destroy();
  },
};
</script>
