const config = require("./config.json");
const { get, create, remove, update } = require("./common").default;

const notesUrl = config.apis.base.url + "projects/";

/**
 * Add notes to a project.
 *
 * @param {string} projectId - The ID of the project to add notes to.
 * @param {object} notesPayload - The payload containing the notes data.
 */
exports.addNotes = async (projectId, notesPayload) => {
  return await create(notesUrl + projectId + "/notes", notesPayload);
};

/**
 * Get notes for a project.
 *
 * @param {string} projectId - The ID of the project to retrieve notes from.
 */
exports.getNotes = async (projectId) => {
  return await get(notesUrl + projectId + "/notes");
};

/**
 * Delete a note from a project.
 *
 * @param {string} projectId - The ID of the project containing the note.
 * @param {string} noteId - The ID of the note to be deleted.
 */
exports.deleteNote = async (projectId, noteId) => {
  return await remove(notesUrl + projectId + "/notes/" + noteId);
};

/**
 * Update a note in a project.
 *
 * @param {string} projectId - The ID of the project containing the note.
 * @param {string} noteId - The ID of the note to be updated.
 * @param {object} updatedNote - The updated note data.
 */
exports.updateNote = async (projectId, noteId, updatedNote) => {
  return await update(notesUrl + projectId + "/notes/" + noteId, updatedNote);
};
