<template>
  <div class="_text-warning text-muted">
    <label
      class="fa fa-star ml-2"
      v-for="index in wholeStarCount"
      :key="`w${index}`"
      :style="{ fontSize: dynamicFontSize, color: '#FDD836' }"
    ></label>
    <label
      class="fa fa-star-half-o ml-2"
      v-for="index in halfStarCount"
      :key="`h${index}`"
      :style="{ fontSize: dynamicFontSize, color: '#FDD836' }"
    ></label>
    <label
      :class="[
        'fa',
        'ml-2',
        { 'fa-star': readyOnly == true, 'fa-star-o': readyOnly === false },
      ]"
      v-for="index in emptyStarCount"
      :key="`e${index}`"
      :style="{ fontSize: dynamicFontSize, color: '#D8D8D8' }"
    ></label>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    dynamicFontSize: {
      type: String,
      default: "1.5rem", // Set the default font size to "text-large"
    },
    value: Number, // A percent value represented as a decimal between 0 & 1
    readyOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalStars: 5,
    };
  },
  computed: {
    wholeStarCount() {
      return Math.floor(this.value * this.totalStars);
    },
    halfStarCount() {
      return this.totalStars - this.wholeStarCount - this.emptyStarCount;
    },
    emptyStarCount() {
      return this.totalStars - Math.ceil(this.value * this.totalStars);
    },
  },
};
</script>
