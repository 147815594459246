<template>
  <div>
    <template v-if="!isLoadingDetails && client">
      <div class="row clearfix">
        <div :class="isCreating ? `col-lg-6 col-md-12` : `col-lg-6 col-md-12`">
          <div class="card">
            <div class="header">
              <h2>{{ isCreating ? "New Client" : this.client.name }}</h2>
            </div>
            <div class="body">
              <form
                ref="clientForm"
                @submit.prevent="submitClient"
                :disabled="isSubscriptionReadOnly()"
              >
                <div class="row clearfix">
                  <div class="col-lg-7 col-md-7">
                    <div class="row clearfix">
                      <div class="col-lg-12 col-md-12">
                        <form-input-group
                          v-for="model in mainDetails.slice(0, 1)"
                          :key="model.name"
                          :field="model"
                          v-model="client[model.name]"
                          :required="model.name == 'name'"
                        />
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div
                        v-for="model in mainDetails.slice(1, 2)"
                        :key="model.name"
                        class="col-lg-12 col-md-12"
                      >
                        <form-input-group
                          :field="model"
                          v-model="client[model.name]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-4 mt-5">
                    <div class="input-group">
                      <div class="d-flex flex-column align-items-center">
                        <ClickableImagePlaceholder
                          target="client-logo-file-input"
                          text="ADD LOGO"
                          :image="getLogoImage"
                          :disabled="isUploadingLogo"
                        />
                        <button
                          v-if="getLogoImage"
                          class="btn btn-sm btn-default btnRemoveLogo"
                          type="button"
                          @click="removeLogo"
                        >
                          Remove logo
                        </button>
                      </div>
                      <input
                        type="file"
                        class="d-none"
                        id="client-logo-file-input"
                        name="client-logo-file-input"
                        @change="loadClientLogoFile($event)"
                        accept="image/*"
                      />
                      <div
                        class="d-flex flex-column align-items-center ml-4 justify-content-center"
                      >
                        <small style="font-size: 10px; color: #747474">
                          Recommended size:<br />
                          250px by 250px
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Timezone -->
                <div class="row clearfix">
                  <div class="col-lg-7 col-md-7">
                    <div>
                      <b><label for="input-timezone">Timezone</label></b>
                      <div class="input-group mb-3">
                        <select
                          v-model="client['timezone']"
                          class="form-control"
                          name="timezone"
                          id="input-timezone"
                          aria-label="timezone"
                          aria-describedby="basic-addon1"
                          @input="(e) => $emit('input', e.target.value)"
                        >
                          <option
                            v-for="tz in timeZoneOptions"
                            :key="tz.value"
                            :value="tz.value"
                          >
                            {{ tz.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Language -->
                <div class="row clearfix">
                  <div class="col-lg-7 col-md-7">
                    <div>
                      <b><label for="input-language">Language</label></b>
                      <div class="input-group mb-3">
                        <select
                          v-model="client['language']"
                          class="form-control"
                          name="language"
                          id="input-language"
                          aria-label="language"
                          aria-describedby="basic-addon1"
                          @input="(e) => $emit('input', e.target.value)"
                        >
                          <option
                            v-for="l in languageOptions"
                            :key="l.value"
                            :value="l.value"
                          >
                            {{ l.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-lg-8 col-md-8">
                    <div>
                      <b
                        ><label for="average-rating"
                          >Client feedback average rating</label
                        ></b
                      >
                      <star-rating
                        :dynamicFontSize="'1.5rem'"
                        :value="allProjectAverageRating"
                        :readyOnly="true"
                      ></star-rating>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div :class="isCreating ? `col-lg-6 col-md-12` : `col-lg-6 col-md-12`">
          <div class="col text-right hidden-xs">
            <button
              v-on:click="submitForm"
              class="btn btn-primary"
              :disabled="isSubscriptionReadOnly()"
            >
              {{ isCreating ? "Create" : "Update" }} Client
            </button>
          </div>
        </div>
      </div>

      <contact-list-editor
        ref="contactsList"
        :contacts="contacts"
        title="Client Contacts"
      />
    </template>
    <div class="row clearfix pb-5">
      <div class="col-lg-6 col-md-6">
        <button
          v-on:click="submitForm"
          class="float-right btn btn-primary"
          :disabled="isSubscriptionReadOnly()"
        >
          {{ isCreating ? "Create" : "Update" }} Client
        </button>
      </div>
      <div v-if="!isCreating" class="col-lg-6 col-md-6">
        <button
          v-on:click="isDeleteClientClicked = true"
          class="float-right btn btn-danger"
          :disabled="isSubscriptionReadOnly()"
        >
          Delete
        </button>
      </div>
    </div>
    <CropImageModal
      v-if="showCropImageModal && newClientLogo"
      :file="newClientLogo"
      title="Crop Client Logo"
      :aspect-ratio="1 / 1"
      :width="400"
      :height="400"
      :is-saving="isUploadingLogo"
      @close="
        () => {
          this.clearLogoInput();
          this.clearClientLogoFile();
          this.showCropImageModal = false;
        }
      "
      @submit="
        (img) => {
          this.clearLogoInput();
          img.toBlob((blob) => {
            this.$set(this.newClientLogo, 'file', blob);
            this.showCropImageModal = false;
          });
        }
      "
    />
    <ActionConfirmModal
      v-if="isDeleteClientClicked"
      :title="deleteModalProps.title"
      :text="deleteModalProps.text"
      :submitText="deleteModalProps.submitText"
      @close="isDeleteClientClicked = false"
      @submit="
        () => {
          deleteClient();
          isDeleteClientClicked = false;
        }
      "
    />
  </div>
</template>

<script>
import FormInputGroup from "../../components/ui/FormInputGroup.vue";
import ContactListEditor from "../../components/ui/ContactListEditor.vue";
import StarRating from "../../components/ui/Dashboard/StarRating.vue";
import CropImageModal from "@/components/ui/Modals/CropImageModal.vue";
import {
  addClient,
  deleteClientById,
  updateClient,
  uploadClientLogo,
} from "@/apis/clients";
import usersMixin from "../../mixins/users";
import { timeZonesNames } from "@vvo/tzdb";
import { getMimeType } from "@/utils/mime";
import ClickableImagePlaceholder from "@/components/ui/ClickableImagePlaceholder.vue";
import ActionConfirmModal from "../../components/ui/Modals/ActionConfirmModal.vue";
import { LANGUAGES } from "@/utils/app.constant";

export default {
  name: "ClientDetails",
  components: {
    FormInputGroup,
    ContactListEditor,
    ClickableImagePlaceholder,
    StarRating,
    CropImageModal,
    ActionConfirmModal,
  },
  mixins: [usersMixin],
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    projectsSurveysAverageRating: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
    allProjectRating: Object,
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoadingDetails: false,
      isLoadingWorkflows: false,
      isSavingDetails: false,
      clientId: this.$route.params.clientId,
      isDeleteClientClicked: false,
      deleteModalProps: {
        submitText: "Delete client",
        title: "Are you sure you want to delete this client?",
        text: "By deleting this client, you will also permanently delete all associated projects. This action is irreversible!",
      },

      mainDetails: [
        { label: "Client Name", name: "name" },
        {
          label: "Client Owner",
          name: "agency_client_director",
          type: "select",
          options: [{ text: "Loading agents", value: -1 }],
        },
        //{ label: "Accounts receivable email address", name: "billing_email", type: "email", },
        { label: "Timezone", name: "timezone", type: "select" },
        { label: "Language", name: "language", type: "select" },
        // { label: "Client Logo", name: "image" },
      ],
      timeZonesNames,
      archivedProjects: [],
      activeTab: "active_projects",
      clientLogoField: {
        label: "Client Logo",
        name: "logo",
      },
      showCropImageModal: false,
      isUploadingLogo: false,
      newClientLogo: {
        name: null,
        file: null,
        src: null,
        type: null,
      },
    };
  },
  async created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  computed: {
    clientProjects() {
      return this.projects;
    },
    getLogoImage() {
      if (this.newClientLogo?.file) {
        return URL.createObjectURL(this.newClientLogo.file);
      } else if (this.newClientLogo?.src) {
        return this.newClientLogo?.src;
      } else {
        return this.client.image;
      }
    },
    allProjectAverageRating() {
      return (
        ((this.allProjectRating.totalAverageRating /
          this.allProjectRating.totalProject) *
          2) /
          10 || 0
      );
    },
    timeZoneOptions() {
      return this.timeZonesNames.map((tz) => ({
        value: tz,
        text: tz,
      }));
    },
    languageOptions() {
      return LANGUAGES.map((l) => ({
        value: l["1"],
        text: l.name,
      }));
    },
    isCreating() {
      return this.$route.params.clientId === "create";
    },
    projectToDisplay() {
      if (this.activeTab === "active_projects") {
        return this.projects;
      } else {
        return this.archivedProjects;
      }
    },
  },
  methods: {
    removeLogo() {
      this.$set(this.client, "image", "");
      this.clearClientLogoFile();
    },
    uploadLogoHelper() {
      this.showCropImageModal = false;
    },
    async uploadLogo(client) {
      if (this.isUploadingLogo || !this.newClientLogo?.file) return;

      try {
        this.isUploadingLogo = true;
        const result = await uploadClientLogo(client?.id, this.newClientLogo);
        client.image = result.path;
      } catch (uploadError) {
        this.handleError(uploadError, "Error uploading client logo");
      }

      try {
        client = await updateClient(client);
        this.$set(this.client, "image", client.image);
        this.notifySuccess("Client saved");
      } catch (updateError) {
        this.handleError(updateError, "Error updating client");
      } finally {
        this.isUploadingLogo = false;
        this.showCropImageModal = false;
      }
    },

    handleError(error, errorMessage) {
      this.notifyError(error, errorMessage);
      this.isUploadingLogo = false;
      this.showCropImageModal = false;
    },

    clearClientLogoFile() {
      if (this.newClientLogo.src) {
        URL.revokeObjectURL(this.newClientLogo.src);
      }

      this.newClientLogo = {
        name: null,
        file: null,
        src: null,
        type: null,
      };
    },
    clearLogoInput() {
      document.getElementById("client-logo-file-input").value = null;
    },
    loadClientLogoFile(event) {
      const { files } = event.target;

      if (files && files[0]) {
        if (this.newClientLogo.src) {
          URL.revokeObjectURL(this.newClientLogo.src);
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
          this.newClientLogo = {
            name: files[0].name,
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
          this.showCropImageModal = true;
        };

        reader.readAsArrayBuffer(files[0]);
      }
    },
    onProjectTabSwitch(selectedTab) {
      this.activeTab = selectedTab;
    },
    submitForm() {
      // We don't call submitClient() directly so that any form validations can be executed
      if (!this.$refs.clientForm.checkValidity()) {
        this.$refs.clientForm.requestSubmit();
        return;
      }
      if (!this.$refs.contactsList.checkValidity()) return;
      this.$refs.clientForm.requestSubmit();
    },
    async submitClient() {
      this.isSavingDetails = true;

      let client = { ...this.client };
      client.contacts = this.contacts.map((obj) => {
        delete obj.inviteStatus;
        return obj;
      });

      if (this.isCreating) {
        try {
          let result = await addClient(client);
          // result = The object sent with a new property; id
          if (result.id) {
            this.notifySuccess("Client saved");
            client.id = result.id;

            if (this.newClientLogo?.file) {
              try {
                // Upload the image and update the client with the image's path
                this.uploadLogo(client);
              } catch (err) {
                this.notifyError(err, "Client image upload");
              }
            }

            // We redirect anyone as client has been created, so next we 'update'
            this.$router.push({
              name: "client-details",
              params: { clientId: client.id },
            });
          } else {
            this.notifyError("Client not saved");
          }
        } catch (err) {
          this.notifyError(err, "Client not saved");
        }
      } else {
        try {
          client.id = this.clientId;
          if (this.newClientLogo?.file) {
            this.uploadLogo(client);
          } else {
            await updateClient(client);
            this.notifySuccess("Client saved");
          }
        } catch (err) {
          this.notifyError(err, "Client not saved");
        }
      }

      this.isSavingDetails = false;
    },
    async deleteClient() {
      try {
        if (!this.$auth.user?.admin) {
          this.notifySuccess("Insufficient Access Privileges");
          return;
        }
        await deleteClientById(this.client.id);
        this.notifySuccess("Client deleted: " + this.client.name);
        this.$router.push("/clients/");
      } catch (err) {
        this.notifyError(err, "Error: Client Delete");
      }
    },
    addProject() {
      this.$router.push({
        path: "/projects/create",
        query: { client: this.client?.id },
      });
    },
  },
  destroyed() {
    if (this.newClientLogo?.src) {
      URL.revokeObjectURL(this.newClientLogo.src);
    }
  },
};
</script>
