<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Workflow Templates</h2>
          </div>

          <div class="body">
            <div class="tab-bar-container body p-0" style="border: none">
              <div class="tab-bar-scroll">
                <ul class="nav nav-tabs">
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'workflows' }"
                      @click="setActiveTab('workflows')"
                    >
                      Workflows
                    </a>
                  </li>
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: activeTab === 'qc_checklist' }"
                      @click="setActiveTab('qc_checklist')"
                    >
                      QC checklists
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-3 col-md-3" v-if="selectedTabTemplates.length">
                <b v-if="activeTab === 'workflows'"
                  ><label class="pt-4" id="linear_workflow_templates"
                    >Linear workflow templates</label
                  ></b
                >
                <b v-else
                  ><label class="pt-4" id="qc_checklist_templates"
                    >QC checklist templates</label
                  ></b
                >
              </div>
              <div class="col-lg-9 col-md-9"></div>
            </div>
            <div class="row clearfix" v-if="selectedTabTemplates.length">
              <div class="" style="width: 260px">
                <ul class="list-group">
                  <li
                    v-for="(template, index) in selectedTabTemplates"
                    :key="template.title"
                    :class="[
                      'list-group-item',
                      'd-flex',
                      'justify-content-between',
                      'align-items-center',
                      {
                        'active-list': chosenTemplateIndex === index,
                      },
                      {
                        'inactive-list': chosenTemplateIndex !== index,
                      },
                    ]"
                    @click="
                      () => {
                        chosenTemplateIndex = index;
                      }
                    "
                  >
                    <div
                      v-if="chosenTemplateIndex === index"
                      class="d-inline-block header"
                      style="padding-bottom: 0px !important"
                    >
                      <h2 style="font-size: 14px">
                        <strong>{{ template.name }}</strong>
                      </h2>
                    </div>
                    <div v-else class="d-inline-block">
                      {{ template.name }}
                    </div>
                    <div class="header-dropdown dropdown d-inline-block px-1">
                      <div v-if="activeTab !== 'invites'" class="dropdown">
                        <a data-toggle="dropdown" aria-haspopup="true">
                          <img
                            src="/assets/icons/dots-three-circle.svg"
                            height="20px"
                            width="20px"
                            style="margin-top: -1px"
                            alt="menu"
                          />
                        </a>
                        <ul class="dropdown-menu text-nowrap">
                          <li v-for="item in menuItems" :key="item">
                            <a
                              v-if="item === 'Clone template'"
                              @click="
                                () => {
                                  chosenTemplateIndex = index;
                                  cloneTemplate();
                                }
                              "
                            >
                              <span class="fa fa-copy mr-1"></span>
                              {{ item }}
                            </a>
                            <a
                              v-if="item === 'Delete template'"
                              @click="
                                () => {
                                  chosenTemplateIndex = index;
                                  removeTemplate();
                                }
                              "
                            >
                              <span class="fa fa-trash-o mr-1"></span>
                              {{ item }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <div
                    v-if="activeTab !== 'invites'"
                    class="align-right mt-2 mr-1"
                  >
                    <button
                      @click="addTemplate"
                      type="button"
                      class="btn btn-primary"
                      :title="
                        canCreateTemplate
                          ? activeTab === 'workflows'
                            ? 'Add Workflow Template'
                            : 'Add QC checklist'
                          : 'You reached the workflow templates limit for your workspace.'
                      "
                    >
                      <span class="sr-only">Add Workflow Template</span>
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </ul>
              </div>
              <div
                class="col-lg-9 col-md-9 d-flex flex-column"
                style="margin: 0px; padding: 0px"
              >
                <div
                  class="card"
                  v-for="(template, index) in selectedTabTemplates"
                  :key="index"
                  v-show="index == chosenTemplateIndex"
                >
                  <workflow-template-editor
                    :ref="`templateEditor_${index}`"
                    :template="template"
                    :activeTab="activeTab"
                    :qcTemplates="qualityControlChecklistTemplates"
                  />
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 d-flex justify-content-center">
                    <button
                      v-on:click="saveDetails"
                      :disabled="isSavingDetails || isSubscriptionReadOnly()"
                      class="btn btn-primary"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="body d-flex flex-column align-items-center"
              style="min-height: 400px"
              v-else-if="
                (workflowTemplates.length === 0 ||
                  qualityControlChecklistTemplates.length == 0) &&
                !removedWorkflowTemplates.length
              "
            >
              <p class="text-center">
                {{
                  activeTab === "workflows"
                    ? "You have no workflow templates so far"
                    : "You have no QC templates so far"
                }}.<br />
              </p>
              <button
                v-if="canCreateTemplate"
                @click="addTemplate"
                type="button"
                class="btn btn-primary mt-4"
                title="Add Template"
              >
                <i class="fa fa-plus"></i>
                <span>{{
                  activeTab === "workflows"
                    ? "Add Workflow Template"
                    : "Add QC Template"
                }}</span>
              </button>
            </div> -->
            <div
              v-else-if="showNoWorkflowTemplatesMessage"
              class="body d-flex flex-column align-items-center"
              style="min-height: 400px"
            >
              <p class="text-center">You have no workflow templates so far.</p>
              <button
                v-if="canCreateTemplate"
                @click="addTemplate"
                type="button"
                class="btn btn-primary mt-4"
                title="Add Workflow Template"
              >
                <i class="fa fa-plus"></i>
                <span>Add Workflow Template</span>
              </button>
            </div>
            <div
              v-else-if="showNoQCTemplatesMessage"
              class="body d-flex flex-column align-items-center"
              style="min-height: 400px"
            >
              <p class="text-center">You have no QC checklists so far.</p>
              <button
                v-if="canCreateTemplate"
                @click="addTemplate"
                type="button"
                class="btn btn-primary mt-4"
                title="Add QC Checklist"
              >
                <i class="fa fa-plus"></i>
                <span>Add QC checklist</span>
              </button>
            </div>
            <div v-else class="row mt-4">
              <div
                class="col d-flex justify-content-center"
                style="min-height: 400px"
              >
                <button
                  v-on:click="saveDetails"
                  :disabled="isSavingDetails || isSubscriptionReadOnly()"
                  class="btn btn-primary"
                  style="height: 35px"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import {
  getWorkflowTemplates,
  addWorkflowTemplate,
  updateWorkflowTemplate,
  deleteWorkflowTemplateById,
} from "../../apis/workflow-templates";
import WorkflowTemplateEditor from "../../components/ui/WorkflowTemplates/TemplateEditor.vue";
import {
  updateQCTemplate,
  addQCTemplate,
  deleteQCTemplateById,
} from "@/apis/qc-templates";

export default {
  name: "SettingsWorkflowTemplatesPage",
  components: {
    WrapperPage,
    WorkflowTemplateEditor,
  },
  data() {
    return {
      isLoadingTemplates: false,
      isSavingDetails: false,
      workflowTemplates: [],
      qcTemplates: [],
      removedWorkflowTemplates: [], // Keeps track of templates that were deleted.
      removedQCTemplates: [], // Keeps track of templates that were deleted.
      chosenTemplateIndex: 0,
      menuItems: ["Clone template", "Delete template"],
      activeTab: "workflows",
    };
  },
  computed: {
    showNoWorkflowTemplatesMessage() {
      return (
        this.activeTab === "workflows" &&
        this.workflowTemplates.length === 0 &&
        this.removedWorkflowTemplates.length === 0
      );
    },
    showNoQCTemplatesMessage() {
      return (
        this.activeTab === "qc_checklist" &&
        this.qualityControlChecklistTemplates.length === 0 &&
        this.removedQCTemplates.length === 0
      );
    },
    qualityControlChecklistTemplates() {
      return this.$store.getters.qcChecklistTemplates;
    },
    canCreateTemplate() {
      if (this.activeTab === "workflows")
        return this.$store.getters.workflowTemplatesRemaining !== 0;
      else return true;
    },
    currentTemplate() {
      if (this.selectedTabTemplates.length === 0) {
        return null;
      }
      return this.selectedTabTemplates[this.chosenTemplateIndex ?? 0];
    },
    selectedTabTemplates() {
      return this.activeTab === "workflows"
        ? this.workflowTemplates
        : this.qcTemplates;
    },
  },
  methods: {
    async loadData() {
      this.workflowTemplates = await getWorkflowTemplates();
      this.chosenTemplateIndex = 0;
    },
    setActiveTab(selectedTab) {
      this.activeTab = selectedTab;
      this.chosenTemplateIndex = 0;
    },
    addTemplate() {
      if (this.activeTab === "workflows") {
        this.workflowTemplates.push({
          name: "New Workflow",
          stages: [{ title: "Stage 0", revisions: 1 }],
        });
      } else {
        this.qcTemplates.push({
          name: "New QC Checklist",
          stages: [{ title: "New QC 0" }],
        });
      }
      this.chosenTemplateIndex =
        this.activeTab === "workflows"
          ? this.workflowTemplates.length - 1
          : this.qcTemplates.length - 1;
      window.nestable();
    },
    cloneTemplate() {
      let newTemplate = JSON.parse(JSON.stringify(this.currentTemplate));
      newTemplate.name += " Copy";
      delete newTemplate.id;

      if (this.activeTab === "workflows") {
        this.workflowTemplates.push(newTemplate);
        this.chosenTemplateIndex = this.workflowTemplates.length - 1;
      } else {
        this.qcTemplates.push(newTemplate);
        this.chosenTemplateIndex = this.qcTemplates.length - 1;
      }

      window.nestable();
    },
    removeTemplate() {
      let index = this.chosenTemplateIndex;
      let removedTemplate;

      if (this.activeTab === "workflows") {
        removedTemplate = this.workflowTemplates.splice(index, 1)[0];
        if (removedTemplate.id) {
          this.removedWorkflowTemplates.push(removedTemplate.id);
        }
      } else {
        removedTemplate = this.qcTemplates.splice(index, 1)[0];
        if (removedTemplate.id) {
          this.removedQCTemplates.push(removedTemplate.id);
        }
      }

      this.chosenTemplateIndex = Math.min(
        this.chosenTemplateIndex,
        this.activeTab === "workflows"
          ? this.workflowTemplates.length - 1
          : this.qcTemplates.length - 1
      );

      this.notifyWarn(
        "Template - " +
          removedTemplate.name +
          ' - will be deleted when you click on "Save Changes"',
        "Edit Templates"
      );
    },
    loadTemplate(index) {
      this.chosenTemplateIndex = index;
    },
    async saveDetails() {
      if (this.isSubscriptionReadOnly()) return;
      let updateTemplate;
      let addTemplate;
      let deleteTemplateById;
      if (this.activeTab === "workflows") {
        updateTemplate = updateWorkflowTemplate;
        addTemplate = addWorkflowTemplate;
        deleteTemplateById = deleteWorkflowTemplateById;
      } else {
        updateTemplate = updateQCTemplate;
        addTemplate = addQCTemplate;
        deleteTemplateById = deleteQCTemplateById;
      }

      this.isSavingDetails = true;

      try {
        // Delete removed templates
        const removedTemplateArray =
          this.activeTab === "workflows"
            ? this.removedWorkflowTemplates
            : this.removedQCTemplates;
        for (let u = 0; u < removedTemplateArray.length; u++) {
          await deleteTemplateById(removedTemplateArray[u]);
        }
        // Add new templates & Update others
        let promises = [];
        for (let t = 0; t < this.selectedTabTemplates.length; t++) {
          const template =
            this.$refs[`templateEditor_${t}`][0].getOrderedWorkflow();
          if ("id" in template) {
            promises.push(updateTemplate(template));
          } else {
            promises.push(addTemplate(template));
          }
        }
        await Promise.allSettled(promises);
        if (this.activeTab === "workflows") {
          this.loadData();
          this.notifySuccess("Workflow Template saved");
          this.removedWorkflowTemplates = [];
        } else {
          this.$store.dispatch("getQCChecklistTemplates");
          this.notifySuccess("QC Template saved");
          this.removedQCTemplates = [];
        }
      } catch (err) {
        this.notifyError(err, "Failed to save Templates");
      }
      this.isSavingDetails = false;
    },
  },
  watch: {
    chosenTemplateIndex: function () {
      document.getElementById("input-name").focus();
    },
    "$store.getters.qcChecklistTemplates": {
      handler(newVal) {
        this.qcTemplates = newVal;
      },
      immediate: true, // Trigger the watcher immediately after component creation
    },
  },
  created() {
    if (!this.$auth.user.isAgent) {
      this.$router.push("/projects/");
    }
  },
  async mounted() {
    if (
      !this.$auth.user.owner &&
      !this.$auth.user.admin &&
      !this.$auth.user.workflow_templates
    )
      return this.$router.push("/");

    this.loadData();
  },
};
</script>
<style scoped>
.active-list {
  background-color: #f7f8fa;
  border-bottom: 1px solid #dde4ea;
  border-left: 1px solid #dde4ea;
  border-right: 1px solid #dde4ea;
  margin-bottom: 0px;
  /* border-radius: 0.25rem; */
}
.inactive-list {
  margin-bottom: 0px;
  border-bottom: 1px solid #dde4ea;
  /* border-radius: 0.25rem; */
}
</style>
