<template>
  <div class="progress progress-striped">
    <div
      :class="classStyle"
      :data-transitiongoal="value"
      :aria-valuenow="value"
      role="progressbar"
      :style="`width: ${value}%; background-color: ${
        isProjectCancelled ? customColor : ''
      };`"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    showAsPercent: {
      type: Boolean,
      default: true,
    },
    color: String,
    isProjectCancelled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customColor() {
      return this.isProjectCancelled ? "#BAB9B9" : "";
    },
    text() {
      if (this.value == 0) return "";
      if (this.showAsPercent) return this.value + "%";
      return this.value;
    },
    classStyle() {
      let c = "progress-bar";
      if (this.color === "success") c += " progress-bar-success";
      if (this.color === "warning") c += " progress-bar-warning";
      if (this.color === "danger") c += " progress-bar-danger";

      return c;
    },
  },
};
</script>
