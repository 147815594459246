var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{class:[
          'table',
          'table-hover',
          'table-custom',
          'spacing8',
          'dataTable',
          _vm.identifier,
        ],attrs:{"id":_vm.identifier}},[_vm._m(0),_c('tbody',_vm._l((_vm.topClientRatings),function(clientRating,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'client-details',
                  params: { clientId: clientRating.clientId },
                },"title":clientRating.clientName}},[_vm._v(_vm._s(clientRating.clientName))])],1),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(clientRating.completedProjects)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(clientRating.projectCount)+" ")]),_c('td',{staticStyle:{"display":"flex"}},[_c('star-rating',{attrs:{"value":clientRating.averageRating / 5 || 0}}),_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(clientRating.averageRating)+" ")])],1)])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Client")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Completed Projects")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Rated Projects")]),_c('th',{staticStyle:{"padding-left":"20px"}},[_vm._v("Average Rating")])])])
}]

export { render, staticRenderFns }