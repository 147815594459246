const config = require("./config.json");
const { get, create, remove, update } = require("./common").default;

const emailSettingsUrl = config.apis.base.url + "email-settings/";

/**
 * Gets all the Email Settings for all domains regsitered for this Tenant
 * @returns Array of email settings objects
 */
exports.getEmailSettings = async () => {
  return await get(emailSettingsUrl);
};

/**
 * Adds the given domain to the Email Settings and fetches the DNS records that need to be added on the client's DNS
 * @param {string} domain domain name used in Agents' emails. Ex: mycompany.com
 * @returns Object with DNS entries to create
 */
exports.addEmailAuthenticationDomain = async (domain) => {
  return await create(emailSettingsUrl, { domain });
};
/**
 * Adds the given domain to the Email Settings and fetches the DNS records that need to be added on the client's DNS
 * @param {domain, send_from_own_email} domain domain name used in Agents' emails. Ex: mycompany.com. send_from_own_email: boolean
 */
exports.updateEmailDomainSettings = async ({ domain, send_from_own_email }) => {
  return await update(emailSettingsUrl + domain, {
    send_from_own_email,
  });
};

/**
 * After the user creates the DNS entries, they should notify & verify that they are correct with SendGrid
 * @param {string} domain
 * @returns true or false
 */
exports.verifyDomainDnsEntries = async (domain) => {
  return await create(emailSettingsUrl + domain + "/validate-email-domain", {});
};

/**
 * Removes the given domain from the Email Settings
 * @param {string} domain the email's domain to be removed from the Email Settings
 * @returns true or false
 */
exports.deleteEmailAuthenticationDomain = async (domain) => {
  return await remove(emailSettingsUrl + domain);
};
