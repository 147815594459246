<template>
  <div>
    <!-- Top Number cards -->
    <div class="row clearfix">
      <div :class="topRowCardClass">
        <div class="card">
          <div class="body top_counter">
            <div class="icon bg-warning text-white" :style="dynamicStyle">
              <i class="fa fa-spinner"></i>
            </div>
            <div class="content">
              <span style="display: block">Production progress</span>
              <div style="width: 15rem">
                <progress-bar
                  :value="productionProgress"
                  :color="getStatusColorObject.color[0]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="topRowCardClass">
        <div class="card">
          <div class="body top_counter">
            <div
              class="icon bg-warning text-white"
              style="background: #00e396 !important"
            >
              <i class="fa fa-calendar"></i>
            </div>
            <div class="content">
              <span>Total Production days</span>
              <h5 class="number mb-0">
                {{
                  totalProductionBreakdownInDays.totalInProductionDays > 1
                    ? `${totalProductionBreakdownInDays.totalInProductionDays} days`
                    : `${totalProductionBreakdownInDays.totalInProductionDays} day`
                }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div :class="topRowCardClass">
        <div class="card">
          <div class="body top_counter">
            <div
              class="icon bg-warning text-white"
              style="background: #00e396 !important"
            >
              <i class="fa fa-clock-o"></i>
            </div>
            <div class="content">
              <span>Project duration</span>
              <h5 class="number mb-0">
                {{
                  getProjectBusinessDayDuration > 1
                    ? `${getProjectBusinessDayDuration} days`
                    : `${getProjectBusinessDayDuration} day`
                }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div :class="topRowCardClass" v-if="hasClientSatisfaction">
        <div
          class="card"
          :class="{ disabledComponent: !isCsatSurveyAvailable }"
        >
          <div class="body p-2 topRow">
            <div class="header">
              <h2><small>Client satisfaction</small></h2>
            </div>
            <div class="body p-3 align-center">
              <template v-if="isCsatSurveyAvailable">
                <p class="align-center mb-0">coming soon</p>
                <star-rating :value="overallCustomerSatisfaction"></star-rating>
              </template>
              <p v-else class="align-center mb-0">
                data not available for this version
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Middle row -->
    <div class="row clearfix">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="header">
            <h2>Revision rounds: Agreed vs Executed</h2>
          </div>
          <div class="body p-3">
            <StageProductionRound
              :stageProductionDurationGraphData="
                getStageProductionDurationGraphData
              "
            ></StageProductionRound>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="header">
            <h2>{{ "" }}</h2>
          </div>
          <div
            class="body p-3"
            style="border: none; background: #f7f9fb !important"
          >
            <div class="row clearfix">
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #008ffb !important"
                    >
                      <i class="fa fa icon-layers"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle">Total revisions allowed</span>
                      <h5 class="number mb-0">
                        {{ projectStats.agreed_revisions }} rounds
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #00e396 !important"
                    >
                      <i class="fa fa icon-layers"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle">Total executed revisions</span>
                      <h5 class="number mb-0">
                        {{ projectStats.executed_revisions }} rounds
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #feb019 !important"
                    >
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle"
                        >Average client feedback time</span
                      >
                      <h5 class="number mb-0">
                        {{
                          averageClientFeedbackTime > 1
                            ? `${averageClientFeedbackTime} days`
                            : `${averageClientFeedbackTime} day`
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #feb019 !important"
                    >
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle"
                        >Email Engagement Response Time</span
                      >
                      <h5 class="number mb-0" style="text-wrap: nowrap">
                        {{ totalProjectAverageReviewResponseTime }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #ff4560 !important"
                    >
                      <i class="fa fa-calendar"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle">Total revision days</span>
                      <h5 class="number mb-0">
                        {{ totalProductionBreakdownInDays.totalRevisionDays }}
                        days
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="card">
                  <div class="body top_counter">
                    <div
                      class="icon bg-warning text-white"
                      style="background: #feb019 !important"
                    >
                      <i class="fa fa-bell"></i>
                    </div>
                    <div class="content">
                      <span class="cardTitle">Feedback reminders sent</span>
                      <h5 class="number mb-0">
                        {{
                          feedbackRemindersSent > 1
                            ? `${feedbackRemindersSent} reminders`
                            : `${feedbackRemindersSent} reminders`
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="header">
            <h2>Stage duration</h2>
          </div>
          <div class="body p-3">
            <StageDurationChart
              :tasks="getStageNameAndOpenDuration"
            ></StageDurationChart>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="header">
          <h2>Project days breakdown</h2>
        </div>
        <div class="body p-3">
          <DurationBreakdownChart
            :totalProductionBreakdowns="totalProductionBreakdownInDays"
            :isLinear="workflow.is_linear"
          ></DurationBreakdownChart>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="header">
            <h2>Planned vs Executed production timeline</h2>
          </div>
          <div class="body p-3">
            <ProjectTimelineChart :workflow="workflow"></ProjectTimelineChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProjectStats,
  getProjectAverageReviewResponseTime,
} from "../../apis/projects";
// import ProgressChart from "../ui/Dashboard/ProgressChart.vue";
import StageProductionRound from "../ui/Dashboard/StageProductionRound.vue";
import StageDurationChart from "../ui/Dashboard/StageDurationChart.vue";
import ProjectTimelineChart from "../ui/Dashboard/ProjectTimelineChart.vue";
import DurationBreakdownChart from "../ui/Dashboard/DurationBreakdownChart.vue";
import StarRating from "../ui/Dashboard/StarRating.vue";
import workflowMixin from "../../mixins/workflow";
import analyticsMixin from "../../mixins/analytics";
import timeMixin from "../../mixins/time";
import ProgressBar from "../ui/ProgressBar.vue";
import { isWorkspaceProfessionalLevel } from "@/utils/helper";

export default {
  name: "ProjectAnalytics",
  components: {
    // ProgressChart,
    StarRating,
    StageProductionRound,
    StageDurationChart,
    ProjectTimelineChart,
    DurationBreakdownChart,
    ProgressBar,
  },
  mixins: [workflowMixin, timeMixin, analyticsMixin],
  props: {
    workflow: Object,
    project: Object,
  },
  data() {
    return {
      isLoadingStats: true,
      projectStats: {},
      stageProductionDurationGraphData: {},
      stageNameAndOpenDuration: [],
      projectAverageReviewResponseTime: undefined,
    };
  },
  computed: {
    isCsatSurveyAvailable() {
      return isWorkspaceProfessionalLevel(this.$auth.tenant.subscription);
    },
    dynamicStyle() {
      return {
        backgroundColor: `${this.getStatusColorObject.color[1]} !important`,
      };
    },
    topRevisionRounds() {
      if (!this.workflow?.tasks) return;
      let arr = [...this.workflow.tasks];
      // Sort descending
      return arr
        .sort((a, b) => b.revisionRoundsProgress - a.revisionRoundsProgress)
        .slice(0, 3);
    },
    hasClientSatisfaction() {
      // TODO
      return false;
    },
    topRowCardClass() {
      return "col-lg-4 col-md-6 col-sm-12";
    },

    productionProgress() {
      return this.getProductionProgress(
        this.workflow,
        this.project?.is_survey_sent,
        this.project?.send_csat_survey
      );
    },

    averageClientFeedbackTime() {
      return Math.round(
        (this.projectStats.average_awaiting_feedback_time || 0) / 24
      );
    },
    clientFeedbackAllowedTime_FooterText() {
      if (!this.project) return null;

      return {
        footerString: "Allowed time",
        days: this.project.days_allowed_for_client_feedback,
      };
    },

    feedbackRemindersSent() {
      return this.projectStats.feedback_reminders_sent || "No";
    },
    mostRemindedStages_FooterText() {
      if (!this.projectStats?.most_reminded_stage) return null;
      return {
        footerString: "Most sent",
        days: this.projectStats.most_reminded_stage,
      };
    },

    overallCustomerSatisfaction() {
      // TODO
      return 0.7;
    },
    getStatusColorObject() {
      const statusText = this.getProjectStatus(this.project);
      return this.getProjectStatusWithColorCode(statusText);
    },
    getStageProductionDurationGraphData() {
      return this.stageProductionDurationGraphData;
    },
    getStageNameAndOpenDuration() {
      return this.stageNameAndOpenDuration;
    },
    totalProductionBreakdownInDays() {
      return this.productionBreakdownInDays(this.workflow?.tasks, this.project);
    },
    totalProjectAverageReviewResponseTime() {
      return this.projectAverageReviewResponseTime;
    },
    getProjectBusinessDayDuration() {
      return this.getProjectDuration(this.workflow, this.project);
    },
  },
  methods: {
    convertDecimalToDuration(decimalDays) {
      const days = Math.floor(decimalDays);
      const decimalHours = (decimalDays - days) * 24;
      const hours = Math.floor(decimalHours);

      if (days > 0 && hours > 0) {
        return `${days} day(s) ${hours} hour(s)`;
      } else if (days > 0) {
        return `${days} day(s)`;
      } else if (hours > 0) {
        return `${hours} hour(s)`;
      } else if (decimalDays > 0) {
        return "1 hour"; // Display minimum duration of 1 hour if average time is less than one hour
      } else {
        return "0 day(s)";
      }
    },
    getProjectStatus() {
      this.project.workflow = this.project.workflow || this.workflow;
      const projectStatus = this.cellText_ProjectStatus(this.project);
      return projectStatus;
    },
    /**
     * Calculate stage progress for some cards
     */
    calculateRevisionRoundsProgressPerStage() {
      if (!this.workflow?.tasks?.length) return;

      this.workflow.tasks.forEach((stage) => {
        stage.executedRevisions = this.getExecutedRevisionsInStage(stage);
        stage.agreedRevisions = this.getAgreedRevisionsInStage(stage);

        stage.revisionRoundsProgress = stage?.revisionsWithoutPreview?.length
          ? (stage.executedRevisions / stage.revisionsWithoutPreview.length) *
            100
          : -1;
      });
    },
    loadStageProductionDurationGraph() {
      const budgeted = [
        "budgeted",
        ...this.workflow.tasks.map((stage) => stage.agreedRevisions),
      ];
      const executed = [
        "executed",
        ...this.workflow.tasks.map((stage) => stage.executedRevisions),
      ];
      const categories = this.workflow.tasks.map((stage) => stage.title); // Stage Names
      const options = {
        budgeted,
        executed,
        categories,
      };
      this.stageProductionDurationGraphData = options;
    },
    async fetchProjectStats() {
      this.isLoadingStats = true;

      try {
        this.projectStats = await getProjectStats(this.project.id);
        getProjectAverageReviewResponseTime(this.project.id).then(
          (response) => {
            this.projectAverageReviewResponseTime =
              this.convertDecimalToDuration(response?.averageWaitTime);
          },
          (error) => {
            console.log("Error fetching average review response time:", error);
          }
        );
        this.calculateRevisionRoundsProgressPerStage();
        this.loadStageProductionDurationGraph();
        this.calculateOpenDurationOfEachStage(this.workflow);
      } finally {
        this.isLoadingStats = false;
      }
    },
  },
  beforeMount() {
    // If we don't have the project and workflow set already here, then we need to 'watch' these 2 properties
    this.fetchProjectStats();
    this.calculateRevisionRoundsProgressPerStage();
  },
};
</script>

<style>
.card .body .topRow {
  height: 180px;
}
.c3 svg {
  width: 100% !important;
}
.cardTitle {
  font-size: 12px;
}
.number {
  font-size: 1.2rem;
}
</style>
