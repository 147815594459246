import Vue from "vue";
const eventBus = new Vue();

export const channels = {
  upgradeSubscription: "upgrade-subscription",
  fetchProject: "fetch-project",
  refreshProject: "refresh-project",
  fetchTimeline: "fetch-timeline",
  fetchAllSurveyWithFeedback: "fetch-all-survey-with-feedback",
  fetchAllSurveyStatus: "fetch-all-survey-status",
  addDeliverable: "add-deliverable",
  updateDeliverable: "update-deliverable",
  deleteDeliverable: "delete-deliverable",
  saveDeliverable: "save-deliverable",
  sendDeliverable: "send-deliverable",
};

export default eventBus;
