<template>
  <wrapper-page :ignoreSubscriptionStatus="true">
    <add-payment-method />
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import AddPaymentMethod from "../../components/subscriptions/AddPaymentMethod.vue";

export default {
  name: "AddPaymentMethodPage",
  components: { WrapperPage, AddPaymentMethod },
  data() {
    return {};
  },
  mounted() {
    if (!this.$auth.user.owner) this.$router.push("/");
  },
};
</script>
