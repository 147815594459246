<template>
  <Modal
    :title="title"
    :show-close-button="false"
    @submit="$emit('submit', undoLastActionReason)"
    @close="$emit('close')"
    cancel-text="Close"
    submit-text="Save"
    :close-on-outside-click="true"
    :disable-submit-button="disableSubmitButton || isSubscriptionReadOnly()"
  >
    <div class="container">
      <form-input-group
        v-model="undoLastActionReason"
        :field="getActionField"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import FormInputGroup from "@/components/ui/FormInputGroup.vue";

export default {
  name: "UndoLastActionConfirmModal",
  components: { FormInputGroup, Modal },
  props: {
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Undo last action",
    },
    isReopeningStage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      undoLastActionField: {
        label: "Re-opening reason",
        type: "select",
        options: [
          {
            value: "mistake",
            text: "Stage opened by mistake",
          },
          {
            value: "misunderstood",
            text: "Client feedback misunderstood",
          },
          {
            value: "incomplete",
            text: "Client feedback not completed",
          },
          {
            value: "other",
            text: "Other",
          },
        ],
      },
      reopenStageActionField: {
        label: "Re-opening reason",
        type: "select",
        options: [
          {
            value: "mistake",
            text: "Incorrectly marked as complete",
          },
          {
            value: "request",
            text: "Changes requested by client",
          },
          {
            value: "misunderstood",
            text: "Client feedback misunderstood",
          },
          {
            value: "incomplete",
            text: "Client feedback not completed",
          },
          {
            value: "other",
            text: "Other",
          },
        ],
      },
      undoLastActionReason: "mistake",
    };
  },
  computed: {
    getActionField() {
      if (this.isReopeningStage) {
        return this.reopenStageActionField;
      } else {
        return this.undoLastActionField;
      }
    },
  },
};
</script>
