<template>
  <div
    :class="{
      'body d-flex flex-column': true,
      disabledComponent: isProjectCancelled || isArchived,
    }"
    style="padding-top: 10px; border: none !important"
  >
    <button
      v-if="$auth.user.isAgent"
      style="margin-bottom: 25px"
      type="button"
      class="btn btn-primary ml-auto"
      title="Add Note"
      data-toggle="modal"
      data-target=".feed-note-editor-modal"
      @click="onAddNoteClick"
      :disabled="isSubscriptionReadOnly()"
    >
      <i class="fa fa-plus"></i>
      <span class="ml-2">Add note</span>
    </button>
    <div class="d-flex flex-column" style="width: 100%; max-width: 900px">
      <NotesTemplate
        v-for="(note, index) in notes"
        :key="index"
        :selected-notes="note"
        :isDeleteButtonVisible="true"
        :alwaysScrollable="false"
        @delete="onDeleteClick"
        style="
          padding-bottom: 32px;
          margin-bottom: 44px;
          border-bottom: 1px solid #dde4ea;
        "
      >
        <template v-if="$auth.user.isAgent" #icon>
          <a
            href="#"
            class="mr-2 border-bottom custom-icon-color"
            data-toggle="modal"
            data-target=".feed-note-editor-modal"
            @click="onEditNoteClick(note)"
            ><i class="icon-pencil icon-custom-size"></i
          ></a>
          <a
            href="#"
            v-if="note.visible_to_client"
            class="mr-2 custom-icon-color"
            @click="toggleVisibility($event, note)"
            ><i class="icon-eye icon-custom-size"></i
          ></a>
          <a
            href="#"
            v-else
            class="mr-2 custom-icon-color"
            @click="toggleVisibility($event, note)"
            ><i class="fa fa-eye-slash icon-custom-size"></i
          ></a>
        </template>
      </NotesTemplate>
    </div>

    <NotesEditorModel
      v-if="isShowNotesEditorModel"
      :notesData="notesData"
      :title="title"
      :timeline="timeline"
      :action="action"
      :selectedStage="selectedStage"
      @close="isShowNotesEditorModel = false"
      @save="onSaveNote"
    />
  </div>
</template>

<script>
import NotesTemplate from "./NotesTemplate.vue";
import NotesEditorModel from "./NotesEditorModel.vue";
import {
  addNotes,
  getNotes,
  deleteNote,
  updateNote,
} from "../../../apis/notes.js";
import notesFormatterMixin from "../../../mixins/notesFormatter";

export default {
  components: {
    NotesTemplate,
    NotesEditorModel,
  },
  name: "NotesTab",
  mixins: [notesFormatterMixin],
  props: {
    timeline: {
      type: Object,
      default: null,
    },
    project_id: {
      type: Number,
      default: null,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    project: Object,
  },
  data() {
    return {
      chosenNotesIndex: 0,
      isShowNotesEditorModel: false,
      isHide: false,
      title: "Add note",
      editorText: "",
      action: "add",
      selectedStage: {},
    };
  },
  computed: {
    isProjectCancelled() {
      return this.project.cancelled;
    },
    isArchived() {
      return Boolean(this.project.archived);
    },
    selectedNotes() {
      return this.notes[this.chosenNotesIndex];
    },
  },
  methods: {
    updateSelectedStage(notesData) {
      this.selectedStage =
        this.timeline?.tasks?.find((task) => task.id === notesData.stage_id) ||
        {};
    },
    async onDeleteClick(selectedNote) {
      const projectId = selectedNote.project_id;
      const noteId = selectedNote.id;

      try {
        await deleteNote(projectId, noteId);
        this.$emit("delete", selectedNote.id);
        this.notifySuccess("Note deleted");
        // this.notes = this.notes.filter((note) => note.id !== selectedNote.id);
      } catch (error) {
        this.notifyError(error, "Error deleting note");
      }
    },
    toggleVisibility(event, note) {
      event.preventDefault();
      const selectedNote = this.notes.find((noteObj) => noteObj.id === note.id);
      if (selectedNote) {
        selectedNote.visible_to_client = !selectedNote.visible_to_client;
        if (selectedNote.visible_to_client === false) {
          selectedNote.notify_client = false;
        }
        const payload = {
          body: selectedNote.body,
          stage_id: selectedNote?.stage_id,
          visible: {
            client: selectedNote.visible_to_client,
            agency: selectedNote.visible_to_agency,
          },
          notify: {
            client: selectedNote.notify_client,
            agency: selectedNote.notify_agency,
          },
        };
        updateNote(this.project_id, selectedNote.id, payload)
          .then(() => {
            this.notifySuccess("Note visibility updated");
          })
          .catch((error) => {
            this.notifyError(error, "Error updating note visibility");
            // Rollback the change
            selectedNote.visible_to_client = !selectedNote.visible_to_client;
          });
      }
    },
    onEditNoteClick(notesData) {
      this.title = "Edit note";
      this.action = "edit";
      this.notesData = notesData;
      this.updateSelectedStage(notesData);
      this.isShowNotesEditorModel = true;
    },
    onAddNoteClick() {
      this.editorText = "";
      this.notesData = undefined;
      this.title = "Add note";
      this.action = "add";
      this.selectedStage = {};
      this.isShowNotesEditorModel = true;
    },
    async onSaveNote({ payload, action, id, project_id }) {
      if (action === "edit") {
        try {
          const updatedNote = await updateNote(project_id, id, payload);
          const targetNoteIndex = this.notes.findIndex(
            (note) => note.id === id
          );
          if (targetNoteIndex !== -1) {
            this.formatNotes(updatedNote, this.timeline);
            this.$emit("update", targetNoteIndex, updatedNote);
            this.notifySuccess("Note updated");
          } else {
            this.notifyError("Note not found", "Error updating note");
          }
        } catch (error) {
          this.notifyError(error, "Error updating note");
        }
      } else {
        try {
          const newNote = await addNotes(this.project_id, payload);
          this.formatNotes(newNote, this.timeline);
          this.$emit("add", newNote);
          this.notifySuccess("Note added");
        } catch (error) {
          this.notifyError(error, "Error adding note");
        }
      }
      this.isShowNotesEditorModel = false;
    },
    async getAllNotes(projectId) {
      try {
        const allnotes = await getNotes(projectId);
        allnotes.forEach((element) => {
          this.formatNotes(element, this.timeline);
        });
        this.notes = allnotes;
      } catch (error) {
        this.notifyError(error, "Error fetching notes");
      }
    },
  },
};
</script>

<style>
.custom-icon-color {
  color: gray;
}
</style>
