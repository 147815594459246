import axios from "axios";

export default {
  // config,
  get: async (url, config) => {
    return axios.get(url, config).then((response) => response.data);
  },

  create: async (url, data) => {
    return axios.post(url, data).then((response) => response.data);
  },

  update: async (url, data) => {
    return axios.put(url, data).then((response) => response.data);
  },

  remove: async (url) => {
    return axios.delete(url).then((response) => response.data);
  },

  fileUpload: async (url, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { "Content-Type": "multipart/form-data" };
    return axios
      .post(url, formData, { headers })
      .then((response) => response.data);
  },
};
