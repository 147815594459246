<template>
  <ApexChart
    type="rangeBar"
    :chartHeight="chartHeight"
    :chartData="chartData"
  ></ApexChart>
</template>

<script>
import ApexChart from "./ApexChart.vue";
import timeMixin from "../../../mixins/time";

export default {
  name: "ProjectTimelineChart",
  mixins: [timeMixin],
  components: {
    ApexChart,
  },
  props: {
    workflow: Object,
  },
  data() {
    return {
      chartHeight: 350,
    };
  },
  computed: {
    chartData() {
      const series = this.generateSeries(this.workflow?.tasks);
      const chartOptions = this.generateChartOptions();
      return {
        series: series,
        chartOptions: chartOptions,
      };
    },
  },
  methods: {
    generateSeries(tasks) {
      let series = [];

      // Iterate through tasks and create series data dynamically
      let seriesData1 = [];
      tasks?.forEach((task) => {
        if (task.status) {
          // If target_date is provided, I'm creating a 12-hour range to represent a line in the chart.
          // If target_date is not given, the range will be zero as both the start and end dates are initialized with the same date.
          // Therefore, the "planned" data won't be visible in the chart.
          let startedOn = new Date();
          let approvedOn = new Date();
          if (task.target_date) {
            startedOn = new Date(task.target_date);
            approvedOn = new Date(startedOn.getTime() + 12 * 60 * 60 * 1000);
          }
          startedOn = startedOn.getTime();
          approvedOn = approvedOn.getTime();
          let seriesItem = {
            x: task.title,
            y: [startedOn, approvedOn],
            fillColor: task.target_date ? "#3498db" : "#808080", // You can create a function to generate random colors
          };
          // Add the series data object to the seriesData array
          seriesData1.push(seriesItem);
        }
      });
      const seriesObj1 = {
        name: "Planned",
        data: seriesData1,
      };
      series.push(seriesObj1);

      const seriesData2 = [];
      tasks?.forEach((task) => {
        if (task.status) {
          let startedOn = task.started_on
            ? new Date(task.started_on).getTime()
            : null;
          let approvedOn = task.approved_on
            ? new Date(task.approved_on).getTime()
            : null;
          if (!startedOn && approvedOn) {
            startedOn = approvedOn - 12 * 60 * 60 * 1000; // Setting startedOn to an hour before approvedOn
          } else if (startedOn && !approvedOn) {
            approvedOn = new Date().getTime();
          }

          // Create series data object
          let seriesItem = {
            x: task.title,
            y: [startedOn, approvedOn],
            fillColor: "#2ecc71", // You can create a function to generate random colors
          };

          // Add the series data object to the seriesData array
          seriesData2.push(seriesItem);
        }
      });
      const seriesObj2 = {
        name: "Executed",
        data: seriesData2,
      };
      series.push(seriesObj2);

      // Return the seriesData array containing dynamic series data
      return series;
    },
    generateChartOptions() {
      return {
        chart: {
          height: 350,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            // let label = opts.w.globals.labels[opts.dataPointIndex];
            let a = new Date(val[0]);
            let b = new Date(val[1]);
            // let diffInMilliseconds = Math.abs(b - a);
            // let diffInDays = Math.ceil(
            //   diffInMilliseconds / (1000 * 60 * 60 * 24)
            // );
            let diffInDays = this.calculateDaysDifference(a, b, true, false);
            if (diffInDays < 1) {
              return "";
            } else {
              diffInDays = Math.round(diffInDays * 10) / 10; // Round to nearest tenth
              return diffInDays + (diffInDays > 1 ? " days" : " day");
            }
          },

          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          show: true,
        },
        grid: {
          row: {
            colors: ["#f3f4f5", "#fff"],
            opacity: 1,
          },
        },
        legend: {
          position: "bottom",
          colors: ["#fff", "#f3f4f5"],
        },
        colors: ["#3498db", "#2ecc71", "#808080"],
      };
    },
  },
};
</script>
<style></style>
