<template>
  <wrapper-page>
    <div class="row clearfix">
      <div class="card">
        <div id="card-element" class="body">
          You have {{ appsumoCodes.length }} AppSumo code{{
            appsumoCodes.length > 1 ? "s" : ""
          }}
          <div v-if="this.appsumoCodes.length > 0">
            {{
              appsumoCodes.length > 1 ? "These codes allow" : "This code allows"
            }}
            up to {{ allowedUsers }} Users with <em>Unlimited</em>; Active and
            Archived Projects, Workflows, Email templates and Clients.
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <form-input-group :field="field" v-model="code" />
        <button class="btn btn-primary mt-1" @click="addCode">
          Redeem Code
        </button>
      </div>
    </div>
  </wrapper-page>
</template>

<script>
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputGroup from "../../components/ui/FormInputGroup.vue";
import { addAppSumoCode, getSubscriptions } from "../../apis/subscriptions";

export default {
  name: "AppSumoPage",
  components: {
    WrapperPage,
    FormInputGroup,
  },
  data() {
    return {
      appsumoCodes: [], // List of AppSumo codes owned by current user
      allowedUsers: 0, // Number of users covered by the AppSumo subscription
      code: "",
      field: { label: "Redeem codes", name: "code" },
    };
  },
  methods: {
    async addCode() {
      try {
        let updateSubs = await addAppSumoCode(this.code);
        this.loadSubscriptionProperties(updateSubs);
      } catch (err) {
        this.notifyError(err, "Error adding code");
      }
    },
    loadSubscriptionProperties(subscription) {
      if (!subscription?.details) return;
      const details = JSON.parse(subscription.details);
      if (details.provider !== "appsumo") return;

      const items = JSON.parse(subscription.details).items;
      let codes = [];
      let users = 0;
      items.forEach((item) => {
        codes.push(item.appsumo_code);
        users += Number(item.users) || 0;
      });
      this.appsumoCodes = codes;
      this.allowedUsers = users;
    },
  },
  mounted() {
    if (!this.$auth.user.owner) this.$router.push("/");

    getSubscriptions().then((subs) => this.loadSubscriptionProperties(subs[0]));
  },
};
</script>
